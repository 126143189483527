import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {getShareLink} from "Features/scenes/scenesSlice";
import useAccessToken from "Features/auth/useAccessToken";

export default function useShareLink({sceneId, scopeId, propDependency}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const entityId = scopeId || sceneId;

  const shareLinkMap = useSelector((s) => s.scenes.shareLinkMap);

  const shareLink = shareLinkMap[entityId];

  useEffect(() => {
    if (accessToken && sceneId && !shareLink) {
      dispatch(getShareLink({sceneId, scopeId, accessToken}));
    }
  }, [accessToken, sceneId, scopeId, propDependency, shareLink]);

  return shareLink;
}
