import {useDispatch, useSelector} from "react-redux";

import {
  Box,
  Paper,
  IconButton,
  // Tooltip,
  Typography,
} from "@mui/material";
import {
  ArrowDropDown as Down,
  Error as Issues,
  Home,
  Refresh,
} from "@mui/icons-material";

import {
  setLeftPanelSelection,
  setShowLeftSelectionInViewer,
} from "../leftPanelSlice";

import useSceneModule from "Features/navigation/useSceneModule";
import useSceneModuleNameMap from "Features/navigation/useSceneModuleNameMap";
import IconModuleInLeftPanel from "Features/ui/components/IconModuleInLeftPanel";

import {
  setScopeSelectionMode,
  setScopeInputId,
  setSectorsInScope,
  setMeasurementsModelsInScope,
  setRoomsInScope,
  setFilters,
  setSelectedRessourcesInDatagrid,
  setShortcutElementTypes,
  setSelectedMeasurementIds,
} from "Features/measurements/measurementsSlice";
import {setBuildingInScope} from "Features/buildings/buildingsSlice";
import {setSelectedIssueId} from "Features/issues/issuesSlice";
import {
  setSelectedIssueId as setSelectedIssueIdInModule,
  setShortcutToolViewer3D,
} from "Features/ui/uiSlice";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import {setShortcutElevationZ} from "Features/sectors/sectorsSlice";
import {setSelectedRessourceId} from "Features/ressources/ressourcesSlice";
import {setSelectedZoneId} from "Features/zones/zonesSlice";
import {setSelectedShortcutRight} from "Features/shortcut/shortcutSlice";
import {setSelectedPhaseIds} from "Features/phases/phasesSlice";

import {fetchRemoteScene} from "Features/scenes/scenesSlice";
import useAccessToken from "Features/auth/useAccessToken";

import {
  fetchArticlesGroups,
  fetchArticles,
} from "Features/articles/articlesSlice";
import ButtonSyncArticles from "Features/articles/components/ButtonSyncArticles";

// import SelectorShowHelpers from "Features/helpers3d/components/SelectorShowHelpers";

export default function HeaderInLeftPanel({scene, caplaEditor, viewer}) {
  const dispatch = useDispatch();

  const accessToken = useAccessToken();
  const sceneId = scene?.id;

  // data

  const sceneModule = useSceneModule();
  const namesMap = useSceneModuleNameMap();

  const types = useElementTypesBySceneProxy(scene);

  const defaultScope = useSelector(
    (s) => s.measurements.scopeDefaultSelectionMode
  );

  const selectedArticlesGroupId = useSelector(
    (s) => s.articles.selectedArticlesGroupId
  );

  // handlers

  function handleReset() {
    console.log("[click] reset from header");
    if (sceneModule === "RESSOURCES") {
      dispatch(fetchRemoteScene({sceneId, accessToken}));
    } else if (sceneModule === "ARTICLES") {
      dispatch(fetchArticlesGroups({sceneId, accessToken}));
      if (selectedArticlesGroupId)
        dispatch(
          fetchArticles({
            sceneId,
            articlesGroupId: selectedArticlesGroupId,
            accessToken,
          })
        );
    } else {
      dispatch(setScopeSelectionMode(defaultScope));
      dispatch(setScopeInputId(null));
      dispatch(setSectorsInScope([]));
      dispatch(setRoomsInScope([]));
      dispatch(setBuildingInScope(null));
      dispatch(setMeasurementsModelsInScope([]));
      dispatch(setFilters({reset: true}));
      dispatch(setShowLeftSelectionInViewer(false));
      dispatch(setLeftPanelSelection({}));
      dispatch(setSelectedRessourcesInDatagrid([]));
      dispatch(setSelectedRessourceId(null));
      dispatch(setSelectedIssueId(null));
      dispatch(setSelectedIssueIdInModule(null));
      dispatch(setShortcutElementTypes(types.map((t) => t.id)));
      dispatch(setShortcutElevationZ([]));
      dispatch(setSelectedPhaseIds([]));
      dispatch(setSelectedMeasurementIds([]));
      dispatch(setSelectedZoneId(null));
      dispatch(setShortcutToolViewer3D(null));
      dispatch(setSelectedShortcutRight(null));
      caplaEditor?.editor3d.sceneEditor.hideAllImageModels();
      if (caplaEditor?.openSections.fixedViewersBox)
        caplaEditor.editorPdf.webViewer.Core.annotationManager.deselectAllAnnotations();
      if (caplaEditor?.openSections.viewer3D)
        caplaEditor?.editor3d.sceneEditor.unselect();
    }
  }

  return (
    <Box sx={{p: 1, display: "flex", width: 1}}>
      <Paper
        elevation={12}
        sx={{
          p: 1,
          width: 1,
          height: (theme) => theme.spacing(5),
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <IconModuleInLeftPanel scene={scene} />

        <Typography sx={{fontWeight: "bold", fontSize: 15}}>
          {namesMap[sceneModule]}
        </Typography>

        {sceneModule === "ARTICLES" && <ButtonSyncArticles scene={scene} />}

        {sceneModule !== "ARTICLES" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <IconButton onClick={handleReset}>
              <Refresh fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Paper>
    </Box>
  );
}
