import {useState} from "react";

import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import useAccessToken from "Features/auth/useAccessToken";
import {deleteRemoteScene, deleteScene} from "Features/scenes/scenesSlice";
import {deleteModel} from "Features/viewer3D/viewer3DSlice";
import {deleteStoredModelsByScene} from "Database/models/services";
import {setSelectedSceneClientId} from "Features/scenes/scenesSlice";

import DeleteDialog from "Features/ui/components/DeleteDialog";

export default function DialogDeleteScene({scene, open, onClose}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useAccessToken();

  // state

  const [loading, setLoading] = useState(false);

  // data

  const models = useSelector((s) => s.viewer3D.models).filter(
    (m) => m.sceneClientId === scene?.clientId
  );

  // handler

  function handleClose() {
    onClose();
    setLoading(false);
  }
  async function handleDeleteConfirm() {
    if (loading) return;
    setLoading(true);
    try {
      if (scene?.id) {
        await dispatch(deleteRemoteScene({scene, accessToken}));
      }
      await dispatch(deleteScene(scene));
      for (const model of models) {
        await dispatch(deleteModel({model}));
      }
      await deleteStoredModelsByScene(scene?.clientId);
      dispatch(setSelectedSceneClientId(null));
      navigate("/");
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
    onClose();
  }

  return (
    <DeleteDialog
      open={open}
      onCancel={handleClose}
      onConfirm={handleDeleteConfirm}
      ressource="scene"
      loading={loading}
    />
  );
}
