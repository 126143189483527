import {useEffect, useState} from "react";

import {useDispatch, useSelector} from "react-redux";

import {updateModelNotAsync} from "Features/viewer3D/viewer3DSlice";

import {Box} from "@mui/material";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import SelectorAutocomplete from "Components/SelectorAutocomplete";
import modelLoadingStatusMap from "Features/overviewer/modelLoadingStatusMap";

export default function SectionPackageSectorId({scene, model}) {
  const dispatch = useDispatch();

  // strings

  const sectorS = "Secteur";

  // data

  const sectors = useSectorsByScene(scene);

  // helper - options

  const options = sectors
    .map((sector) => ({
      ...sector,
      name: sector.building
        ? `${sector.building} • ${sector.name}`
        : sector.name,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  const optionsMap = options.reduce((acc, sector) => {
    acc[sector.id] = sector;
    return acc;
  }, {});

  // helper - selectedSector

  const sectorId = model?.sectorId;
  const selectedSector = sectorId ? optionsMap[sectorId] : null;

  // state

  const [value, setValue] = useState(selectedSector);
  // effect init

  useEffect(() => {
    setValue(selectedSector);
  }, [model?.id]);

  // handlers

  function handleChange(newSector) {
    const updates = {id: model?.id, sectorId: newSector?.id};
    dispatch(updateModelNotAsync({updates, sync: true}));
  }

  return (
    <Box sx={{width: 1, p: 1}}>
      <SelectorAutocomplete
        name={sectorS}
        value={selectedSector}
        options={options}
        onChange={handleChange}
      />
    </Box>
  );
}
