import {useDispatch} from "react-redux";

import {deleteMaterial} from "../materialsSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function useDeleteMaterial() {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  const deleteGroup = async (material) => {
    await dispatch(
      deleteMaterial({
        accessToken,
        material,
      })
    );
  };

  return deleteGroup;
}
