import {useSelector, useDispatch} from "react-redux";

import {Share, People} from "@mui/icons-material";

export default function useActionsBoxHomeMap({scene, caplaEditor}) {
  const dispatch = useDispatch();

  // handlers

  // actions map

  const actionsMap = {
    shareSceneScope: {
      icon: <Share fontSize="small" />,
      name: "Partager",
      label: "Partager",
    },
    editMemberships: {
      icon: <People fontSize="small" />,
      name: "Ajouter des membres",
      label: "Ajouter des membres",
    },
    deleteScene: {
      name: "Supprimer",
      label: "Supprimer la bimbox",
    },
  };

  return actionsMap;
}
