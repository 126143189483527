import {useSelector} from "react-redux";

export default function useReportsByScene(scene, options) {
  const reportsMap = useSelector((s) => s.reports.itemsMap);

  let reports = Object.values(reportsMap);
  reports = reports.filter((v) => v.sceneId === scene?.id);

  if (options?.filterByScope) {
    reports = reports.filter((v) => v.scopeId === options.filterByScope);
  }

  if (options?.sortByName) {
    reports = reports.sort((a, b) => a.name.localeCompare(b.name));
  }

  return reports;
}
