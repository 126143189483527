import {Dialog, DialogContent, DialogActions, Button, Box} from "@mui/material";

import useTranslation from "Features/translations/useTranslation";
import {capitalizeStart} from "Features/translations/utils";

export default function DeleteDialog({
  open,
  onCancel,
  onConfirm,
  ressource,
  loading,
}) {
  const {t} = useTranslation(["ui", "common"]);

  // string

  const startString = t("ui:deleteDialog.start");
  const endStringsMap = {
    scene: t("ui:deleteDialog.end.scene"),
    model: t("ui:deleteDialog.end.model"),
    listing: t("ui:deleteDialog.end.listing"),
    clippingPlane: t("ui:deleteDialog.end.clippingPlane"),
    pdfZone: t("ui:deleteDialog.end.pdfZone"),
    measurement: t("ui:deleteDialog.end.measurement"),
    package: t("ui:deleteDialog.end.package"),
    elementType: t("ui:deleteDialog.end.elementType"),
    elementTypesGroup: t("ui:deleteDialog.end.elementTypesGroup"),
    room: t("ui:deleteDialog.end.room"),
    sector: t("ui:deleteDialog.end.sector"),
    zone: t("ui:deleteDialog.end.zone"),
    ressourceItem: t("ui:deleteDialog.end.ressourceItem"),
    ressourcesGroup: t("ui:deleteDialog.end.ressourcesGroup"),
    sharedbox: t("ui:deleteDialog.end.sharedbox"),
    file3d: " un modèle 3d",
    roomsGroup: " une liste de locaux",
    survey: " une visite",
    poi: " un point de vue",
    allArticles: " tous les articles",
    article: " un article",
    articlesGroup: " un groupe d'articles",
  };
  const endString = endStringsMap[ressource];

  const messageString = startString + endString;

  const cancelString = capitalizeStart(t("common:cancel"));
  const confirmString = capitalizeStart(t("common:confirm"));

  //

  return (
    <Dialog open={open} onClose={onCancel}>
      <Box
        sx={{
          width: 1,
          borderTop: (theme) => `4px solid ${theme.palette.error.main}`,
        }}
      ></Box>
      <DialogContent>
        <span style={{fontSize: 24}}>✋ </span>
        {messageString}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={loading}>
          {cancelString}
        </Button>
        <Button variant="contained" onClick={onConfirm} disabled={loading}>
          {confirmString}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
