import {client} from "API/capla360";

export async function fetchSamplesService({accessToken, sceneId}) {
  const response = await client.get(`/scenes/${sceneId}/bimboxsamples/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const sharedSamples = response.data;
  const samples = sharedSamples.map((r) => {
    return {...r, sceneId: r.scene};
  });
  return {items: samples, sceneId};
}

export async function createSampleService({accessToken, sample}) {
  const newSample = {...sample};
  const sceneId = sample.sceneId;
  delete newSample.scene;
  newSample.scene = sample.sceneId;
  const response = await client.post(
    `scenes/${sceneId}/bimboxsamples/`,
    newSample,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {item: {...response.data, sceneId}};
}

export async function fetchSampleService({accessToken, sceneId, sampleId}) {
  const response = await client.get(
    `/scenes/${sceneId}/bimboxsamples/${sampleId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {item: {...response.data, sceneId}};
}

export async function updateSampleService({
  accessToken,
  sampleId,
  sceneId,
  updates,
}) {
  const response = await client.put(
    `/scenes/${sceneId}/bimboxsamples/${sampleId}/`,
    updates,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const updatedSample = response.data;
  return {item: {...updatedSample, sceneId: updatedSample.scene}};
}

export const deleteSampleService = async ({accessToken, sampleId, sceneId}) => {
  await client.delete(`/scenes/${sceneId}/bimboxsamples/${sampleId}/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return sampleId;
};
