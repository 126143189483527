import {useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";

import useSelectedPdfModel from "Features/pdf/useSelectedPdfModel";

import {updateArticleTemp} from "../articlesSlice";
import {setSnackbarMessage} from "Features/ui/uiSlice";

import {Box, Typography, IconButton} from "@mui/material";
import {Delete, Refresh} from "@mui/icons-material";
import ButtonFocusOnExtractedText from "Features/pdf/components/ButtonFocusOnExtractedText";

export default function BlockEditableArticleMainPdfLink({
  article,
  caplaEditor,
}) {
  const dispatch = useDispatch();
  // string

  const label = "Lien PDF";

  // data

  const extractedText = useSelector((state) => state.pdf.extractedText);
  const selectedPdf = useSelectedPdfModel();

  // helpers

  const selectedArticleId = article?.id;

  const noLink = !article?.mainPdfLink;
  const disable = !selectedPdf;

  // effect - auto update

  useEffect(() => {
    if (
      extractedText.extractedAt &&
      extractedText.selectedArticleId === selectedArticleId
    ) {
      const message = `Article ${article.name} lié à p${extractedText.pageNumber}`;
      const triggeredAt = Date.now();
      dispatch(setSnackbarMessage({message, triggeredAt}));
      dispatch(
        updateArticleTemp({
          id: selectedArticleId,
          mainPdfLink: extractedText,
        })
      );
    }
  }, [
    extractedText.extractedAt,
    extractedText.selectedArticleId,
    selectedArticleId,
  ]);

  // handlers

  function handleDelete() {
    dispatch(
      updateArticleTemp({
        id: selectedArticleId,
        mainPdfLink: null,
      })
    );
  }

  return (
    <Box sx={{display: "flex", alignItems: "center"}}>
      {article?.mainPdfLink && (
        <ButtonFocusOnExtractedText
          editorPdf={caplaEditor.editorPdf}
          pdfExtractedText={article.mainPdfLink}
        />
      )}
      {!article?.mainPdfLink && (
        <Typography
          sx={{
            fontSize: 12,
            color: noLink ? "grey.400" : "text.secondary",
            mx: 1,
          }}
        >
          {label}
        </Typography>
      )}
      {!noLink && (
        <IconButton onClick={handleDelete} size="small">
          <Delete fontSize="small" />
        </IconButton>
      )}
    </Box>
  );
}
