import {useState, useEffect} from "react";
import {useSelector} from "react-redux";

import {Box} from "@mui/material";

// import SectionMain from "./components/SectionMain";
import SectionMainV2 from "./components/SectionMainV2";
// import SectionLeft from "./components/SectionLeft";

import SearchBarObjects from "Features/search/components/SearchBarObjects";
import useScenesAndRemoteScenes from "Features/scenes/useScenesAndRemoteScenes";
import ContainerLoadingBimbox from "Features/overviewer/components/ContainerLoadingBimbox";
import ButtonDialogCreateScene from "../ButtonDialogCreateScene";

export default function SectionScenes() {
  const allScenes = useSelector((s) => s.scenes.items);

  const [scenes, setScenes] = useState([]);

  console.log("[DEBUG] search scenes", allScenes.length);

  function handleSearch(objects) {
    setScenes(objects);
  }

  // effect - init

  useEffect(() => {
    setScenes(allScenes);
  }, [allScenes.length]);

  // data

  const isFetchingRemoteScenes = useSelector(
    (s) => s.scenes.isFetchingRemoteScenes
  );
  const isSyncing = useSelector((s) => s.sync.isSyncing);

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        flexDirection: "column",
        justifyItems: "stretch",
      }}
    >
      <Box sx={{height: "60px", minHeight: "60px"}} />
      <Box sx={{display: "flex", alignItems: "center", my: 3}}>
        <Box sx={{px: 3}}>
          <ButtonDialogCreateScene />
        </Box>
        <Box
          sx={{
            p: 3,
            width: 350,
            visibility: allScenes?.length > 0 ? "visible" : "hidden",
          }}
        >
          <SearchBarObjects
            objects={allScenes}
            onSearch={handleSearch}
            keys={["title", "description"]}
            //keys={["operationName", "title", "tradesName", "description"]}
            darkMode
          />
        </Box>
      </Box>
      <Box sx={{flexGrow: 1, display: "flex", minHeight: 0, overflow: "auto"}}>
        {(!isFetchingRemoteScenes && !isSyncing) || scenes?.length > 0 ? (
          <SectionMainV2 scenes={scenes} mode="GRID" />
        ) : (
          <ContainerLoadingBimbox />
        )}
      </Box>
    </Box>
  );
}
