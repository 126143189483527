export default function translateAndScalePath({
  path,
  pointRefOld,
  pointRefNew,
  scale,
  deltaX,
  deltaY,
}) {
  const newPath = path.map((point) => {
    const [oldPointX, oldPointY] = point;
    let newPointX = oldPointX;
    let newPointY = oldPointY;
    //
    if (pointRefOld && pointRefNew) {
      newPointX = (oldPointX - pointRefOld[0]) * scale + pointRefNew[0];
      newPointY = (oldPointY - pointRefOld[1]) * scale + pointRefNew[1];
    }
    //
    if (deltaX) newPointX += deltaX;
    if (deltaY) newPointY += deltaY;

    return [newPointX, newPointY];
  });

  return newPath;
}
