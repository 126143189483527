import {useSelector} from "react-redux";

import useMaterialsCategories from "./useMaterialsCategories";
import useSamplesByScene from "Features/samples/hooks/useSamplesByScene";

import getSamplesByMaterial from "Features/samples/utils/getSamplesByMaterial";
import getItemsMapById from "Utils/getItemsMapById";

export default function useMaterialsByScene(scene, options) {
  // options
  const withCategories = options?.withCategories;
  const withSamples = options?.withSamples;
  const withComments = options?.withComments;
  //
  //const materials = useSelector((s) => s.materials.items);
  const materialsMap = useSelector((s) => s.materials.materialsMap);
  //
  const categories = useMaterialsCategories();
  const samples = useSamplesByScene(scene);
  const commentsMap = useSelector((s) => s.comments.commentsMap);
  //
  let materials = Object.values(materialsMap);
  //

  if (withCategories) {
    const categoriesById = getItemsMapById(categories);
    materials = materials.map((m) => ({
      ...m,
      category: categoriesById[m.materialCategoryId],
    }));
  }

  if (withSamples) {
    const samplesByMat = getSamplesByMaterial(samples);
    materials = materials.map((material) => ({
      ...material,
      samples: samplesByMat[material.clientId],
    }));
  }

  if (withComments) {
    materials = materials.map((material) => {
      if (material.commentsClientIds) {
        const comments = material.commentsClientIds.map(
          (clientId) => commentsMap[clientId]
        );
        return {...material, comments};
      } else {
        return material;
      }
    });
  }
  return materials;
}
