import {configureStore} from "@reduxjs/toolkit";

import authReducer from "Features/auth/authSlice";
import profileReducer from "Features/profile/profileSlice";
import listingsReducer from "Features/listings/listingsSlice";
import issuesReducer from "Features/issues/issuesSlice";
import issuesetsReducer from "Features/issuesets/issuesetsSlice";
import uiReducer from "Features/ui/uiSlice";
import listingReducer from "Features/listing/listingSlice";
import imagesReducer from "Features/images/imagesSlice";
// import categoriesReducer from "Features/categories/categoriesSlice";
import operationsReducer from "Features/operations/operationsSlice";
import documentsReducer from "Features/documents/documentsSlice";
import mapsReducer from "Features/maps/mapsSlice";
import psetsReducer from "Features/psets/psetsSlice";
import rowsReducer from "Features/rows/rowsSlice";
import mapviewsReducer from "Features/mapviews/mapviewsSlice";
import viewer3DReducer from "Features/viewer3D/viewer3DSlice";
import scenesReducer from "Features/scenes/scenesSlice";
import objectsetsReducer from "Features/objectsets/objectsetsSlice";
import ressourcesReducer from "Features/ressources/ressourcesSlice";
import articlesReducer from "Features/articles/articlesSlice";
import worksitesReducer from "Features/worksites/worksitesSlice";
import spaceReducer from "Features/space/spaceSlice";
import picturesReducer from "Features/pictures/picturesSlice";
import navigationReducer from "Features/navigation/navigationSlice";
import measurementsReducer from "Features/measurements/measurementsSlice";
import pdfReducer from "Features/pdf/pdfSlice";
import cutsReducer from "Features/cuts/cutsSlice";
import quantitiesReducer from "Features/quantities/quantitiesSlice";
import markersReducer from "Features/markers/markersSlice";
import zonesReducer from "Features/zones/zonesSlice";
import sectorsReducer from "Features/sectors/sectorsSlice";
import ifcReducer from "Features/ifc/ifcSlice";
import elementTypesReducer from "Features/elementTypes/elementTypesSlice";
import progressReducer from "Features/progress/progressSlice";
import syncReducer from "Features/sync/syncSlice";
import phasesReducer from "Features/phases/phasesSlice";
import versionsReducer from "Features/versions/versionsSlice";
import coloringsReducer from "Features/colorings/coloringsSlice";
import coloringJobsReducer from "Features/coloringJobs/coloringJobsSlice";
import selectionReducer from "Features/selection/selectionSlice";
import packagesReducer from "Features/elementPackages/packagesSlice";
import measurementsAutoReducer from "Features/measurementsAuto/measurementsAutoSlice";
import inputsReducer from "Features/inputs/inputsSlice";
import roomsReducer from "Features/rooms/roomsSlice";
import boxhomeReducer from "Features/boxhome/boxhomeSlice";
import annotatedPdfReducer from "Features/annotatedPdf/annotatedPdfSlice";

import sharedboxesReducer from "Features/sharedboxes/sharedboxesSlice";
import libraryReducer from "Features/library/librarySlice";
import leftPanelReducer from "Features/leftPanel/leftPanelSlice";
import shortcutReducer from "Features/shortcut/shortcutSlice";
import materialsReducer from "Features/materials/materialsSlice";
import buildingsReducer from "Features/buildings/buildingsSlice";
import pricingReducer from "Features/pricing/pricingSlice";
import relationsReducer from "Features/relations/relationsSlice";
import poisReducer from "Features/pois/poisSlice";
import overviewerReducer from "Features/overviewer/overviewerSlice";
import helpers3dReducer from "Features/helpers3d/helpers3dSlice";
import filtersReducer from "Features/filters/filtersSlice";
import onboardingReducer from "Features/onboarding/onboardingSlice";
import scopesReducer from "Features/scopes/scopesSlice";
import elementTyporReducer from "Features/elementTypor/elementTyporSlice";
import organisationsReducer from "Features/organisations/organisationsSlice";
import viewsReducer from "Features/views/viewsSlice";
import surveysReducer from "Features/surveys/surveysSlice";
import samplesSlice from "Features/samples/samplesSlice";
import reportsSlice from "Features/reports/reportsSlice";
import commentsSlice from "Features/comments/commentsSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    profile: profileReducer,
    listings: listingsReducer,
    listing: listingReducer,
    issues: issuesReducer,
    issuesets: issuesetsReducer,
    ui: uiReducer,
    images: imagesReducer,
    //categories: categoriesReducer,
    //editor: editorReducer,
    operations: operationsReducer,
    documents: documentsReducer,
    maps: mapsReducer,
    psets: psetsReducer,
    rows: rowsReducer,
    mapviews: mapviewsReducer,
    viewer3D: viewer3DReducer,
    scenes: scenesReducer,
    objectsets: objectsetsReducer,
    ressources: ressourcesReducer,
    articles: articlesReducer,
    worksites: worksitesReducer,
    space: spaceReducer,
    pictures: picturesReducer,
    navigation: navigationReducer,
    measurements: measurementsReducer,
    pdf: pdfReducer,
    cuts: cutsReducer,
    quantities: quantitiesReducer,
    markers: markersReducer,
    zones: zonesReducer,
    sectors: sectorsReducer,
    ifc: ifcReducer,
    elementTypes: elementTypesReducer,
    progress: progressReducer,
    sync: syncReducer,
    phases: phasesReducer,
    versions: versionsReducer,
    colorings: coloringsReducer,
    coloringJobs: coloringJobsReducer,
    selection: selectionReducer,
    packages: packagesReducer,
    measurementsAuto: measurementsAutoReducer,
    inputs: inputsReducer,
    rooms: roomsReducer,
    boxhome: boxhomeReducer,
    annotatedPdf: annotatedPdfReducer,

    sharedboxes: sharedboxesReducer,
    library: libraryReducer,
    leftPanel: leftPanelReducer,
    shortcut: shortcutReducer,
    materials: materialsReducer,
    buildings: buildingsReducer,
    pricing: pricingReducer,
    relations: relationsReducer,
    overviewer: overviewerReducer,
    helpers3d: helpers3dReducer,
    filters: filtersReducer,
    onboarding: onboardingReducer,
    scopes: scopesReducer,
    elementTypor: elementTyporReducer,
    organisations: organisationsReducer,
    views: viewsReducer,
    surveys: surveysReducer,
    pois: poisReducer,
    samples: samplesSlice,
    reports: reportsSlice,
    comments: commentsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
