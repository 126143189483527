import {useSelector} from "react-redux";

export default function useKvtScene() {
  //
  const profileData = useSelector((s) => s.profile.data);
  const organisation = {
    id: profileData.organisationId,
    name: profileData.organisationName,
  };
  //
  console.log("organisation", organisation);

  const kvt = {
    key: "scene",
    fields: [
      {
        key: "organisation",
        name: "Organisation",
        type: "combo",
        options: [organisation],
      },
      {
        key: "identification",
        type: "section",
        name: "Identification",
      },
      {
        key: "title",
        name: "Nom",
        type: "text",
      },
      {
        key: "clientReference",
        type: "text",
        name: "Réf. interne",
      },
      {
        key: "description",
        name: "Description",
        type: "text",
      },
    ],
  };
  return kvt;
}
