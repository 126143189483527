import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {useOutletContext} from "react-router-dom";

import {newFirstLoadedPage} from "Features/ui/uiSlice";
import {setOpenSections} from "Features/ui/uiSlice";
import {setOpenSelectionPanel} from "Features/selection/selectionSlice";
import {setGrid} from "Features/viewer3D/viewer3DSlice";

import {Box, Grid, Typography, lighten} from "@mui/material";

export default function PageSceneHome() {
  const dispatch = useDispatch();

  const {caplaEditor, scene, viewer} = useOutletContext();

  // data

  const models = useSelector((s) => s.viewer3D.models).filter(
    (m) => m.sceneClientId === scene?.clientId
  );
  const firstLoadedPages = useSelector((s) => s.ui.firstLoadedPages);

  // helpers

  const firstLoaded = firstLoadedPages.includes("SCENE_HOME");
  const noModel = models.length === 0;

  useEffect(() => {
    if (!firstLoaded) {
      dispatch(newFirstLoadedPage("SCENE_HOME"));
      //
      const os = {
        outlet: false,
        fixedViewersBox: false,
        viewer3D: true,
        callToAction: false,
      };
      dispatch(setOpenSections(os));
      //
      dispatch(setOpenSelectionPanel(true));
      //
      if (noModel) {
        dispatch(setGrid(true));
        caplaEditor.editor3d?.grid.show();
      }
    }
  }, [firstLoaded]);
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        justifyItems: "stretch",
        minWidth: 0,
      }}
    ></Box>
  );
}
