import {useDispatch, useSelector} from "react-redux";

import {Box, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";

import ImageAnnotator from "Components/ImageAnnotator";
import FormAddFrame from "./components/FormAddFrame";

import FormAddModelPov from "./components/FormAddModelPov";

import {setMainSection, setScreenshot} from "Features/viewer3D/viewer3DSlice";
import FormAddIssueFromViewer from "Features/issues/components/FormAddIssueFromViewer";

export default function SectionScreenshotViewer({
  scene,
  editor3d,
  editorPdf,
  viewer,
  mobile,
}) {
  const dispatch = useDispatch();

  // data

  const fullscreen = useSelector((s) => s.ui.fullscreen);
  const screenshot = useSelector((state) => state.viewer3D.screenshot);
  const story =
    screenshot.storyId &&
    scene.data?.stories?.find((s) => s.id === screenshot.storyId);

  const sceneListingId = screenshot.options?.target?.listingId;

  // helpers

  let mode;
  if (screenshot.storyId) mode = "FRAME";
  if (screenshot.sceneListingId) mode = "NOTE";
  if (screenshot.modelId) mode = "MODEL";
  if (screenshot.options?.target?.listingType === "issueset") mode = "ISSUE";

  // handlers

  function handleDragStart(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  function handleClose() {
    dispatch(setMainSection(null));
    dispatch(setScreenshot({url: null}));
  }

  function handleFrameCreate() {
    handleClose();
  }

  function handleNoteCreate() {
    handleClose();
  }

  function handleIssueCreate() {
    handleClose();
  }

  // function handleModelPovCreate() {
  //   handleClose();
  // }

  return (
    <Box
      sx={{
        height: 1,
        width: 1,
        bgcolor: "background.default",
        position: "relative",
      }}
      onDragStart={handleDragStart}
    >
      <Box
        sx={{
          bgcolor: "common.white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          position: "absolute",
          top: fullscreen || viewer ? "4px" : "60px",
          right: "4px",
          zIndex: 10,
        }}
      >
        <IconButton onClick={handleClose} size="small" color="primary">
          <Close fontSize="small" />
        </IconButton>
      </Box>
      <ImageAnnotator
        url={screenshot.url}
        width="100%"
        height="100%"
        editor3d={editor3d}
        mobile={mobile}
        viewer={viewer}
        editorPdf={editorPdf}
      />
      {!viewer && screenshot?.url && mode === "ISSUE" && (
        <Box
          sx={{
            position: "absolute",
            bottom: (theme) => theme.spacing(2),
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <FormAddIssueFromViewer
            scene={scene}
            editor3d={editor3d}
            screenshot={screenshot}
            onCreate={handleIssueCreate}
            sceneListingId={sceneListingId}
          />
        </Box>
      )}

      {!viewer && screenshot?.url && mode === "FRAME" && (
        <Box
          sx={{
            position: "absolute",
            bottom: (theme) => theme.spacing(2),
            right: (theme) => theme.spacing(2),
          }}
        >
          <FormAddFrame
            editor3d={editor3d}
            scene={scene}
            story={story}
            screenshot={screenshot}
            onCreate={handleFrameCreate}
          />
        </Box>
      )}
      {!viewer && screenshot?.url && mode === "NOTE" && (
        <Box
          sx={{
            position: "absolute",
            bottom: (theme) => theme.spacing(2),
            left: "50%",
            transform: "translateX(-50%)",
          }}
        ></Box>
      )}

      {!viewer && screenshot?.url && mode === "MODEL" && (
        <Box
          sx={{
            position: "absolute",
            bottom: (theme) => theme.spacing(2),
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <FormAddModelPov
            editor3d={editor3d}
            scene={scene}
            modelId={screenshot.modelId}
            screenshot={screenshot}
            onCreate={handleNoteCreate}
          />
        </Box>
      )}
    </Box>
  );
}
