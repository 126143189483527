import React, {useState} from "react";

import {useNavigate, useSearchParams} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {
  setSelectedSceneClientId,
  setNewScene,
  createScene,
} from "../scenesSlice";
import {createRemoteScene} from "../scenesSlice";
import {setOpenSections} from "Features/ui/uiSlice";

import {unwrapResult} from "@reduxjs/toolkit";

import {Box, Button, Typography, alpha, LinearProgress} from "@mui/material";
import {Add} from "@mui/icons-material";

import format from "date-fns/format";
import {setOpenSelectionPanel} from "Features/selection/selectionSlice";
import {setOpen} from "Features/navigation/navigationSlice";

import elementTypesDefault from "Features/elementTypes/data/elementTypesDefault";

import useAccessToken from "Features/auth/useAccessToken";

export default function ActionsNewSceneInSelectionPanel() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useAccessToken();
  const [searchParams] = useSearchParams();

  // helpers

  const suffixUrl = "";

  // strings

  const createS = "Créer la bimbox";
  const sectorNameS = "Général";
  const creatingS = "Création en cours ...";

  // data

  const newScene = useSelector((s) => s.scenes.newScene);
  const connectSceneOnCreation = useSelector(
    (s) => s.scenes.connectSceneOnCreation
  );

  // state

  const [loading, setLoading] = useState(false);

  // helpers

  const disabled = !newScene?.title?.length > 0 || loading;

  // handlers

  async function handleCreateClick() {
    console.log("create new scene", newScene);
    let remoteScene;
    if (loading) return;

    const d = new Date();
    const createdAt = d.toISOString();
    const sectors = [{id: nanoid(), name: sectorNameS, code: "GEN", z: 0}];
    const elementTypes = [...elementTypesDefault];

    setLoading(true);
    const result = await dispatch(
      createScene({...newScene, createdAt, data: {sectors, elementTypes}})
    );
    const {scene} = unwrapResult(result);

    // connect scene
    if (connectSceneOnCreation && accessToken) {
      const result = await dispatch(
        createRemoteScene({sceneClientId: scene.clientId, accessToken})
      );
      remoteScene = unwrapResult(result);
      console.log("remoteScene created", remoteScene);
    }
    setLoading(false);

    dispatch(setNewScene({}));
    dispatch(setSelectedSceneClientId(scene.clientId));

    dispatch(setOpenSelectionPanel(false));
    dispatch(setOpen(false));
    dispatch(
      setOpenSections({
        outlet: false,
        fixedViewersBox: true,
        viewer3D: false,
        callToAction: false,
      })
    );

    const sceneId = remoteScene?.id ? remoteScene.id : scene.clientId;
    navigate(`/bimboxes/${sceneId}/inputs` + suffixUrl);
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        //"&>*:not(:last-child)": {mr: 1},
      }}
    >
      {!loading ? (
        <Box sx={{flexGrow: 1, p: 1}}>
          <Button
            startIcon={<Add />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleCreateClick}
            disabled={disabled}
          >
            {createS}
          </Button>
        </Box>
      ) : (
        <Box sx={{width: 1}}>
          <Typography variant="body2" color="secondary.main" sx={{p: 1}}>
            {creatingS}
          </Typography>
          <LinearProgress sx={{width: 1}} color="secondary" />
        </Box>
      )}
    </Box>
  );
}
