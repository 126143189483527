import useScopesByScene from "Features/scopes/hooks/useScopesByScene";
import {useSelector} from "react-redux";

import {Box, List, ListItemButton, Typography, Paper} from "@mui/material";
import SectionCreateScopeEmpty from "Features/scopes/components/SectionCreateScopeEmpty";

export default function SectionSelectorScopeList({onClick, scene}) {
  // strings

  const selectS = "Sélectionnez une sous-bimbox";

  // data

  const scopes = useScopesByScene(scene, {sortByName: true, addScopeAll: true});
  const models = useSelector((s) => s.viewer3D.models).filter(
    (m) => m.sceneClientId === scene?.clientId
  );

  // helpers

  const noScope =
    models?.length === 0 && scopes.filter((s) => s.id !== "all")?.length === 0;

  // handlers

  function handleClick(scope) {
    onClick(scope);
  }

  return (
    <>
      {noScope && (
        <Box sx={{width: 400, minWidth: 0, display: "flex"}}>
          <SectionCreateScopeEmpty scene={scene} />
        </Box>
      )}
      {!noScope && (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            minHeight: 0,
            justifyItems: "stretch",
            justifyContent: "center",
            color: "grey.400",
            p: 3,
          }}
        >
          <Box sx={{p: 2}}>
            <Typography>{selectS}</Typography>
          </Box>
          <Paper
            elevation={12}
            sx={{
              bgcolor: "common.caplaBlack",
              p: 1,
              overflowY: "auto",
              minHeight: 0,
              display: "flex",
              flexDirection: "column",
              color: "inherit",
            }}
          >
            <List>
              {scopes.map((scope) => (
                <ListItemButton
                  key={scope.id}
                  onClick={() => handleClick(scope)}
                >
                  <Box>
                    <Typography>{scope.name}</Typography>
                  </Box>
                </ListItemButton>
              ))}
            </List>
          </Paper>
        </Box>
      )}
    </>
  );
}
