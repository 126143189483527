import {Box} from "@mui/material";

import ScenePicturesEditor from "Features/pictures/components/ScenePicturesEditor";

export default function SectionListingDetail({
  caplaEditor,
  scene,
  viewer,
  listing,
}) {
  // helpers

  const isNotes = listing?.listingType === "noteset";
  const isPictures = listing?.listingType === "pictureset";

  return (
    <Box sx={{height: 1}}>
      {isPictures && (
        <ScenePicturesEditor
          caplaEditor={caplaEditor}
          scene={scene}
          viewer={viewer}
          listing={listing}
        />
      )}
    </Box>
  );
}
