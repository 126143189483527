import {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";

import useZonesByScene from "Features/zones/hooks/useZonesByScene";

import {
  // updateMeasurements,
  setSelectedMeasurementIds,
} from "../measurementsSlice";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Button,
} from "@mui/material";
import KeyValueForm from "Features/kvform/components/KeyValueForm";
import useKvtMeasurementMultiple from "Features/kvform/hooks/useKvtMeasurementMultiple";

import ButtonRefreshMeasurements3D from "./ButtonRefreshMeasurements3D";
import ButtonDeleteMeasurements from "./ButtonDeleteMeasurements";
import useKvtMeasurementV2 from "Features/kvform/hooks/useKvtMeasurementV2";

export default function DialogEditMultipleMeasurements({
  open,
  onClose,
  caplaEditor,
  measurements,
  scene,
}) {
  const dispatch = useDispatch();

  // strings

  const title = "Edit measurements";
  const saveS = "Save";
  const cancelS = "Cancel";

  // data

  const kvtMeasurement = useKvtMeasurementMultiple(scene?.clientId);
  const kvtMeasurementV2 = useKvtMeasurementV2(scene?.clientId);
  const {modelId} = useParams();
  const zonesById = useZonesByScene(scene, {variant: "map"});

  // state

  const m0 = measurements[0];
  const initTempM = {drawingShape: m0?.drawingShape};
  const [tempM, setTempM] = useState(initTempM);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTempM(initTempM);
  }, [m0?.id]);

  // helpers

  const isEditMode = modelId ? true : false;

  const template = isEditMode ? kvtMeasurementV2 : kvtMeasurement;

  const fullTitle = `${title} (x${measurements.length})`;

  const hiddenFields = [
    "identification",
    "codeName",
    "helperImage",
    "zone",
    "length",
    "are",
    "volume",
    "exeComment",
    "updates",
    "isLocked",
  ];

  // handlers

  function handleTempMChange(m) {
    const temp = {};
    console.log("m", m);
    Object.entries(m).forEach(([key, value]) => {
      if (value !== undefined || value !== null) temp[key] = value;
    });
    setTempM(temp);
  }

  async function handleSave() {
    if (loading) return;
    setLoading(true);
    const mnger =
      caplaEditor?.editorPdf?.annotationsManager.measurementsPdfManager;
    mnger?.setIsEditing(true);
    const newMs = [];
    for (let measurement of measurements) {
      const newMeasurement = {...measurement, ...tempM};
      // dispatch(updateMeasurements([newMeasurement]));
      newMs.push(newMeasurement);
      if (isEditMode) {
        mnger?.updateAnnotation(newMeasurement, {
          multipleUpdates: true,
          zonesById,
        });
      } else {
        // in case no pdf
        caplaEditor?.editor3d?.sceneEditor.updateMeasurement3D(newMeasurement);
      }
    }
    caplaEditor?.measDataManager.updateMeasurements(newMs);
    mnger?.setIsEditing(false);
    setLoading(false);
    onClose();
  }

  function handleCancel() {
    onClose();
  }

  // handlers - delete callback

  function handleMeasurementsDeleted() {
    const mnger =
      caplaEditor?.editorPdf?.annotationsManager.measurementsPdfManager;
    dispatch(setSelectedMeasurementIds([]));
    onClose();
    mnger?.setIsEditing(false);
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{fullTitle}</DialogTitle>
      <DialogContent>
        <Box sx={{width: 1, my: 2, display: "flex", alignItems: "end"}}>
          <ButtonRefreshMeasurements3D
            editor3d={caplaEditor?.editor3d}
            measurements={measurements}
          />
          <ButtonDeleteMeasurements
            caplaEditor={caplaEditor}
            measurements={measurements}
            onDelete={handleMeasurementsDeleted}
          />
        </Box>
        <Box sx={{width: 500}}>
          <KeyValueForm
            item={tempM}
            onChange={handleTempMChange}
            template={template}
            hiddenFields={hiddenFields}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>{cancelS}</Button>
        <Button onClick={handleSave}>{saveS}</Button>
      </DialogActions>
    </Dialog>
  );
}
