import {Box} from "@mui/material";

import SectionArticleQtyDetail from "./SectionArticleQtyDetail";
import BlockEditableArticleCode from "./BlockEditableArticleCode";

export default function SectionArticleDetail({article, variant}) {
  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        flexDirection: variant === "wide" ? "row" : "column",
      }}
    >
      <Box
        sx={{
          p: 1,
          flex: 1,
          minWidth: 0,
          display: "flex",
          flexDirection: "column",
          "& > *": {mb: 1},
        }}
      >
        <BlockEditableArticleCode article={article} />
      </Box>
      <Box sx={{p: 1, flex: 1, minWidth: 0}}>
        <SectionArticleQtyDetail article={article} />
      </Box>
    </Box>
  );
}
