import {useState, useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";

import {setSelectedShortcutRight} from "Features/shortcut/shortcutSlice";

export default function useAutoOpenSelectionPanelInViewer() {
  const dispatch = useDispatch();

  // data

  const selectedArticleId = useSelector((s) => s.articles.selectedArticleId);
  //   const open =
  //     useSelector((s) => s.shortcut.selectedShortcutRight) === "SELECTION";

  // state

  const [wasOpenOnce, setWasOpenOnce] = useState(false);

  // effect

  useEffect(() => {
    if (!wasOpenOnce && selectedArticleId) {
      setWasOpenOnce(true);
      dispatch(setSelectedShortcutRight("SELECTION"));
    }
  }, [wasOpenOnce, selectedArticleId]);
}
