export default function updateMeasurementZsFromZone(measurement, zone) {
  let {zInf, zSup, offset, height, heights, zFrom} = measurement;

  // height
  if (heights) {
    const floatHeights = heights.split(";").map((f) => parseFloat(f));
    height = Math.max(...floatHeights);
  }

  if (!height && height !== 0) {
    const zoneH = zone?.elementsH;
    if (zoneH || zoneH === 0) {
      height = zoneH;
    } else {
      height = 0;
    }
  }

  // zInf / zSup / offset
  let _offset = offset ? offset : 0; // offset for extrusion
  const zoneZ = zone?.position.y ?? 0;
  if (zFrom === "zInf") {
    zInf = zInf;
    zSup = zInf + height;
  } else if (zFrom === "zSup") {
    zSup = zSup;
    zInf = zSup - height;
  } else if (zone?.zBasis === "ZSUP") {
    zSup = zoneZ + _offset;
    zInf = zSup - height;
  } else {
    zInf = zoneZ + _offset;
    zSup = zInf + height;
  }

  zInf = Math.round((zInf + Number.EPSILON) * 10000) / 10000;
  zSup = Math.round((zSup + Number.EPSILON) * 10000) / 10000;
  offset = Math.round((offset + Number.EPSILON) * 10000) / 10000;

  return {...measurement, zInf, zSup, offset};
}
