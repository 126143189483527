import {useState} from "react";

import {useSelector, useDispatch} from "react-redux";
import {setSelectedPoiId} from "../poisSlice";

import useRelevantPois from "../hooks/useRelevantPois";
import useSceneModule from "Features/navigation/useSceneModule";

import {Box} from "@mui/material";

import ContainerAddPoiFromPdf from "./ContainerAddPoiFromPdf";
import ContainerAddPoiFrom3d from "./ContainerAddPoiFrom3d";
import ContainerSelectorPois from "./ContainerSelectorPois";
import ContainerPoiDetail from "./ContainerPoiDetail";

import DialogDeletePoi from "./DialogDeletePoi";

export default function SectionPois({
  viewer,
  scene,
  caplaEditor,
  inViewer = "PDF",
}) {
  const dispatch = useDispatch();

  // data

  const pois = useRelevantPois(scene, {
    filterFromViewer: inViewer,
    inViewer: viewer,
  });

  const sceneModule = useSceneModule();

  const selectedPoiId = useSelector((s) => s.pois.selectedPoiId);

  // state

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  // helper

  const selectedPoi = pois.find((poi) => poi.id === selectedPoiId);

  // helper

  const canAdd = true;
  const canEdit = true;

  // handlers

  function handleSelectionChange(poiId) {
    dispatch(setSelectedPoiId(poiId));
  }

  function handleClose() {
    dispatch(setSelectedPoiId(null));
    setOpenDeleteDialog(false);
  }

  function handleDelete() {
    setOpenDeleteDialog(true);
  }

  return (
    <Box>
      <DialogDeletePoi
        scene={scene}
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onDeleted={handleClose}
      />

      <Box sx={{display: "flex", alignItems: "center"}}>
        <ContainerSelectorPois
          viewer={viewer}
          scene={scene}
          caplaEditor={caplaEditor}
          pois={pois}
          selectedPoiId={selectedPoiId}
          onSelectionChange={handleSelectionChange}
        />
        {inViewer === "PDF" && !viewer && canAdd && (
          <ContainerAddPoiFromPdf caplaEditor={caplaEditor} />
        )}
        {inViewer === "3D" && !viewer && canAdd && (
          <ContainerAddPoiFrom3d
            viewer={viewer}
            scene={scene}
            caplaEditor={caplaEditor}
          />
        )}
      </Box>
      {selectedPoi && (
        <Box sx={{pt: 2}}>
          <ContainerPoiDetail
            viewer={viewer}
            poi={selectedPoi}
            onClose={handleClose}
            onDelete={handleDelete}
            canEdit={canEdit}
            scene={scene}
            caplaEditor={caplaEditor}
          />
        </Box>
      )}
    </Box>
  );
}
