export default function getSamplesByMaterial(samples) {
  const mapByMat = {};
  samples.forEach((sample) => {
    const item = mapByMat[sample.materialClientId];
    if (item) {
      item.push(sample);
    } else {
      mapByMat[sample.materialClientId] = [sample];
    }
  });
  return mapByMat;
}
