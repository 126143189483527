import parseArticleTypesString from "Features/articles/utils/parseArticleTypesString";
import createExcelWorkbookFromFile from "./createExcelWorkbookFromFile";
import getItemPropsFromNameCellStyle from "./getItemPropsFromNameCellStyle";
import getItemPropsFromQtyCellStyleAndValue from "./getItemPropsFromQtyCellStyleAndValue";

export default async function getArticlesFromFile(file, elementTypesGroups) {
  const wb = await createExcelWorkbookFromFile({file});
  const worksheet = wb.worksheets[0];

  const start = worksheet.getRow(1).getCell(2).value;
  const end = worksheet.getRow(2).getCell(2).value;

  const rows = worksheet.getRows(start, end - start + 1)?.values();

  const fields = [
    "id",
    "num",
    "name",
    "code",
    "coreTypes",
    "description",
    "unit",
    "qty",
    "refQty",
    "gap",
    "comment",
  ];

  let valuesA = [];
  for (let row of rows) {
    const rowValuesMap = {};
    let props = {};

    const noArticle = !row.getCell(3).value;
    if (!noArticle) {
      for (let i = 1; i <= row.values.length; i++) {
        const value = row.getCell(i).value;
        //
        if (i === 2) {
          if (value === "x") rowValuesMap["toDelete"] = true;
        }
        if (i === 3) {
          props = getItemPropsFromNameCellStyle(row.getCell(i));
          if (props?.style) {
            rowValuesMap["style"] = props.style;
          }
          if (props?.isAdded) {
            rowValuesMap["isAdded"] = props.isAdded;
          }
          if (props?.isNomenclature) {
            rowValuesMap["isNomenclature"] = props.isNomenclature;
          }
        }
        if (i === 8) {
          // qty
          props = getItemPropsFromQtyCellStyleAndValue(row.getCell(i));
          if (props?.hardcodedQty) {
            rowValuesMap["hardcodedQty"] = props.hardcodedQty;
          }
        }
        //
        rowValuesMap[fields[i - 1]] = value;
      }
      // skip rows of subArticles.
      if (rowValuesMap["code"] !== "-") {
        valuesA.push(rowValuesMap);
      }
    }
  }

  const loadedArticles = [];

  valuesA.map((rowValuesMap) => {
    const article = {};
    Object.entries(rowValuesMap).forEach(([field, value]) => {
      if (field === "coreTypes") {
        if (value) {
          article["coreTypes"] = parseArticleTypesString(
            value,
            elementTypesGroups
          );
        }
      } else {
        article[field] = value;
      }
    });
    loadedArticles.push(article);
  });
  return loadedArticles;
}
