import {useSelector} from "react-redux";

import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";

import createExcelWorkbook from "Features/excel/utils/createExcelWorkbook";

import getElementTypesWithQtiesV2 from "Features/elementTypes/utils/getElementTypesWithQtiesV2";
import sanitizeString from "Utils/sanitizeString";
import getMeasurementsForExcelByTypes from "../utils/getMeasurementsForExcelByTypes";
import getMeasurementsByTypeForExcelByType from "../utils/getMeasurementsByTypeForExcelByType";

import getItemsMapById from "Utils/getItemsMapById";
import downloadExcelFileFromWorkbook from "Features/excel/utils/downloadExcelFileFromWorkbook";

export default function DialogDownloadExcelMeasurements({
  scene,
  open,
  onClose,
}) {
  // string

  const title = "Repérages";
  const description = "Télécharger les repérages au format Excel";

  const downloadS = "Télécharger";
  const cancelS = "Annuler";

  // data

  const measurements = useSelector((s) => s.measurements.measShowedInViewer3d);
  const elementTypes = useElementTypesBySceneProxy(scene);
  const sectors = useSectorsByScene(scene);

  // helpers

  const elementTypesById = getItemsMapById(elementTypes);
  const sectorsById = getItemsMapById(sectors);
  const byTypes = getMeasurementsByTypeForExcelByType(
    measurements,
    elementTypes
  );

  const measurementsForExcel = getMeasurementsForExcelByTypes(measurements, {
    elementTypesById,
    sectorsById,
  });

  console.log("byTypes", byTypes);

  const buildingTypes = {};
  if (measurementsForExcel) {
    measurementsForExcel.forEach((m) => {
      const key = m.element_type_group + m.element_type_num;
      const value = m.element_type_quantity_value;
      const building = m.sector_building;
      const sector = m.sector_name;
      if (building in buildingTypes) {
        if (sector in buildingTypes[building]) {
          if (key in buildingTypes[building][sector]) {
            buildingTypes[building][sector][key] += value;
          } else {
            buildingTypes[building][sector][key] = value;
          }
        } else {
          buildingTypes[building][sector] = {
            [key]: value,
            name: sector,
            z: m.sector_z,
          };
        }
      } else {
        buildingTypes[building] = {
          [sector]: {[key]: value, name: sector, z: m.sector_z},
        };
      }
    });
  }

  // handlers

  async function handleDownloadClick() {
    console.log("download");

    const data = {
      elementTypes,
      byType: byTypes,
      buildingTypes,
    };

    let fileName = "repérages.xlsx";
    const wb = await createExcelWorkbook({
      template: "MEASUREMENTS_BY_TYPE",
      data,
    });
    downloadExcelFileFromWorkbook(wb, fileName);
    onClose();
  }

  function handleCancelClick() {
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{description}</DialogContent>
      <DialogActions>
        <Button onClick={handleCancelClick}>{cancelS}</Button>
        <Button onClick={handleDownloadClick}>{downloadS}</Button>
      </DialogActions>
    </Dialog>
  );
}
