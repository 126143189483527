import React, {useState, useEffect, useRef} from "react";

import {useSelector} from "react-redux";

import {Box} from "@mui/material";
import {Height} from "@mui/icons-material";

import debounce from "lodash.debounce";
import throttle from "lodash.throttle";

export default function BoxResizable({
  children,
  width,
  //fullWidth = false,
  //show = true,
  onChange,
  left = false,
  right = true,
  isWidthInPercent = false,
  disabled = false,
  auto = false, // if auto => auto sizing. Used when display: PDF | 3D view
  hide,
  viewer,
}) {
  // refs

  const boxRef = useRef();

  // data

  const open = useSelector((s) => s.navigation.open);
  const navPanelWidth = useSelector((s) => s.navigation.navPanelWidth);
  const navPanelSceneModulesWidth = useSelector(
    (s) => s.navigation.navPanelSceneModulesWidth
  );

  const openSelectionPanel = useSelector((s) => s.selection.openSelectionPanel);
  const selectionPanelWidth = useSelector(
    (s) => s.selection.selectionPanelWidth
  );

  const openLeftPanel = useSelector((s) => s.navigation.openLeftPanel);
  const leftPanelWidth = useSelector((s) => s.navigation.leftPanelWidth);

  // state

  const [helperBorder, setHelperBorder] = useState(false);
  const [helperArea, setHelperArea] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [tempWidth, setTempWidth] = useState(0);
  useEffect(() => setTempWidth(width), [width]);

  // helpers

  const borderRight = (theme) =>
    helperBorder
      ? `4px solid ${theme.palette.primary.main}`
      : `4px solid transparent`;

  const borderLeft = (theme) =>
    helperBorder
      ? `4px solid ${theme.palette.secondary.main}`
      : `4px solid transparent`;

  const cursor = helperArea ? "ew-resize" : "default";

  let boxWidth = width;
  if (isWidthInPercent) boxWidth = `${(width * 100).toFixed(0)}%`;

  // helpers - offset

  // let offsetNav = open ? navPanelWidth : navPanelSceneModulesWidth;
  //if (viewer) offsetNav = openLeftPanel ? leftPanelWidth : 0;
  let offsetNav = openLeftPanel
    ? leftPanelWidth + navPanelSceneModulesWidth
    : navPanelSceneModulesWidth;

  let offsetSelection = !viewer && openSelectionPanel ? selectionPanelWidth : 0;

  const offset = offsetNav + offsetSelection;

  //if (!show) boxWidth = 0;
  //if (fullWidth) boxWidth = 1;

  // listeners

  const onPointerDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const onPointerMove = (e) => {
    //e.preventDefault();
    //e.stopPropagation();
    if (dragging) {
      const bounds = boxRef.current.getBoundingClientRect();
      let newWidth;
      if (right) {
        //newWidth = e.clientX - bounds.left - offset;
        newWidth = e.clientX - bounds.left;
      } else {
        newWidth = window.innerWidth - e.clientX - offsetSelection;
      }

      if (isWidthInPercent && right)
        //newWidth = newWidth / (window.innerWidth);
        newWidth = newWidth / (window.innerWidth - offset);
      if (isWidthInPercent && left)
        newWidth = newWidth / (window.innerWidth - offset);

      //onChange(newWidth);
      setTempWidth(newWidth);
    } else {
      setHelperArea(false);
      setHelperBorder(false);
    }
  };

  const onPointerUp = (e) => {
    console.log("on pointer up");
    e.preventDefault();
    e.stopPropagation();
    onChange(tempWidth);

    setDragging(false);
    setHelperArea(false);
    setHelperBorder(false);
  };

  // handlers

  function handleMouseLeave(e) {
    e.stopPropagation();
    console.log("mouse leave");
    if (!dragging) {
      setHelperBorder(false);
      setHelperArea(false);
    }
  }

  function handleMouseOver(e) {
    e.stopPropagation();
    setHelperBorder(true);
    setHelperArea(true);
  }

  // effect

  // useEffect(() => {
  //   if (boxRef.current) {
  //     boxRef.current.addEventListener("pointermove", onPointerMove);
  //   }
  // }, [boxRef.current]);

  return (
    <Box
      ref={boxRef}
      sx={{
        position: "relative",
        height: 1,
        boxSizing: "border-box",
        //display: width === 0 ? "none" : "block",
        //width: width !== 0 ? boxWidth : "unset",
        width: auto ? "unset" : tempWidth,
        flexGrow: auto ? 1 : "unset",
        minWidth: auto || hide === 0 ? "unset" : tempWidth, // THIS IS THE TRICK TO GET THE RIGHT WIDTH !!
        minHeight: 0,
        display: "flex",
      }}
    >
      {children}
      {!disabled && width !== 0 && !auto && (
        <Box
          sx={{
            display: helperArea ? "flex" : "none",
            alignItems: "center",
            justifyContent: "center",
            width: 300,
            position: "absolute",
            top: 0,
            bottom: 0,
            cursor,
            zIndex: 80,

            ...(right && {right: 0, transform: "translateX(50%)"}),
            ...(left && {left: 0, marginLeft: "-150px"}),
          }}
          onPointerDown={onPointerDown}
          onPointerUp={onPointerUp}
          //onPointerMove={throttle(onPointerMove, 30)}
          onPointerMove={onPointerMove}
        ></Box>
      )}
      {!disabled && width !== 0 && !auto && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,

            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor,
            zIndex: 80,
            ...(right && {borderRight, right: 0}),
            ...(left && {borderLeft, left: 0}),
          }}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
          onPointerDown={onPointerDown}
          onPointerUp={onPointerUp}
        ></Box>
      )}
    </Box>
  );
}
