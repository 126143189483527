export default function mergeBboxes(bboxA, bboxB, options) {
  let x = Math.min(bboxA.x, bboxB.x);
  let y = Math.min(bboxA.y, bboxB.y);
  let width = Math.max(bboxA.x + bboxA.width, bboxB.x + bboxB.width) - x;
  let height = Math.max(bboxA.y + bboxA.height, bboxB.y + bboxB.height) - y;

  // options

  if (options.fixedDim) {
    //
    const centerX = x + width / 2;
    const centerY = y + height / 2;
    //
    //width = Math.max(bboxA.width, bboxB.width);
    width = 50; // fixed width of the cluster
    height = Math.max(bboxA.height, bboxB.height);
    //
    x = centerX - width / 2;
    y = centerY - height / 2;
  }

  return {x, y, width, height};
}
