export default function useSectorsByScene(scene, options) {
  let sectors = scene?.data?.sectors;
  if (!sectors) return [];

  if (options?.nameWithBuilding) {
    sectors = sectors.map((sector) => ({
      ...sector,
      name: sector?.building
        ? `${sector?.name} [${sector.building}]`
        : sector?.name,
    }));
  }

  if (options?.sortByZ) {
    sectors = sectors
      .map((sector) => ({...sector, building: sector.building ?? "-"}))
      .sort((a, b) => a.z - b.z)
      .sort((a, b) => a.building.localeCompare(b.building));
  }

  if (options?.filterByIds) {
    sectors = sectors.filter((sector) =>
      options.filterByIds.includes(sector.id)
    );
    if (options.filterByIds.includes("undefined"))
      sectors.push({id: "undefined", name: "Autre", code: "Autre"});
  }
  return sectors;
}
