import {useDispatch} from "react-redux";

import {resetPdfViewer} from "../pdfSlice";

import {Paper, IconButton, Tooltip} from "@mui/material";
import {Refresh} from "@mui/icons-material";

export default function ButtonRefreshPdfViewer({editorPdf}) {
  const dispatch = useDispatch();

  // strings

  const refreshS = "Rafraichir les annotations";

  // handler

  function handleClick() {
    editorPdf?.annotationsManager.measurementsPdfManager.reset();
    dispatch(resetPdfViewer());
  }
  return (
    <Paper
      elevation={1}
      sx={{
        p: 0.5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 34,
        width: 34,
      }}
    >
      <Tooltip title={refreshS}>
        <IconButton sx={{borderRadius: "4px"}} onClick={handleClick}>
          <Refresh fontSize="small" />
        </IconButton>
      </Tooltip>
    </Paper>
  );
}
