import getNomenclatureArticleSubArticles from "./getNomenclatureArticleSubArticles";

export default function getSubArticlesMap({
  articles,
  elementTypesGroupsWithTransferedQties,
  measurements,
  sectors,
}) {
  const subArticlesMap = {};
  //
  const updatedArticles = articles.map((article) => ({
    ...article,
    ...(article?.updates ?? {}),
  }));
  //
  console.log("debugafa getSubArticlesMap from", updatedArticles);
  //
  updatedArticles.forEach((article) => {
    if (article.isNomenclature) {
      const subArticles = getNomenclatureArticleSubArticles(
        article,
        elementTypesGroupsWithTransferedQties,
        measurements,
        {fromMeasurements: true, sectors}
      );
      //
      if (subArticles?.length > 0) {
        subArticlesMap[article.id] = subArticles.map((subArticle, index) => ({
          ...subArticle,
          articleId: article.id, // used for the selection
          index,
          isSubArticle: true,
        }));
      }
    }
  });
  //
  return subArticlesMap;
}
