import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {nanoid} from "@reduxjs/toolkit";

import useSelectedMeasurementsModel from "./useSelectedMeasurementsModel";
import translateAndScalePath from "./utils/translateAndScalePath";
import flipPath from "./utils/flipPath";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";

export default function usePasteMeasurements({caplaEditor, scene}) {
  const dispatch = useDispatch();

  //

  const mnger =
    caplaEditor?.editorPdf?.annotationsManager?.measurementsPdfManager;

  // data

  const measurementsModel = useSelectedMeasurementsModel();
  const pageNumber = useSelector((s) => s.viewer3D.pdfCurrentPage);

  const copiedMeasurements = useSelector(
    (s) => s.measurements.copiedMeasurements
  );
  const zones = useZonesByScene(scene);

  const zonesMap = zones.reduce((acc, zone) => {
    acc[zone.id] = zone;
    return acc;
  }, {});

  const paste = ({
    measurements,
    height,
    offsetZ,
    slopeH,
    marker,
    sector,
    type,
    scale,
    deltaX,
    deltaY,
    flipV,
    flipH,
    toZone,
  }) => {
    console.log(
      "paste measurements",
      measurements,
      deltaX,
      deltaY,
      flipH,
      flipV,
      toZone
    );

    if (!measurements?.length > 0) return;

    const newMeasurements = measurements.map((m) => {
      // id

      const id = nanoid();
      const fromZone = zonesMap[m.zoneId];

      // offsetZ
      let zInf = m.zInf;
      let zSup = m.zSup;

      if (offsetZ?.length > 0) {
        zInf = zInf + Number(offsetZ);
        zSup = zSup + Number(offsetZ);
      }

      // drawingProps
      let newDrawingProps;
      newDrawingProps = {
        ...m.drawingProps,
        pageNumber,
      };

      if (marker || deltaX || deltaY || flipV || flipH) {
        let newPath;
        let pointRefOld;
        let pointRefNew;
        if (copiedMeasurements.marker) {
          newPath = [];
          pointRefOld = [
            copiedMeasurements.marker?.px,
            copiedMeasurements.marker?.py,
          ];
          pointRefNew = marker ? [marker.px, marker.py] : null;
        }
        // m.drawingProps.path.forEach((p) => {
        //   newPath.push([p[0] + dx, p[1] + dy]);
        // });
        newPath = translateAndScalePath({
          path: m.drawingProps.path,
          pointRefOld,
          pointRefNew,
          scale,
          deltaX: Number(deltaX) / fromZone.scale,
          deltaY: Number(deltaY) / fromZone.scale,
        });

        if (flipH) newPath = flipPath({path: newPath, variant: "horizontal"});
        if (flipV) newPath = flipPath({path: newPath, variant: "vertical"});

        newDrawingProps = {
          ...m.drawingProps,
          pageNumber,
          path: newPath ? newPath : m.drawingProps.path,
        };
      }

      // new measurement

      const newMeasurement = {
        ...m,
        id,
        zInf,
        zSup,
        voids: [],
        height: height ? parseFloat(height) : m.height,
        drawingProps: newDrawingProps ? newDrawingProps : m.drawingProps,
      };
      if (slopeH && m.drawingShape === "SLOPE")
        newMeasurement.slopeH = parseFloat(slopeH);
      // if (zone?.id) {
      //   newMeasurement.zoneId = zone.id;
      // }
      if (sector?.id) newMeasurement.sectorId = sector?.id;
      if (type?.id) {
        newMeasurement.elementTypeId = type.id;
        newMeasurement.color = type.color;
      }

      return newMeasurement;
    });

    // add codeName
    //newMeasurements = addCodeNameToMeasurements(newMeasurements);

    let newMs = newMeasurements?.map((m) => {
      return {
        ...m,
        measurementsModelId: measurementsModel.id,
        pdfModelId: measurementsModel.fromModel.modelId,
      };
    });
    // dispatch(addMeasurements(newMs));

    // 3D // it doesn't work. We need first to create the annotation before to use the updateMeasurement3D.
    // newMs = newMs.map((measurement) => {
    //   const updatedMeas = mnger?.updateMeasurement3D(measurement, {
    //     doNotUpdateMeasData: true,
    //   });
    //   console.log("paste2 newMs1", updatedMeas);
    //   return updatedMeas;
    // });
    // data
    //caplaEditor?.measDataManager.addMeasurements(newMs);
    //

    newMs.forEach((measurement) =>
      mnger?.createAnnotationFromMeasurement(measurement, {
        defaultMode: true,
        fromZone: zonesMap[measurement.zoneId],
        toZone,
      })
    );
  };

  return paste;
}
