import {Dialog, DialogTitle, DialogContent} from "@mui/material";

import SectionAutoMeasTypesDetails from "./SectionAutoMeasTypesDetails";
import SectionAutoMeasUpdateHeights from "./SectionAutoMeasUpdateHeights";
import SectionAutoMeasAddHeight from "./SectionAutoMeasAddHeight";

export default function DialogAutomation({open, onClose, scene, caplaEditor}) {
  // strings

  const titleS = "Automatisation";

  // handlers

  function handleClose() {
    onClose();
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{titleS}</DialogTitle>
      <DialogContent>
        <SectionAutoMeasTypesDetails
          scene={scene}
          caplaEditor={caplaEditor}
          onSaved={handleClose}
        />
        <SectionAutoMeasUpdateHeights
          caplaEditor={caplaEditor}
          onUpdated={handleClose}
        />
        <SectionAutoMeasAddHeight
          caplaEditor={caplaEditor}
          onUpdated={handleClose}
        />
      </DialogContent>
    </Dialog>
  );
}
