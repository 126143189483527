import {Box} from "@mui/material";

import useSelection from "../useSelection";

import useSceneModule from "Features/navigation/useSceneModule";
import usePageType from "Features/navigation/usePageType";
//import ButtonMoreActionsScene from "Features/scenes/components/ButtonMoreActionsScene";
import ButtonMoreActionsPackage from "Features/elementPackages/components/ButtonMoreActionsPackage";
import ButtonMoreActionsMeasurement from "Features/measurements/components/ButtonMoreActionsMeasurement";
import ButtonMoreActionsZone from "Features/zones/components/ButtonMoreActionsZone";
import ButtonMoreActionsElementType from "Features/elementTypes/components/ButtonMoreActionsElementType";
import ButtonMoreActionsPdf from "Features/pdf/components/ButtonMoreActionsPdf";
import ButtonMoreActionsRessource from "Features/ressources/components/ButtonMoreActionsRessource";
import ButtonMoreActionsRoom from "Features/rooms/components/ButtonMoreActionsRoom";
import ButtonMoreActionsSector from "Features/sectors/components/ButtonMoreActionsSector";
import ButtonMoreActionsIssue from "Features/issues/components/ButtonMoreActionsIssue";
import ButtonMoreActionsSharedbox from "Features/sharedboxes/components/ButtonMoreActionsSharedbox";

export default function HeaderSelectionMoreButton({
  scene,
  caplaEditor,
  selection,
}) {
  // data

  const sceneModule = useSceneModule();
  const pageType = usePageType();

  return (
    <Box>
      {selection?.type === "ELEMENT_TYPE" && selection?.item && (
        <ButtonMoreActionsElementType scene={scene} />
      )}
      {selection?.type === "MEASUREMENT" && (
        <ButtonMoreActionsMeasurement
          scene={scene}
          caplaEditor={caplaEditor}
          selection={selection}
        />
      )}
      {selection?.type === "TEMP_MEASUREMENT" && (
        <ButtonMoreActionsMeasurement
          scene={scene}
          caplaEditor={caplaEditor}
          selection={selection}
        />
      )}
      {selection?.type === "PACKAGE" && selection?.item && (
        <ButtonMoreActionsPackage scene={scene} caplaEditor={caplaEditor} />
      )}
      {selection?.type === "ZONE" && selection?.item && (
        <ButtonMoreActionsZone scene={scene} caplaEditor={caplaEditor} />
      )}
      {selection?.type === "SECTOR" && selection?.item && (
        <ButtonMoreActionsSector scene={scene} />
      )}
      {selection?.type === "ROOM" && selection?.item && (
        <ButtonMoreActionsRoom scene={scene} />
      )}
      {selection?.type === "PDF_MODEL" && selection?.item && (
        <ButtonMoreActionsPdf />
      )}
      {selection?.type === "RESSOURCE" && selection?.item && (
        <ButtonMoreActionsRessource scene={scene} />
      )}

      {selection?.type === "ISSUE" && selection?.item && (
        <>
          {["ISSUES", "MEASUREMENTS"].includes(sceneModule) ? (
            <ButtonMoreActionsIssue
              scene={scene}
              editor3d={caplaEditor?.editor3d}
            />
          ) : (
            <div />
          )}
        </>
      )}
      {selection?.type === "SHAREDBOX" && selection?.item && (
        <ButtonMoreActionsSharedbox scene={scene} />
      )}
    </Box>
  );
}
