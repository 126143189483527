import {useSelector} from "react-redux";

export default function useScene(sceneId) {
  const scenes = useSelector((state) => state.scenes.items);

  // helper - scene

  let scene = scenes.find((scene) => scene.id === sceneId);
  if (!scene) scene = scenes.find((scene) => scene.clientId === sceneId);

  return scene;
}
