import {nanoid} from "@reduxjs/toolkit";

import testMarkersOverlap from "../js/utilsMarkersManager/testMarkersOverlap";
import mergeBboxes from "../js/utilsMarkersManager/mergeBboxes";

export default function getClustersFromMarkers(markers, stageScale) {
  const clusteredMarkers = [];
  const notClusteredMarkers = [];

  const markerIdMap = {}; // to avoid duplicates;

  // init

  markers = markers.map((marker) => ({...marker, clustered: false}));

  // Iterate over each marker
  for (let i = 0; i < markers.length; i++) {
    const marker = markers[i];

    // If the marker is already part of a cluster, skip it
    if (marker.clustered) continue;

    // Create a new cluster with the current marker as the initial member
    const cluster = [marker];
    marker.clustered = true;

    // Iterate over remaining markers to find potential cluster members
    let baseMarker = marker;
    for (let j = i + 1; j < markers.length; j++) {
      const otherMarker = markers[j];

      // If the other marker is not already clustered and is within the radius, add it to the cluster
      if (
        !otherMarker.clustered &&
        testMarkersOverlap(baseMarker, otherMarker, stageScale)
      ) {
        cluster.push(otherMarker);
        otherMarker.clustered = true;
        // Update the base marker to include the bbox of the new cluster member
        baseMarker = {
          ...baseMarker,
          bbox: mergeBboxes(baseMarker.bbox, otherMarker.bbox, {
            fixedDim: true,
          }),
        };
      }
    }

    if (cluster.length === 1) {
      const mainMarker = cluster[0];
      if (!markerIdMap[mainMarker.id]) {
        mainMarker.clustered = false;
        notClusteredMarkers.push(mainMarker);
      }
    } else if (cluster.length > 1) {
      // Calculate the barycenter of the cluster and create a new cluster marker
      const clusterMarker = {
        id: nanoid(),
        x: cluster.reduce((sum, m) => sum + m.x, 0) / cluster.length,
        y: cluster.reduce((sum, m) => sum + m.y, 0) / cluster.length,
        cluster,
        label: `Groupe (x${cluster.length})`,
        variant: "CLUSTER",
      };
      clusteredMarkers.push(clusterMarker);
    }
  }

  // Add remaining unclustered markers to the output array
  markers
    .filter((m) => !m.clustered)
    .forEach((m) => {
      if (!markerIdMap[m.id]) {
        notClusteredMarkers.push(m);
      }
    });

  // new markers

  const newMarkers = [...clusteredMarkers, ...notClusteredMarkers];

  console.log("newMarkers", markers, newMarkers);
  return newMarkers;
}
