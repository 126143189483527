import {useSelector, useDispatch} from "react-redux";

import {Box, Typography, Chip} from "@mui/material";
import {
  CenterFocusStrong as Focus,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";

import FormMeasurementOverview from "./FormMeasurementOverview";
import ButtonEditMeasurement from "./ButtonEditMeasurement";

import {
  setSelectedPdfModelId,
  updateModel,
} from "Features/viewer3D/viewer3DSlice";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import {setJump} from "Features/pdf/pdfSlice";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import {addZoneToBuffer, removeZoneFromBuffer} from "Features/zones/zonesSlice";
import {setSnackbarMessage} from "Features/ui/uiSlice";

export default function ButtonSeeMeasurementZone({
  scene,
  caplaEditor,
  measurement,
}) {
  const dispatch = useDispatch();
  // strings

  const seeZoneS = " le fond de plan";

  // data

  const models = useSelector((s) => s.viewer3D.models);
  const zones = useZonesByScene(scene);
  const useBuffer = useSelector((s) => s.zones.useBufferInShortcut);

  const modelsWithInitialTexture = useSelector(
    (s) => s.viewer3D.modelsWithInitialTexture
  );

  // helpers

  const zone = zones.find((z) => z.id === measurement?.zoneId);
  const zoneModel = models.find((m) => m.id === zone?.imageModelId);

  const loaded = modelsWithInitialTexture.includes(zone?.imageModelId);

  const selected = loaded && zoneModel?.hidden === false;

  // helper (patch : zoneModel.hidden = true by default in the viewer)

  let labelIsShow = zoneModel?.hidden;
  //if (viewer) labelIsShow = !labelIsShow;

  // handlers

  async function handleSeeZone() {
    if (zoneModel) {
      if (!loaded) {
        const options = {hideImageModels: true, loadTexture: true};
        await caplaEditor?.editor3d?.loader.loadInitialModel(
          zoneModel,
          options
        );
      }

      const hidden = loaded ? selected : false;
      const updatedModel = {...zoneModel, hidden: hidden};
      dispatch(updateModel({updatedModel, sync: false}));

      const entity = caplaEditor?.editor3d.getEntity(zoneModel.id);
      if (!hidden) {
        entity?.show();
        dispatch(addZoneToBuffer(zone));
      } else {
        entity?.hide();
        if (!useBuffer) dispatch(removeZoneFromBuffer(zone));
      }
    } else {
      const message = "Pas de zone associée au repérage";
      const triggeredAt = Date.now();
      dispatch(setSnackbarMessage({message, triggeredAt, isError: true}));
    }
  }
  return (
    <Chip
      size="small"
      onClick={handleSeeZone}
      label={labelIsShow ? "Afficher" + seeZoneS : "Masquer" + seeZoneS}
    />
  );
}
