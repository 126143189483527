import useZonesByScene from "../hooks/useZonesByScene";

import {Box} from "@mui/material";

import ListZones from "./ListZones";

export default function SectionZonesInLeftPanel({scene, caplaEditor}) {
  // data

  const allZones = useZonesByScene(scene, {
    sortByPdf: true,
    filterByScope: true,
  });

  console.log("allZones", allZones);

  // helpers

  const zones = allZones;

  // pdfs?.forEach((pdf) => {
  //   const pdfZones = zones
  //     .filter((z) => z.pdfModelName === pdf.name)
  //     .sort((a, b) => a.name.localeCompare(b.name));
  //   if (pdfZones.length > 0) {
  //     items.push({id: pdf.id, num: "", name: pdf.name, style: "title1"});
  //     pdfZones.forEach((z) => {
  //       const model = modelsById[z.imageModelId];
  //       items.push({id: z.id, num: "", name: z.name, model});
  //     });
  //   }
  // });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 1,
        flexGrow: 1,
        justifyItems: "stretch",
        minHeight: 0,
        overflow: "auto",
      }}
    >
      <ListZones zones={zones} caplaEditor={caplaEditor} />
    </Box>
  );
}
