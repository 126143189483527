import {createSlice} from "@reduxjs/toolkit";

export const poisSlice = createSlice({
  name: "pois",
  initialState: {
    tempPoi: "",
    selectedPoiId: null,
    enablePoiFilters: false,
  },
  reducers: {
    setTempPoi: (state, action) => {
      state.tempPoi = action.payload;
    },
    setSelectedPoiId: (state, action) => {
      state.selectedPoiId = action.payload;
    },
    setEnablePoiFilters: (state, action) => {
      state.enablePoiFilters = action.payload;
    },
  },
});

export const {setTempPoi, setSelectedPoiId, setEnablePoiFilters} =
  poisSlice.actions;

export default poisSlice.reducer;
