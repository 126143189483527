import {useSelector} from "react-redux";

// import useElementTypesByScene from "./useElementTypesByScene";
import useElementTypesBySceneProxy from "./useElementTypesBySceneProxy";
import useSelectedScope from "Features/scopes/hooks/useSelectedScope";

export default function useElementTypesGroupsProxyByScene(scene, options) {
  //
  // [{id,name,fromScene}]
  //
  //
  const sortByName = options?.sortByName;
  const filterByIds = options?.filterByIds;
  const filterByScope = options?.filterByScope;

  // data

  const elementTypes = useElementTypesBySceneProxy(scene);
  const remoteGroups = useSelector(
    (s) => s.elementTypes.elementTypesGroups
  )?.filter(
    (g) => g.sceneId === scene?.id || g.sceneClientId === scene?.clientId
  );
  const scope = useSelectedScope();

  // helpers

  const remoteGroupsProxy = remoteGroups.map((group) => ({
    id: group.id,
    name: group.name,
    code: group.code,
  }));

  // logic

  let groupsMap = {}; // id:{id,name,fromScene}
  let groups = [];

  // --- sceneGroups
  elementTypes.forEach((type) => {
    const groupsMapItem = groupsMap[type.groupId];
    if (!groupsMapItem && type.fromScene) {
      groups.push({
        id: type.groupId,
        name: type.group,
        fromScene: type.fromScene,
      });
      groupsMap[type.groupId] = "ok";
    }
  });

  // --- add remotes
  groups = [...groups, ...remoteGroupsProxy];

  if (sortByName) {
    groups = groups.sort((a, b) => a.name.localeCompare(b.name));
  }
  if (filterByIds) {
    groups = groups.filter((g) => filterByIds.includes(g.id));
  }
  if (filterByScope && scope?.data?.typesGroupIds?.length > 0) {
    groups = groups.filter((g) => scope?.data?.typesGroupIds?.includes(g.id));
  }

  return groups;
}
