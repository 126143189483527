import {Box, Typography} from "@mui/material";

import bimboxModesMap from "../data/bimboxModesMap";

export default function SelectorBimboxModes({
  selection,
  options,
  onChange,
  multiple,
}) {
  const modes = options.map((mode) => bimboxModesMap[mode]);

  console.log("modes", modes);

  // handlers

  function handleClick(mode) {
    const inPrevSelection = selection.includes(mode.key);
    if (multiple) {
      if (inPrevSelection) {
        onChange(selection.filter((key) => key !== mode.key));
      } else {
        onChange([...selection, mode.key]);
      }
    } else {
      if (inPrevSelection) {
        onChange([]);
      } else {
        onChange([mode.key]);
      }
    }
  }

  return (
    <Box
      sx={{
        bgcolor: "common.caplaBlack",
        display: "flex",
        alignItems: "center",
        "&>*:not(:last-child)": {mr: 1},
        justifyContent: "center",
        p: 1,
        borderRadius: "4px",
      }}
    >
      {modes.map((mode) => {
        const bgcolor = selection.includes(mode.key)
          ? mode.bgcolor
          : "grey.600";
        return (
          <Box
            key={mode.key}
            sx={{
              bgcolor,
              color: "common.white",
              borderRadius: "4px",
              cursor: "pointer",
              px: 1,
            }}
            onClick={() => handleClick(mode)}
          >
            <Typography variant="body2">{mode.name}</Typography>
          </Box>
        );
      })}
    </Box>
  );
}
