import {useDispatch, useSelector} from "react-redux";

import {setSnackbarMessage} from "Features/ui/uiSlice";

import {
  Add,
  Replay10 as RefreshQtiesIcon,
  ThreeDRotation as Refresh3DIcon,
  AutoAwesome as AutomationIcon,
} from "@mui/icons-material";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import getItemsMapById from "Utils/getItemsMapById";

import cleanMeasurementPaths from "./utils/cleanMeasurementPaths";
import cleanMeasurement from "./cleanMeasurement";
import useSelectedMeasurementsModel from "./useSelectedMeasurementsModel";
import {triggerLastComputedArticles} from "Features/articles/articlesSlice";

export default function useActionsMeasurementsMap({scene, caplaEditor}) {
  const dispatch = useDispatch();

  // strings

  const updateRessourcesFromTypesSuccessMessage = "Quantités mises à jour";

  // data

  const selectedMeasurementsModel = useSelectedMeasurementsModel();
  const os = useSelector((s) => s.ui.openSections);
  const measurements = useSelector((s) => s.measurements.items).filter(
    (m) => m.measurementsModelId === selectedMeasurementsModel?.id
  );

  const elementTypes = useElementTypesBySceneProxy(scene);
  const selectedMeasIds = useSelector(
    (s) => s.measurements.selectedMeasurementIds
  );
  const copiedMeasurements = useSelector(
    (s) => s.measurements.copiedMeasurements
  );

  // helpers

  const elementTypesById = getItemsMapById(elementTypes);

  // handlers

  const refresh3D = () => {
    console.log("refresh3D, #meas", measurements.length, measurements?.[0]);
    const mnger =
      caplaEditor?.editorPdf.annotationsManager.measurementsPdfManager;
    measurements.forEach((measurement) => {
      mnger?.updateMeasurement3D(measurement);
    });
    dispatch(
      setSnackbarMessage({
        message: "3D mise à jour",
        triggeredAt: Date.now(),
      })
    );
  };

  const useTypeColor = () => {
    let newMeasurements = measurements.map((meas) => {
      const newMeas = {...meas};
      const type = elementTypesById[meas.elementTypeId];
      if (type) {
        newMeas.color = type.color;
      }
      return newMeas;
    });
    //
    caplaEditor?.measDataManager.updateMeasurements(newMeasurements);
    dispatch(
      setSnackbarMessage({
        message: "Nouvelles couleurs appliquées",
        triggeredAt: Date.now(),
      })
    );
  };

  const cleanMeasurements = () => {
    let newMeas = measurements.map((meas) =>
      cleanMeasurement(meas, selectedMeasurementsModel)
    );
    // remove duplicates
    const idsMap = {};
    const measToDelete = [];
    newMeas.forEach((meas) => {
      if (!idsMap[meas.id]) {
        idsMap[meas.id] = true;
      } else {
        measToDelete.push(meas);
      }
    });
    //
    caplaEditor?.measDataManager.updateMeasurements(newMeas);
    caplaEditor?.measDataManager.deleteMeasurements(measToDelete);
    dispatch(
      setSnackbarMessage({
        message: "Repérages nettoyés",
        triggeredAt: Date.now(),
      })
    );
  };

  function handleRefreshArticlesQties() {
    dispatch(triggerLastComputedArticles());
  }

  function updateRessourcesFromTypes() {
    const newMs = measurements.map((measurement) => {
      let newM;
      const type = elementTypesById[measurement.elementTypeId];
      const res = type?.res
        ? type.res.map(({resId}) => ({resId, off: false}))
        : [];
      if (res) {
        newM = {...measurement, res};
      } else {
        newM = {...measurement};
      }

      // clean path (decimal places <=4 )
      newM = cleanMeasurementPaths(newM);
      // force count to 1
      newM.count = 1;
      return newM;
    });
    console.log("debug 31-05 action updateRessourcesFromTypes", newMs.length);
    caplaEditor?.measDataManager.updateMeasurements(newMs);
    //
    dispatch(
      setSnackbarMessage({
        message: updateRessourcesFromTypesSuccessMessage,
        triggeredAt: Date.now(),
      })
    );
  }

  // actions map

  const actionsMap = {
    createElementType: {
      icon: <Add fontSize="small" />,
      name: "Elément",
      label: "Créer un élément / ouvrage",
    },
    updateRessourcesFromTypes: {
      icon: <RefreshQtiesIcon fontSize="small" />,
      name: "Quantités",
      label: "Rafraîchir les quantités",
      handler: updateRessourcesFromTypes,
    },
    refreshArticlesQties: {
      icon: <RefreshQtiesIcon fontSize="small" />,
      name: "Quantités",
      label: "Rafraîchir les quantités",
      handler: handleRefreshArticlesQties,
    },
    refresh3D: {
      icon: <Refresh3DIcon fontSize="small" />,
      name: "MAJ 3D",
      label: "Mettre à jour la 3D",
      handler: refresh3D,
      disabled: !os.fixedViewersBox,
    },
    extractMeasFromPdf: {
      label: "Import depuis annotations PDF",
    },
    copyMeasurements: {
      label: "Copier les repérages sélectionnés",
      disabled: !selectedMeasIds.length > 0,
    },
    pasteMeasurements: {
      label: "Coller",
      disabled: !copiedMeasurements?.measurements?.length > 0,
    },
    cleanMeasurements: {
      label: "Nettoyer les repérages",
      handler: cleanMeasurements,
    },
    useTypeColor: {
      label: "Réinitialiser les couleurs",
      handler: useTypeColor,
    },
    automation: {
      icon: <AutomationIcon fontSize="small" />,
      name: "Automatisation",
      label: "Automatisation",
    },
  };

  return actionsMap;
}
