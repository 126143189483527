import {useDispatch} from "react-redux";
import {addMaterial} from "../materialsSlice";
import {nanoid} from "@reduxjs/toolkit";

export default function useCreateMaterial() {
  const dispatch = useDispatch();

  const createMaterial = (material) => {
    const name = material?.name;
    const materialCategoryId = material?.materialCategoryId;
    const code = material?.code;
    const fwc = material?.fwc;

    const newMaterial = {
      clientId: nanoid(),
      name,
      code,
      fwc,
      materialCategoryId,
    };
    dispatch(addMaterial(newMaterial));
    return newMaterial;
  };

  return createMaterial;
}
