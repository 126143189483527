import getUpdatesToRefreshQties from "./getUpdatesToRefreshQties";
import articlesWithStyleToArticlesWithTempNum from "./articlesWithStyleToArticlesWithTempNum";

export default function getArticlesFromTemplateAndSubArticles({
  initialArticlesMap,
  sortedArticlesProxyByGroupId,
  articlesGroup,
  elementTypesGroupsWithSortedItems,
}) {
  // helpers - sorted articles

  const sortedArticlesProxy =
    sortedArticlesProxyByGroupId[articlesGroup?.id] ?? [];

  let articles_step2 = Object.values(initialArticlesMap);

  let articles_step2_byClientId = articles_step2.reduce((acc, article) => {
    acc[article.clientId] = article;
    return acc;
  }, {});
  const sortedArticles_step2 = [];
  sortedArticlesProxy.forEach((clientId) => {
    const article = articles_step2_byClientId[clientId];
    if (article) sortedArticles_step2.push(article);
  });

  // data transfo : sort & add tempNum

  const sortedArticlesWithTempNum_step2 =
    articlesWithStyleToArticlesWithTempNum(sortedArticles_step2);

  // step 2 : compute types for articles

  const batchUpdates = getUpdatesToRefreshQties(
    sortedArticlesWithTempNum_step2,
    elementTypesGroupsWithSortedItems
  );

  return batchUpdates;
}
