import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Box, Paper, Tab, Tabs, Typography, styled} from "@mui/material";

import HeaderRessourceSelectionHelper from "./HeaderRessourceSelectionHelper";

import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import SectionHomeRessourceInSelectionPanel from "Features/boxhome/components/SectionHomeRessourceInSelectionPanel";
import FormIssueDetailInSelectionPanel from "Features/issues/components/FormIssueDetailInSelectionPanel";
import SectionMeasurementOverviewInSelectionPanel from "Features/measurements/components/SectionMeasurementOverviewInSelectionPanel";
import SectionZoneOverviewInLeftPanel from "Features/zones/components/SectionZoneOverviewInSelectionPanel";
import useSelectedRessource from "Features/ressources/hooks/useSelectedRessource";
import {
  setSelectionInViewerFirstRendered,
  setSelectionTabInViewer,
} from "Features/selection/selectionSlice";
import useSelectedIssue from "Features/issues/useSelectedIssue";
import useSelectedMeasurement from "Features/measurements/useSelectedMeasurement";
// import {setShortcutToolViewer3D} from "Features/ui/uiSlice";
import {setSelectedShortcutRight} from "Features/shortcut/shortcutSlice";
import {updateModel} from "Features/viewer3D/viewer3DSlice";

import useSceneModule from "Features/navigation/useSceneModule";
import useSelectedElementType from "Features/elementTypes/hooks/useSelectedElementType";
import SectionElementTypeWithRessourcesOverview from "./SectionElementTypeWithRessourcesOverview";
import SectionSelectedArticleInViewer from "Features/articles/components/SectionSelectedArticleInViewer";
import useSelectedArticle from "Features/articles/hooks/useSelectedArticle";

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({theme}) => ({
    textTransform: "none",
    fontSize: theme.typography.pxToRem(13),
    margin: 0,
    padding: 0,
    minWidth: 50,
  })
);

export default function SectionLeftSelectionInViewer({
  scene,
  caplaEditor,
  viewer,
}) {
  const dispatch = useDispatch();
  // strings

  const ressourceS = "Article";
  const articleS = "Article";
  const elementTypeS = "Type";
  const measurementS = "Element";
  const zonesS = "Fond de plan";
  const issueS = "Note";
  const noSelectedRessourceS = "Pas d'article sélectionné";
  const noSelectedMeasurementS = "Pas d'élément sélectionné";
  const noSelectedTypeS = "Pas de type sélectionné";

  // data

  const shortcutRight = useSelector((s) => s.shortcut.selectedShortcutRight);
  const selectedTabFromState = useSelector(
    (s) => s.selection.selectionTabInViewer
  );
  const firstRendered = useSelector(
    (s) => s.selection.selectionInViewerFirstRendered
  );
  const selectedArticle = useSelectedArticle(scene);
  const selectedRessource = useSelectedRessource(scene);
  const selectedIssue = useSelectedIssue();
  const mainSection = useSelector((s) => s.viewer3D.mainSection);
  const rendererSize = useSelector((s) => s.viewer3D.rendererSize);
  const selectedMeasurement = useSelectedMeasurement();
  const selectedZoneId = useSelector((s) => s.zones.selectedZoneId);
  const models = useSelector((s) => s.viewer3D.models);
  const zones = useZonesByScene(scene);
  const is3dEnabled = useSelector((s) => s.viewer3D.is3dEnabled);
  const sceneModule = useSceneModule();
  const selectedElementType = useSelectedElementType(scene);
  const selectedRessourceGroup = useSelector(
    (s) => s.measurements.selectedRessourceGroup
  );

  const clickedObject = useSelector((s) => s.viewer3D.clickedObject);
  //console.log("clickedObject", clickedObject);

  // helper

  let showTypeNotRessource = ["ELEMENT_TYPES", "MEASUREMENTS"].includes(
    sceneModule
  );
  if (selectedRessourceGroup) showTypeNotRessource = false;

  let selectedTab = selectedTabFromState;
  if (selectedTab === "ressource" && showTypeNotRessource)
    selectedTab = "elementType"; // used to manage initialisation

  const elementTypeInPanel = selectedElementType;

  // helper - tabs

  const tabsMap = {
    //0: showTypeNotRessource ? "elementType" : "ressource",
    0: "article",
    1: "measurement",
    2: "zone",
    //3: "issue",
  };

  const tabsMapInverse = {
    article: 0,
    ressource: 0,
    elementType: 0,
    measurement: 1,
    zone: 2,
    issue: 3,
  };

  const handleChange = (event, newValue) => {
    dispatch(setSelectionTabInViewer(tabsMap[newValue]));
  };

  // helper - clickedModel & zone

  let clickedZone;

  let clickedImageModel;
  if (["IMAGE_ENTITY", "IMAGE_PART_ENTITY"].includes(clickedObject?.type)) {
    clickedImageModel = models.find((m) => m.id === clickedObject?.modelId);
    clickedZone = zones.find(
      (z) => z.id === clickedImageModel?.fromModel?.zoneId
    );
  }

  if (!clickedZone && selectedZoneId) {
    clickedZone = zones.find((z) => z.id === selectedZoneId);
  }

  // helpers

  const maxHeight = rendererSize.height + 50;

  const display = mainSection !== "SCREENSHOTVIEWER";

  if (!firstRendered && (selectedMeasurement || clickedZone || selectedIssue)) {
    dispatch(setSelectedShortcutRight("SELECTION"));
    // else dispatch(setShortcutToolViewer3D("SELECTION"));
    if (selectedMeasurement) dispatch(setSelectionTabInViewer("measurement"));
    if (clickedZone) dispatch(setSelectionTabInViewer("zone"));
    if (selectedIssue) dispatch(setSelectionTabInViewer("issue"));
    dispatch(setSelectionInViewerFirstRendered(true));
  }

  // v   WHY ??  v
  // useEffect(() => {
  //   if (clickedZone && selectedTab !== "zone") {
  //     const model = models.find((m) => m.id === clickedZone?.imageModelId);
  //     const updatedModel = {...model, showMainPart: false};
  //     dispatch(updateModel({updatedModel, sync: false}));
  //     const entity = caplaEditor?.editor3d?.getEntity(model.id);
  //     entity?.hideMainPart();
  //   }
  // }, [selectedTab]);

  if (is3dEnabled && shortcutRight)
    return (
      <Paper
        elevation={12}
        sx={{
          display: display ? "flex" : "none",
          width: 350,
          height: 1,
          maxHeight: maxHeight,
          position: "relative",
          flexDirection: "column",
        }}
      >
        <Box sx={{borderBottom: 1, borderColor: "divider"}}>
          <Tabs
            value={tabsMapInverse[selectedTab]}
            onChange={handleChange}
            variant="fullWidth"
          >
            {/* {showTypeNotRessource ? (
              <StyledTab label={elementTypeS} />
            ) : (
              <StyledTab label={ressourceS} />
            )} */}
            <StyledTab label={articleS} />
            <StyledTab label={measurementS} />
            <StyledTab label={zonesS} />
            {/* <StyledTab label={issueS} /> */}
          </Tabs>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            maxHeight: maxHeight - 36,
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {selectedTab === "article" && (
            <Box>
              <SectionSelectedArticleInViewer
                scene={scene}
                article={selectedArticle}
                caplaEditor={caplaEditor}
              />
            </Box>
          )}
          {selectedTab === "ressource" && selectedRessource && (
            <Box sx={{width: 1}}>
              <HeaderRessourceSelectionHelper
                scene={scene}
                viewer={viewer}
                caplaEditor={caplaEditor}
                selectedRessource={selectedRessource}
              />
              <SectionHomeRessourceInSelectionPanel
                scene={scene}
                caplaEditor={caplaEditor}
                viewer={viewer}
                ressource={selectedRessource}
              />
            </Box>
          )}
          {selectedTab === "ressource" && !selectedRessource && (
            <Box
              sx={{
                p: 1,
                pl: 2,
                display: "flex",
                alignItems: "top",
                width: 1,
              }}
            >
              <Typography sx={{p: 1, fontSize: 13, color: "text.secondary"}}>
                {noSelectedRessourceS}
              </Typography>
            </Box>
          )}
          {selectedTab === "elementType" && elementTypeInPanel && (
            <SectionElementTypeWithRessourcesOverview
              elementType={elementTypeInPanel}
              scene={scene}
              viewer={viewer}
            />
          )}
          {selectedTab === "elementType" && !elementTypeInPanel && (
            <Box
              sx={{
                p: 1,
                pl: 2,
                display: "flex",
                alignItems: "top",
                width: 1,
              }}
            >
              <Typography sx={{p: 1, fontSize: 13, color: "text.secondary"}}>
                {noSelectedTypeS}
              </Typography>
            </Box>
          )}
          {selectedTab === "issue" && (
            <FormIssueDetailInSelectionPanel
              issue={selectedIssue}
              caplaEditor={caplaEditor}
              scene={scene}
              isEditing={false}
            />
          )}
          {selectedTab === "measurement" && selectedMeasurement && (
            <SectionMeasurementOverviewInSelectionPanel
              measurement={selectedMeasurement}
              scene={scene}
              caplaEditor={caplaEditor}
              viewer={viewer}
            />
          )}
          {selectedTab === "measurement" && !selectedMeasurement && (
            <Box
              sx={{
                p: 1,
                pl: 2,
                display: "flex",
                alignItems: "top",
                width: 1,
              }}
            >
              <Typography sx={{p: 1, fontSize: 13, color: "text.secondary"}}>
                {noSelectedMeasurementS}
              </Typography>
            </Box>
          )}
          {selectedTab === "zone" && (
            <SectionZoneOverviewInLeftPanel
              zone={clickedZone}
              caplaEditor={caplaEditor}
            />
          )}
        </Box>
      </Paper>
    );
  return <></>;
}
