import flattenItemsWithChildren from "./flattenItemsWithChildren";
import getItemsWithChildrenFromNum from "./getItemsWithChildrenFromNum";
import getNextNumWithSameRank from "./getNextNumWithSameRank";
import getItemRankFromNum from "./getItemRankFromNum";

function addOthersToItemsWithNumAndChildren(items) {
  const otherLabel = "Autre";

  if (!items || items.length === 0) return [];

  const itemsWithChildrenWithOther = items.map((item) => {
    if (item.children) {
      const lastChildren = item.children[item.children.length - 1];
      const nextNum = getNextNumWithSameRank(lastChildren.num);
      const other = {id: item.id, num: nextNum, name: otherLabel};
      const newChildren = [...item.children, other];
      return {...item, children: newChildren};
    } else {
      return item;
    }
  });

  const itemsWithOthers = flattenItemsWithChildren(itemsWithChildrenWithOther);

  const itemsOfRank1 = itemsWithOthers.filter(
    (item) => getItemRankFromNum(item) === 1
  );
  const lastItemOfRank1 = itemsOfRank1[itemsOfRank1.length - 1];
  const nextNumOfRank1 = getNextNumWithSameRank(lastItemOfRank1.num);
  //
  itemsWithOthers.push({
    id: lastItemOfRank1.id,
    num: nextNumOfRank1,
    name: otherLabel,
  });

  return itemsWithOthers;
}

export default function addOthersToItemsWithNum(items) {
  const itemsWithChildren = getItemsWithChildrenFromNum(items);
  return addOthersToItemsWithNumAndChildren(itemsWithChildren);
}
