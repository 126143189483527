import {Box, Typography} from "@mui/material";

import ContainerEditableLogo from "./ContainerEditableLogo";
import ContainerEditableEmailDomain from "./ContainerEditableEmailDomain";
import ContainerEditableNomenclatures from "./ContainerEditableNomenclatures";

import useSelectedOrganisation from "../hooks/useSelectedOrganisation";
import ContainerEditableBimboxModes from "./ContainerEditableBimboxModes";
import ContainerEditableApiKey from "./ContainerEditableApiKey";

export default function SectionSelectedOrganisation() {
  //data

  const organisation = useSelectedOrganisation();

  // helper

  const display = organisation ? "flex" : "none";

  return (
    <Box sx={{display, flexDirection: "column", width: 1}}>
      <Box sx={{p: 1}}>
        <Typography>{organisation?.name}</Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: 1,
          mt: 1,
          "&>*": {
            mb: 2,
          },
        }}
      >
        <ContainerEditableLogo organisation={organisation} />
        <ContainerEditableEmailDomain organisation={organisation} />
        <ContainerEditableNomenclatures organisation={organisation} />
        <ContainerEditableBimboxModes organisation={organisation} />
        <ContainerEditableApiKey organisation={organisation} />
      </Box>
    </Box>
  );
}
