export default function sceneFromFormToState(sceneForm) {
  const scene = {...sceneForm};
  //
  if (scene.organisation) {
    scene.organisationId = scene.organisation.id;
    delete scene.organisation;
  } else {
    scene.organisationId = null;
    delete scene.organisation;
  }
  //
  return scene;
}
