import {useSelector} from "react-redux";

export default function useNomenclatureByMaterialsGroupId(groupId) {
  const materialsGroupsMap = useSelector((s) => s.materials.materialsGroupsMap);
  const nomenclatures = useSelector((s) => s.materials.nomenclatures);
  const materialsGroup = materialsGroupsMap[groupId];

  const nomenclature = nomenclatures?.find(
    (n) => n.id === materialsGroup?.data?.materialsNomenclatureId
  );

  return nomenclature;
}
