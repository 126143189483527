import {client} from "API/capla360";

export const createCommentService = async ({accessToken, comment}) => {
  const newComment = {...comment};
  const sceneId = comment.sceneId;
  delete newComment.scene;
  newComment.scene = comment.sceneId;

  const response = await client.post(
    `scenes/${sceneId}/comments/`,
    newComment,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {item: response.data, sceneId};
};

export const fetchCommentsService = async ({accessToken, sceneId}) => {
  const response = await client.get(`/scenes/${sceneId}/comments/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const remoteComments = response.data;

  const comments = remoteComments.map((r) => {
    return {...r, sceneId: r.scene};
  });

  return {items: comments, sceneId};
};

export const updateCommentService = async ({accessToken, comment}) => {
  const commentId = comment.id;
  console.log("updateBimAppService", comment);
  if (!commentId) return comment;
  const response = await client.put(`/comments/${commentId}`, comment, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const updatedComment = response.data;
  return {item: {...updatedComment, sceneId: updatedComment.scene}};
};

export const deleteCommentService = async ({accessToken, commentId}) => {
  const response = await client.delete(`/comments/${commentId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return commentId;
};
