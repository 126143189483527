import {useState} from "react";

import useActionsArticlesMap from "../hooks/useActionsArticlesMap";
import useSelectedArticlesGroup from "../hooks/useSelectedArticlesGroup";

import SectionGenericActions from "Components/SectionGenericActions";

import DialogCreateArticlesGroup from "./DialogCreateArticlesGroup";
import DialogCreateArticle from "./DialogCreateArticle";
// import DialogFsEditArticlesGroup from "./DialogFsEditArticlesGroup";
import DialogDeleteArticlesGroup from "./DialogDeleteArticlesGroup";
import DialogDeleteArticle from "./DialogDeleteArticle";
import DialogDeleteAllArticles from "./DialogDeleteAllArticles";
import DialogImportArticlesFromExcel from "./DialogImportArticlesFromExcel";
import DialogAutoUpdateArticlesTypes from "./DialogAutoUpdateArticlesTypes";

import DialogUpdateArticlesQties from "./DialogUpdateArticlesQties";
import {deleteAllArticles} from "../articlesSlice";

export default function SectionActionsArticles({
  scene,
  caplaEditor,
  actionsMainKeys = [],
  actionsMoreKeys = [],
}) {
  // data

  const selectedArticlesGroup = useSelectedArticlesGroup(scene);

  // state

  const [openDialogXxx, setOpenDialogXxx] = useState(false);

  const [openDialogCreateArticlesGroup, setOpenDialogCreateArticlesGroup] =
    useState(false);

  const [openDialogDeleteArticlesGroup, setOpenDialogDeleteArticlesGroup] =
    useState(false);
  const [openDialogCreateArticle, setOpenDialogCreateArticle] = useState(false);
  //
  const [openDialogDeleteArticle, setOpenDialogDeleteArticle] = useState(false);
  //
  const [
    openDialogImportArticlesFromExcel,
    setOpenDialogImportArticlesFromExcel,
  ] = useState(false);
  //
  const [openDialogEditArticlesGroup, setOpenDialogEditArticlesGroup] =
    useState(false);
  const [openDialogDeleteAllArticles, setOpenDialogDeleteAllArticles] =
    useState(false);
  const [openDialogAutoUpdateArticles, setOpenDialogAutoUpdateArticles] =
    useState(false);
  const [openDialogUpdateArticlesQties, setOpenDialogUpdateArticleQties] =
    useState(false);

  // handlerMap

  const setOpenDialogMap = {
    launchXxx: () => setOpenDialogXxx(true),
    createArticlesGroup: () => setOpenDialogCreateArticlesGroup(true),
    deleteArticlesGroup: () => setOpenDialogDeleteArticlesGroup(true),
    deleteAllArticles: () => setOpenDialogDeleteAllArticles(true),
    createArticle: () => setOpenDialogCreateArticle(true),
    deleteArticle: () => setOpenDialogDeleteArticle(true),
    importExcelArticles: () => setOpenDialogImportArticlesFromExcel(true),
    editArticlesGroup: () => setOpenDialogEditArticlesGroup(true),
    autoUpdateArticles: () => setOpenDialogAutoUpdateArticles(true),
    //refreshQties: () => setOpenDialogUpdateArticleQties(true),
  };

  // handlers

  function handleClick(actionKey) {
    console.log("trigger", actionKey);
    //
    const setOpenDialog = setOpenDialogMap[actionKey];
    if (setOpenDialog) setOpenDialog();
    //
    const action = actionsMap[actionKey];
    if (action?.handler) action.handler();
  }

  // handlers - close Dialogs

  function handleCloseDialogCreateArticlesGroup() {
    setOpenDialogCreateArticlesGroup(false);
  }
  function handleCloseDialogCreateArticle() {
    setOpenDialogCreateArticle(false);
  }

  // data - handlers

  const actionsMap = useActionsArticlesMap({scene, caplaEditor});

  const actionsMain = actionsMainKeys.map((key) => ({key, ...actionsMap[key]}));
  const actionsMore = actionsMoreKeys.map((key) => ({key, ...actionsMap[key]}));

  return (
    <>
      <SectionGenericActions
        actionsMain={actionsMain}
        actionsMore={actionsMore}
        onClick={handleClick}
      />

      {openDialogCreateArticlesGroup && (
        <DialogCreateArticlesGroup
          scene={scene}
          caplaEditor={caplaEditor}
          open={openDialogCreateArticlesGroup}
          onClose={handleCloseDialogCreateArticlesGroup}
        />
      )}
      {openDialogCreateArticle && (
        <DialogCreateArticle
          scene={scene}
          caplaEditor={caplaEditor}
          open={openDialogCreateArticle}
          onClose={handleCloseDialogCreateArticle}
        />
      )}
      {/* {openDialogEditArticlesGroup && (
        <DialogFsEditArticlesGroup
          open={openDialogEditArticlesGroup}
          onClose={() => {
            setOpenDialogEditArticlesGroup(false);
          }}
          scene={scene}
          articlesGroup={selectedArticlesGroup}
        />
      )} */}
      {openDialogDeleteArticlesGroup && (
        <DialogDeleteArticlesGroup
          scene={scene}
          caplaEditor={caplaEditor}
          open={openDialogDeleteArticlesGroup}
          onClose={() => setOpenDialogDeleteArticlesGroup(false)}
        />
      )}
      {openDialogDeleteAllArticles && (
        <DialogDeleteAllArticles
          scene={scene}
          caplaEditor={caplaEditor}
          open={openDialogDeleteAllArticles}
          onClose={() => setOpenDialogDeleteAllArticles(false)}
        />
      )}

      {openDialogDeleteArticle && (
        <DialogDeleteArticle
          scene={scene}
          caplaEditor={caplaEditor}
          open={openDialogDeleteArticle}
          onClose={() => setOpenDialogDeleteArticle(false)}
        />
      )}
      {openDialogImportArticlesFromExcel && (
        <DialogImportArticlesFromExcel
          open={openDialogImportArticlesFromExcel}
          onClose={() => {
            setOpenDialogImportArticlesFromExcel(false);
          }}
          scene={scene}
        />
      )}
      {openDialogAutoUpdateArticles && (
        <DialogAutoUpdateArticlesTypes
          open={openDialogAutoUpdateArticles}
          onClose={() => setOpenDialogAutoUpdateArticles(false)}
          scene={scene}
        />
      )}
      {openDialogUpdateArticlesQties && (
        <DialogUpdateArticlesQties
          open={openDialogUpdateArticlesQties}
          onClose={() => setOpenDialogUpdateArticleQties(false)}
          scene={scene}
        />
      )}
    </>
  );
}
