import {useState} from "react";

import {useSelector, useDispatch} from "react-redux";
import {
  setCopiedMeasurements,
  setPasteDeltaX,
  setPasteDeltaY,
  setPasteMulti,
  setPasteFlipH,
  setPasteFlipV,
  setPasteToZone,
} from "../measurementsSlice";

import {
  Box,
  Input,
  Badge,
  TextField,
  Paper,
  IconButton,
  Typography,
  Checkbox,
  FormControlLabel,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import {ContentCopy, Close, Flip} from "@mui/icons-material";

import SelectorAutocomplete from "Components/SelectorAutocomplete";

import useZonesByScene from "Features/zones/hooks/useZonesByScene";

export default function ToolbarPasteMeasurements({scene}) {
  const dispatch = useDispatch();

  // strings

  const title = "Coller";
  const multiS = "plusieurs copies";

  // data

  const copiedMeasurements = useSelector(
    (s) => s.measurements.copiedMeasurements
  );

  const deltaX = useSelector((s) => s.measurements.pasteDeltaX);
  const deltaY = useSelector((s) => s.measurements.pasteDeltaY);
  const multi = useSelector((s) => s.measurements.pasteMulti);
  const flipH = useSelector((s) => s.measurements.pasteFlipH);
  const flipV = useSelector((s) => s.measurements.pasteFlipV);
  const pasteToZone = useSelector((s) => s.measurements.pasteToZone);

  const zones = useZonesByScene(scene, {currentPage: true});

  // helpers

  const copiedCount = copiedMeasurements?.measurements?.length;

  const toggleValue = [];
  if (flipH) toggleValue.push("flipH");
  if (flipV) toggleValue.push("flipV");

  // handlers

  function handleDeltaXChange(e) {
    let value = e.target.value;
    value = value.replace(",", ".");
    dispatch(setPasteDeltaX(value));
  }
  function handleDeltaYChange(e) {
    let value = e.target.value;
    value = value.replace(",", ".");
    dispatch(setPasteDeltaY(value));
  }

  function handleClose() {
    dispatch(setCopiedMeasurements([]));
  }

  function handleToggleChange(e, value) {
    const newFlipH = value.includes("flipH");
    const newFlipV = value.includes("flipV");
    if (newFlipH !== flipH) dispatch(setPasteFlipH(newFlipH));
    if (newFlipV !== flipV) dispatch(setPasteFlipV(newFlipV));
  }

  return (
    <Paper sx={{p: 0.5}}>
      <Box sx={{display: "flex", alignItems: "center"}}>
        <Badge badgeContent={copiedCount} color="secondary">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              px: 1,
            }}
          >
            <Typography sx={{fontSize: 12}}>{title}</Typography>
          </Box>
        </Badge>
        <Box sx={{mx: 2}}>
          <TextField
            sx={{width: 60, mr: 1}}
            size="small"
            value={deltaX}
            onChange={handleDeltaXChange}
            label="ΔX m"
            InputProps={{sx: {fontSize: 12}}}
          />
          <TextField
            sx={{width: 60}}
            size="small"
            value={deltaY}
            onChange={handleDeltaYChange}
            label="ΔY m"
            InputProps={{sx: {fontSize: 12}}}
          />
        </Box>
        <ToggleButtonGroup
          size="small"
          onChange={handleToggleChange}
          value={toggleValue}
        >
          <ToggleButton value="flipH">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                rotate: "90deg",
              }}
            >
              <Flip />
            </Box>
          </ToggleButton>
          <ToggleButton value="flipV">
            <Flip />
          </ToggleButton>
        </ToggleButtonGroup>
        <IconButton onClick={handleClose} size="small">
          <Close fontSize="small" />
        </IconButton>
      </Box>
      <Box sx={{p: 1}}>
        <SelectorAutocomplete
          options={zones}
          value={pasteToZone}
          onChange={(zone) => dispatch(setPasteToZone(zone))}
        />
      </Box>
      <Box sx={{ml: 1}}>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={multi}
              onChange={(e) => dispatch(setPasteMulti(e.target.checked))}
            />
          }
          label={<Typography sx={{fontSize: 12}}>{multiS}</Typography>}
        />
      </Box>
    </Paper>
  );
}
