import {Box} from "@mui/material";
import KeyValueForm from "Features/kvform/components/KeyValueForm";
import useKvtScene from "Features/kvform/hooks/useKvtScene";
import useUserOrganisation from "Features/organisations/hooks/useUserOrganisation";

import sceneFromFormToState from "../utils/sceneFromFormToState";

export default function FormSceneKvf({scene, onChange}) {
  // data

  const template = useKvtScene();

  const userOrganisation = useUserOrganisation();

  // handlers

  function handleChange(newScene) {
    const sceneInState = sceneFromFormToState(newScene);
    if (onChange) onChange(sceneInState);
  }

  // helpers

  const item = {...scene};
  if (scene.organisationId) item.organisation = userOrganisation;

  return (
    <Box sx={{borderTop: (theme) => `1px solid ${theme.palette.divider}`}}>
      <KeyValueForm
        sizeKey={4}
        scene={scene}
        item={item}
        template={template}
        onChange={handleChange}
      />
    </Box>
  );
}
