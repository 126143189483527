export default function markerToMarkerNodeProps({marker, imageNode}) {
  const imageWidth = imageNode.width();
  const imageHeight = imageNode.height();
  //
  const x = marker.x * imageWidth + imageNode.x();
  const y = marker.y * imageHeight + imageNode.y();
  //
  const label = marker.label;
  const id = marker.id;
  //
  let variant = "DEFAULT";
  //
  return {id, x, y, label, variant};
}
