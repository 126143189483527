import getItemsMapById from "Utils/getItemsMapById";
import useZonesByScene from "./useZonesByScene";
import {useSelector} from "react-redux";

export default function useZoneFromImageModelId(scene, imageModelId) {
  const models = useSelector((s) => s.viewer3D.models);
  const zonesMap = useZonesByScene(scene, {variant: "map"});

  const modelsMap = getItemsMapById(models);

  const imageModel = modelsMap[imageModelId];

  const zoneId = imageModel?.fromModel?.zoneId;

  const zone = zonesMap[zoneId];

  return zone;
}
