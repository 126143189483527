import {useState} from "react";

import {Box, Typography, List, ListItemButton} from "@mui/material";

import getItemRankFromNum from "Utils/getItemRankFromNum";
import ListMaterialsFromNomenclatureParents from "./ListMaterialsFromNomenclatureParents";
import getItemChildrenFromNum from "Utils/getItemChildrenFromNum";
import addOthersToItemsWithNum from "Utils/addOthersToItemsWithNum";
import sortByNum from "Utils/sortByNum";

export default function ListMaterialsFromNomenclature({
  materials,
  nomenclature,
  variant,
  onClick,
  selectedMaterialsClientIds,
}) {
  const nomenclatureItems = nomenclature?.tree?.items ?? [];
  const sortedItems = [...nomenclatureItems].sort((a, b) =>
    sortByNum(a.num, b.num)
  );
  //
  const enhancedItems = addOthersToItemsWithNum(sortedItems);

  // state

  const [parents, setParents] = useState([]);

  const showedRank = parents.length + 1;
  const lastParent = parents.length > 0 ? parents[parents.length - 1] : null;

  // helpers - items

  let items = getItemChildrenFromNum(lastParent, enhancedItems);

  // handlers

  function handleClick(item) {
    if (!item.isMaterial) {
      setParents([...parents, item]);
      return;
    }
    if (onClick) {
      onClick(item);
    }
  }

  function handleRootClick() {
    setParents([]);
  }

  function handleParentClick(parent) {
    const parentIndex = parents.findIndex((p) => p.id === parent.id);
    setParents(parents.slice(0, parentIndex + 1));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        height: 1,
        flexDirection: "column",
        minHeight: 0,
      }}
    >
      <Box sx={{bgcolor: "background.default"}}>
        <ListMaterialsFromNomenclatureParents
          parents={parents}
          onParentClick={handleParentClick}
          onRootClick={handleRootClick}
        />
      </Box>
      <Box
        sx={{
          width: 1,
          flexGrow: 1,
          overflowY: "auto",
          minHeight: 0,
        }}
      >
        <List dense disablePadding sx={{bgcolor: "common.white"}}>
          {items.map((item) => {
            const selected = selectedMaterialsClientIds?.includes(item.id);
            return (
              <ListItemButton
                key={item.id + item.num}
                selected={selected}
                disableGutters
                onClick={() => handleClick(item)}
                divider
              >
                <Box sx={{px: 1}}>
                  <Typography sx={{fontSize: 12}}>{item.name}</Typography>
                </Box>
              </ListItemButton>
            );
          })}
        </List>
      </Box>
    </Box>
  );
}
