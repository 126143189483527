import {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {setSelectionPanelWidth} from "../selectionSlice";

import {Box, Paper} from "@mui/material";

import HeaderSelection from "./HeaderSelection";
import HeaderSelectionMulti from "./HeaderSelectionMulti";
import SectionSelection from "./SectionSelection";
import SectionSelectionMulti from "./SectionSelectionMulti";
import SectionActions from "./SectionActions";
import SectionActionsMulti from "./SectionActionsMulti";

import useSelection from "../useSelection";
import useSelectionMulti from "../useSelectionMulti";

import Stretch from "Features/ui/components/Stretch";
import zoomInOut from "Features/ui/zoomInOut";
import usePageType from "Features/navigation/usePageType";
import useSceneModule from "Features/navigation/useSceneModule";
import useIsCreatingZone from "Features/zones/hooks/useIsCreatingZone";

import HeaderSelectionByModule from "./HeaderSelectionByModule";
import ContainerModeSelector from "./ContainerModeSelector";
import useForceSelectionType from "../useForceSelectionType";

export default function PanelSelectionContent({
  caplaEditor,
  scene,
  viewer,
  mode,
}) {
  const dispatch = useDispatch();

  // data

  const _forceSelectionType = useForceSelectionType();
  const forceSelectionType = mode === "SELECTION" ? _forceSelectionType : null;

  const selection = useSelection({
    scene,
    caplaEditor,
    forceSelectionType,
    viewer,
  });
  console.log("selection PSC", selection, forceSelectionType);

  const selectionMulti = useSelectionMulti();

  const pageType = usePageType();
  const sceneModule = useSceneModule();

  const width = useSelector((s) => s.selection.selectionPanelWidth);
  const defaultWidth = useSelector(
    (s) => s.selection.selectionPanelWidthDefault
  );

  const open = useSelector((s) => s.selection.openSelectionPanel);

  const isCreatingZone = useIsCreatingZone();

  const selectedArticleId = useSelector((s) => s.articles.selectedArticleId);

  const isLargePanel = useSelector((s) => s.selection.panelIsLarge);

  // effect to double size

  // helper

  const isMulti = selectionMulti?.items?.length > 1;

  //const showMulti = isMulti && ["MEASUREMENTS"].includes(sceneModule);
  const showMulti =
    isMulti &&
    (mode === "SELECTION" ||
      ["MEASUREMENTS", "EDIT_MEASUREMENTS"].includes(sceneModule));

  //const showSpecificHeader = ["ARTICLES"].includes(sceneModule);
  const showSpecificHeader = selection?.type === "ARTICLE";

  const showDefaultHeader = !showSpecificHeader;

  // helper • display

  //const display = (open || pageType === "HOME") && !["LIBRARY", "PRICINGS"].includes(sceneModule);
  const display =
    open && !["LIBRARY", "PRICINGS", "RELATIONS"].includes(sceneModule);

  return (
    <Box
      sx={{
        height: 1,
        width: 1,
        position: "relative",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Paper
        elevation={12}
        sx={{
          boxSizing: "border-box",
          display: display ? "flex" : "none",
          flexDirection: "column",
          width,
          minWidth: width,
          justifyContent: "space-between",
          flexGrow: 1,
          minHeight: 0,
          bgcolor: "common.white",
          //borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
          position: "relative",
        }}
      >
        {!isCreatingZone && !showMulti && (
          <Box>
            {showDefaultHeader && (
              <HeaderSelection
                scene={scene}
                caplaEditor={caplaEditor}
                viewer={viewer}
                forceSelectionType={forceSelectionType}
              />
            )}
            {showSpecificHeader && <HeaderSelectionByModule scene={scene} />}
          </Box>
        )}
        {showMulti && <HeaderSelectionMulti scene={scene} />}
        <Box
          sx={{
            overflowY: "auto",
            position: "relative",
            flexGrow: 1,
            boxSizing: "border-box",
            minHeight: 0,
            display: "flex",
            flexDirection: "column",
            //border: "3px solid orange",
          }}
        >
          {showMulti ? (
            <SectionSelectionMulti scene={scene} caplaEditor={caplaEditor} />
          ) : (
            <SectionSelection
              caplaEditor={caplaEditor}
              scene={scene}
              viewer={viewer}
              forceSelectionType={forceSelectionType}
            />
          )}
        </Box>

        <Box sx={{boxSizing: "border-box"}}>
          {!viewer && !showMulti && (
            <Box sx={{width: 1}}>
              <SectionActions
                caplaEditor={caplaEditor}
                scene={scene}
                selection={selection}
              />
            </Box>
          )}
          {!viewer && showMulti && (
            <Box sx={{width: 1}}>
              <SectionActionsMulti
                caplaEditor={caplaEditor}
                scene={scene}
                selection={selection}
              />
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
}
