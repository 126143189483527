import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {FormControlLabel, Switch, Typography, Box} from "@mui/material";

import {setUseOptimized} from "Features/pdf/pdfSlice";
import useSelectedPdfModel from "Features/pdf/useSelectedPdfModel";

export default function SwitchOptimizedPdfFile({pdfModel}) {
  const dispatch = useDispatch();

  // strings

  const disableOptiS = "PDF optimisé";

  // data

  const os = useSelector((s) => s.ui.openSections);
  const toggleValue = useSelector((s) => s.pdf.useOptimized);

  // helper

  const display = pdfModel?.fileLowResoRemoteUrl && os.fixedViewersBox;

  // state

  const [checked, setChecked] = useState(toggleValue);

  // handler

  const handleCheckChange = () => {
    dispatch(setUseOptimized(!checked));
    setChecked(!checked);
  };

  return (
    <Box sx={{pl: 2, display: display ? "flex" : "none"}}>
      <FormControlLabel
        control={
          <Switch
            size="small"
            checked={checked}
            onChange={handleCheckChange}
            color="secondary"
          />
        }
        label={<Typography variant="body2">{disableOptiS}</Typography>}
      />
    </Box>
  );
}
