/*
 * Camera2 = camera used in multiviews. Perspective camera.
 */

import theme from "Styles/theme";
import {
  SphereGeometry,
  Mesh,
  MeshBasicMaterial,
  Vector3,
  Color,
  Vector2,
  Object3D,
} from "three";
import {CSS2DObject} from "three/examples/jsm/renderers/CSS2DRenderer.js";
import {snapPointToCircle, snapAngleRadToCircle} from "../utils/snapToCircle";

export default class Camera2Helper {
  constructor({editor}) {
    this.editor = editor;
    this.radius = 0.1;
    this.dragging = false;
    this.draggingEyes = false;
    this.eyesSize = 40;
    this.eyesDiam = 40;
    this.pointerSize = 40; // / ! \ pb when pointerSize != eyesDiam
    this.centerSize = 8;
  }

  createObject() {
    // need to be called once the camera2 is created.
    const geometry = new SphereGeometry(this.radius, 16, 16);
    const material = new MeshBasicMaterial({
      color: new Color(theme.palette.primary.main),
      transparent: true,
      opacity: 0.8,
    });
    const object = new Mesh(geometry, material);
    object.layers.enable(1);
    this.editor.scene.add(object);

    // label
    const pointer = document.createElement("div");
    pointer.style.height = `${this.pointerSize}px`;
    pointer.style.width = `${this.pointerSize}px`;
    pointer.style.borderRadius = `${this.pointerSize / 2}px`;
    pointer.style.transform = `translate(-${this.pointerSize / 2}px,-${
      this.pointerSize / 2
    }px)`;
    //pointer.style.border = `1px solid ${theme.palette.primary.main}`;
    pointer.style.backgroundColor = "rgb(255,255,255,0.1)";
    pointer.style.cursor = "move";
    pointer.style.position = "relative";
    pointer.style.zIndex = 2;

    pointer.addEventListener(
      "mouseover",
      (e) => (pointer.style.backgroundColor = "rgb(250,180,31,0.1)")
    );

    pointer.addEventListener(
      "mouseleave",
      (e) => (pointer.style.backgroundColor = "rgb(255,255,255,0.1)")
    );

    const center = document.createElement("div");

    center.style.height = `${this.centerSize}px`;
    center.style.width = `${this.centerSize}px`;
    center.style.borderRadius = `${this.centerSize / 2}px`;
    center.style.transform = `translate(-${this.centerSize / 2}px,-${
      this.centerSize / 2
    }px)`;
    center.style.backgroundColor = "rgb(250,180,31)";
    center.style.position = "absolute";
    center.style.top = `${this.pointerSize / 2}px`;
    center.style.left = `${this.pointerSize / 2}px`;

    const eyes = document.createElement("div");
    eyes.style.height = `${this.eyesSize}px`;
    eyes.style.width = `${this.eyesSize}px`;
    eyes.style.borderRadius = "10px";
    eyes.style.clipPath = "polygon(0% 50%, 100% 0%, 100% 100%)";

    eyes.style.background = `linear-gradient(to right,rgb(250,180,31,1),rgb(250,180,31,0))`;
    //eyes.style.border = `1px solid ${theme.palette.primary.main}`;
    eyes.style.cursor = "grab";
    eyes.style.position = "absolute";
    eyes.style.top = "0px";
    eyes.style.left = `${this.eyesDiam / 2}px`;
    eyes.style.transformOrigin = "center";
    eyes.style.transform = `translate(-${this.eyesSize / 2}px,-${
      this.eyesSize / 2
    }px)`;

    pointer.appendChild(eyes);
    pointer.appendChild(center);

    // WIP

    const el1 = this.editor.multiviews.el1;

    // helpers

    const moveEyes = (x, y, angleDeg) => {
      eyes.style.top = `calc(${y}px + ${this.eyesDiam / 2}px)`;
      eyes.style.left = `calc(${x}px + ${this.eyesDiam / 2}px)`;
      eyes.style.transform = `translate(-${this.eyesSize / 2}px,-${
        this.eyesSize / 2
      }px) rotate(${angleDeg}deg)`;
    };
    // events - pointer

    const onPointerdown = (e) => {
      e.stopPropagation();
      this.dragging = true;
      el1.addEventListener("pointermove", onPointermove);
      el1.addEventListener("pointerup", onPointerup);
    };

    const onPointermove = (event) => {
      // optim
      if (!this.editor.sceneEditor.optimized && this.dragging)
        this.editor.sceneEditor.optimize();

      event.stopPropagation();
      event.preventDefault();

      const nextPosition = new Vector3();

      const camera2 = this.editor.cameras.camera2;
      const camera1 = this.editor.cameras.camera1;
      const raycaster = this.editor.raycaster;

      const distance = Math.abs(camera1.position.y - camera2.position.y);

      if (this.dragging && !this.draggingEyes) {
        let pointer = {};
        const bounds = el1.getBoundingClientRect();

        const x1 = event.clientX - bounds.left;
        const x2 = bounds.right - bounds.left;
        pointer.x = (x1 / x2) * 2 - 1;

        const y1 = event.clientY - bounds.top;
        const y2 = bounds.bottom - bounds.top;
        pointer.y = -(y1 / y2) * 2 + 1;

        raycaster.setFromCamera(pointer, camera1);
        raycaster.ray.at(distance, nextPosition);

        camera2.position.copy(nextPosition);
      }
    };

    const onPointerup = (e) => {
      // optim
      if (this.editor.sceneEditor.optimized)
        this.editor.sceneEditor.restoreState();

      el1.removeEventListener("pointermove", onPointermove);
      el1.removeEventListener("pointerup", onPointerup);
      this.dragging = false;
    };

    pointer.addEventListener("pointerdown", onPointerdown);

    // events - eyes

    const dir2in3D = new Vector3();
    const dir2in2D = new Vector2();
    const newTarget = new Vector3();

    const onPointerdownEyes = (event) => {
      event.stopPropagation();
      this.draggingEyes = true;
      el1.addEventListener("pointermove", onPointermoveEyes);
      el1.addEventListener("pointerup", onPointerupEyes);
    };

    const onPointermoveEyes = (event) => {
      event.stopPropagation();

      // optim
      if (!this.editor.sceneEditor.optimized && this.draggingEyes)
        this.editor.sceneEditor.optimize();

      if (this.draggingEyes) {
        const cam2 = this.editor.cameras.camera2;
        const orbit2 = this.editor.controls.orbitControls2;

        cam2.getWorldDirection(dir2in3D);
        dir2in2D.set(dir2in3D.x, dir2in3D.z);
        const angleRadStart = dir2in2D.angle();

        //cam2.rotateY(angleRad);

        const pointerBounds = pointer.getBoundingClientRect();

        const origin = {
          x: pointerBounds.left + this.eyesDiam / 2,
          y: pointerBounds.top + this.eyesDiam / 2,
        };

        const point = {
          x: event.clientX,
          y: event.clientY,
        };
        const radius = this.eyesDiam / 2;

        const {pos, angleRad: angleRadEnd} = snapPointToCircle(
          origin,
          radius,
          point
        );
        const angleDeg = (angleRadEnd * 180) / Math.PI;

        moveEyes(pos.x, pos.y, angleDeg.toFixed(0));

        // compute new control target

        const deltaAngle = -angleRadEnd + angleRadStart;
        const pivotO = new Object3D();
        const target = new Vector3();
        pivotO.position.copy(cam2.position);
        target.copy(orbit2.target);
        target.sub(pivotO.position);
        pivotO.rotateY(deltaAngle);
        pivotO.updateMatrixWorld();
        pivotO.localToWorld(target);
        orbit2.target.copy(target);
        orbit2.update();
      }
    };

    const onPointerupEyes = (event) => {
      event.stopPropagation();

      // optim
      if (this.editor.sceneEditor.optimized)
        this.editor.sceneEditor.restoreState();

      this.draggingEyes = false;
      el1.removeEventListener("pointermove", onPointermoveEyes);
      el1.removeEventListener("pointerup", onPointerupEyes);
    };

    eyes.addEventListener("pointerdown", onPointerdownEyes);

    const objectCSS = new CSS2DObject(pointer);

    // v Comment to remove the pointer v
    //this.editor.scene.add(objectCSS);

    this.objectCSS = objectCSS;
    this.object = object;
    this.eyes = eyes;
    this.pointer = pointer;

    this.disable();
  }

  update() {
    if (this.object && this.objectCSS) {
      this.object.position.copy(this.editor.cameras.camera2.position);
      this.objectCSS.position.copy(this.editor.cameras.camera2.position);
    }

    if (this.editor.cameras?.camera2 && this.eyes && !this.draggingEyes) {
      const cam2 = this.editor.cameras.camera2;
      const dir2in3D = new Vector3();
      const dir2in2D = new Vector2();

      cam2.getWorldDirection(dir2in3D);
      dir2in2D.set(dir2in3D.x, dir2in3D.z);
      const angleRad = dir2in2D.angle();
      const angleDeg = (angleRad * 180) / Math.PI;

      const pointerBounds = this.pointer.getBoundingClientRect();

      const origin = {
        x: pointerBounds.left + this.eyesDiam / 2,
        y: pointerBounds.top + this.eyesDiam / 2,
      };

      const {x, y} = snapAngleRadToCircle(origin, this.eyesDiam / 2, angleRad);
      this.eyes.style.top = `calc(${y}px + ${this.eyesDiam / 2}px)`;
      this.eyes.style.left = `calc(${x}px + ${this.eyesDiam / 2}px)`;
      this.eyes.style.transform = `translate(-${this.eyesSize / 2}px,-${
        this.eyesSize / 2
      }px) rotate(${angleDeg.toFixed(0)}deg)`;
    }
  }

  enable() {
    console.log("enable cam2helper", this.pointer);
    this.pointer.hidden = false;
    this.object.layers.enable(1);
    this.objectCSS.layers.enable(1);
  }

  disable() {
    this.pointer.hidden = true;
    this.object.layers.disable(1);
    this.objectCSS.layers.disable(1);
  }
}
