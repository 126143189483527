import {forwardRef} from "react";
import {useSelector, useDispatch} from "react-redux";

import {Dialog, Button, Typography, Box, Fade} from "@mui/material";

import ContainerLoadingBimbox from "./ContainerLoadingBimbox";
import {useNavigate, useSearchParams} from "react-router-dom";
import {setOpenDialogFsInvalidBimbox} from "../overviewerSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} timeout={{enter: 0, exit: 1000}} />;
});

export default function DialogFsInvalidBimbox() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // helpers

  const suffixUrl = "";

  // strings

  const warningS = "Bimbox non reconnue";

  const goHomeS = "Voir mes bimboxes";

  // data

  const open = useSelector((s) => s.overviewer.openDialogFsInvalidBimbox);

  // handlers

  function goHome() {
    const url = `/` + suffixUrl;
    dispatch(setOpenDialogFsInvalidBimbox(false));
    navigate(url);
  }

  return (
    <Dialog open={open} fullScreen TransitionComponent={Transition}>
      <Box
        sx={{
          display: "flex",
          width: 1,
          height: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "common.caplaBlack",
          position: "relative",
          color: "common.white",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "42px",
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            color: "common.white",
          }}
        >
          <Typography variant="h6">{warningS}</Typography>
          <Button color="inherit" sx={{mt: 3}} onClick={goHome}>
            {goHomeS}
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <ContainerLoadingBimbox />
        </Box>
      </Box>
    </Dialog>
  );
}
