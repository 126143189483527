import getNumForSorting from "Utils/getNumForSorting";

function getQuantity(unit, m, filterNoVoids) {
  let q;
  if (unit === "UN") q = m.count;
  if (unit === "ML") q = m.lengthNet ?? m.length;
  if (unit === "M2") q = m.areaNet ?? m.area;
  if (unit === "M3") q = m.volumeNet ?? m.volume;
  return q;
}

export default function getMeasurementsForExcelByTypes(measurements, data) {
  const elementTypesById = data.elementTypesById;
  const roomsById = data.roomsById ?? {};
  const sectorsById = data.sectorsById;

  const measWithType = [];

  measurements.forEach((m) => {
    const elementType = elementTypesById[m.elementTypeId];
    const room = roomsById[m.roomId];
    const sector = sectorsById[m.sectorId];
    measWithType.push({
      id: m.id,
      code: m.codeName,
      isVoid: m.isVoid,
      element_type_group: elementType?.group,
      element_type_num: elementType?.num ? elementType?.num : "0",
      element_type_num_for_sorting: elementType?.num
        ? getNumForSorting(elementType?.num)
        : "0",
      element_type_name: elementType?.name ? elementType.name : "-",
      element_type_unit: elementType?.unit,
      element_type_quantity_value: getQuantity(elementType?.unit, m),
      sector_name: sector?.name ? sector.name : "-",
      sector_building: sector?.building ? sector.building : "-",
      sector_z: sector?.z ? sector.z : "-",
      room_sector: room?.sector ? room.sector : "-",
      room_num: room?.num ? room.num : "-",
      room_name: room?.name ? room.name : "-",
      room_type: room?.type ? room.type : "-",
    });
  });
  return measWithType;
}
