import {useEffect, useRef} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {unwrapResult} from "@reduxjs/toolkit";

import {Box} from "@mui/material";

import {
  setIsLoaded,
  setIsViewerFullScreen,
  setCanUpdateModels,
  updateScenesStatus,
  fetchStoredModel,
  fetchStoredModels,
} from "Features/viewer3D/viewer3DSlice";
import {setOpen} from "Features/navigation/navigationSlice";
import {setSelectedFixedViewer} from "Features/ui/uiSlice";
import {setShowScreenDatagridHelper} from "Features/leftPanel/leftPanelSlice";
import useSceneIsLoaded from "Features/scenes/useSceneIsLoaded";
import useScene from "Features/scenes/useScene";
import useSharedRemoteScene from "Features/scenes/useSharedRemoteScene";
import useColoringScene from "Features/colorings/useColoringScene";
import PanelSelection from "Features/selection/components/PanelSelection";
import AppTopBar from "Features/navigation/components/AppTopBar";
import Stretch from "Features/ui/components/Stretch";
import Viewer3D from "Features/viewer3D/components/Viewer3D";
import PageDownloadingScene from "Features/scenes/components/PageDownloadingScene";
import useMeasurementsModelsInitialScope from "Features/measurements/useMeasurementsModelsInitialScope";
import usePageType from "Features/navigation/usePageType";
import DialogCreateElementTypeFast from "Features/elementTypes/components/DialogCreateElementTypeFast";
import useSelectedScene from "Features/scenes/useSelectedScene";
import useIsFullscreen from "Features/ui/useIsFullscreen";
import DialogMobileScene from "Features/scenes/components/DialogMobileScene";
import SectionMeasurementsInLeftPanel from "Features/leftPanel/components/SectionRessourcesInLeftPanelInViewer";

export default function AppMobile({caplaEditor}) {
  const dispatch = useDispatch();

  // refs

  const editorRef = useRef();

  // params

  const params = useParams();

  // data

  const scene = useScene(params.sceneId);
  const selectedScene = useSelectedScene();
  const sharedRemoteScene = useSharedRemoteScene({shareLink: params.shareLink});
  const coloringScene = useColoringScene(params.coloringId);
  const fullscreen = useIsFullscreen();
  const scenesStatus = useSelector((state) => state.viewer3D.scenesStatus);
  const sceneStatus = scenesStatus[scene?.clientId];
  const space = false; // used to display space & multiscenes
  const remoteDataOnly = useSelector((s) => s.sync.remoteDataOnly);
  const pageType = usePageType();

  // helpers • active scene

  const isHome = Object.keys(params).length === 0;

  const isShared = Boolean(params.shareLink);
  const isColoring = Boolean(params.coloringId);

  let activeScene = scene;
  if (isShared) activeScene = sharedRemoteScene;
  if (isColoring) activeScene = coloringScene;

  // data helper

  const sceneIsLoaded = useSceneIsLoaded(activeScene);

  // helpers - showSelectionPanel

  const showSelectionPanel = pageType === "HOME" || sceneIsLoaded;

  // effect - initial measurements scope

  useMeasurementsModelsInitialScope(activeScene);

  // effect - init

  useEffect(() => {
    dispatch(setShowScreenDatagridHelper(false));
  }, []);
  // effect - nav panel

  useEffect(() => {
    if (isShared) {
      dispatch(setOpen(false));
    }
  }, [isShared]);

  // effects - can update models

  useEffect(() => {
    if (isShared) {
      dispatch(setCanUpdateModels(false));
    } else {
      dispatch(setCanUpdateModels(true));
    }
  }, [isShared]);

  // handlers

  function handleEditorCreated(editor) {
    editorRef.current = editor;
    window.editor = editorRef.current; // make editor accessible from the browser
    dispatch(setIsLoaded(true));
  }

  // effects

  useEffect(() => {
    if (isHome) {
      dispatch(setIsViewerFullScreen(false));
    }
  }, [isHome]);

  /*
   * SCENE & MODELS PRE-LOADING
   */

  // preload models in scene

  async function fetchClientModels(sceneClientId) {
    const result = await dispatch(fetchStoredModels(sceneClientId, false));
    const {models} = unwrapResult(result);
    for (let model of models) {
      if (model.enabled) await dispatch(fetchStoredModel(model.id));
    }
    dispatch(updateScenesStatus({sceneClientId, status: "preloaded"})); // scene's models in state
  }

  useEffect(() => {
    if (
      !space &&
      !isShared &&
      sceneStatus === "idle" &&
      scene?.clientId &&
      (!remoteDataOnly || !scene?.id)
    ) {
      fetchClientModels(scene.clientId); // preload all the models in the store.
    }
  }, [scene?.clientId, sceneStatus, space, remoteDataOnly, isShared]);

  /*
   * COLORING PAGES
   */
  useEffect(() => {
    if (pageType === "NEW_COLORING") dispatch(setOpen(false));
    if (pageType === "COLORING") dispatch(setOpen(false));
  }, [pageType]);

  /*
   * WORKSITES & FIXED VIEWER
   */
  useEffect(() => {
    if (pageType === "WORKSITES") {
      dispatch(setSelectedFixedViewer("MAP"));
    } else {
      dispatch(setSelectedFixedViewer("PDF"));
    }
  }, [pageType]);

  return (
    <Stretch>
      <DialogCreateElementTypeFast scene={scene} />
      <DialogMobileScene scene={activeScene} viewer={isShared} />

      <Box
        sx={{
          width: 1,
          height: 1,
          overflow: "hidden",
          display: "flex",
          boxSizing: "border-box",
          position: "relative",
          flexDirection: "column",
          //border: (theme) => `8px solid ${theme.palette.primary.flash}`,
        }}
      >
        {(isHome || activeScene) && (
          <Box
            sx={{
              position: "absolute",
              right: "8px",
              left: "8px",
              top: "8px",
              zIndex: "100",
            }}
          >
            <AppTopBar
              editor={editorRef.current}
              scene={activeScene}
              viewer={isShared}
            />
          </Box>
        )}

        <Box
          sx={{
            height: 1,
            width: 1,
            position: "relative",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            minWidth: 0,
            minHeight: 0,
          }}
        >
          <Box sx={{width: 1, flexGrow: 1}}>
            <Viewer3D
              onEditorCreated={handleEditorCreated}
              scene={activeScene}
              viewer={isShared}
            />
          </Box>

          {!fullscreen && (
            <Box
              sx={{
                width: 1,
                height: "60%",
                display: "flex",
                minWidth: 0,
                minHeight: 0,
                overflow: "hidden",
              }}
            >
              {activeScene || isHome || pageType === "NEW_COLORING" ? (
                <SectionMeasurementsInLeftPanel
                  editor={editorRef.current}
                  scene={activeScene}
                  viewer={isShared}
                />
              ) : (
                <PageDownloadingScene />
              )}
            </Box>
          )}
        </Box>

        {false && showSelectionPanel && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 10,
            }}
          >
            <PanelSelection
              scene={pageType === "HOME" ? selectedScene : activeScene}
              editor={editorRef.current}
              viewer={isShared}
            />
          </Box>
        )}
      </Box>
    </Stretch>
  );
}
