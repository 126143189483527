import {useDispatch} from "react-redux";

import {setSelectedPoiId} from "../poisSlice";

import {useState} from "react";
import {IconButton, Menu, MenuItem} from "@mui/material";
import {MoreVert as More} from "@mui/icons-material";

import DialogDeletePoi from "./DialogDeletePoi";

export default function ButtonMoreMenuPoi({poi, scene}) {
  const dispatch = useDispatch();
  // string

  const deleteS = "Supprimer";

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  // handlers

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleCloseMenu() {
    setAnchorEl(null);
  }
  function handleClose() {
    dispatch(setSelectedPoiId(null));
    setOpenDeleteDialog(false);
  }
  // handlers - menu - delete

  function handleDelete() {
    setOpenDeleteDialog(true);
    setAnchorEl(null);
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <More fontSize="small" />
      </IconButton>

      <DialogDeletePoi
        scene={scene}
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onDeleted={handleClose}
      />

      <Menu open={open} onClose={handleCloseMenu} anchorEl={anchorEl}>
        <MenuItem onClick={handleDelete}>{deleteS}</MenuItem>
      </Menu>
    </>
  );
}
