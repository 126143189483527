import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import useScene from "./useScene";
import useScenesAndRemoteScenes from "./useScenesAndRemoteScenes";

export default function useSelectedScene() {
  //const allScenes = useScenesAndRemoteScenes();
  const allScenes = useSelector((s) => s.scenes.items);
  const id = useSelector((s) => s.scenes.selectedSceneClientId);
  const params = useParams();
  const scene = useScene(params.sceneId);

  if (params.sceneId) {
    return scene;
  } else if (id) {
    return allScenes.find((s) => s.clientId === id);
  } else {
    return null;
  }
}
