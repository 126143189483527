import {useSelector, useDispatch} from "react-redux";

import {Box, Button, alpha} from "@mui/material";
import {Edit, Save} from "@mui/icons-material";

import {
  setIsEditingMeasurementMulti,
  setEditedMeasurementMulti,
  // updateMeasurements,
  setSelectedMeasurementIds,
  setMeasurementsEditedFromForm,
  setEditedTimeStamp,
} from "../measurementsSlice";
import useSelectedMeasurementMulti from "../useSelectedMeasurementMulti";
import useSceneMeasurements from "../useSceneMeasurements";
import updateMeasurementZsFromZone from "../utils/updateMeasurementZsFromZone";

import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import getItemsMapById from "Utils/getItemsMapById";

export default function ActionsMeasurementMultiInSelectionPanel({
  caplaEditor,
  scene,
}) {
  const dispatch = useDispatch();
  // strings

  const saveS = "Save";
  const editS = "Edit";
  const cancelS = "Cancel";

  // data

  const isEditing = useSelector(
    (s) => s.measurements.isEditingMeasurementMulti
  );
  const selectedMeasurementMulti = useSelectedMeasurementMulti(scene);
  const editedMeasurementMulti = useSelector(
    (s) => s.measurements.editedMeasurementMulti
  );

  console.log("editedMeasurementMulti", editedMeasurementMulti);
  const elementTypes = useElementTypesBySceneProxy(scene);

  const measurements = useSceneMeasurements(scene);

  const selectedMeasurementIds = useSelector(
    (s) => s.measurements.selectedMeasurementIds
  );
  const zones = useZonesByScene(scene);

  // helpers - zonesById

  const zonesById = getItemsMapById(zones);

  // helpers

  const selectedMeasurements = measurements.filter((m) =>
    selectedMeasurementIds?.includes(m.id)
  );

  console.log(
    "debugafa1709 selectedMeasurements",
    selectedMeasurements.map((m) => m.sectorId)
  );

  const mnger =
    caplaEditor?.editorPdf.annotationsManager.measurementsPdfManager;

  // helper - measurement

  let measurementMulti = isEditing
    ? editedMeasurementMulti
    : selectedMeasurementMulti;

  const measurementMultiElementType = elementTypes.find(
    (t) => t.id === measurementMulti.elementTypeId
  );

  // handlers

  function handleEditClick() {
    let res = [];
    if (measurementMultiElementType?.res)
      res = measurementMultiElementType.res.map(({resId}) => ({
        resId,
        off: false,
      }));
    const editedMeasurementMulti = {
      ...selectedMeasurementMulti,
      res,
    };
    // mnger.unlockAnnot({annotationId: measurement.id});
    mnger.setIsEditing(true); // otherwise, the measurements annotation won't be updated (cf mnger.updateAnnotation)
    // mnger.setEditedMeasurement(editedMeasurement);

    dispatch(setIsEditingMeasurementMulti(true));
    dispatch(setEditedMeasurementMulti(editedMeasurementMulti));
  }

  function handleCancelClick() {
    // const mnger = editorPdf.annotationsManager.measurementsPdfManager;
    // mnger.resetSelectedMeasurement();
    // mnger.lockAnnot({annotationId: selectedMeasurement.id});
    console.log("cancel measurements updates", selectedMeasurements);
    for (const measurement of selectedMeasurements) {
      mnger?.updateAnnotation(measurement, {multipleUpdates: true, zonesById});
      //caplaEditor?.editor3d.sceneEditor.updateMeasurement3D(measurement);
    }
    dispatch(setIsEditingMeasurementMulti(false));
    //unselect
    mnger?.unselectMeasurements(selectedMeasurementIds);
    dispatch(setSelectedMeasurementIds([]));
    //
    mnger.setIsEditing(false);
  }

  function handleSaveClick() {
    //mnger?.setIsEditing(true); // no need, already set in handleEditClick
    const newMeasurementFromMulti = {...editedMeasurementMulti};
    delete newMeasurementFromMulti.quantities;
    if (!newMeasurementFromMulti.roomId) delete newMeasurementFromMulti.roomId;
    if (!newMeasurementFromMulti.phaseId)
      delete newMeasurementFromMulti.phaseId;
    if (!newMeasurementFromMulti.sectorId)
      delete newMeasurementFromMulti.sectorId;
    if (!newMeasurementFromMulti.relatedMId)
      delete newMeasurementFromMulti.relatedMId;
    if (!newMeasurementFromMulti.ressourceId)
      delete newMeasurementFromMulti.ressourceId;
    if (!newMeasurementFromMulti.materialId)
      delete newMeasurementFromMulti.materialId;
    const newMs = [];

    for (const [i, measurement] of selectedMeasurements.entries()) {
      let m0 = {
        ...measurement,
        ...newMeasurementFromMulti,
        roomId: measurement.roomId,
        phaseId: measurement.phaseId,
        //sectorId: measurement.sectorId, // ? why => it removes the computed selection ??
        sectorId: newMeasurementFromMulti.sectorId
          ? newMeasurementFromMulti.sectorId
          : measurement.sectorId,
        res:
          newMeasurementFromMulti.res.length > 0
            ? newMeasurementFromMulti.res
            : measurement.res,
        // count: measurement.count,
        // length: measurement.length,
        // area: measurement.area,
        // volume: measurement.volume,
      };

      // update quantities
      const zone = zonesById[m0.zoneId];
      const measurementWithZs = updateMeasurementZsFromZone(m0, zone);
      const zoneZ = zone?.position.y ?? 0;
      const zInf = measurementWithZs.zInf;
      const offsetToGetPath3D = zInf - zoneZ;
      let {path3D, path3d3} = mnger.getPath3DFromDrawingProps(m0.drawingProps, {
        drawingShape: m0.drawingShape,
        dim1: m0.dim1,
        offset: offsetToGetPath3D,
        mpd: zone?.scale,
        zone,
      });

      m0 = {...measurementWithZs, path3d3, path3D};
      const quantities = caplaEditor?.computeQuantitiesFromPaths({
        drawingShape: m0.drawingShape,
        path: m0.drawingProps?.path,
        path3D: m0.path3D,
        path3d2: m0.path3d2,
        height: m0.height,
        heights: m0.heights,
        heightE: m0.heightE,
        heightN: m0.heightN,
        slopeH: m0.slopeH,
        slopingA: m0.slopingA,
        dim1: m0.dim1,
        dim2: m0.dim2,
        mpd: zonesById[m0.zoneId]?.scale,
      });
      const m = {...m0, ...quantities};
      //
      // const {length, area, volume, lengthP, areaP} = caplaEditor.getMeasurementQuantities(m);
      // m.area = area;
      // m.volume = volume;
      // if (length) m.length = length;
      // if (lengthP) m.lengthP = lengthP;
      // if (areaP) m.areaP = areaP;
      const applyColor = m.color !== measurement.color;
      if (m.elementTypeId !== measurement.elementTypeId) {
        const newElementType = elementTypes.find(
          (t) => t.id === m.elementTypeId
        );
        m.elementType = newElementType;
        let maxCode = measurements
          ?.filter((m) => m?.codeName.startsWith(newElementType?.num))
          ?.map((m) => parseInt(m?.codeName.split("-")[1]));
        if (maxCode)
          maxCode = `${newElementType?.num}-${Math.max(...maxCode) + i + 1}`;
        else maxCode = `${newElementType.num}-001`;
        if (maxCode === `${newElementType.num}--Infinity`)
          maxCode = `${newElementType.num}-001`;
        m.codeName = maxCode;
        m.unit = m.elementType?.unit;
        m.material = m.elementType?.material ? m.elementType?.material : null;
        if (!applyColor) {
          m.color = m.material
            ? m.material.color
            : m.elementType?.color
            ? m.elementType.color
            : m.color;
        }
      }
      if (m.material !== measurement.material) {
        if (!applyColor)
          m.color = m.material ? m.material.color : m.elementType?.color;
      }
      const newM = {
        ...m,
        //relatedMId: m.relatedM?.id,
        //phaseId: m.phase?.id,
        //sectorId: m.sector?.id, // this line delete the initial sector. The same for the other props. object doest exist...
        //roomId: m.room?.id,
        //materialId: m.material?.id,
        //ressourceId: m.ressource?.id,
        //versionId: m.version?.id,
      };
      // v the update used to be based on the updated props. Now, it is not dependented anymore.
      if (
        newM.color !== measurement.color ||
        newM.dim1 !== measurement.dim1 ||
        newM.dim2 !== measurement.dim2 ||
        newM.dim3 !== measurement.dim3 ||
        newM.drawingShape !== measurement.drawingShape
      ) {
        newMs.push(newM);
        // mnger?.updateAnnotation(newM, {multipleUpdates: true, zonesById}); // no need : the update is done when updating the form
      } else {
        // dispatch(updateMeasurements([newM]));
        newMs.push(newM);
        if (
          newM.height !== measurement.height ||
          newM.heightE !== measurement.heightE ||
          newM.heightN !== measurement.heightN ||
          newM.slopeH !== measurement.slopeH ||
          newM.slopingA !== measurement.slopingA ||
          newM.offset !== measurement.offset ||
          newM.zInf !== measurement.zInf ||
          newM.zSup !== measurement.zSup ||
          newM.side !== measurement.side ||
          newM.heights !== measurement.heights
        ) {
          // caplaEditor?.editor3d.sceneEditor.updateMeasurement3D(newM); // the update is done by the form
        }
      }
    }
    console.log("updateNewMs", newMs);
    caplaEditor?.measDataManager.updateMeasurements(newMs);
    mnger?.setIsEditing(false);
    dispatch(setMeasurementsEditedFromForm(true));
    dispatch(setIsEditingMeasurementMulti(false));
    dispatch(setEditedTimeStamp(`${Date.now()}`));
    //unselect
    mnger?.unselectMeasurements(selectedMeasurementIds);
    dispatch(setSelectedMeasurementIds([]));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      {/* <Box sx={{flexGrow: 1}}>
        <Button variant="contained" color="primary" fullWidth>
          {deleteS}
        </Button>
      </Box> */}

      {!isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Edit />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleEditClick}
          >
            {editS}
          </Button>
        </Box>
      )}

      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button variant="outlined" fullWidth onClick={handleCancelClick}>
            {cancelS}
          </Button>
        </Box>
      )}

      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Save />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSaveClick}
          >
            {saveS}
          </Button>
        </Box>
      )}
    </Box>
  );
}
