import {Box} from "@mui/material";

import SectionSceneHomeInLeftPanelDiag from "Features/boxhome/components/SectionSceneHomeInLeftPanelDiag";

export default function SectionSceneHomeInLeftPanel({scene}) {
  const bimboxMode = scene?.bimboxMode;

  return (
    <Box>
      {bimboxMode === "DIAG" && (
        <SectionSceneHomeInLeftPanelDiag scene={scene} />
      )}
    </Box>
  );
}
