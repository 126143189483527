import {useSelector} from "react-redux";

export default function useSelectedMaterialsGroup() {
  const selectedMaterialsGroupId = useSelector(
    (state) => state.materials.selectedMaterialsGroupId
  );
  const materialsGroupsMap = useSelector(
    (state) => state.materials.materialsGroupsMap
  );
  return materialsGroupsMap[selectedMaterialsGroupId];
}
