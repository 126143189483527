import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import useTempMeasurement from "../useTempMeasurement";
import useSelectedMeasurement from "../useSelectedMeasurement";
import FormMeasurementInViewerEdit from "./FormMeasurementInViewerEdit";
import FormMeasurementInViewerCreate from "./FormMeasurementInViewerCreate";
// import ButtonSwitchAutoGeo3D from "./ButtonSwitchAutoGeo3D";
import CardIsDrawing from "./CardIsDrawing";

import useIsDrawing from "Features/measurements/useIsDrawing";
import usePageType from "Features/navigation/usePageType";
import useSceneModule from "Features/navigation/useSceneModule";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import CardCreateElementType from "Features/elementTypes/components/CardCreateElementType";
import ButtonSeeMeasurementZone from "./ButtonSeeMeasurementZone";

export default function SectionMeasurementInSelectionPanel({
  scene,
  caplaEditor,
}) {
  // data

  const selectedMeasurement = useSelectedMeasurement();

  const tempMeasurement = useTempMeasurement({
    editorPdf: caplaEditor?.editorPdf,
    scene,
  });
  // const selectedFormGroup = useSelector(
  //   (s) => s.measurements.selectedFormGroup
  // );
  const isEditing = useSelector((s) => s.measurements.isEditingMeasurement);

  const editedMeasurement = useSelector(
    (s) => s.measurements.editedMeasurement
  );

  const sceneModule = useSceneModule();
  const pageType = usePageType();

  const elementTypes = useElementTypesBySceneProxy(scene);

  const isDrawing = useIsDrawing();

  // helpers

  const measurement = isEditing ? editedMeasurement : selectedMeasurement;
  const noTypes = elementTypes.length === 0;

  // helper - show tempM

  const showTempM =
    sceneModule === "EDIT_MEASUREMENTS" || pageType === "COLORING";

  return (
    <Box sx={{width: 1, height: 1}}>
      {noTypes && (
        <Box sx={{p: 2}}>
          <CardCreateElementType />
        </Box>
      )}

      {selectedMeasurement && (
        <Box
          sx={{display: "flex", flexDirection: "column", width: 1, flexGrow: 1}}
        >
          <Box sx={{display: "flex", px: 2, width: 1, justifyContent: "end"}}>
            <ButtonSeeMeasurementZone
              measurement={measurement}
              scene={scene}
              caplaEditor={caplaEditor}
            />
          </Box>
          <FormMeasurementInViewerEdit
            measurement={measurement}
            scene={scene}
            caplaEditor={caplaEditor}
          />
        </Box>
      )}

      {!selectedMeasurement && showTempM && !noTypes && !isDrawing && (
        <FormMeasurementInViewerCreate
          scene={scene}
          tempMeasurement={tempMeasurement}
          editorPdf={caplaEditor?.editorPdf}
        />
      )}
      {/* <Box sx={{width: 1}}>
          {selectedFormGroup === "PROPS" && (
            <Box sx={{px: 2, mt: 2}}>
              <ButtonSwitchAutoGeo3D editorPdf={editorPdf} />
            </Box>
          )}
        </Box> */}

      {isDrawing && <CardIsDrawing editorPdf={caplaEditor?.editorPdf} />}
    </Box>
  );
}
