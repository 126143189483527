import React from "react";

import {Box, Typography} from "@mui/material";

import getQtyWithDec from "../utils/getQtyWithDec";

export default function ContainerArticleQties({article, qty}) {
  const refQty = article?.refQty;
  const unit = article?.unit;
  const hardcodedQty = article?.hardcodedQty;

  const articleQty = qty || hardcodedQty;

  const gap = refQty ? (articleQty - refQty) / refQty : null;

  const articleQtyLabel =
    articleQty > 0 || articleQty === 0
      ? `${getQtyWithDec(articleQty, article?.dec)} ${unit}`
      : "-";

  const refQtyLabel =
    "Qté de référence :" +
    (getQtyWithDec(refQty, article?.dec) ?? "-") +
    " " +
    unit;
  const gapLabel = `(Δ ${(gap * 100).toFixed(1)}%)`;

  // helper

  const isRed = Math.abs(gap ?? 0) > 0.1;
  const isOrange = Math.abs(gap ?? 0) > 0.05;
  const isBold = Math.abs(gap ?? 0) > 0.1;

  return (
    <Box>
      <Typography
        variant="body2"
        sx={{
          ...(isOrange && {color: "error.main"}),
          ...(isRed && {color: "error.main"}),
          ...(isBold && {fontWeight: "bold"}),
        }}
      >
        {articleQtyLabel}
      </Typography>
      {article?.refQty && (
        <Box sx={{display: "flex", alignItems: "center", flexDirection: "row"}}>
          <Typography
            sx={{fontSize: 12, color: "text.secondary", fontStyle: "italic"}}
          >
            {refQtyLabel}
          </Typography>
          {qty && (
            <Typography
              sx={{
                fontSize: 12,
                fontStyle: "italic",
                ml: 2,
                color: "text.secondary",
              }}
            >
              {gapLabel}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
}
