// string = "GO1.POUTR {{SECTEUR=="R+1"}} {{H===1}}; ..."

import articleTypesArrayToMap from "./articleTypesArrayToMap";

function removeExprFilters(expr) {
  if (expr) return expr.replace(/\s?\{\{[^\}]+\}\}/g, "");
  else return "";
}

function getExprFiltersStrings(expr) {
  const matches = expr?.matchAll(/\s?\{\{([^\}]+)\}\}/g);
  let filters = [];
  if (matches) filters = [...matches].map((match) => match[1]);
  return filters;
}

export default function parseArticleTypesString(
  string,
  groupsWithElementTypes
) {
  //

  const substrings = string.split(";");

  console.log("substrings", substrings);

  const articleTypesArray = [];

  substrings.forEach((substring) => {
    substring = substring.trim();
    const filtersStr = getExprFiltersStrings(substring);
    const typeStr = removeExprFilters(substring);
    const [typeGroupCode, typeCode] = typeStr.split(".");
    const group = groupsWithElementTypes.find(
      (group) => group.code === typeGroupCode
    );
    const type = group?.elementTypes.find((type) => type.code === typeCode);

    const item = {
      groupId: group?.id,
      typeId: type?.id,
    };

    if (filtersStr.length > 0) {
      item.filtersStr = filtersStr;
    } else {
      item.func = "x";
    }
    articleTypesArray.push(item);
  });

  const articleTypes = articleTypesArrayToMap(articleTypesArray);

  return articleTypes;
}
