import {nanoid} from "@reduxjs/toolkit";

import {MathUtils, Object3D, Vector3} from "three";

import ImageTexture from "./ImageTexture";
import ImageEntity from "./ImageEntity";
// import ImageBackgroundEntity from "./ImageBackgroundEntity";
import ImagePartEntity from "./ImagePartEntity";
import ImageMaskEntity from "./ImageMaskEntity";

import validatePart from "../utils/validatePart";

import {resizeImagePOT} from "Features/images/imageUtils";
import {deleteModel} from "Features/viewer3D/viewer3DSlice";

export default class ImageAsyncModel {
  imageTexture;
  resolution;
  opacity;
  cropRatios; // [left,right,bottom,top]
  delta; // offset of the image in Z direction.

  savedTransformation; // used to restore saved Transformation.
  isShowingMainPart; // used to hide / show the right entities.

  constructor({model, onModelChange, scene, objectEntities, editor3d}) {
    this.editor3d = editor3d;
    this.type = "IMAGE_MODEL";
    this.entityID = model.id;
    this.model = model;
    this.onModelChange = onModelChange;
    this.scene = scene;
    this.objectEntities = objectEntities; // editor3d object entities.

    this.cropRatios = [0, 1, 0, 1];

    this.initialWidth = model.width; // keep the initial width of the image (real width = object.scale * initialWidth)
    this.initialHeight = model.height;
    this.initialTransformation = {
      position: {...model.position},
      rotation: {...model.rotation},
    };

    // center
    this.center = model.center;
    // object
    this.object = new Object3D(); // group of all the objects.
    this.object.layers.enable(1);
    this.scene.add(this.object);

    // image texture
    this.imageTexture = new ImageTexture({
      url: this.model.url,
      urlLowReso: this.model.urlLowReso,
      urlHightReso: this.model.urlHightReso,
    });

    this.isTransparent = true;

    // entities

    this.imageEntity = new ImageEntity({
      imageTexture: this.imageTexture,
      model: this.model,
    });

    this.mainImagePartEntity = new ImagePartEntity({
      model: this.model,
      part: {
        id: nanoid(),
        mask: [
          [
            [0, 0],
            [1, 0],
            [1, 1],
            [0, 1],
          ],
        ],
        visible: true,
      },
      imageTexture: this.imageTexture,
      imageModel: this,
      scene: this.scene,
    });

    this.imageBackgroundEntity = null;
    // this.imageBackgroundEntity = new ImageBackgroundEntity({
    //   imageTexture: this.imageTexture,
    //   model: this.model,
    // });

    this.imagePartEntities = !this.model.parts
      ? []
      : this.model.parts.reduce((acc, part) => {
          if (validatePart(part)) {
            const e = new ImagePartEntity({
              model: this.model,
              part,
              imageTexture: this.imageTexture,
              imageModel: this,
              scene: this.scene,
            });
            acc.push(e);
          }
          return acc;
        }, []);
    this.imageMaskEntities = !this.model.parts
      ? []
      : this.model.parts.reduce((acc, part) => {
          if (validatePart(part)) {
            const e = new ImageMaskEntity({
              model: this.model,
              part,
            });
            acc.push(e);
          }
          return acc;
        }, []);

    // resolution
    this.initResolution();

    // parts
    this.parts = this.model.parts;

    // init
    this.init();
  }

  /*
   * ACCESSORS
   */

  get position() {
    return {
      x: this.object.position.x,
      y: this.object.position.y,
      z: this.object.position.z,
    };
  }

  get rotation() {
    return {
      x: this.object.rotation.x,
      y: this.object.rotation.y,
      z: this.object.rotation.z,
    };
  }

  get width() {
    return this.initialWidth * this.object.scale.x;
  }

  get height() {
    return this.initialHeight * this.object.scale.x;
  }

  get upToDateModel() {
    // model consistent with entity geometry.
    return {
      ...this.model,
      width: this.width,
      height: this.height,
      rotation: this.rotation,
      position: this.position,
      resolution: this.resolution,
      center: this.center,
      mask: this.mask,
      parts: this.parts,
    };
  }

  get initialTextureIsLoaded() {
    return Boolean(this.imageTexture.textureRR);
  }

  /*
   * SETTERS
   */

  setIsShowingMainPart(bool) {
    this.isShowingMainPart = bool;
  }
  /*
   * Update Model at the redux level with the onModelChange callback.
   * Should be called for each transformation to be persisted.
   */

  updateModel() {
    const m = this.upToDateModel;
    this.model = m;
    this.onModelChange(m);
  }

  setModel(model) {
    // update model from outside, with new urls. Impact all related entities !!
    this.model = model;
    this.imageTexture.update({url: model.url});
    this.imageTexture.updateLowAndHightReso(
      model.urlLowReso,
      model.urlHightReso
    );

    this.imageEntity.updateResoMaterials(this.resolution);
    this.mainImagePartEntity.updateResoMaterials(this.resolution);
  }

  resetModelFromState(model) {
    this.setModel(model); // TO DO : remove setModel function
  }

  // method used to update rotation / translation or file.
  // pass by Loader.updateImageModel to update the model + state.
  updateImageModel({width, height, position, rotation, file, name}) {
    console.log(
      "updateImageModel",
      width,
      height,
      position,
      rotation,
      file,
      name
    );
    const size = file?.size;

    if (file) {
      const newUrl = URL.createObjectURL(file);
      this.imageTexture.update({url: newUrl});
      this.imageEntity.updateResoMaterials(this.resolution);
      this.mainImagePartEntity.updateResoMaterials(this.resolution);
    }

    if (width && height) {
      const scaleX = width / this.initialWidth;
      const scaleY = height / this.initialHeight;
      this.imageEntity.object?.scale.set(scaleX, scaleY, 1);
    }

    if (position) {
      this.object.position.set(position.x, position.y, position.z);
    }

    if (rotation) {
      this.object.rotation.set(rotation.x, rotation.y, rotation.z);
    }

    const updates = {id: this.model.id};
    if (file) updates.file = file;
    if (size) updates.fileSize = size;
    if (width) updates.width = width;
    if (height) updates.height = height;
    if (position) updates.position = position;
    if (rotation) updates.rotation = rotation;
    if (name) updates.name = name;

    // state update
    //this.onModelChange(updates); // state change manage by the loader...
  }
  /*
   * INIT
   */

  initResolution() {
    //this.resolution = "NULL"; // pb with remote images.
    this.resolution = "RR";
    if (this.model.url)
      this.resolution = this.model.resolution ? this.model.resolution : "RR"; // "HR" === Hight 2x2 resolution, "RR" = raw resolution
  }

  init() {
    this.object.rotation.set(
      this.model.rotation.x,
      this.model.rotation.y,
      this.model.rotation.z
    );
    this.object.position.set(
      this.model.position.x,
      this.model.position.y,
      this.model.position.z
    );
  }

  /*
   * PREPARE MODEL
   */

  static async computeInitialModel({
    file,
    sceneClientId,
    // slope = 0,
    resize = false,
    options = {},
  }) {
    //resize = true => auto resize the image
    let {
      name: optionName,
      width,
      height,
      position,
      rotation,
      fromPdf,
      fromModel,
      parts,
      d3,
      zBasis,
    } = options;
    //
    let fileHR, fileLR;
    if (resize) {
      fileHR = await resizeImagePOT({file, fileName: file.name});
      fileLR = await resizeImagePOT({file, fileName: file.name, pot: 8});
    }

    // slope in deg. O = horizontal. 90 = vertical
    const id = MathUtils.generateUUID();
    const type = "IMAGE";
    const fileSize = file?.size;
    // const fileName = file?.name;
    const url = file && URL.createObjectURL(file);
    const urlLowReso = fileLR && URL.createObjectURL(fileLR);
    const urlHightReso = fileHR && URL.createObjectURL(fileHR);
    const center = {x: 0, y: position ? position.y : 0, z: 0};
    const name = optionName ? optionName : file?.name;
    const depth = 0.001;
    const hidden = options?.hidden ?? false;
    const enabled = true;
    const autoloading = true;
    //
    if (!width || !height) {
      const imageTexture = new ImageTexture({url, urlLowReso, urlHightReso});
      const modelSize = await imageTexture.getModelSizeFromUrl();
      width = modelSize.width;
      height = modelSize.height;
    }

    //
    const model = {
      id,
      enabled,
      sceneClientId,
      name,
      fileSize,
      url,
      urlLowReso,
      urlHightReso,
      type,
      rotation: rotation ? rotation : {x: -Math.PI / 2, y: 0, z: 0},
      position: position ? position : {x: 0, y: 0, z: 0},
      center,
      width,
      height,
      depth,
      hidden,
      autoloading,
      fromPdf,
      fromModel,
      parts,
      d3,
      zBasis,
    };
    return {model, fileHR, fileLR};
  }

  /*
   * LOADER
   */

  loadInitialTexture() {
    if (!this.imageTexture.textureRR) {
      this.imageTexture.update({url: this.model.url});
      this.imageEntity.updateResoMaterials(this.resolution);
      this.mainImagePartEntity.updateResoMaterials(this.resolution);
    }
  }

  async loadTexturesAsync() {
    console.log("loadTextureAsync");
    await this.imageTexture.loadTexturesAsync();
  }

  loadImageEntity() {
    try {
      this.initResolution();
      this.imageEntity.updateMaterialsFromTexture();
      this.imageEntity.loadImage(this.resolution);
      this.imageEntity.setOpacity(this.model.opacity);
      this.object.add(this.imageEntity.object);
      this.objectEntities.push(this.imageEntity);
    } catch (e) {
      console.log(e);
    }
  }
  loadImageBackgroundEntity() {
    if (this.imageBackgroundEntity) {
      this.imageBackgroundEntity.loadImage();
      this.object.add(this.imageBackgroundEntity.object);
      this.objectEntities.push(this.imageBackgroundEntity);
    }
  }
  loadMainImagePartEntity() {
    this.initResolution();
    this.mainImagePartEntity.updateMaterialsFromTexture();
    this.mainImagePartEntity.loadImage(this.resolution);
    //this.object.add(mainImagePartEntity.object); // managed in the loadImage method.
    this.objectEntities.push(this.mainImagePartEntity);
  }
  loadImagePartEntities() {
    this.imagePartEntities.forEach((imagePartEntity) => {
      imagePartEntity.loadImage(this.resolution);
      //this.object.add(imagePartEntity.object); // managed in the loadImage method.
      this.objectEntities.push(imagePartEntity);
    });
  }
  loadImageMaskEntities() {
    this.imageMaskEntities.forEach((imageMaskEntity) => {
      this.object.add(imageMaskEntity.object);
      this.objectEntities.push(imageMaskEntity);
    });
  }

  async createResizedImages() {
    const fileHR = await resizeImagePOT({
      fileURL: this.model.url,
      fileName: this.model.name,
    });
    const fileLR = await resizeImagePOT({
      fileURL: this.model.url,
      fileName: this.model.name,
      pot: 8,
    });
    return {fileHR, fileLR};
  }

  /*
   * RESOLUTION
   */

  switchResolutionTo(reso) {
    this.resolution = reso;
    if (this.imageEntity?.switchResolutionTo)
      this.imageEntity.switchResolutionTo(reso);
    this.imagePartEntities.forEach((e) => e.switchResolutionTo(reso));
    this.updateModel();
  }

  /*
   * MATERIAL & COLORS
   */

  setTransparent(transparent) {
    console.log("transparent", this.imageEntity.object.material.transparent);
    this.imageEntity.materialRR.transparent = transparent;
    this.isTransparent = transparent;
  }

  /*
   * MASK & PARTS
   */

  setMask(mask) {
    console.log("set mask", mask);
    this.mask = mask;
    const newModel = {...this.model, mask: this.mask};
    this.model = newModel;
    console.log("this.model", newModel);
    this.onModelChange(newModel);

    this.imageEntity.updateMask(mask);
  }

  createPart({name, mask, id, offset}) {
    // offset is used to translate the object when it is created in the config center.
    const newPart = {name, mask, id, visible: true};
    let parts = this.model.parts;
    if (!parts) {
      parts = [newPart];
    } else {
      parts = [...this.model.parts, newPart];
    }
    const m = {...this.model, parts};
    this.model = m;
    this.onModelChange(m);

    const newMaskEntity = new ImageMaskEntity({
      model: this.model,
      part: newPart,
    });
    this.imageMaskEntities.push(newMaskEntity);
    this.objectEntities.push(newMaskEntity);
    // we update update the scale of the object before adding to the main object (scaled after config)
    const scaleV = new Vector3().copy(this.object.scale);
    newMaskEntity.object.scale.set(1 / scaleV.x, 1 / scaleV.x, 1 / scaleV.x);
    this.object.add(newMaskEntity.object);

    const newPartEntity = new ImagePartEntity({
      model: this.model,
      part: newPart,
      imageTexture: this.imageTexture,
      imageModel: this,
      scene: this.scene,
    });
    newPartEntity.loadImage(this.resolution);

    if (offset)
      newPartEntity.object.position.add(
        new Vector3(offset.x, offset.y, offset.z)
      );

    this.imagePartEntities.push(newPartEntity);
    this.objectEntities.push(newPartEntity);
    this.object.add(newPartEntity.object);
  }

  deletePart(partId) {
    const parts = this.model.parts;
    const newParts = parts.filter((part) => part.id !== partId);
    const m = {...this.model, parts: newParts};
    this.model = m;
    this.onModelChange(m);

    const imageMaskEntity = this.imageMaskEntities.find(
      (e) => e.partId === partId
    );
    const imagePartEntity = this.imagePartEntities.find(
      (e) => e.partId === partId
    );
    this.object.remove(imageMaskEntity.object);
    this.object.remove(imagePartEntity.object);

    this.imageMaskEntities = this.imageMaskEntities.filter(
      (e) => e.partId !== partId
    );
    this.imagePartEntities = this.imagePartEntities.filter(
      (e) => e.partId !== partId
    );
  }

  updatePartsTransformations() {
    this.imagePartEntities.forEach((e) => e.updateTransformation());
  }

  /*
   * HIDE / SHOW
   */

  hide() {
    // const hasParts = this.model.parts?.length > 0;
    this.object.layers.disable(1);
    this.object.visible = false; // used to get toggle visibility

    this.imageEntity?.hide();
    this.mainImagePartEntity.hide();

    // this.imageBackgroundEntity?.hide();
    // this.imagePartEntities?.forEach((e) => e.hide());
    // this.imageMaskEntities?.forEach((e) => e.hide());

    //this.loadingBox?.object?.layers.disable(1);
  }
  show() {
    // const hasParts = this.model.parts?.length > 0;
    this.object.layers.enable(1);
    this.object.visible = true; // used to get toggle visibility

    if (this.isShowingMainPart) {
      this.mainImagePartEntity.show();
      this.imageEntity?.hide();
    } else {
      this.mainImagePartEntity.hide();
      this.imageEntity?.show();
    }

    // hasParts ? this.imageEntity.hide() : this.imageEntity?.show();
    // hasParts
    //   ? this.imagePartEntities?.forEach((e) => e?.show())
    //   : this.imagePartEntities?.forEach((e) => e?.hide());
    // this.imageMaskEntities?.forEach((e) => e.hide());
    // this.imageBackgroundEntity?.hide();

    // this.applyPartsFilters();
    //this.loadingBox?.object?.layers.enable(1);
  }

  toggleVisibility() {
    if (this.object.visible) {
      this.hide();
    } else {
      this.show();
    }
  }

  showMainPart() {
    this.mainImagePartEntity.show();
    this.imageEntity.hide();
    this.setIsShowingMainPart(true);
  }

  hideMainPart() {
    this.mainImagePartEntity.hide();
    this.imageEntity.show();
    this.setIsShowingMainPart(false);
  }

  // filters

  setPartVisibility(partId, visible) {
    const newParts = this.model.parts.map((part) => {
      if (part.id === partId) {
        return {...part, visible};
      } else {
        return part;
      }
    });
    this.model = {...this.model, parts: newParts};
    this.onModelChange(this.model);
    this.applyPartsFilters();
  }

  showAllParts() {
    const newParts = this.model.parts.map((part) => {
      return {...part, visible: true};
    });
    this.model = {...this.model, parts: newParts};
    this.onModelChange(this.model);
    this.applyPartsFilters();
  }

  hideAllParts() {
    const newParts = this.model.parts.map((part) => {
      return {...part, visible: false};
    });
    this.model = {...this.model, parts: newParts};
    this.onModelChange(this.model);
    this.applyPartsFilters();
  }

  applyPartsFilters() {
    const parts = this.model.parts;
    this.imagePartEntities.map((imagePartEntity) => {
      const part = parts.find((part) => part.id === imagePartEntity.partId);
      if (part.visible || part.visible === undefined) {
        imagePartEntity.show();
      } else {
        imagePartEntity.hide();
      }
    });
  }

  // masks

  showMasks() {
    this.imageMaskEntities?.forEach((e) => e.show());
  }
  hideMasks() {
    this.imageMaskEntities?.forEach((e) => e.hide());
  }
  showMask(partId) {
    this.imageMaskEntities?.forEach((e) => e.partId === partId && e.show());
  }
  hideMask(partId) {
    this.imageMaskEntities?.forEach((e) => e.partId === partId && e.hide());
  }

  // background

  toggleBackgroundVisibility() {
    this.imageBackgroundEntity.toggleVisibility();
  }
  hideBackground() {
    if (!this.imageBackgroundEntity) return;
    this.imageBackgroundEntity.hide();
  }
  showBackground() {
    if (!this.imageBackgroundEntity) return;
    this.imageBackgroundEntity.show();
  }

  toggleImageEntityVisibility() {
    this.imageEntity.object.layers.toggle(1);
  }
  showImageEntity() {
    this.imageEntity.object.layers.enable(1);
  }
  // used in the configCEnter
  hideImageEntity() {
    this.imageEntity.object.layers.disable(1);
  }

  setOpacity(opacity) {
    //this.imageEntity.setOpacity(opacity);
    this.mainImagePartEntity.setOpacity(opacity);
    this.opacity = opacity;
  }

  setPartOpacity(partId, opacity) {
    const imagePartEntity = this.imagePartEntities.find(
      (e) => e.partId === partId
    );
    imagePartEntity.setOpacity(opacity);
  }

  initPartConfigDisplay(partId) {
    // parts
    this.imagePartEntities?.forEach((e) => e.hide());
    // mask
    this.imageMaskEntities?.forEach((e) => {
      if (e.partId === partId) {
        e?.show();
      } else {
        e.hide();
      }
    });
  }

  initMainSceneDisplay() {
    console.log("init main scene display");
    const hasParts = this.model.parts?.length > 0;
    hasParts ? this.imageEntity.hide() : this.imageEntity.show();
    hasParts && this.imagePartEntities?.forEach((e) => e?.show());
    hasParts && this.imageMaskEntities?.forEach((e) => e.hide());
    this.hideBackground();
    this.applyPartsFilters();
  }

  /*
   * TRANSFORMATIONS
   */

  saveTransformation() {
    const p = this.position;
    const r = this.rotation;
    this.savedTransformation = {
      position: {...p},
      rotation: {...r},
    };
  }

  getPositionFromOffsetImage(offset3D) {
    // used when pdf annotation is changed
    const newPositionV = new Vector3();
    const pivot = new Object3D();
    pivot.position.set(offset3D.x, offset3D.y, offset3D.z);
    this.object.add(pivot);
    pivot.getWorldPosition(newPositionV);
    const {x, y, z} = newPositionV;
    const newP = {x, y, z};
    console.log("new pos from offetImage", newP, "from offset", offset3D);

    return newP;
  }

  restoreSavedTransformation() {
    console.log("restore transfo");
    if (this.savedTransformation) {
      const p = this.savedTransformation.position;
      const r = this.savedTransformation.rotation;
      this.object.position.set(p.x, p.y, p.z);
      this.object.rotation.set(r.x, r.y, r.z);
      const newModel = {
        ...this.model,
        position: {x: p.x, y: p.y, z: p.z},
        rotation: {x: r.x, y: r.y, z: r.z},
      };
      this.model = newModel;
      this.onModelChange({
        id: newModel.id,
        position: newModel.position,
        rotation: newModel.rotation,
      });
    }
  }

  translate(x, y, z) {
    this.object.position.add(new Vector3(x, y, z));
    this.center = {...this.position};

    const p = new Vector3();
    this.object.getWorldPosition(p);
    const center = this.model.center;
    const newCenter = {x: center.x + x, y: center.y + y, z: center.z + z};
    const newModel = {
      ...this.model,
      position: {x: p.x, y: p.y, z: p.z},
      center: newCenter,
    };

    this.model = newModel;
    this.onModelChange({
      id: newModel.id,
      position: newModel.position,
      center: newModel.center,
    });
  }

  tempTranslate(delta) {
    //this.imageEntity?.object?.position.set(0, 0, delta);
    this.mainImagePartEntity?.object?.position.set(0, 0, delta);
    this.delta = delta;
  }

  tempCrop(cropRatios) {
    const [l, r, b, t] = cropRatios;
    const mask = [
      [
        [l, b],
        [r, b],
        [r, t],
        [l, t],
      ],
    ];
    this.mainImagePartEntity.updateMask(mask);
    this.cropRatios = cropRatios;
  }

  resetTempTranslate() {
    this.imageEntity?.object?.position.set(0, 0, 0);
  }

  scale(s) {
    this.object.scale.set(s, s, s);
    const m = {...this.model};
    m.height *= s;
    m.width *= s;
    this.model = m;
    this.onModelChange(m);
  }

  resetPosition() {
    console.log("[imageModel] reset position");
    this.object.position.set(0, 0, 0);
    this.object.rotation.set(0, 0, 0);
    this.center = {...this.position};
    this.parts = this.model.parts?.map((part) => {
      return {
        ...part,
        position: {x: 0, y: 0, z: 0},
        rotation: {x: 0, y: 0, z: 0},
      };
    });

    this.imagePartEntities.forEach((e) => {
      e.resetTransformation();
      e.updateTransformation();
    });

    this.updateModel();
  }

  translatePart(partId, x, y, z) {
    const p = new Vector3();
    const imagePartEntity = this.imagePartEntities.find(
      (e) => e.partId === partId
    );
    this.scene.attach(imagePartEntity.object);
    imagePartEntity.object.position.add(new Vector3(x, y, z));
    p.copy(imagePartEntity.object.position);
    this.object.attach(imagePartEntity.object);

    const parts = this.model.parts;
    const newParts = parts.map((part) => {
      if (part.id === partId) {
        const newPart = {...part, position: {x: p.x, y: p.y, z: p.z}};
        return newPart;
      } else {
        return part;
      }
    });
    const newModel = {...this.model, parts: newParts};
    this.model = newModel;
    this.onModelChange(newModel);
  }

  rotatePart(partId, x, y, z) {
    const r = new Vector3();
    const imagePartEntity = this.imagePartEntities.find(
      (e) => e.partId === partId
    );
    this.scene.attach(imagePartEntity.object);
    imagePartEntity.object.rotation.set(x, y, z);
    r.copy(imagePartEntity.object.rotation);
    this.object.attach(imagePartEntity.object);

    const parts = this.model.parts;
    const newParts = parts.map((part) => {
      if (part.id === partId) {
        const newPart = {...part, rotation: {x: r.x, y: r.y, z: r.z}};
        return newPart;
      } else {
        return part;
      }
    });
    const newModel = {...this.model, parts: newParts};
    this.model = newModel;
    this.onModelChange(newModel);
  }

  parse() {
    return {
      type: "IMAGE_MODEL",
      name: this.name,
      modelId: this.model.id,
      model: {
        ...this.model,
        position: this.position,
        rotation: this.rotation,
        width: this.width,
        height: this.height,
      },
    };
  }
  /*
   * Update entity object based on the model.
   * used in the config center
   * model => entity update in the 3D scene.
   */
  updateTransformation() {
    const scale = this.model.width / this.initialWidth;
    this.object.scale.set(scale, scale, scale);

    console.log(
      "Update model entity transfo, scale:",
      scale,
      "width",
      this.model.width
    );

    this.object.rotation.set(
      this.model.rotation.x,
      this.model.rotation.y,
      this.model.rotation.z
    );
    this.object.position.set(
      this.model.position.x,
      this.model.position.y,
      this.model.position.z
    );
  }

  /*
   * DISABLE / ENABLE
   */

  disable() {
    this.object.layers.disable(1);
    this.object.visible = false;
    this.imageEntity?.object?.layers.disable(1);
    this.imageBackgroundEntity?.object?.layers.disable(1);
  }
  enable() {
    this.object.layers.enable(1);
    this.object.visible = true;
    this.imageEntity?.object?.layers.enable(1);
    this.imageBackgroundEntity?.object?.layers.disable(1);
  }

  /*
   * DELETE
   */
  delete() {
    this.scene.remove(this.object);
    this.editor3d.dispatch(deleteModel({id: this.model.id}));
  }

  /*
   * Animation
   */
}
