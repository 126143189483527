import Konva from "konva";

import createMarkerNode from "./utilsMarkersManager/createMarkerNode";

import getClustersFromMarkers from "../utils/getClustersFromMarkers";
import getItemsMapById from "Utils/getItemsMapById";
import markersSample from "../data/markersSample";

export default class MarkersManager {
  constructor({zonesEditor}) {
    this.zonesEditor = zonesEditor;

    this.markerNodesById = {};
    this.markersMap = [];

    this.markerRadius = 20;
  }

  // ADD

  addMarkers(markers, options) {
    console.log("addMarkers", markers);
    //
    // options

    const reset = options?.reset;
    const init = options?.init;

    // init

    if (init) {
      this.markersMap = getItemsMapById(markers);
    }

    // reset

    if (reset) {
      this.deleteMarkersNodes(Object.keys(this.markerNodesById));
      this.markerNodesById = {};
    }

    //
    markers.forEach((marker) => {
      if (this.markerNodesById[marker.id]) {
        return;
      } else {
        this.createNode(marker);
      }
    });
  }

  createNode = (marker) => {
    //
    const markerNode = createMarkerNode({
      marker,
      stage: this.zonesEditor.stage,
      onMarkerChange: (marker) => this.handlerMarkerChange(marker),
    });
    //
    this.markerNodesById[marker.id] = markerNode;
    this.zonesEditor.layerMarkers.add(markerNode);
  };

  // CHANGE

  handlerMarkerChange(marker) {
    const existingMarker = this.markersMap[marker.id];
    // the marker can be a cluster. In this case, we do not want to update the makersMap
    if (existingMarker) {
      this.markersMap[marker.id] = marker;
    }
  }

  // DELETE

  deleteAllMarkers() {
    this.deleteMarkersNodes(Object.keys(this.markerNodesById));
  }

  deleteMarkersNodes(ids) {
    ids.forEach((id) => {
      const markerNode = this.markerNodesById[id];
      if (markerNode) markerNode.destroy();
      delete this.markerNodesById[id];
    });
  }

  // RESIZE & CLUSTER

  resizeNodes() {
    const stageScale = this.zonesEditor.stage.scaleX();
    this.deleteAllMarkers();
    const markers = Object.values(this.markersMap);
    const clusteredMarkers = getClustersFromMarkers(markers, stageScale);
    this.addMarkers(clusteredMarkers);
  }

  clusterNodes(clusterMarker) {
    const idsToCluster = clusterMarker.cluster.map((m) => m.id);
    this.deleteMarkersNodes(idsToCluster);
    this.markerNodesById[clusterMarker.id] = this.createMarkerNode(
      clusterMarker,
      {isCluster: true}
    );
  }
}
