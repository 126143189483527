import {useSelector} from "react-redux";

export default function useMapsByScene(scene, options) {
  const models = useSelector((s) => s.viewer3D.models);
  let maps = models.filter(
    (m) => m.type === "IMAGE" && m.sceneClientId === scene?.clientId
  );

  if (options?.sortByName) {
    maps.sort((a, b) => a.name.localeCompare(b.name));
  }

  return maps;
}
