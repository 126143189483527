import {useDispatch, useSelector} from "react-redux";

import useSelectedZone from "../hooks/useSelectedZone";

import {Add} from "@mui/icons-material";
import useUpdatePdfZone from "./useUpdatePdfZone";

export default function useActionsZonesMap({scene, caplaEditor}) {
  const dispatch = useDispatch();

  // data

  const os = useSelector((s) => s.ui.openSections);
  const selectedZone = useSelectedZone(scene);
  const updateZone = useUpdatePdfZone();

  // handlers

  function repairZoneProps() {
    console.log("repairZoneProps", selectedZone);
    const updatedZone =
      caplaEditor.editorPdf.annotationsManager.repairZoneAnnotationProps(
        selectedZone
      );
    updateZone(updatedZone);
  }

  // actions map

  const actionsMap = {
    createZone: {
      icon: <Add fontSize="small" />,
      name: "Fond de plan",
      label: "Créer un fond de plan",
    },
    createZoneFromImage: {
      label: "Créer depuis une image",
    },
    repairZoneProps: {
      label: "Réparer la zone du PDF",
      handler: repairZoneProps,
      disabled: !selectedZone || !os.fixedViewersBox,
    },
  };

  return actionsMap;
}
