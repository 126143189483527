import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {setOpenDialogFsCreateSample} from "Features/samples/samplesSlice";

import {
  createMaterialsGroupService,
  fetchMaterialsGroupsService,
  updateMaterialsGroupService,
  updateMaterialService,
  deleteMaterialsGroupService,
  deleteMaterialService,
} from "./services";

import {fetchOrganisationNomenclaturesService} from "Features/organisations/services";

// REMOTE

export const createMaterialsGroup = createAsyncThunk(
  "materials/createMaterialsGroup",
  createMaterialsGroupService
);
export const fetchOrganisationNomenclatures = createAsyncThunk(
  "materials/fetchOrganisationNomenclatures",
  fetchOrganisationNomenclaturesService
);
export const fetchMaterialsGroups = createAsyncThunk(
  "materials/fetchMaterialsGroups",
  fetchMaterialsGroupsService
);
export const updateMaterialsGroup = createAsyncThunk(
  "materials/updateMaterialsGroup",
  updateMaterialsGroupService
);
export const updateMaterial = createAsyncThunk(
  "materials/updateMaterial",
  updateMaterialService
);
export const deleteMaterialsGroup = createAsyncThunk(
  "materials/deleteMaterialsGroup",
  deleteMaterialsGroupService
);
export const deleteMaterial = createAsyncThunk(
  "materials/deleteMaterial",
  deleteMaterialService
);

const defaultMaterial = {
  clientId: "default",
  name: "M-JB-001",
  categoryId: "xx",
  code: "M-JB-001",
  sceneId: "xx",
  scopeId: "yy",
};

const materialsInitialState = {
  items: [defaultMaterial], // items = materials created from the material nomenclature.
  //
  searchText: null,
  viewMode: "LIST", // IMAGES
  //
  nomenclatures: [],
  //
  materialsGroupsMap: {},
  materialsMap: {},
  //
  newMaterial: {},
  editedMaterial: {},
  isEditingMaterial: false,
  //
  materialsNomenclatures: [],
  selectedMaterialsNomenclatureId: null,
  selectedMaterialCategoryId: null,
  //
  selectedMaterialsGroupId: null,
  selectedMaterialClientId: null,
  selectedMaterialsClientIds: [],
  //
  openDialogFsMaterialsSettings: false,
  openDialogFsSelectorMaterial: false,
  openDialogFsSelectorMaterialCategory: false,
};

// export const fetchUserId = createAsyncThunk(
//   "material/fetchUserId",
//   async ({accessToken}) => {
//     const response = await client.get(`/signin/`, {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//     });
//     return response.data;
//   }
// );

export const materialsSlice = createSlice({
  name: "materials",
  initialState: materialsInitialState,
  reducers: {
    //
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    setViewMode: (state, action) => {
      state.viewMode = action.payload;
    },
    //
    setSelectedMaterialsGroupId: (state, action) => {
      state.selectedMaterialsGroupId = action.payload;
    },
    //
    setSelectedMaterialClientId: (state, action) => {
      state.selectedMaterialClientId = action.payload;
    },
    setSelectedMaterialsClientIds: (state, action) => {
      state.selectedMaterialsClientIds = action.payload;
    },
    setSelectedMaterialCategoryId: (state, action) => {
      state.selectedMaterialCategoryId = action.payload;
    },
    //
    addMaterial: (state, action) => {
      const material = action.payload;
      state.materialsMap[material.clientId] = material;
    },
    //
    setOpenDialogFsMaterialsSettings: (state, action) => {
      state.openDialogFsMaterialsSettings = action.payload;
    },
    setSelectedMaterialsNomenclatureId: (state, action) => {
      state.selectedMaterialsNomenclatureId = action.payload;
    },
    setOpenDialogFsSelectorMaterial: (state, action) => {
      state.openDialogFsSelectorMaterial = action.payload;
    },
    setOpenDialogFsSelectorMaterialCategory: (state, action) => {
      state.openDialogFsSelectorMaterialCategory = action.payload;
    },
    //
    setNewMaterial: (state, action) => {
      state.newMaterial = action.payload;
    },
    setEditedMaterial: (state, action) => {
      state.editedMaterial = action.payload;
    },
    setIsEditingMaterial: (state, action) => {
      state.isEditingMaterial = action.payload;
    },
  },
  extraReducers: {
    // REMOTE

    [createMaterialsGroup.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.materialsGroupsMap[item.id] = item;
    },

    [fetchOrganisationNomenclatures.fulfilled]: (state, action) => {
      const {nomenclatures} = action.payload;
      state.nomenclatures = nomenclatures;
    },
    [fetchMaterialsGroups.fulfilled]: (state, action) => {
      const {items} = action.payload;
      console.log("debugafa state fetchMaterialsGroups", items);
      items.forEach((item) => {
        state.materialsGroupsMap[item?.id] = item;
      });
    },

    [updateMaterialsGroup.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.materialsGroupsMap[item.id] = item;
    },
    [updateMaterial.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.materialsMap[item.clientId] = item;
    },
    [deleteMaterialsGroup.fulfilled]: (state, action) => {
      const {item} = action.payload;
      delete state.materialsGroupsMap[item.id];
    },
    [deleteMaterial.fulfilled]: (state, action) => {
      const {item} = action.payload;
      delete state.materialsMap[item.clientId];
    },

    // EXTERNAL

    "scopes/fetchRemoteScopeData/fulfilled": (state, action) => {
      const {data, scopeId} = action.payload;
      const materialsGroups =
        data.materialsGroups?.length > 0 ? data.materialsGroups : [];
      console.log("[STATE MATERIALS] fetchRemoteScopeData", scopeId, data);
      materialsGroups.forEach((materialsGroup) => {
        state.materialsGroupsMap[materialsGroup.id] = materialsGroup;
        if (materialsGroup.materials?.length > 0) {
          materialsGroup.materials.forEach((material) => {
            state.materialsMap[material.clientId] = material;
          });
        }
      });
    },
    "comments/addComment/fulfilled": (state, action) => {
      const comment = action.payload;
      if (comment.materialsClientIds) {
        comment.materialsClientIds.forEach((materialClientId) => {
          const oldMaterial = state.materialsMap[materialClientId];
          const oldCommentIds = oldMaterial?.commentsClientIds;
          //
          const newCommentsClientIds = oldCommentIds
            ? [...oldCommentIds, comment.clientId]
            : [comment.clientId];
          //
          state.materialsMap[materialClientId] = {
            ...oldMaterial,
            commentsClientIds: newCommentsClientIds,
          };
        });
      }
    },
  },
});

export const {
  //
  setSearchText,
  setViewMode,
  //
  setSelectedMaterialsGroupId,
  setSelectedMaterialsNomenclatureId,
  setSelectedMaterialClientId,
  setSelectedMaterialsClientIds,
  setSelectedMaterialCategoryId,
  //
  addMaterial,
  //
  setOpenDialogFsMaterialsSettings,
  setOpenDialogFsSelectorMaterialCategory,
  setOpenDialogFsSelectorMaterial,
  //
  setNewMaterial,
  setEditedMaterial,
  setIsEditingMaterial,
} = materialsSlice.actions;

export default materialsSlice.reducer;
