import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import {withStyles} from "@mui/styles";

import {setSelectedListItemId} from "../leftPanelSlice";

import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import {setSelectedSectorId} from "Features/sectors/sectorsSlice";
import {setSelectedRoomId} from "Features/rooms/roomsSlice";
import {setSelectedMeasurementsModelId} from "Features/measurements/measurementsSlice";

export default function ListSceneSectors({scene, caplaEditor}) {
  const dispatch = useDispatch();

  //

  caplaEditor.timeEnd = performance.now();
  console.log(
    "debugtime navtime toSectors",
    caplaEditor.timeEnd - caplaEditor.timeStart
  );

  // data

  const measurements = useSelector((s) => s.measurements.items);

  const selectedId = useSelector((s) => s.leftPanel.selectedListItemId);
  const sectors = useSectorsByScene(scene, {sortByZ: true});
  const selectedSectorId = useSelector((s) => s.sectors.selectedSectorId);

  // helper - styles

  const styleMap = {
    DEFAULT: {fontWeight: "normal", bgcolor: "inherit", color: "inherit"},
    TITLE1: {fontWeight: "bold", bgcolor: "#bbbbc0", color: "text.primary"},
    TITLE2: {
      fontWeight: "bold",
      bgcolor: "background.default",
      color: "text.primary",
    },
    DETAIL: {
      fontWeight: "normal",
      bgcolor: "common.white",
      color: "text.secondary",
    },
  };

  const StyledItem = withStyles({
    root: {
      "&:hover": {
        backgroundColor: "secondary.main",
      },
    },
    selected: {},
  })(ListItem);

  // helper - buildings, sectors

  const sectorsWithRoomsandCount = sectors.map((s) => {
    return {
      ...s,
      count: measurements.filter((m) => m.sectorId === s.id).length,
    };
  });

  const sectorsByBuildings = {};

  sectorsWithRoomsandCount.map((sector) => {
    const building = sector?.building ? sector.building : "-?-";
    if (sector && building in sectorsByBuildings) {
      sectorsByBuildings[building].push(sector);
    } else if (sector) {
      sectorsByBuildings[building] = [sector];
    }
  });

  // helpers - items and callback

  const items = [];
  Object.keys(sectorsByBuildings)
    .sort((a, b) => a.localeCompare(b))
    .map((building) => {
      items.push({
        id: building,
        name: building,
        style: "title1",
        isBuilding: true,
      });
      sectorsByBuildings[building]?.forEach((sector) => {
        items.push({
          id: sector.id,
          name: sector.name,
          code: sector.code,
          count: sector.count,
          z: sector.z > 0 ? "+" + sector.z : sector.z,
        });
      });
    });

  const callback = (id) => {
    dispatch(setSelectedSectorId(id));
  };

  // handlers

  const handleListItemClick = (option) => {
    const id = selectedId !== option.id ? option.id : null;
    dispatch(setSelectedListItemId(id));
    callback(id);
  };

  // effects

  useEffect(() => {
    if (selectedId !== selectedSectorId)
      dispatch(setSelectedListItemId(selectedSectorId));
    if (caplaEditor?.measDataManager.selectedModelId) {
      // caplaEditor?.measDataManager.setSelectedModelById(null);
      dispatch(setSelectedMeasurementsModelId(null));
    }
    dispatch(setSelectedRoomId(null));
  }, []);

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        maxHeight: 1,
        display: items?.length > 0 ? "flex" : "none",
        flexDirection: "column",
        overflow: "auto",
        bgcolor: "common.white",
        mt: 2,
      }}
    >
      <List dense disablePadding>
        {items.map((item) => {
          let styleType = "DEFAULT";
          if (item.style === "title1") styleType = "TITLE1";
          const {fontWeight, bgcolor, color} = styleMap[styleType];

          const labelBuilding = item.name;
          const labelSector = `${item.name} [${item.code}]`;

          const label = item.isBuilding ? labelBuilding : labelSector;
          const label2 = item.isBuilding ? "" : `${item.z} m`;

          return (
            <StyledItem
              key={item.id}
              disablePadding
              secondaryAction={
                typeof item.count === "number" &&
                !item.isTitle && (
                  <Typography
                    sx={{fontSize: 12, mr: -0.5, color: "secondary.main"}}
                  >
                    {item.count}
                  </Typography>
                )
              }
            >
              <ListItemButton
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "stretch",
                  px: 1,
                  py: 0.5,
                  color,
                  bgcolor,
                }}
                selected={selectedId === item.id}
                onClick={() => handleListItemClick(item)}
                disableGutters
                divider={true}
              >
                <Box sx={{display: "flex", alignItems: "center"}}>
                  <Typography sx={{fontWeight, fontSize: 12}}>
                    {label}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight,
                      fontSize: 12,
                      color: "text.secondary",
                      ml: 2,
                    }}
                  >
                    {label2}
                  </Typography>
                </Box>
              </ListItemButton>
            </StyledItem>
          );
        })}
      </List>
    </Box>
  );
}
