import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import ToolbarBottomViewerPdf from "./ToolbarBottomViewerPdf";
import TogglePanSelect from "./TogglePanSelect";
import ButtonDialogPdfScale from "./ButtonDialogPdfScale";
import SelectorPdfModelViewer from "./SelectorPdfModelViewer";
import ButtonToggleSearchPanel from "./ButtonToggleSearchPanel";
import ButtonRotateClockwise from "./ButtonRotateClockwise";
import SectionSelectorPdf from "./SectionSelectorPdf";

import useSceneModule from "Features/navigation/useSceneModule";
import usePageType from "Features/navigation/usePageType";
import ToolShortcuts from "Features/viewer3D/components/ToolShortcuts";

import SectionMeasuredLength from "Features/measurements/components/SectionMeasuredLength";
import useIsFullscreen from "Features/ui/useIsFullscreen";
import ButtonDownloadPdfInToolbar from "./ButtonDownloadPdfInToolbar";
import useIsDrawing from "Features/measurements/useIsDrawing";
import SectionDifferenceNewOldPdf from "./SectionDifferenceNewOldPdf";
import ButtonSectionDifference from "./ButtonSectionDifference";
import ButtonRefreshPdfViewer from "./ButtonRefreshPdfViewer";

import SectionPois from "Features/pois/components/SectionPois";
import IconButtonDebug from "./IconButtonDebug";
import SectionLoadPdfBimData from "./SectionLoadPdfBimData";
import useShowLoadPdfBimData from "../hooks/useShowLoadPdfBimData";

export default function LayerMain({caplaEditor, scene, space, viewer}) {
  // data

  const fullscreen = useIsFullscreen();
  const sceneModule = useSceneModule();
  const pageType = usePageType();

  const os = useSelector((s) => s.ui.openSections);
  const measuredLength = useSelector((s) => s.measurements.measuredLength);
  const selectedPdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);
  const showPdfSelector = useSelector((s) => s.pdf.showPdfSelector);
  const showScreenPdfViewerHelper = useSelector(
    (s) => s.pdf.showScreenPdfViewerHelper
  );
  const showLoadPdfBimData = useShowLoadPdfBimData(scene);

  const isDrawing = useIsDrawing();

  // helper

  const top = viewer || fullscreen ? "16px" : "68px";
  const topOffset = fullscreen ? "68px" : "120px";

  // helper - selection

  const noPdf = !Boolean(selectedPdfModelId);

  // helper - show PDF scale

  let showPdfScale = false;
  if (
    ["EDIT_MEASUREMENTS", "ZONES", "INPUTS", "PACKAGES", "ONBOARDING"].includes(
      sceneModule
    )
  ) {
    showPdfScale = true;
  }

  // helper - hide selector

  const hidePdfSelector = sceneModule === "DATA" || pageType === "COLORING";

  // helper - show overview

  // const showOverview =
  //   false &&
  //   (sceneModule === "HOME" || viewer) &&
  //   !os.viewer3D &&
  //   !os.callToAction;

  return (
    <>
      {/* <Box
        sx={{
          position: "absolute",
          top: "16px",
          right: "16px",
          display: noPdf ? "none" : "flex",
          alignItems: "center",
          "&>*:not(:last-child)": {mr: 1},
          zIndex: 60,
        }}
      >
        <IconButtonDebug caplaEditor={caplaEditor} />
      </Box> */}

      {/* {!isDrawing && (
        <Box
          sx={{
            position: "absolute",
            top: "16px",
            left: "16px",
            display: noPdf ? "none" : "flex",
            alignItems: "center",
            "&>*:not(:last-child)": {mr: 1},
            zIndex: 60,
          }}
        >
          <SectionPois caplaEditor={caplaEditor} inViewer="PDF" />
        </Box>
      )} */}

      {showLoadPdfBimData && !isDrawing && sceneModule === "INPUTS" && (
        <Box
          sx={{
            position: "absolute",
            top: "8px",
            transform: "translateX(-50%)",
            left: "50%",
            display: noPdf ? "none" : "flex",
            alignItems: "center",
            "&>*:not(:last-child)": {mr: 1},
            zIndex: 60,
          }}
        >
          <SectionLoadPdfBimData caplaEditor={caplaEditor} scene={scene} />
        </Box>
      )}

      {!isDrawing && sceneModule === "INPUTS" && (
        <Box
          sx={{
            position: "absolute",
            top: "16px",
            left: "16px",
            display: noPdf ? "none" : "flex",
            alignItems: "center",
            "&>*:not(:last-child)": {mr: 1},
            zIndex: 60,
          }}
        >
          <ButtonSectionDifference caplaEditor={caplaEditor} scene={scene} />
        </Box>
      )}
      {!isDrawing && sceneModule !== "INPUTS" && (
        <Box
          sx={{
            position: "absolute",
            top: "16px",
            left: "16px",
            display: noPdf ? "none" : "flex",
            alignItems: "center",
            "&>*:not(:last-child)": {mr: 1},
            zIndex: 60,
          }}
        >
          <ButtonRefreshPdfViewer editorPdf={caplaEditor.editorPdf} />
        </Box>
      )}
      {measuredLength?.length && (
        <Box
          sx={{
            position: "absolute",
            top: topOffset,
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <SectionMeasuredLength editorPdf={caplaEditor?.editorPdf} />
        </Box>
      )}
      {!noPdf && !isDrawing && (
        <Box
          sx={{
            position: "absolute",
            bottom: "16px",
            left: "16px",
            zIndex: 30,
            display: "flex",
            alignItems: "center",
          }}
        >
          <TogglePanSelect editorPdf={caplaEditor?.editorPdf} />
        </Box>
      )}
      {!noPdf &&
        os.fixedViewersBox &&
        !os.outlet &&
        (!["LIBRARY", "PRICINGS", "MODULES"].includes(sceneModule) ||
          viewer) && (
          <Box
            sx={{
              position: "absolute",
              bottom: "16px",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 35,
              display: "flex",
              alignItems: "center",
              "&>*:not(:last-child)": {mr: 1},
            }}
          >
            <ToolbarBottomViewerPdf
              caplaEditor={caplaEditor}
              viewer={viewer}
              scene={scene}
            />
          </Box>
        )}

      {!isDrawing && (
        <Box
          sx={{
            position: "absolute",
            top,
            left: "16px",
            display: noPdf ? "none" : "flex",
            alignItems: "center",
            "&>*:not(:last-child)": {mr: 1},
            zIndex: 60,
          }}
        >
          <ToolShortcuts
            caplaEditor={caplaEditor}
            space={space}
            scene={scene}
            sceneViewer="PDF"
            viewer={viewer}
          />
        </Box>
      )}
      {!isDrawing && (
        <Box
          sx={{
            position: "absolute",
            bottom: "16px",
            right: "16px",
            zIndex: 30,
            display: noPdf ? "none" : "flex",
            alignItems: "center",
            "&>*:not(:last-child)": {
              mr: 1,
            },
          }}
        >
          {!viewer && !os.outlet && sceneModule !== "HOME" && (
            <ButtonDownloadPdfInToolbar editorPdf={caplaEditor?.editorPdf} />
          )}
          {/* {!os.outlet && (showPdfScale || viewer) && (
          <ButtonRotateClockwise editorPdf={caplaEditor?.editorPdf} />
        )} */}
          {!os.outlet && showPdfScale && (
            <ButtonDialogPdfScale
              scene={scene}
              editorPdf={caplaEditor?.editorPdf}
            />
          )}
          {!os.outlet &&
            (!["HOME", "LIBRARY", "PRICINGS", "MODULES"].includes(
              sceneModule
            ) ||
              viewer) && (
              <ButtonToggleSearchPanel editorPdf={caplaEditor?.editorPdf} />
            )}
        </Box>
      )}

      {viewer &&
        !hidePdfSelector &&
        !os.outlet &&
        (!["HOME", "LIBRARY", "PRICINGS", "MODULES"].includes(sceneModule) ||
          viewer) && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              position: "absolute",
              top,
              right: viewer || os.viewer3D ? "8px" : "43px",
              // transform: viewer ? "translateX(0)" : "translateX(50%)",
              zIndex: 30,
              "&>*:not(:last-child)": {
                mr: 1,
              },
            }}
          >
            {!fullscreen && (
              <SelectorPdfModelViewer
                maxWidth={240}
                sceneModule={sceneModule}
              />
            )}
          </Box>
        )}

      {!os.outlet &&
        showPdfSelector &&
        (!["LIBRARY", "PRICINGS", "MODULES"].includes(sceneModule) ||
          viewer) && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              bgcolor: "common.white",
              zIndex: 90,
              borderLeft: "1px solid black",
              display: showScreenPdfViewerHelper ? "none" : "flex",
            }}
          >
            <SectionSelectorPdf
              viewer={viewer}
              caplaEditor={caplaEditor}
              scene={scene}
            />
          </Box>
        )}
    </>
  );
}
