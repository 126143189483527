import {useEffect} from "react";

import {useDispatch} from "react-redux";

import useProfile from "Features/profile/useProfile";

import {fetchOrganisationNomenclatures} from "../materialsSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function useInitFetchUserOrganisationNomenclatures() {
  //
  const accessToken = useAccessToken();
  const dispatch = useDispatch();
  //
  const {data: profile} = useProfile();
  //
  useEffect(() => {
    if (profile?.organisationId && accessToken) {
      dispatch(
        fetchOrganisationNomenclatures({
          accessToken,
          organisationId: profile.organisationId,
        })
      );
    }
  }, [accessToken, profile?.organisationId]);
}
