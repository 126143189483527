import {useDispatch} from "react-redux";
import {
  setOpenDialogFsOpenScope,
  setOpenDialogFsSelectScope,
} from "Features/overviewer/overviewerSlice";

import {Box, Typography, IconButton, Tooltip} from "@mui/material";
import {Refresh, MoreHoriz as More} from "@mui/icons-material";

export default function SelectorScopeListHeader({onRefreshClick, onMoreClick}) {
  const dispatch = useDispatch();

  // strings

  const title = "Sélectionnez une sous-bimbox";

  const refreshS = "Rafraîchir la liste";

  // handlers

  // function handleRefreshClick() {
  //   onRefreshClick();
  // }

  // function handleMoreClick() {
  //   console.log("click");
  //   dispatch(setOpenDialogFsSelectScope(true));
  //   if (onMoreClick) onMoreClick();
  // }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: 1,
        justifyContent: "space-between",
        px: 1,
        color: "text.secondary",
      }}
    >
      <Typography sx={{fontSize: 12}}>{title}</Typography>
      <Box sx={{display: "flex", alignItems: "center"}}>
        {/* <IconButton size="small" onClick={handleMoreClick} color="inherit">
          <More fontSize="small" />
        </IconButton> */}
        {/* <Tooltip title={refreshS}>
          <IconButton size="small" onClick={handleRefreshClick} color="inherit">
            <Refresh fontSize="small" />
          </IconButton>
        </Tooltip> */}
      </Box>
    </Box>
  );
}
