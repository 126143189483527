import {useDispatch} from "react-redux";
import useAccessToken from "Features/auth/useAccessToken";

import {deleteArticlesGroup} from "../articlesSlice";

export default function useDeleteArticlesGroup() {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const deleteGroup = async (group) => {
    if (accessToken && group?.id) {
      await dispatch(
        deleteArticlesGroup({
          articlesGroupId: group.id,
          sceneId: group.sceneId,
          accessToken,
        })
      );
    }
  };

  return deleteGroup;
}
