import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
// import {useParams} from "react-router-dom";

import {Menu, MenuItem, Box, Typography, IconButton} from "@mui/material";
import {MoreHoriz as More} from "@mui/icons-material";

import DialogEditMultipleMeasurements from "./DialogEditMultipleMeasurements";
import DialogPasteMeasurements from "./DialogPasteMeasurements";
// import getCleanedMeasurements from "../utils/getCleanedMeasurements";
import updateMeasurementFromType from "../utils/updateMeasurementFromType";
import {
  setHideSavedMeasurements,
  // updateMeasurements,
} from "../measurementsSlice";
import useMeasurementsScopeMode from "../useMeasurementsScopeMode";
import useFilteredSceneMeasurements from "../useFilteredSceneMeasurements";

import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";
// import useSelectedPdfModel from "Features/pdf/useSelectedPdfModel";

import cleanMeasurementPaths from "../utils/cleanMeasurementPaths";
import DialogCleanMeasurements from "./DialogCleanMeasurements";
import useSelectedMeasurementsModelsData from "../useSelectedMeasurementsModelData";
import useSelectedMeasurementsModel from "../useSelectedMeasurementsModel";
import {updateModel} from "Features/viewer3D/viewer3DSlice";
import getItemsMapById from "Utils/getItemsMapById";

export default function ButtonMoreTools({caplaEditor, scene, viewer}) {
  const dispatch = useDispatch();

  // strings

  const updateFromTypesS = "Update from types";
  const updateResFromTypesS = "Update articles (from types)";
  const editMultipleS = "Edit filtered elements";
  const updateHeightsS = "Update elements height";
  const cleanMeasurementsS = "Clean measurements";
  const updateQuantitiesS = "Update quantities";
  const pasteElementsS = "Paste elements";
  const refresh3DS = "Refresh 3D from PDF";
  const hideShowSavedS = "les éléments sauvegardés";

  // data

  // const {modelId} = useParams();
  const os = useSelector((s) => s.ui.openSections);
  const elementTypes = useElementTypesBySceneProxy(scene);
  const ressources = useRessourcesByScene(scene);
  const rooms = useRoomsByScene(scene);
  const mode = useMeasurementsScopeMode(scene, viewer);
  const [fms] = useFilteredSceneMeasurements(scene, mode, false, true, false);
  const zones = useZonesByScene(scene);
  // const pdfModelId = useSelectedPdfModel()?.id;
  const copiedMeasurements = useSelector(
    (s) => s.measurements.copiedMeasurements
  );
  const hideSaved = useSelector((s) => s.measurements.hideSavedMeasurements);
  const {measurements} = useSelectedMeasurementsModelsData(scene);
  const model = useSelectedMeasurementsModel();

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [openEditMultiple, setOpenEditMultiple] = useState(false);
  const [openCopy, setOpenCopy] = useState(false);

  const [openDialogCleanMeasurements, setOpenDialogCleanMeasurements] =
    useState(false);
  const [cleanMeasurementIds, setCleanMeasurementIds] = useState(null);
  const [measurementsClean, setMeasurementsClean] = useState(null);

  // helpers

  const mnger =
    caplaEditor?.editorPdf?.annotationsManager?.measurementsPdfManager;
  const hideShowSavedLabel =
    `${hideSaved ? "Afficher" : "Masquer"} ` + hideShowSavedS;

  const ressourcesById = getItemsMapById(ressources);
  const elementTypesById = getItemsMapById(elementTypes);
  const zonesById = getItemsMapById(zones);

  // helpers - handlers

  function updateFromTypes() {
    mnger?.setIsEditing(true);
    fms
      .filter((m) => !m.isLocked)
      .forEach((measurement) => {
        const type = elementTypes.find(
          (t) => t.id === measurement.elementTypeId
        );
        const newMeasurement = updateMeasurementFromType(measurement, type);
        mnger?.updateAnnotation(newMeasurement, {
          multipleUpdates: true,
          zonesById,
        });
      });
    mnger?.setIsEditing(false);

    setAnchorEl(null);
  }

  function updateRessourcesFromTypes() {
    const newMs = fms.map((measurement) => {
      let newM;
      const type = elementTypesById[measurement.elementTypeId];
      const res = type?.res
        ? type.res.map(({resId}) => ({resId, off: false}))
        : [];
      if (res) {
        newM = {...measurement, res};
      } else {
        newM = {...measurement};
      }

      // clean path (decimal places <=4 )
      newM = cleanMeasurementPaths(newM);
      // force count to 1
      newM.count = 1;
      return newM;
    });

    // console.log("updateM Measurements", newMs);
    // dispatch(updateMeasurements(newMs));
    caplaEditor?.measDataManager.updateMeasurements(newMs);
    setAnchorEl(null);
  }

  function updateHeights() {
    mnger?.setIsEditing(true);
    fms
      .filter((m) => !m.isLocked)
      .forEach((measurement) => {
        const zone = zonesById[measurement.zoneId];
        const elementType = elementTypes.find(
          (t) => t.id === measurement.elementTypeId
        );
        const room = rooms.find((r) => r.id === measurement.roomId);
        const newMeasurement = {...measurement};

        if (elementType?.heightFromRoom && room?.hsp) {
          newMeasurement.height = room.hsp;
          mnger?.updateAnnotation(newMeasurement, {
            multipleUpdates: true,
            zonesById,
          });
        } else if (!measurement.height && zone?.elementsH) {
          newMeasurement.height = zone.elementsH;
          mnger?.updateAnnotation(newMeasurement, {
            multipleUpdates: true,
            zonesById,
          });
        }
      });
    mnger?.setIsEditing(false);

    setAnchorEl(null);
  }

  function editMultiple() {
    setOpenEditMultiple(true);
    setAnchorEl(null);
  }

  // function cleanMeasurements() {
  //   const newMs = getCleanedMeasurements({measurements: fms, ressources});
  //   dispatch(updateMeasurements(newMs));
  //   setAnchorEl(null);
  // }

  function handleCancelCleanMeasurements() {
    setOpenDialogCleanMeasurements(false);
  }

  function handleStartCleanMeasurements() {
    setAnchorEl(null);
    let noZone = 0;
    let noType = 0;
    let changedRes = 0;
    let duplicates = 0;
    let cleanMeas = [];
    const measIds = new Set();
    for (const m of measurements) {
      const meas = {...m};
      let discard = false;
      if (typeof m.zoneId !== "string") {
        noZone += 1;
        discard = true;
      }
      if (
        typeof m.elementTypeId !== "string" ||
        !elementTypesById[m.elementTypeId]
      ) {
        noType += 1;
        discard = true;
      }
      if (measIds.has(m.id)) {
        duplicates += 1;
        discard = true;
      }
      if (Array.isArray(m.res)) {
        const newRes = m.res.filter(({resId}) => ressourcesById[resId]);
        if (m.res.length !== newRes.length) {
          changedRes += 1;
          meas.res = newRes;
        }
      }
      if (!discard) cleanMeas.push(meas);
    }
    setCleanMeasurementIds({
      noZone,
      noType,
      changedRes,
      duplicates,
    });
    setMeasurementsClean(cleanMeas);
    setOpenDialogCleanMeasurements(true);
  }

  function handleConfirmCleanMeasurements() {
    if (Array.isArray(measurementsClean)) {
      const updatedModel = {
        ...model,
        measCount: measurementsClean.length,
        measurementsData: {
          ...model.measurementsData,
          measurements: measurementsClean,
        },
      };
      dispatch(updateModel({updatedModel, sync: true}));
    }
    setOpenDialogCleanMeasurements(false);
  }

  function updateQuantities() {
    fms.map((m) => mnger?.updateMeasurement3D(m));
    setAnchorEl(null);
  }

  function pasteElements() {
    setOpenCopy(true);
    setAnchorEl(null);
  }

  function refresh3D() {
    const mnger =
      caplaEditor?.editorPdf.annotationsManager.measurementsPdfManager;
    fms.forEach((measurement) => {
      mnger?.updateMeasurement3D(measurement);
    });
    setAnchorEl(null);
  }

  const hideShowSaved = () => {
    dispatch(setHideSavedMeasurements(!hideSaved));
  };

  // helpers - tools

  const tools = [
    {
      id: "UPDATE_FROM_TYPES",
      label: updateFromTypesS,
      handler: updateFromTypes,
    },
    {
      id: "UPDATE_RESSOURCES_FROM_TYPES",
      label: updateResFromTypesS,
      handler: updateRessourcesFromTypes,
    },
    {id: "UPDATE_HEIGHTS", label: updateHeightsS, handler: updateHeights},
    {id: "EDIT_MULTIPLE", label: editMultipleS, handler: editMultiple},
    {
      id: "CLEAN_MEASUREMENTS",
      label: cleanMeasurementsS,
      handler: handleStartCleanMeasurements,
    },
    {
      id: "UPDATE_QUANTITIES",
      label: updateQuantitiesS,
      handler: updateQuantities,
    },
    {
      id: "PASTE_ELEMENTS",
      label: pasteElementsS,
      handler: pasteElements,
    },
    {
      id: "REFRESH_3D",
      label: refresh3DS,
      handler: refresh3D,
    },
    {
      id: "HIDE_SHOW_SAVED",
      label: hideShowSavedLabel,
      handler: hideShowSaved,
    },
  ];

  return (
    <>
      <IconButton size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
        <More fontSize="small" color="action" />
      </IconButton>

      <Menu open={open} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {tools.map((tool) => {
          if (tool.id === "PASTE_ELEMENTS" && copiedMeasurements.pdfModelId)
            return (
              <MenuItem key={tool.id} onClick={tool.handler}>
                <Typography variant="body2">{tool.label}</Typography>
              </MenuItem>
            );
          else if (tool.id === "REFRESH_3D" && os.fixedViewersBox)
            return (
              <MenuItem key={tool.id} onClick={tool.handler}>
                <Typography variant="body2">{tool.label}</Typography>
              </MenuItem>
            );
          else if (!["PASTE_ELEMENTS", "REFRESH_3D"].includes(tool.id))
            return (
              <MenuItem key={tool.id} onClick={tool.handler}>
                <Typography variant="body2">{tool.label}</Typography>
              </MenuItem>
            );
        })}
      </Menu>
      <DialogPasteMeasurements
        open={openCopy}
        onClose={() => setOpenCopy(false)}
        copiedMeasurements={copiedMeasurements}
        scene={scene}
        caplaEditor={caplaEditor}
      />
      <DialogEditMultipleMeasurements
        open={openEditMultiple}
        onClose={() => setOpenEditMultiple(false)}
        caplaEditor={caplaEditor}
        scene={scene}
        measurements={fms}
      />
      <DialogCleanMeasurements
        cleanMeasurementIds={cleanMeasurementIds}
        onConfirm={handleConfirmCleanMeasurements}
        open={openDialogCleanMeasurements}
        onClose={handleCancelCleanMeasurements}
        onCancel={handleCancelCleanMeasurements}
      />
    </>
  );
}
