export default function updateMeasurementsHeightsWithContinuity(measurements) {
  const sortedMeasurements = measurements.sort((a, b) => a.zInf - b.zInf);
  //
  const updatedMeasurements = [];
  //
  sortedMeasurements.forEach((meas, index) => {
    if (index < measurements.length - 1) {
      const measUp = measurements[index + 1];
      if (meas.zSup !== measUp.zInf) {
        const delta = measUp.zInf - meas.zSup;
        const updatedMeas = {
          ...meas,
          zSup: meas.zSup + delta,
          height: meas.height + delta,
        };
        updatedMeasurements.push(updatedMeas);
      }
    }
  });
  //
  return updatedMeasurements;
}
