import {useState} from "react";

import useActionsSceneHomeMap from "../hooks/useActionsSceneHomeMap";

import {Box} from "@mui/material";

import SectionGenericActions from "Components/SectionGenericActions";

import DialogShareSceneScope from "./DialogShareSceneScope";
import DialogEditMemberships from "Features/viewer3D/components/DialogEditMemberships";
import DialogDeleteScene from "Features/scenes/components/DialogDeleteScene";

export default function SectionActionsSceneHome({
  scene,
  caplaEditor,
  actionsMainKeys = [],
  actionsMoreKeys = [],
}) {
  // state

  const [openDialogXxx, setOpenDialogXxx] = useState(false);

  const [openDialogShareSceneScope, setOpenDialogShareSceneScope] =
    useState(false);
  const [openDialogEditMemberships, setOpenDialogEditMemberships] =
    useState(false);
  const [openDialogDeleteScene, setOpenDialogDeleteScene] = useState(false);

  // handlerMap

  const setOpenDialogMap = {
    launchXxx: () => setOpenDialogXxx(true),
    shareSceneScope: () => setOpenDialogShareSceneScope(true),
    editMemberships: () => setOpenDialogEditMemberships(true),
    deleteScene: () => setOpenDialogDeleteScene(true),
  };

  // handlers

  function handleClick(actionKey) {
    console.log("trigger", actionKey);
    //
    const setOpenDialog = setOpenDialogMap[actionKey];
    if (setOpenDialog) setOpenDialog();
    //
    const action = actionsMap[actionKey];
    if (action?.handler) action.handler();
  }

  // data - handlers

  const actionsMap = useActionsSceneHomeMap({scene, caplaEditor});

  const actionsMain = actionsMainKeys.map((key) => ({key, ...actionsMap[key]}));
  const actionsMore = actionsMoreKeys.map((key) => ({key, ...actionsMap[key]}));

  return (
    <>
      <SectionGenericActions
        actionsMain={actionsMain}
        actionsMore={actionsMore}
        onClick={handleClick}
      />

      {openDialogShareSceneScope && (
        <DialogShareSceneScope
          scene={scene}
          caplaEditor={caplaEditor}
          open={openDialogShareSceneScope}
          onClose={() => setOpenDialogShareSceneScope(false)}
        />
      )}
      {openDialogEditMemberships && (
        <DialogEditMemberships
          scene={scene}
          open={openDialogEditMemberships}
          onClose={() => setOpenDialogEditMemberships(false)}
        />
      )}
      {openDialogDeleteScene && (
        <DialogDeleteScene
          scene={scene}
          open={openDialogDeleteScene}
          onClose={() => setOpenDialogDeleteScene(false)}
        />
      )}
    </>
  );
}
