import {useSelector} from "react-redux";
import zonesSlice from "../zonesSlice";
import useSelectedScope from "Features/scopes/hooks/useSelectedScope";

import getItemsMapById from "Utils/getItemsMapById";

export default function useZonesByScene(scene, options) {
  // options

  const filterByPdf = options?.filterByPdf;
  const sortByPdf = options?.sortByPdf;
  const filterByScope = options?.filterByScope;
  const currentPage = options?.currentPage;
  const sortByPage = options?.sortByPage;
  const variant = options?.variant; // "map"/"array"

  // data
  const scope = useSelectedScope();
  const sci = scene?.clientId;
  const sectors = scene?.data?.sectors ? scene.data.sectors : [];
  const rooms = scene?.data?.rooms ? scene.data.rooms : [];

  const models = useSelector((s) => s.viewer3D.models).filter(
    (m) => m.sceneClientId === sci
  );
  const modelsWithInitialTexture = useSelector(
    (s) => s.viewer3D.modelsWithInitialTexture
  );

  const selectedPdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);
  const pdfCurrentPage = useSelector((s) => s.viewer3D.pdfCurrentPage);

  // helpers

  const modelsWithZones = models.filter((m) => m.zones?.length > 0);
  const sectorsMap = getItemsMapById(sectors);
  const modelsMap = getItemsMapById(models);
  const roomsMap = getItemsMapById(rooms);

  let annotZones = [];
  modelsWithZones.forEach((model) => {
    const zones = model.zones.map((zone) => {
      const sector = sectorsMap[zone.sectorId];
      const room = roomsMap[zone.roomId];
      const imageModel = modelsMap[zone.imageModelId];
      const imageUrl = imageModel?.fileRemoteUrl
        ? imageModel.fileRemoteUrl
        : imageModel?.url;
      return {
        ...zone,
        //position: imageModel?.position, // position is taken from the imageModel, not the zone props...
        //rotation: imageModel?.rotation, // commented to manage zone update from markers
        pdfModelId: model.id,
        pdfModelName: model.name,
        building: sector?.building,
        sectorCode: sector?.code,
        sectorName: sector?.name,
        roomName: room?.name,
        zForSorting: sector?.z || sector?.z === 0 ? sector.z : -1000,
        imageUrl,
        imageModel: imageModel,
        imageModelName: imageModel?.name,
        name: zone.name ? zone.name : "-?-",
        d3: imageModel?.d3,
        hidden: imageModel?.hidden,
        imageModelPreviewUrl: imageModel?.previewUrl,
        imageModelWithInitialTexture: modelsWithInitialTexture.includes(
          imageModel?.id
        ),
      };
    });
    annotZones.push(...zones);
  });

  // zones from Image only

  const imageModelsWithoutPdf = models.filter(
    (m) => m.type === "IMAGE" && !m.fromModel?.modelId
  );
  const zonesProxy = imageModelsWithoutPdf.map((model) => ({
    ...model,
    imageUrl: model?.fileRemoteUrl ? model.fileRemoteUrl : model?.url,
    imageModel: model,
    imageModelName: model?.name,
    pdfModelId: "undefined",
    pdfModelName: "Autre",
    pageNumber: 0, // to manage sortByPage option
    imageSize: model?.imageSize ?? {width: model.width, height: model.height},
  }));

  annotZones = [...annotZones, ...zonesProxy];

  //

  annotZones = annotZones
    .sort((a, b) => a.name.localeCompare(b.name))
    .sort((a, b) => b.zForSorting - a.zForSorting);

  // filter by pdf

  if (filterByPdf) {
    annotZones = annotZones.filter(
      (zone) => zone.pdfModelId === selectedPdfModelId
    );
  }
  // filter by current page

  if (currentPage) {
    annotZones = annotZones.filter((zone) => {
      return (
        zone.pdfModelId === selectedPdfModelId &&
        zone.pageNumber === pdfCurrentPage
      );
    });
  }
  // filter by scope
  if (filterByScope && scope?.data) {
    const pdfIds = scope.data.inputIds ?? [];
    const zonesIds = scope.data.zoneIds ?? [];
    const mapIds = scope.data.mapIds ?? [];
    annotZones = annotZones.filter((zone) => {
      const test1 = zonesIds.includes(zone.imageModelId);
      const test2 = pdfIds.includes(zone.pdfModelId);
      const test3 = mapIds.includes(zone.id);
      return test2 || test3; // no test 1, we do not update the scope for each zone creation. It is updated with the getFixedScope method.
    });
  }

  if (sortByPdf) {
    const zonesWithPdf = [];
    const pdfMap = {};
    annotZones
      .sort((a, b) => a.name.localeCompare(b.name))
      .sort((a, b) => a.pdfModelName.localeCompare(b.pdfModelName))
      .forEach((zone) => {
        if (pdfMap[zone.pdfModelId]) {
          zonesWithPdf.push(zone);
        } else {
          const zoneProxy = {
            id: zone.pdfModelId,
            name: zone.pdfModelName,
            style: "title1",
            isPdf: true,
          };
          zonesWithPdf.push(zoneProxy);
          zonesWithPdf.push(zone);
          pdfMap[zone.pdfModelId] = "ok";
        }
      });
    annotZones = zonesWithPdf;
  }

  if (sortByPage) {
    annotZones = annotZones.sort((a, b) => a.pageNumber - b.pageNumber);
  }

  if (variant === "map") {
    const annotZonesMap = getItemsMapById(annotZones);
    return annotZonesMap;
  } else {
    return annotZones;
  }
}
