import {useDispatch} from "react-redux";

import {setHasBimData, removeFromDialogLoadBimDataScope} from "../pdfSlice";
import useSelectedPdfModel from "../useSelectedPdfModel";
import {setModelIdsInMeasDataManager} from "Features/overviewer/overviewerSlice";
import {addElementTypesGroups} from "Features/elementTypes/elementTypesSlice";
import {updateModel} from "Features/viewer3D/viewer3DSlice";
import {
  // updateModelMeasurements,
  addMeasurementsModelToScope,
  addZonesInScope,
  addSectorsInScope,
  addToShortcutElementTypes,
  addToShortcutSectors,
  addToShortcutPhases,
  addElementTypesInScope,
} from "Features/measurements/measurementsSlice";

import useAddSceneBimData from "Features/measurements/useAddSceneBimData";

export default function useLoadPdfBimData(scene, caplaEditor) {
  const dispatch = useDispatch();
  const addSceneBimData = useAddSceneBimData(scene);

  const load = async (selectedPdfModel) => {
    dispatch(removeFromDialogLoadBimDataScope(selectedPdfModel?.id));

    let updatedModel = {...selectedPdfModel};

    let {sectors, zones, elementTypesGroups, packages, pdfScale} =
      caplaEditor?.editorPdf.loader.getBimData();

    // elementTypes
    const elementTypes = (elementTypesGroups ?? []).reduce((acc, g) => {
      return [...acc, ...g.elementTypes];
    }, []);
    const newElementTypesGroups = elementTypesGroups.map((g) => {
      const newGroup = {...g};
      if (scene?.id) {
        newGroup.sceneId = scene.id;
      } else {
        newGroup.sceneClientId = scene.clientId;
      }
      return newGroup;
    });

    // update scale

    caplaEditor?.editorPdf.annotationsManager.setScale(pdfScale);
    updatedModel.pdfScale = pdfScale;
    //dispatch(updateModel({updatedModel: {...selectedPdfModel, pdfScale}}));

    // sectors & elementTypes & ressources & phases
    addSceneBimData({sectors});
    dispatch(addElementTypesGroups(newElementTypesGroups));
    //
    dispatch(addToShortcutElementTypes(elementTypes.map((t) => t.id)));
    dispatch(addToShortcutSectors(sectors.map((s) => s.id)));

    // zones
    dispatch(addZonesInScope(zones.map((z) => z.id)));
    updatedModel.zones = zones;

    caplaEditor?.editorPdf.annotationsManager.loadZoneAnnotations(
      zones,
      selectedPdfModel?.id
    );
    zones = zones.map((z) => ({...z, pdfModelId: selectedPdfModel?.id}));
    caplaEditor?.editorPdf.annotationsManager.createImageModelsFromZones(zones);

    // measurementModels (packages)
    if (packages) {
      const fromModel = {type: "PDF", modelId: selectedPdfModel?.id};
      for (let pack of packages) {
        const name = pack.name;
        const measurements = pack.measurementsData?.measurements;
        const measurementsModel =
          await caplaEditor?.editor3d?.loader.createMeasurementsModel({
            name,
            fromModel,
            measurements,
          });

        caplaEditor?.measDataManager?.createOrUpdateModelInManager(
          measurementsModel
        );
      }
      dispatch(setModelIdsInMeasDataManager(packages.map((p) => p.id)));
    }
    //caplaEditor?.measDataManager?.setShortMemory();

    // pdf model
    dispatch(updateModel({updatedModel}));
  };

  return load;
}
