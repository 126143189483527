import {useSelector} from "react-redux";
import {useState} from "react";

import {Box, Typography, Button, Paper, TextField} from "@mui/material";

import updateMeasurementsHeightsWithDelta from "../utils/updateMeasurementsHeightsWithDelta";

export default function SectionAutoMeasAddHeight({caplaEditor, onUpdated}) {
  // strings

  const title = "Ajouter un bout de hauteur";

  const description =
    "Pour les repérages sélectionnés, ajouter un bout de hauteur";

  const updateS = "Mettre à jour";

  // data

  const selectedMeasurementIds = useSelector(
    (s) => s.measurements.selectedMeasurementIds
  );
  const measurements = useSelector((s) => s.measurements.measShowedInViewer3d);

  // state

  const [delta, setDelta] = useState(0);

  // helpers

  const countLabel = selectedMeasurementIds.length + " repérages";

  // helpers - selectedMeasurements

  const measMap = measurements.reduce((acc, m) => {
    acc[m.id] = m;
    return acc;
  }, {});
  const selectedMeasurements = selectedMeasurementIds.map((id) => measMap[id]);

  // handlers

  function handleClick() {
    const updatedMeasurements = updateMeasurementsHeightsWithDelta(
      selectedMeasurements,
      delta
    );
    caplaEditor?.measDataManager.updateMeasurements(updatedMeasurements);

    onUpdated();
  }

  return (
    <Paper>
      <Box sx={{p: 1}}>
        <Typography sx={{mb: 1}}>{title}</Typography>
        <Box sx={{p: 1, bgcolor: "background.default"}}>
          <Typography variant="body2">{description}</Typography>
        </Box>
        <Box sx={{p: 1}}>
          <TextField
            size="small"
            label="Delta H (m)"
            value={delta}
            onChange={(e) => setDelta(e.target.value)}
            InputProps={{sx: {fontSize: 13}}}
          />
        </Box>
        <Box sx={{p: 1}}>
          <Typography variant="body2">{countLabel}</Typography>
        </Box>

        <Box sx={{display: "flex", justifyContent: "end"}}>
          <Button size="small" onClick={handleClick}>
            {updateS}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}
