import {useDispatch, useSelector} from "react-redux";

import useSelectedPdfModel from "../useSelectedPdfModel";

import {removeFromDialogLoadBimDataScope, setHasBimData} from "../pdfSlice";

import {Box, Button, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";
import useLoadPdfBimData from "../hooks/useLoadPdfBimData";

export default function SectionLoadPdfBimData({caplaEditor, scene}) {
  const dispatch = useDispatch();

  // label

  const label = "Charger les données du PDF";

  // data

  const selectedPdfModel = useSelectedPdfModel();

  // data - methods

  const load = useLoadPdfBimData(scene, caplaEditor);

  // handlers

  function handleClick() {
    dispatch(removeFromDialogLoadBimDataScope(selectedPdfModel?.id));
    //
    load(selectedPdfModel);
  }

  function handleClose() {
    dispatch(removeFromDialogLoadBimDataScope(selectedPdfModel?.id));
  }
  return (
    <Box
      sx={{
        bgcolor: "warning.main",
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        color: "common.white",
      }}
    >
      <Button color="inherit" onClick={handleClick}>
        {label}
      </Button>
      <IconButton color="inherit" onClick={handleClose} sx={{ml: 1}}>
        <Close />
      </IconButton>
    </Box>
  );
}
