import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {updateOrganisation} from "../organisationsSlice";

import {Paper, Box, Typography, Button} from "@mui/material";

import {Save} from "@mui/icons-material";

import useAccessToken from "Features/auth/useAccessToken";

import SelectorBimboxModes from "Features/navigation/components/SelectorBimboxModes";

export default function ContainerEditableBimboxModes({organisation}) {
  //
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  // strings

  const labelS = "Configurations";
  const saveS = "Enregistrer";

  // data

  const options = ["BASIC", "STANDARD", "PRO", "DIAG", "EXPERT"];

  // state

  const initBimboxModes = organisation?.bimboxModes ?? [];

  const [tempBimboxModes, setTempBimboxModes] = useState(initBimboxModes);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTempBimboxModes(initBimboxModes);
  }, [organisation?.id]);

  // helpers

  const showSave = initBimboxModes !== tempBimboxModes;
  const organisationId = organisation?.id;

  // handler

  function handleTempBimboxModesChange(modes) {
    setTempBimboxModes(modes);
  }

  async function handleSave() {
    if (loading) return;
    setLoading(true);
    await dispatch(
      updateOrganisation({
        accessToken,
        organisationId,
        updates: {bimboxModes: tempBimboxModes},
      })
    );
    setLoading(false);
  }

  return (
    <Box sx={{px: 2, width: 1}}>
      <Paper sx={{p: 1, width: 1}}>
        <Typography variant="body2" sx={{mb: 1}}>
          {labelS}
        </Typography>
        <SelectorBimboxModes
          selection={tempBimboxModes}
          options={options}
          onChange={handleTempBimboxModesChange}
          multiple={true}
        />
        <Box sx={{display: "flex", justifyContent: "end", mt: 1}}>
          <Button disabled={loading} onClick={handleSave}>
            {saveS}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
