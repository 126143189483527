import {useState} from "react";
import {Box} from "@mui/material";

import DeleteDialog from "Features/ui/components/DeleteDialog";
import useSelectedMaterial from "../hooks/useSelectedMaterial";
import useDeleteMaterial from "../hooks/useDeleteMaterial";

export default function DialogDeleteMaterial({scene, open, onClose}) {
  // state

  const [loading, setLoading] = useState(false);

  // data

  const selectedMaterial = useSelectedMaterial(scene);
  const deleteMaterial = useDeleteMaterial(scene);

  // handler

  function handleClose() {
    onClose();
    setLoading(false);
  }

  async function handleDeleteConfirm() {
    if (loading) return;
    setLoading(true);
    await deleteMaterial(selectedMaterial);
    setLoading(false);
    onClose();
  }

  return (
    <DeleteDialog
      open={open}
      onCancel={handleClose}
      onConfirm={handleDeleteConfirm}
      material="materialItem"
      loading={loading}
    />
  );
}
