import {useState} from "react";

import {useDispatch} from "react-redux";
import {setSnackbarMessage} from "Features/ui/uiSlice";

import {nanoid} from "@reduxjs/toolkit";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import FormMaterial from "./FormMaterial";
import useCreateMaterial from "../hooks/useCreateMaterial";
import useSelectedMaterialsGroup from "../hooks/useSelectedMaterialsGroup";

export default function DialogCreateMaterial({open, onClose, scene}) {
  const dispatch = useDispatch();

  // string

  const title = "Ajouter un nouvel article";

  const createS = "Créer";

  // state

  const [loading, setLoading] = useState(false);
  const [tempMaterial, setTempMaterial] = useState({});

  // data

  const addSceneMaterial = useCreateMaterial(scene);
  const selectedGroup = useSelectedMaterialsGroup(scene);

  // helper

  const groupLabel = selectedGroup?.fromScene
    ? selectedGroup.name
    : selectedGroup.name + " ✨";
  // handlers

  function handleClose() {
    onClose();
  }

  function handleTempMaterialChange(ressource) {
    setTempMaterial(ressource);
  }

  async function handleSave() {
    const ressource = {...tempMaterial, id: nanoid()};
    console.log("[DialogCreateMaterial] ressource", ressource);
    if (loading) return;
    setLoading(true);
    await addSceneMaterial(ressource);
    setLoading(false);
    const message = `Article "${ressource.num} ${ressource.name}" ajouté`;
    const triggeredAt = Date.now();
    dispatch(setSnackbarMessage({message, triggeredAt}));
    onClose();
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{bgcolor: "background.default", p: 0.5, mb: 2}}>
          <Typography sx={{fontSize: 13}}>{groupLabel}</Typography>
        </Box>
        <FormMaterial onChange={handleTempMaterialChange} />
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleSave}>
          {createS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
