import {useDispatch} from "react-redux";
import {setSelectedMeasurementIds} from "./measurementsSlice";

export default function useDeleteMeasurements(caplaEditor) {
  const dispatch = useDispatch();

  const deleteF = (measurements) => {
    // 3D
    measurements.forEach((measurement) => {
      const entity = caplaEditor?.editor3d?.getEntity(
        measurement.measurementsModelId
      );
      entity?.deleteMeasurement(measurement.id);
      // // state
      // dispatch(deleteMeasurement(measurement.id));
    });

    // state and models
    caplaEditor?.measDataManager.deleteMeasurements(measurements);

    // delete PDF annotations
    const mnger =
      caplaEditor?.editorPdf?.annotationsManager.measurementsPdfManager;
    mnger?.deleteMeasurements(measurements.map((m) => m.id));

    // callback
    // handleMeasurementsDeleted();
    dispatch(setSelectedMeasurementIds([]));
    mnger?.setIsEditing(false);
  };

  return deleteF;
}
