import {Box, CircularProgress, Typography} from "@mui/material";

import bimbox from "../assets/bimbox.png";
import ContainerLoadingBimbox from "Features/overviewer/components/ContainerLoadingBimbox";

export default function PageDownloadingScene() {
  const label = "Chargement de la bimbox ...";

  return (
    <Box
      sx={{
        bgcolor: "common.caplaBlack",
        width: 1,
        height: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "common.white",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Box>
        <ContainerLoadingBimbox />
        <Typography variant="h6" sx={{mt: 0, color: "grey.400"}}>
          {label}
        </Typography>
      </Box>
    </Box>
  );
}
