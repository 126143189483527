import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import useArticlesByScene from "../hooks/useArticlesByScene";

import {setSelectedArticleId} from "../articlesSlice";
import {setSelectedArticlesGroupId} from "../articlesSlice";

import {Box, Typography} from "@mui/material";

import ListArticlesMultipleWithGroups from "./ListArticlesMultipleWithGroups";
import useArticlesGroups from "../hooks/useArticlesGroups";
import useAutoUpdateArticleQtyMap from "../hooks/useAutoUpdateArticleQtyMap";
import ButtonExportQtiesInExcel from "./ButtonExportQtiesInExcel";

import getSectorMeasurementsMap from "../utils/getSectorMeasurementsMap";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import useArticlesByGroup from "../hooks/useArticlesByGroup";
import useSelectedArticlesGroup from "../hooks/useSelectedArticlesGroup";
import useAutoUpdateSubArticlesMap from "../hooks/useAutoUpdateSubArticlesMap";
import useElementTypesGroupsProxyBySceneWithElementTypes from "Features/elementTypes/hooks/useElementTypesGroupsProxyBySceneWithElementTypes";
import useAutoUpdateArticlesProxies from "../hooks/useAutoUpdateArticlesProxies";

import useAutoComputeArticles from "../hooks/useAutoComputeArticles";
import useAutoComputeArticlesStep1 from "../hooks/useAutoComputeArticlesStep1";
import useAutoComputeArticlesStep2 from "../hooks/useAutoComputeArticlesStep2";
import useSelectedScope from "Features/scopes/hooks/useSelectedScope";

export default function SectionArticlesInLeftPanel({scene, caplaEditor}) {
  const dispatch = useDispatch();

  // string

  const noGroupS = "Aucune table d'articles n'est disponible.";

  // data

  const groupsViewVariant = useSelector((s) => s.articles.groupsViewVariant);

  const articlesGroups = useArticlesGroups(scene?.id, {
    sortByName: true,
    filterByScope: true,
  });

  const selectedArticlesGroupId = useSelector(
    (s) => s.articles.selectedArticlesGroupId
  );
  const selectedArticlesGroup = useSelectedArticlesGroup();

  const articles = useArticlesByGroup(scene, selectedArticlesGroup);

  const articlesMap = useSelector((s) => s.articles.articlesMap);

  const selectedArticleId = useSelector((s) => s.articles.selectedArticleId);

  const measurements = useSelector((s) => s.measurements.items);

  const sectorMeasurementsMap = getSectorMeasurementsMap(measurements);

  const measSectorIds = Object.keys(sectorMeasurementsMap);

  const sectors = useSectorsByScene(scene, {
    filterByIds: measSectorIds,
    sortByZ: true,
  });

  const elementTypesGroups = useElementTypesGroupsProxyBySceneWithElementTypes(
    scene,
    {
      sortElementTypesByNum: true,
      filterByTypesGroupsIds: selectedArticlesGroup?.typesGroupsIds,
      filterByScope: true,
    }
  );

  const scope = useSelectedScope();

  // AUTO UPDATES

  useAutoComputeArticles({
    articlesGroup: selectedArticlesGroup,
    elementTypesGroupsWithSortedItems: elementTypesGroups,
    measurements,
    sectors,
  });

  // useAutoComputeArticlesStep1({
  //   articlesGroup: selectedArticlesGroup,
  //   elementTypesGroupsWithSortedItems: elementTypesGroups,
  //   measurements,
  //   sectors,
  // });

  // STEP 2 : compute types for articles.
  useAutoComputeArticlesStep2({
    articlesGroup: selectedArticlesGroup,
    elementTypesGroupsWithSortedItems: elementTypesGroups,
  });

  useAutoUpdateArticleQtyMap({scene, articles, measurements, bySector: true});
  //

  // useAutoUpdateSubArticlesMap({
  //   selectedArticlesGroupId,
  //   elementTypesGroups,
  //   measurements,
  //   sectors,
  // });
  // useAutoUpdateArticlesProxies({articlesGroup: selectedArticlesGroup});

  //

  const articleQtyMap = useSelector((s) => s.articles.articleQtyMap);
  const articlesQtiesMapBySector = useSelector(
    (s) => s.articles.articleQtiesMapBySector
  );

  const listItems = articles.filter((article) => {
    if (!article.isSubArticle) {
      return true;
    } else {
      const qty = articleQtyMap[article.id];
      //return true;
      return qty?.qty > 0;
    }
  });

  console.log("listItems", listItems);

  // init

  useEffect(() => {
    return () => {
      dispatch(setSelectedArticleId(null));
    };
  }, []);

  // handlers

  function handleSelectionChange(ids) {
    const id = ids ? ids[0] : null;
    dispatch(setSelectedArticleId(id));
    const article = id ? articlesMap[id] : null;
    caplaEditor?.editorPdf?.setSelectedArticle(article);
  }

  function handleSelectedArticlesGroupIdChange(id) {
    dispatch(setSelectedArticlesGroupId(id));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        minHeight: 0,
        justifyItems: "strech",
      }}
    >
      <Box
        sx={{
          width: 1,
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          minHeight: 0,
          justifyItems: "strech",
        }}
      >
        {articlesGroups.length > 0 ? (
          <ListArticlesMultipleWithGroups
            scene={scene}
            scope={scope}
            caplaEditor={caplaEditor}
            articles={listItems}
            selectedArticleIds={selectedArticleId ? [selectedArticleId] : []}
            onSelectionChange={handleSelectionChange}
            articlesGroups={articlesGroups}
            forceSelection={true}
            selectedArticlesGroupId={selectedArticlesGroupId}
            onSelectedArticlesGroupIdChange={
              handleSelectedArticlesGroupIdChange
            }
            multipleSelection={false}
            variant={groupsViewVariant}
            articleQtyMap={articleQtyMap}
            options={{canEditArticlesGroupName: true}}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="body2" color="text.secondary">
              {noGroupS}
            </Typography>
          </Box>
        )}
      </Box>
      <ButtonExportQtiesInExcel
        scene={scene}
        articles={listItems}
        articlesQtiesMapBySector={articlesQtiesMapBySector}
        sectors={sectors}
      />
    </Box>
  );
}
