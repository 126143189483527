import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {
  fetchReportsService,
  createViewService,
  fetchViewService,
  updateViewService,
  deleteViewService,
} from "./services";

export const createView = createAsyncThunk(
  "reports/createView",
  createViewService
);

export const fetchReports = createAsyncThunk(
  "reports/fetchReports",
  fetchReportsService
);

export const fetchView = createAsyncThunk(
  "reports/fetchView",
  fetchViewService
);

export const updateView = createAsyncThunk(
  "reports/updateView",
  updateViewService
);
export const deleteView = createAsyncThunk(
  "reports/deleteView",
  deleteViewService
);

const reportsSlice = createSlice({
  name: "reports",
  initialState: {items: [], itemsMap: {}, selectedViewId: null},
  reducers: {
    setSelectedViewId: (state, action) => {
      state.selectedViewId = action.payload;
    },
  },
  extraReducers: {
    [createView.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.items.push(item);
    },
    [fetchReports.fulfilled]: (state, action) => {
      const {items} = action.payload;
      state.items = items;
    },
    [fetchView.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.items = state.items.filter((i) => i.id !== item.id);
      state.items.push(item);
    },
    [updateView.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.items = state.items.filter((i) => i.id !== item.id);
      state.items.push(item);
    },
    [deleteView.fulfilled]: (state, action) => {
      const viewId = action.payload;
      state.items = state.items.filter((i) => i.id !== viewId);
    },
  },
});
export const {setSelectedViewId} = reportsSlice.actions;

export default reportsSlice.reducer;
