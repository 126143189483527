import {Box} from "@mui/material";
import {useSelector} from "react-redux";
import {useState} from "react";

import useSceneModule from "../useSceneModule";
import useVersionsByScene from "Features/versions/hooks/useVersionsByScene";

import TogglePdf3d from "./TogglePdf3d";
import ButtonOpenViewers from "./ButtonOpenViewers";
import usePageType from "../usePageType";

import SelectorScopeInTopBar from "Features/scopes/components/SelectorScopeInTopBar";
import SelectorVersionForMeasurements from "Features/versions/components/SelectorVersionForMeasurements";
import ButtonDialogSync from "Features/scenes/components/ButtonDialogSync";
import SectionScopeAndCounterInTopBar from "Features/overviewer/components/SectionScopeAndCounterInTopBar";
import useFilteredMeasurementsFromPackages from "Features/measurements/useFilteredMeasurementsFromPackages";
import ContainerMeasurementsCount from "Features/measurements/components/ContainerMeasurementsCount";
import ContainerContextOverview from "Features/overviewer/components/ContainerContextOverview";

import DialogModelsInScope from "Features/overviewer/components/DialogModelsInScope";

export default function TopBarOverviewInLeftPanel({
  scene,
  caplaEditor,
  viewer,
}) {
  // data

  const sceneModule = useSceneModule();

  const versions = useVersionsByScene(scene);

  const measCountPackageIdMap = useSelector(
    (s) => s.overviewer.measCountPackageIdMap
  );

  // local state

  const [open, setOpen] = useState(false);

  // helpers - meas count

  const measCount = Object.values(measCountPackageIdMap).reduce(
    (ac, cur) => ac + cur,
    0
  );

  // helpers - no versions

  const noVersions = !versions.length > 0;

  // helpers - hide

  // const hideScopeSelection = ["ONBOARDING"].includes(sceneModule);
  const hideScopeSelection = false;

  // helpers

  const showFilters = measCount > 0;

  // handlers

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <DialogModelsInScope open={open} onClose={handleClose} />
      <Box
        sx={{
          bgcolor: "common.caplaBlack",
          display: "flex",
          boxSizing: "border-box",
          height: "36px",
          minHeight: "36px",
          alignItems: "center",
          py: 0.5,
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          width: 1,
          minWidth: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: 1,
            minWidth: 0,
            width: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <ContainerMeasurementsCount measCount={measCount} /> */}
            <ContainerContextOverview
              count={measCount}
              shrink={true}
              bgcolor={"common.caplaBlack"}
              showFilters={showFilters}
              onCountClick={handleOpen}
            />
          </Box>

          <Box sx={{minWidth: 0, display: "flex", alignItems: "center"}}>
            {!hideScopeSelection && (
              <SelectorScopeInTopBar scene={scene} caplaEditor={caplaEditor} />
            )}
            {!noVersions && (
              <Box sx={{px: 0.5, minWidth: 0}}>
                <SelectorVersionForMeasurements
                  scene={scene}
                  caplaEditor={caplaEditor}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
