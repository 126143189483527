import useIsStagingEnv from "Features/overviewer/hooks/useIsStagingEnv";
import {useParams} from "react-router-dom";

export default function useSceneModuleBackPathMap() {
  const isStagingEnv = useIsStagingEnv();
  const {sceneId} = useParams();

  let urlMap = {
    SURVEY: `/bimboxes/${sceneId}/surveys`,
    VIEW: `/bimboxes/${sceneId}/views`,
    REPORT: `/bimboxes/${sceneId}/reports`,
  };

  return urlMap;
}
