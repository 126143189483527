import {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import useUpdateShortcutElementTypesFromLeftPanel from "../hooks/useUpdateShortcutElementTypesFromLeftPanel";

import {Box} from "@mui/material";

import useSceneModule from "Features/navigation/useSceneModule";

import SectionIssuesInLeftPanel from "Features/issues/components/SectionIssuesInLeftPanel";
import PanelFilters from "Features/filters/components/PanelFilters";
import PanelPackages from "Features/elementPackages/components/PanelPackages";

export default function SectionLeftPanelOverPanels({
  scene,
  caplaEditor,
  viewer,
}) {
  const openFilterPanel = useSelector((s) => s.filters.openFilterPanel);
  const openPackagesPanel = useSelector((s) => s.packages.openPackagesPanel);
  const openIssuesInLeftPanel = useSelector(
    (s) => s.issues.openIssuesInLeftPanel
  );
  const inOutMode = useSelector(
    (s) => s.measurements.filteredMeasurementsViewModeBckp
  );

  return (
    <>
      {openIssuesInLeftPanel && (
        <Box
          sx={{
            position: "absolute",
            top: 36,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 50,
          }}
        >
          <SectionIssuesInLeftPanel
            scene={scene}
            caplaEditor={caplaEditor}
            inOutMode={inOutMode}
            width={"100%"}
          />
        </Box>
      )}
      {openFilterPanel && (
        <Box
          sx={{
            position: "absolute",
            top: 36,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 50,
          }}
        >
          <PanelFilters scene={scene} caplaEditor={caplaEditor} />
        </Box>
      )}
      {openPackagesPanel && (
        <Box
          sx={{
            position: "absolute",
            top: 36,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 50,
          }}
        >
          <PanelPackages scene={scene} caplaEditor={caplaEditor} />
        </Box>
      )}
    </>
  );
}
