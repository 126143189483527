import {nanoid} from "@reduxjs/toolkit";

import {
  MathUtils,
  Object3D,
  MeshBasicMaterial,
  BoxGeometry,
  Mesh,
  MeshLambertMaterial,
  DoubleSide,
  FrontSide,
} from "three";

import MeasurementElement from "./MeasurementElement";

import {
  performTriangulation,
  prepareTriangulation,
  updatePolylineGeometry,
} from "../utils/drawVoids";

import theme from "Styles/theme";

const material = new MeshBasicMaterial({color: 0x00ff00});

class MeasurementsModel {
  type;
  model;
  entityID;
  object;
  editor3d;

  selectedElement; // selectedElement codeName

  constructor({model, editor3d}) {
    this.editor3d = editor3d;
    this.type = "MEASUREMENTS_MODEL";
    this.entityID = model.id;
    this.model = model;
    this.modelId = model.id;
    this.sceneClientId = model.sceneClientId;

    this.object = this.createObject();

    this.measurements = [];
    this.measurementsMap = {};
    this.materialsMap = {}; // {#1201ee:material}
    this.materialsMapDotted = {};
    this.elementTypesMaterials = editor3d.sceneEditor.elementTypesMaterials;
    this.initHostsMap = {};
  }

  static computeInitialModel({
    name,
    sceneClientId,
    fromModel,
    copyFrom,
    measurements,
    restrictedTypes,
    elementTypesGroupIds,
  }) {
    const id = MathUtils.generateUUID();
    const type = "MEASUREMENTS";
    const position = {x: 0, y: 0, z: 0};
    const rotation = {x: 0, y: 0, z: 0};
    const center = {x: 0, y: 2, z: 0};
    const width = 10;
    const depth = 10;
    const height = 2;
    const hidden = false;

    // we create new ids for the measurements.
    let measurementsData;
    if (copyFrom) {
      let ms = copyFrom.measurementsData?.measurements;
      if (!ms) ms = [];
      ms = ms.map((m) => ({...m, id: nanoid()}));
      if (!copyFrom.measurementsData) {
        measurementsData = {measurements: ms};
      } else {
        measurementsData = {...copyFrom.measurementsData, measurements: ms};
      }
    }
    if (measurements?.length > 0) {
      measurementsData = {measurements};
    }

    const initialModel = {
      id,
      enabled: true,
      type,
      sceneClientId,
      name,
      position,
      rotation,
      center,
      width,
      depth,
      height,
      hidden,
      fromModel,
    };

    if (measurements) {
      initialModel.measurementsData = measurementsData;
    } else {
      initialModel.measurementsData = {measurements: []};
    }

    if (restrictedTypes) initialModel.restrictedTypes = restrictedTypes;

    if (elementTypesGroupIds)
      initialModel.elementTypesGroupIds = elementTypesGroupIds;

    if (!copyFrom) {
      return initialModel;
    } else {
      return {
        ...copyFrom,
        id,
        name,
        measurementsData,
      };
    }
  }

  createObject() {
    const object = new Object3D();
    object.layers.enable(1);
    object.layers.enable(3);
    this.editor3d?.scene.add(object);
    return object;
  }

  // Box

  addBox(position) {
    let w, h, d;
    w = h = d = 1;

    const box = new Mesh(new BoxGeometry(w, h, d), material);
    box.position.set(position.x, position.y, position.z);
    box.layers.enable(1);
    this.object.add(box);
  }

  // Material

  getMaterialByColor(color, drawingShape) {
    let mat = this.materialsMap[color];
    if (!mat)
      mat = new MeshLambertMaterial({
        color,
      });
    mat.receiveShadow = true;
    if (this.editor3d.sceneEditor.globalTransparency) {
      mat.transparent = true;
      mat.opacity = this.editor3d?.opacity;
      mat.side = DoubleSide;
    } else {
      mat.transparent = false;
      mat.opacity = 1.0;
      mat.side = FrontSide;
    }
    if (["BRIDGE", "BOWL", "BANK"].includes(drawingShape))
      mat.side = DoubleSide;
    this.materialsMap[color] = mat;
    if (drawingShape !== "BEAM") return mat;
    const beamMaterial = new MeshLambertMaterial({
      color,
      transparent: true,
      opacity: mat.opacity * 0.5,
      depthTest: true,
      side: DoubleSide,
    });
    return [beamMaterial, mat];
  }

  // Voids

  triangulateMeasurementWithVoids(measurement) {
    const data4Triangulation = prepareTriangulation(this, measurement);
    if (
      Array.isArray(data4Triangulation?.voids) &&
      data4Triangulation.voids.length > 0
    ) {
      const {vertices, indices} = performTriangulation(data4Triangulation);
      updatePolylineGeometry(
        measurement,
        vertices,
        indices,
        data4Triangulation.voids.map((v) => v.vertices)
      );
      return "OK";
    }
    return "prepareTriangulation KO";
  }

  async triangulateWithCare(measurement, timeout = 2000) {
    try {
      const triangulationResult = await Promise.race([
        this.triangulateMeasurementWithVoids(measurement),
        new Promise((resolve, reject) =>
          setTimeout(() => reject(new Error("Timeout triangulate")), timeout)
        ),
      ]);
      if (triangulationResult) {
        console.log("Triangulation successful:", triangulationResult);
      } else {
        console.error("Triangulation timed out:", measurement);
      }
    } catch (error) {
      console.error("Error triangulating:", measurement, error);
    }
  }

  drawVoids() {
    for (const wall of this.measurements.filter(
      (m) => m.props3D.drawingShape === "POLYLINE" && Array.isArray(m.voids)
    )) {
      this.triangulateWithCare(wall, 2000);
    }
  }

  setHosts() {
    for (const [voidId, hosts] of Object.entries(this.initHostsMap)) {
      const voidMeasurement = this.getMeasurement(voidId);
      voidMeasurement?.setHosts([...new Set(hosts)]);
    }
  }

  // Measurement

  updateMeasurementsData(measurementsData) {
    this.model = {...this.model, measurementsData};
  }

  loadMeasurements() {
    // this.clearMeasurements();
    let drawVoids = false;
    this.initHostsMap = {};
    const measurements = this.model.measurementsData?.measurements;
    if (measurements) {
      measurements.forEach((measurement) => {
        this.addMeasurement(measurement);
        if (measurement.voids?.length > 0) drawVoids = true;
      });
    }
    // console.log("[DEBUG] loading measurements fails after addMeasurements");
    if (drawVoids) {
      this.setHosts();
      // console.log("[DEBUG] loading measurements fails after setHosts");
      this.drawVoids();
      // console.log("[DEBUG] loading measurements fails after drawVoids");
    }
  }

  addMeasurement(measurement) {
    try {
      let {
        id,
        color,
        dim1,
        dim3,
        elementTypeId,
        zInf,
        zSup,
        path3D,
        path3d3,
        path3d2,
        height,
        heightE,
        heightN,
        heights,
        side,
        faces,
        slopeH,
        slopingA,
        drawingShape,
        isNotHoriz, // to compute 3D with extrusion if it is not the case.
        builtAt,
        zoneId,
        phaseId,
        isRoom,
        isGhost,
        file3d,
      } = measurement;

      // check if it exists
      const element = this.getMeasurement(measurement.id);
      if (element) {
        this.updateMeasurement(measurement);
      } else {
        const props3D = {
          path: path3D,
          path3: path3d3,
          path2: path3d2,
          zInf,
          zSup,
          height: isRoom ? 0.005 : height,
          heightE,
          heightN,
          heights,
          side,
          faces,
          slopeH,
          slopingA,
          drawingShape,
          dim1,
          dim3,
          isNotHoriz,
          file3d,
        };
        if (!color) color = theme.palette.primary.flash;
        const hasZSup = typeof zSup === "number";
        const hasZInf = typeof zInf === "number";

        const material = this.getMaterialByColor(color, drawingShape);

        if ((hasZSup || hasZInf) && path3D.length > 0) {
          const measurementElement = new MeasurementElement({
            // measurementsModel: this,
            modelId: this.modelId,
            editor3d: this.editor3d,
            measurementId: id,
            props3D,
            material,
            elementTypeId,
            builtAt,
            zoneId,
            phaseId,
            isGhost,
            voids: measurement.voids,
            deleteObject: (obj) => this.object.remove(obj),
          });
          this.object.add(measurementElement.object);
          this.measurements.push(measurementElement);
          this.editor3d?.objectEntities.push(measurementElement);

          this.measurementsMap[measurementElement.entityID] =
            measurementElement;
        }
      }
      if (Array.isArray(measurement.voids) && measurement.voids.length > 0) {
        for (const voidId of measurement.voids) {
          if (this.initHostsMap[voidId])
            this.initHostsMap[voidId].push(measurement.id);
          else this.initHostsMap[voidId] = [measurement.id];
        }
      }
    } catch (e) {
      console.log("error creating element");
    }
  }

  // Enable - Disable

  disable() {
    if (this.editor3d) {
      this.editor3d.scene.remove(this.object);
      this.measurements.forEach((measurement) => {
        this.editor3d.objectEntities = this.editor3d.objectEntities.filter(
          (e) => e.entityID !== measurement.entityID
        );
      });
    }
  }

  enable() {
    this.editor3d?.scene.add(this.object);
    this.measurements.forEach((measurement) => {
      this.editor3d?.objectEntities.push(measurement);
    });
  }

  // Hide & Show

  hide() {
    //this.editor3d?.scene.remove(this.object);
    this.object.layers.disable(1);
    this.object.layers.disable(3);
    this.measurements.forEach((e) => e.hide());
    this.hidden = true;
  }

  show() {
    //this.editor3d?.scene.add(this.object);
    this.object.layers.enable(1);
    this.object.layers.enable(3);
    this.measurements.forEach((e) => e.show());
    this.hidden = false;
  }

  // clearMeasurements() {
  //   for (const measurement of this.measurements) {
  //     measurement.delete();
  //   }
  //   this.measurements = [];
  // }

  // delete measurement

  deleteMeasurement(measurementId) {
    const element = this.getMeasurement(measurementId);
    if (element) element.delete();
    this.measurements = this.measurements.filter(
      (m) => m.entityID !== measurementId
    );
  }

  // refresh 3D geometry from model
  // used to refresh the 3D models.

  refreshMeasurement3D(measurement) {
    const element = this.getMeasurement(measurement?.id);
    let {
      zInf,
      zSup,
      path3D,
      path3d3,
      height,
      heightE,
      heightN,
      heights,
      side,
      faces,
      zoneId,
      dim1,
      dim3,
      file3d,
    } = measurement;
    const props3D = {
      path: path3D,
      path3: path3d3,
      zInf,
      zSup,
      height,
      heightE,
      heightN,
      heights,
      side,
      faces,
      dim1,
      dim3,
      file3d,
    };
    this.updateMeasurementProps3D(element, props3D);
    this.updateMeasurementZoneId(element, zoneId);
    this.updateMeasurementVoids(element, measurement.voids);
    // console.log("haaaaaaaaaaa", element)
    this.updateMeasurementHosts(element);
    // this.drawVoids();
  }

  // update measurement

  updateMeasurement(measurement) {
    console.log(
      "[MeasurementsModel] updateMeasurement",
      measurement,
      measurement?.zInf
    );
    const element = this.getMeasurement(measurement.id);
    if (element) {
      // color
      if (!element.selected)
        this.updateMeasurementColor(element, measurement.color);
      // element type
      this.updateMeasurementElementTypeId(element, measurement.elementTypeId);
      // props 3D
      let {
        zInf,
        zSup,
        path3D,
        path3d3,
        path3d2,
        height,
        heightE,
        heightN,
        heights,
        side,
        faces,
        slopeH,
        slopingA,
        drawingShape,
        isRoom,
        dim1,
        dim3,
        isNotHoriz,
        file3d,
      } = measurement;
      const props3D = {
        path: path3D,
        path3: path3d3,
        path2: path3d2,
        zInf,
        zSup,
        height: isRoom ? 0.005 : height,
        heightE,
        heightN,
        heights,
        side,
        faces,
        slopeH,
        slopingA,
        drawingShape,
        dim1,
        dim3,
        isNotHoriz,
        file3d,
      };
      //if (path3D.length > 0) this.updateMeasurementProps3D(element, props3D);
      if (path3d3.length > 0) this.updateMeasurementProps3D(element, props3D);
      // builtAt
      this.updateMeasurementBuiltAt(element, measurement.builtAt);
      // zoneId
      this.updateMeasurementZoneId(element, measurement.zoneId);
      // phaseId
      this.updateMeasurementPhaseId(element, measurement.phaseId);
      // isGhost
      this.updateMeasurementIsGhost(element, measurement.isGhost);
      // Voids
      this.updateMeasurementVoids(element, measurement.voids);
      // // hosts
      // console.log("haaaaaaaaa", element)
      this.updateMeasurementHosts(element);
      // this.drawVoids();
    }
  }

  updateMeasurementColor(measurement, color) {
    let material = this.getMaterialByColor(
      color,
      measurement?.props3D?.drawingShape
    );
    measurement.setMaterial(material);
  }

  updateMeasurementBuiltAt(measurement, builtAt) {
    measurement.setBuiltAt(builtAt);
  }

  updateMeasurementZoneId(measurement, zoneId) {
    measurement.setZoneId(zoneId);
  }

  updateMeasurementPhaseId(measurement, phaseId) {
    measurement.setPhaseId(phaseId);
  }

  updateMeasurementIsGhost(measurement, isGhost) {
    measurement.setIsGhost(isGhost);
  }

  updateMeasurementElementTypeId(measurement, elementTypeId) {
    measurement.setElementTypeId(elementTypeId);
  }

  updateMeasurementProps3D(measurement, props3D) {
    this.object.remove(measurement.object);
    const newObject = measurement.setProps3D(props3D);
    this.object.add(newObject);
  }

  updateMeasurementVoids(measurement, voids) {
    if (Array.isArray(measurement.voids) && measurement.voids.length > 0) {
      for (const voidId of measurement.voids) {
        const voidMeasurement = this.getMeasurement(voidId);
        voidMeasurement?.removeHost(measurement.id);
      }
    }
    measurement.setVoids([...new Set(voids)]);
    if (Array.isArray(voids) && voids.length > 0) {
      this.triangulateMeasurementWithVoids(measurement);
      for (const voidId of voids) {
        const voidMeasurement = this.getMeasurement(voidId);
        voidMeasurement?.addHost(measurement.id);
      }
    }
  }

  updateMeasurementHosts(measurement) {
    if (Array.isArray(measurement.hosts) && measurement.hosts.length > 0) {
      for (const hostId of measurement.hosts) {
        const hostMeasurement = this.getMeasurement(hostId);
        if (hostMeasurement)
          this.triangulateMeasurementWithVoids(hostMeasurement);
      }
    }
  }

  // element types

  applyElementTypeMaterial(elementType) {
    this.measurements.forEach((m) => {
      if (m.elementTypeId === elementType?.id) {
        const material = this.elementTypesMaterials[m.elementTypeId];
        if (material) m.applyMaterial(material);
      }
    });
  }

  applyMeasurementsMaterials(elementType) {
    this.measurements.forEach((m) => {
      if (m.elementTypeId === elementType?.id) m.applyMaterial(m.material);
    });
  }

  // find

  getMeasurement(measurementId) {
    // const measurement = this.measurements.find(
    //   (m) => m.entityID === measurementId
    // );
    const measurement = this.measurementsMap[measurementId];
    return measurement;
  }
  // pick & select

  pickElement(measurementId) {
    const m = this.getMeasurement(measurementId);
    m.pick();
  }

  unpickElement(measurementId) {
    const m = this.getMeasurement(measurementId);
    m.unpick();
  }

  // select & unselect
  selectElement(measurementId) {
    const m = this.getMeasurement(measurementId);
    m?.select();
  }

  unselectElement(measurementId) {
    const m = this.getMeasurement(measurementId);
    m?.unselect();
  }

  // Selection

  // CAPLA model plays the role of the editor when the user click on CSSObjects.

  dispatchSelection = (parsedElement) => {
    this.editor3d?.onSelectionChange(parsedElement);
  };

  dispatchEmptySelection = () => {
    this.editor3d?.onSelectionChange(null);
  };

  onElementClick = (element) => {
    console.log("EL12", element);
    if (element.codeName === this.selectedElement) {
      //element.unselect(); the selection is managed eventually by editor.multipleSelect
      this.selectedElement = null;
      this.dispatchEmptySelection();
    } else {
      this.selectedElement = element.codeName;
      this.dispatchSelection(element);

      //element.select();
    }
  };

  multipleSelect(codeNames) {
    this.elements.forEach((element) => {
      if (codeNames.includes(element.codeName)) {
        element.select();
      } else {
        element.unselect();
      }
    });
  }

  // filters

  applyFiltersByMeasurementIds(measurementIds, mode, applyFilters) {
    const idsToShow = new Set(measurementIds);

    this.measurements.forEach((m) => {
      const show = !applyFilters || idsToShow.has(m.entityID);
      if (show) {
        if (!mode || mode === "IN") m.show();
        if (mode === "IN_AND_OUT") m.showFromTransparent();
      } else {
        if (!mode || mode === "IN") m.hide();
        if (mode === "IN_AND_OUT") m.hideAsTransparent();
      }
    });
  }

  // parse

  parse() {
    return {
      type: "MEASUREMENTS_MODEL",
      modelId: this.modelId,
      name: this.model.name,
    };
  }

  // business process

  updateLayoutFromProgress({measurementsProgress, mode}) {
    const doneIds = measurementsProgress
      .filter(({progress}) => progress > 0.5)
      .map((mp) => mp.measurementId);
    this.measurements.forEach((measurement) => {
      const done = doneIds.includes(measurement.entityID);
      if ((done && mode === "DONE") || (!done && mode === "TODO")) {
        measurement.setOpacityFromProgress(1);
      } else {
        measurement.setOpacityFromProgress(0);
      }
    });
  }

  // edges

  showEdges() {
    this.measurements.forEach((measurement) => {
      // if (!measurement.edges) measurement.addEdges(measurement.object);
      measurement.showEdges(measurement.object);
      // if (measurement.hidden) measurement.hide();
    });
  }

  hideEdges() {
    this.measurements.forEach((measurement) => {
      // if (measurement.edges) measurement.removeEdges();
      measurement.hideEdges();
    });
  }

  // voids

  showVoids() {
    this.measurements.forEach((measurement) => {
      measurement.showVoids();
    });
  }

  hideVoids() {
    this.measurements.forEach((measurement) => {
      measurement.hideVoids();
    });
  }

  substractVoids() {
    this.measurements.forEach((measurement) => {
      measurement.substractVoids();
    });
  }

  fillVoids() {
    this.measurements.forEach((measurement) => {
      measurement.fillVoids();
    });
  }

  // transparency

  setTransparent() {
    this.measurements.forEach((measurement) => {
      measurement.transparent();
    });
  }

  setOpaque() {
    this.measurements.forEach((measurement) => {
      measurement.opaque();
    });
  }
}

export default MeasurementsModel;
