import {useDispatch} from "react-redux";
import {useEffect} from "react";

import {setModeForOverview, setShowedModules} from "./navigationSlice";

import showedModulesByModeMap from "./data/showedModulesByModeMap";

export default function useAutoUpdateShowedModules(scene) {
  const dispatch = useDispatch();

  const bimboxMode = scene?.bimboxMode;

  console.log("bimboxMode", bimboxMode);

  useEffect(() => {
    if (bimboxMode) {
      const showedModules = showedModulesByModeMap[bimboxMode];
      dispatch(setShowedModules(showedModules));
    }
  }, [bimboxMode]);
}
