import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {fetchArticlesGroups} from "Features/articles/articlesSlice";

import useAccessToken from "Features/auth/useAccessToken";
import useSelectedScope from "Features/scopes/hooks/useSelectedScope";

export default function useInitFetchArticlesGroups() {
  const {sceneId} = useParams();
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  const scope = useSelectedScope();
  const groupsIds = scope?.data?.articlesGroupsIds;

  useEffect(() => {
    if (accessToken && sceneId && groupsIds?.length > 0) {
      dispatch(
        fetchArticlesGroups({
          sceneId,
          accessToken,
          groupsIds,
          withArticles: true,
        })
      );
    }
  }, [sceneId, accessToken, scope?.id]);
}
