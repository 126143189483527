import {useDispatch} from "react-redux";

import {updateMaterialsGroup} from "../materialsSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function useUpdateMaterialsGroup() {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const createGroup = async (materialsGroup) => {
    await dispatch(
      updateMaterialsGroup({
        accessToken,
        materialsGroup,
      })
    );
  };
  return createGroup;
}
