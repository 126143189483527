import {useSelector} from "react-redux";

export default function useSamplesByScene(scene, options) {
  const samplesMap = useSelector((s) => s.samples.itemsMap);

  let samples = Object.values(samplesMap);
  samples = samples.filter((v) => v.sceneId === scene?.id);

  if (options?.filterByScope) {
    samples = samples.filter((v) => v.scopeId === options.filterByScope);
  }

  if (options?.sortByName) {
    samples = samples.sort((a, b) => a.name.localeCompare(b.name));
  }

  return samples;
}
