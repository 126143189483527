import {useSelector, useDispatch} from "react-redux";

import useSceneModule from "Features/navigation/useSceneModule";

import {Box, Paper, ToggleButton, Tooltip, lighten} from "@mui/material";
import {
  ArrowBackIos as Show,
  ArrowForwardIos as Hide,
} from "@mui/icons-material";

import {setOpenSelectionPanel} from "../selectionSlice";

import usePageType from "Features/navigation/usePageType";

export default function ButtonToggleSelection() {
  const dispatch = useDispatch();

  // strings

  const viewDataGridS = "List view";
  const openPanelS = "Ouvrir le panneau de sélection";
  const closePanelS = "Fermer le panneau de sélection";

  // data

  const open = useSelector((s) => s.selection.openSelectionPanel);
  const pageType = usePageType();
  const sceneModule = useSceneModule();

  // helpers - colors

  // const lightPink = (theme) => lighten(theme.palette.primary.flash, 0.4);
  const background = (theme) =>
    `linear-gradient(to right,${theme.palette.secondary.main},${theme.palette.primary.main})`;

  // hideButton

  let hideButton = true;
  if (
    (pageType === "SCENE" && !["MODULES"].includes(sceneModule)) ||
    pageType === "COLORING" ||
    pageType === "HOME"
  )
    hideButton = false;

  // handlers

  function handleChange() {
    dispatch(setOpenSelectionPanel(!open));
  }
  return (
    <Paper
      elevation={12}
      sx={{
        display: hideButton ? "none" : "flex",
        background,
        p: 0.5,
        alignItems: "center",
        color: "divider",
        mr: -0.5,
      }}
    >
      <ToggleButton
        selected={open}
        onChange={handleChange}
        value="SELECTION"
        sx={{
          width: 30,
          height: 30,
        }}
      >
        {/* <Tooltip
          title={open ? closePanelS : openPanelS}
          placement={!open ? "bottom-start" : "bottom-end"}
        > */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "common.white",
          }}
        >
          {open ? (
            <Hide fontSize="small" color="inherit" />
          ) : (
            <Show fontSize="small" color="inherit" />
          )}
        </Box>
        {/* </Tooltip> */}
      </ToggleButton>
    </Paper>
  );
}
