//
// add ZONES and PDF related to the PACKAGE ids

import getItemsMapById from "Utils/getItemsMapById";

//
export default function getFixedScope(scope, models) {
  if (scope?.id === "all" || !scope)
    return {
      ...scope,
      data: {
        ...scope?.data,
        packageIds: models
          .filter((m) => m.type === "MEASUREMENTS")
          .map((m) => m.id),
        inputIds: models.filter((m) => m.type === "PDF").map((m) => m.id),
        zoneIds: models.filter((m) => m.type === "IMAGE").map((m) => m.id),
        markerIds: models.filter((m) => m.type === "MARKERS").map((m) => m.id),
      },
    };
  //
  let packageIds = scope?.data?.packageIds;
  let inputIds = scope?.data?.inputIds;
  let zoneIds = scope?.data?.zoneIds;

  if (!packageIds) packageIds = [];
  if (!inputIds) inputIds = [];
  if (!zoneIds) zoneIds = [];

  packageIds = packageIds.filter((id) => id); // to remove null values
  inputIds = inputIds.filter((id) => id);
  zoneIds = zoneIds.filter((id) => id);

  const modelMap = getItemsMapById(models);

  const fixedPdfModelIds = [...inputIds];
  let fixedZoneIds = [...zoneIds];

  // helpers - inputsIds

  packageIds.forEach((packageId) => {
    const model = modelMap[packageId];
    const pdfId = model?.fromModel?.modelId;
    if (pdfId && !fixedPdfModelIds.includes(pdfId)) {
      fixedPdfModelIds.push(pdfId);
    }
  });

  // helpers - zoneIds

  fixedPdfModelIds.forEach((inputId) => {
    const pdfModel = modelMap[inputId];
    let zones = pdfModel?.zones;
    if (zones?.length > 0) {
      zones.forEach((zone) => {
        if (zone.imageModelId && !zoneIds.includes(zone.imageModelId))
          fixedZoneIds.push(zone.imageModelId);
      });
    }
  });

  // helpers - remove zoneIds from PDF models out of scope

  fixedZoneIds = fixedZoneIds.filter((zoneId) => {
    const zoneModel = modelMap[zoneId];
    const pdfModelId = zoneModel?.fromModel?.modelId;
    return fixedPdfModelIds.includes(pdfModelId);
  });

  // helper - fixedData

  const fixedData = {...scope?.data};
  if (packageIds.length > 0) fixedData.packageIds = packageIds;
  if (fixedPdfModelIds.length > 0) fixedData.inputIds = fixedPdfModelIds;
  if (fixedZoneIds.length > 0) fixedData.zoneIds = fixedZoneIds;

  return {
    ...scope,
    data: fixedData,
  };
}
