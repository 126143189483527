import {Box, Paper, Typography} from "@mui/material";

export default function HeaderSharedSceneInLeftPanel({scene}) {
  return (
    <Box sx={{p: 1}}>
      <Paper
        elevation={12}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: (theme) => theme.spacing(5),
          px: 1,
        }}
      >
        <Typography variant="body2">{scene?.title}</Typography>
      </Paper>
    </Box>
  );
}
