// import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import usePageType from "Features/navigation/usePageType";
import useSceneModule from "Features/navigation/useSceneModule";

export default function useSectionDims() {
  // data

  const {shareLink} = useParams();
  // const context = useOutletContext();
  const viewerWidth = useSelector((s) => s.viewer3D.viewerWidth);

  let fixedViewersBoxWidth = useSelector(
    (s) => s.viewer3D.fixedViewersBoxWidth
  );

  const {outlet, viewer3D, fixedViewersBox, callToAction} = useSelector(
    (s) => s.ui.openSections
  );

  const pageType = usePageType();
  const sceneModule = useSceneModule();
  // const isViewerVisible = useSelector((s) => s.viewer3D.isViewerVisible);

  // const isViewerFullScreen = useSelector((s) => s.viewer3D.isViewerFullScreen);

  // const fullscreen = useSelector((s) => s.ui.fullscreen);

  // const allModels = useSelector((s) => s.viewer3D.models);
  // const selectedModelId = useSelector((s) => s.viewer3D.selectedModelId);
  // const showDataPreview = useSelector((s) => s.viewer3D.showDataPreview);

  // helpers - data

  // const selectedModel = allModels.find((m) => m.id === selectedModelId);

  // helpers

  let viewer3DWidth = viewerWidth;
  let callToActionWidth = 0;
  // let showFixedViewersBox = false;
  let showOutlet = true;

  // const isScene = pageType === "SCENE";

  // let previewIsFixedViewers = selectedModel?.type === "PDF";

  // if (false && sceneModule === "ELEMENT_TYPES") {
  //   //showOutlet = true;
  //   //fixedViewersBoxWidth = 0;
  //   //viewer3DWidth = 0;
  //   //callToActionWidth = 0;
  // } else if (fullscreen && viewer3D && fixedViewersBox) {
  //   showOutlet = false;
  //   fixedViewersBoxWidth = 1 - viewer3DWidth;
  //   viewer3DWidth = viewer3DWidth;
  //   callToActionWidth = 0;
  // } else if (!outlet && viewer3D && fixedViewersBox && !callToAction) {
  //   showOutlet = false;
  //   fixedViewersBoxWidth = 1 - viewer3DWidth;
  //   viewer3DWidth = viewer3DWidth;
  //   callToActionWidth = 0;
  // } else if (fullscreen && viewer3D) {
  //   showOutlet = false;
  //   fixedViewersBoxWidth = 0;
  //   viewer3DWidth = 1;
  //   callToActionWidth = 0;
  // } else if (fullscreen && fixedViewersBox && !callToAction) {
  //   // fullscreen only if callToAction is not displayed (image viewer)
  //   showOutlet = false;
  //   fixedViewersBoxWidth = 1;
  //   viewer3DWidth = 0;
  //   callToActionWidth = 0;
  // } else if (isViewerFullScreen && viewer3D && !callToAction) {
  //   showOutlet = false;
  //   fixedViewersBoxWidth = 0;
  //   viewer3DWidth = 1;
  //   callToActionWidth = 0;
  // } else if (outlet && !callToAction && !viewer3D && !fixedViewersBox) {
  //   showOutlet = true;
  //   fixedViewersBoxWidth = 0;
  //   viewer3DWidth = 0;
  //   callToActionWidth = 0;
  // } else if (outlet && callToAction && !viewer3D && !fixedViewersBox) {
  //   showOutlet = true;
  //   fixedViewersBoxWidth = 0;
  //   viewer3DWidth = 0;
  //   callToActionWidth = viewerWidth;
  // } else if (outlet && !callToAction && viewer3D && !fixedViewersBox) {
  //   showOutlet = true;
  //   fixedViewersBoxWidth = 0;
  //   //viewer3DWidth = viewerWidth;
  //   viewer3DWidth = 0;
  //   callToActionWidth = 0;
  // } else if (outlet && !callToAction && !viewer3D && fixedViewersBox) {
  //   showOutlet = true;
  //   //fixedViewersBoxWidth = fixedViewersBoxWidth;
  //   fixedViewersBoxWidth = 0;
  //   viewer3DWidth = 0;
  //   callToActionWidth = 0;
  // } else if (!outlet && !callToAction && !viewer3D && fixedViewersBox) {
  //   showOutlet = false;
  //   fixedViewersBoxWidth = 1;
  //   viewer3DWidth = 0;
  //   callToActionWidth = 0;
  // } else if (!outlet && !callToAction && viewer3D && !fixedViewersBox) {
  //   showOutlet = false;
  //   fixedViewersBoxWidth = 0;
  //   viewer3DWidth = 1;
  //   callToActionWidth = 0;
  //   // CALL TO ACTION + GRAPHICS
  // } else if (!outlet && callToAction && !viewer3D && !fixedViewersBox) {
  //   showOutlet = false;
  //   fixedViewersBoxWidth = 0;
  //   viewer3DWidth = 0;
  //   callToActionWidth = 1;
  // } else if (!outlet && callToAction && viewer3D && !fixedViewersBox) {
  //   showOutlet = false;
  //   fixedViewersBoxWidth = 0;
  //   viewer3DWidth = viewerWidth;
  //   callToActionWidth = 1 - viewerWidth;
  // } else if (!outlet && callToAction && !viewer3D && fixedViewersBox) {
  //   showOutlet = false;
  //   fixedViewersBoxWidth = fixedViewersBoxWidth;
  //   viewer3DWidth = 0;
  //   callToActionWidth = 1 - fixedViewersBoxWidth;
  // } else if (pageType === "HOME" || pageType === "NEW_SCENE") {
  //   fixedViewersBoxWidth = 0;
  //   //viewer3DWidth = isViewerVisible ? viewerWidth : 0;
  //   viewer3DWidth = 0;
  //   callToActionWidth = 0;
  //   showFixedViewersBox = false;
  //   showOutlet = true;
  // } else if (
  //   isScene &&
  //   sceneModule !== "VIEWER_PDF" &&
  //   sceneModule !== "VIEWER_3D"
  // ) {
  //   if (isViewerVisible) {
  //     fixedViewersBoxWidth = 0;
  //     viewer3DWidth = viewerWidth;
  //     callToActionWidth = 0;
  //     showOutlet = true;
  //   } else if (showDataPreview) {
  //     if (!selectedModel) {
  //       fixedViewersBoxWidth = 0;
  //       viewer3DWidth = 0;
  //       callToActionWidth = viewerWidth;
  //       showOutlet = true;
  //     } else if (previewIsFixedViewers) {
  //       fixedViewersBoxWidth = fixedViewersBoxWidth;
  //       viewer3DWidth = 0;
  //       showOutlet = true;
  //     } else {
  //       fixedViewersBoxWidth = 0;
  //       viewer3DWidth = viewerWidth;
  //       showOutlet = true;
  //     }
  //   } else {
  //     fixedViewersBoxWidth = 0;
  //     viewer3DWidth = 0;
  //     showOutlet = true;
  //   }
  // } else if (isScene && sceneModule === "VIEWER_PDF") {
  //   viewer3DWidth = isViewerVisible ? viewerWidth : 0;
  //   fixedViewersBoxWidth = `${(1 - viewerWidth) * 100}%`;
  //   showFixedViewersBox = true;
  //   showOutlet = false;
  // }

  // if (sceneModule === "ADMIN") {
  //   showOutlet = true;
  //   fixedViewersBoxWidth = 0;
  //   viewer3DWidth = 0;
  //   callToActionWidth = 0;
  // } else if (sceneModule === "LIBRARY") {
  //   showOutlet = true;
  //   fixedViewersBoxWidth = 0;
  //   viewer3DWidth = 0;
  //   callToActionWidth = 0;
  // }

  if (
    ["LIBRARY", "PRICINGS", "RELATIONS", "MODULES"].includes(sceneModule) ||
    // (sceneModule === "HOME" && !shareLink) ||
    pageType === "AUTH"
  ) {
    showOutlet = true;
    fixedViewersBoxWidth = 0;
    viewer3DWidth = 0;
    callToActionWidth = 0;
  } else if (outlet || (!fixedViewersBox && !viewer3D && !callToAction)) {
    showOutlet = true;
    fixedViewersBoxWidth = 0;
    viewer3DWidth = 0;
    callToActionWidth = 0;
  } else if (!outlet && viewer3D && fixedViewersBox && !callToAction) {
    showOutlet = false;
    fixedViewersBoxWidth = 1 - viewer3DWidth;
    viewer3DWidth = viewer3DWidth;
    callToActionWidth = 0;
  } else if (!outlet && (viewer3D || fixedViewersBox) && !callToAction) {
    showOutlet = false;
    fixedViewersBoxWidth = fixedViewersBox ? 1 : 0;
    viewer3DWidth = viewer3D ? 1 : 0;
    callToActionWidth = 0;
  }

  const dims = {
    showOutlet,
    fixedViewersBoxWidth,
    viewer3DWidth,
    callToActionWidth,
  };

  return dims;
}
