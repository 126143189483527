import {Box} from "@mui/material";

import {useSelector} from "react-redux";

import ListItemZoneInPdf from "./ListItemZoneInPdf";

import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import getMeasCountByZoneIdMap from "Features/zones/utils/getMeasCountByZoneIdMap";

export default function ShortcutZonesInPdf({editorPdf, scene, viewer}) {
  // data

  const zones = useZonesByScene(scene, {
    filterByPdf: true,
    sortByPage: true,
  });

  const measurements = useSelector((s) => s.pdf.measurementsShowedInViewerPdf);

  // helpers - countMap

  const measCountByZoneIdMap = getMeasCountByZoneIdMap(measurements);

  return (
    <Box sx={{"&>*:not(:last-child)": {mb: 1}}}>
      {zones.map((zone) => (
        <ListItemZoneInPdf
          key={zone.id}
          zone={zone}
          editorPdf={editorPdf}
          count={measCountByZoneIdMap[zone.id]}
        />
      ))}
    </Box>
  );
}
