export default function sceneFromStateToRemote(sceneState) {
  const scene = {...sceneState};
  //
  if (scene.organisationId) {
    scene.organisation = scene.organisationId;
    delete scene.organisationId;
  }
  //
  return scene;
}
