import {useSelector} from "react-redux";

import {Box, Paper, Typography} from "@mui/material";

import useSelectedArticle from "../hooks/useSelectedArticle";

import BlockEditableArticleName from "./BlockEditableArticleName";
import BlockEditableArticleUnit from "./BlockEditableArticleUnit";
import ButtonToggleLargePanelInHeader from "Features/selection/components/ButtonToggleLargePanelInHeader";

import getQtyWithDec from "../utils/getQtyWithDec";

export default function HeaderInSelectionPanelArticle() {
  // data

  const article = useSelectedArticle();
  const articleQtyMap = useSelector((s) => s.articles.articleQtyMap);

  // helper

  const qtyObject = articleQtyMap[article?.id];
  const qtyLabel = getQtyWithDec(qtyObject?.qty, article?.dec);

  return (
    <Box sx={{p: 1, width: 1, display: "flex"}}>
      <Paper
        sx={{
          p: 1,
          heigth: "40px",
          maxHeight: "40px",
          width: 1,
          display: "flex",
          alignItems: "center",
          //justifyContent: "space-between",
        }}
        elevation={6}
      >
        <Box>
          <ButtonToggleLargePanelInHeader />
        </Box>
        <Box sx={{minWidth: 0, flexGrow: 1}}>
          <BlockEditableArticleName article={article} />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            pr: 2,
            pt: 0.5,
          }}
        >
          <Box sx={{display: "flex", alignItems: "baseline"}}>
            <Typography sx={{mr: 1}}>{qtyLabel ?? "-"}</Typography>
            <BlockEditableArticleUnit article={article} />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
