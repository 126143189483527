import {useSelector} from "react-redux";

import {Button} from "@mui/material";
import {Download} from "@mui/icons-material";

import useMeasurementsScopeMode from "../useMeasurementsScopeMode";
import useFilteredSceneMeasurements from "../useFilteredSceneMeasurements";
import useSelectedPdfModel from "Features/pdf/useSelectedPdfModel";
// import useSelectedMeasurementsModel from "../useSelectedMeasurementsModel";

import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import usePhasesByScene from "Features/phases/hooks/usePhasesByScene";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";
import getPackagesForDatagrid from "Features/elementPackages/utils/getPackagesForDatagrid";
import useElementTypesGroupsProxyBySceneWithElementTypes from "Features/elementTypes/hooks/useElementTypesGroupsProxyBySceneWithElementTypes";
import usePackagesByScene from "Features/elementPackages/usePackagesByScene";

export default function ButtonDownloadBimPdf({
  scene,
  editorPdf,
  viewer,
  buttonVariant = "text",
}) {
  // strings

  const downloadS = ".bim.pdf";

  // data

  // const model = useSelectedMeasurementsModel();
  const selectedPdfModel = useSelectedPdfModel(viewer);
  const mode = useMeasurementsScopeMode(scene, viewer);
  const [measurements] = useFilteredSceneMeasurements(
    scene,
    mode,
    false,
    true,
    false
  );
  const phases = usePhasesByScene(scene);
  const sectors = useSectorsByScene(scene);
  const rooms = useRoomsByScene(scene);
  const zones = useZonesByScene(scene).filter(
    (z) => z.pdfModelId === selectedPdfModel?.id
  );
  const elementTypes = useElementTypesBySceneProxy(scene);
  const ressources = useRessourcesByScene(scene);
  const elementTypesGroups = useElementTypesGroupsProxyBySceneWithElementTypes(
    scene,
    {filterByScope: true}
  );

  // data - packages

  // const models = useSelector((s) => s.viewer3D.models);
  // const packages = models.filter(
  //   (m) =>
  //     m.type === "MEASUREMENTS" && m.fromModel?.modelId === selectedPdfModel?.id
  // );
  // const packagesForBimData = getPackagesForDatagrid(packages);

  const packages = usePackagesByScene(scene, {
    filterByScope: true,
    filterByPdf: true,
  });

  // handlers

  async function handleClick() {
    const mnger = editorPdf?.annotationsManager.measurementsPdfManager;

    const fileName = `${selectedPdfModel.name}.bim`;
    await editorPdf?.saver.saveBimData({
      phases,
      sectors,
      rooms,
      zones,
      //elementTypes,
      elementTypesGroups,
      //ressources,
      packages,
      //measurements,
      pdfScale: selectedPdfModel.pdfScale,
    });

    const hasBimData = editorPdf?.annotationsManager.testHasBimData();

    if (hasBimData) {
      mnger?.unlockMeasurementAnnotations();
      const xfdf = await editorPdf?.saver.getMetaAnnotationsXfdf();
      // const xfdf2 =
      //   await editorPdf?.saver.getMetaAndMeasurementsAnnotationsXfdf();

      editorPdf?.download(fileName, xfdf);
    }
  }

  return (
    <Button
      startIcon={<Download />}
      size="small"
      onClick={handleClick}
      color="inherit"
      variant={buttonVariant}
    >
      {downloadS}
    </Button>
  );
}
