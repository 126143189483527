import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {toggleShortcutElementType} from "Features/measurements/measurementsSlice";

import {Box, List} from "@mui/material";
import getItemsMapById from "Utils/getItemsMapById";

import ListItemArticleType from "./ListItemArticleType";
import ListItemArticleTypeGlobalScope from "./ListItemArticleTypeGlobalScope";
import ListItemArticleTypeGroupScope from "./ListItemArticleTypeGroupScope";

import getFilteredNomenclatureWithItems from "Utils/getFilteredNomenclatureWithItems";
import useElementTypesGroupsProxyByScene from "Features/elementTypes/hooks/useElementTypesGroupsProxyByScene";
import getItemsWithParentsAndGroups from "Utils/getItemsWithParentsAndGroups";
import getArticleTypesCount from "../utils/getArticleTypesCount";

import useSelectedArticlesGroup from "../hooks/useSelectedArticlesGroup";
import getArticleTypesListItemsProps from "../utils/getArticleTypesListItemsProps";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";

export default function SectionArticleTypesFormulasInViewer({scene, article}) {
  const dispatch = useDispatch();

  // data

  const articlesGroup = useSelectedArticlesGroup();
  const elementTypesGroups = useElementTypesGroupsProxyByScene(scene, {
    sortByName: true,
    filterByScope: true,
    filterByTypesGroupsIds: articlesGroup?.typesGroupsIds,
  });
  const elementTypes = useElementTypesBySceneProxy(scene);
  const shortcutElementTypes =
    useSelector((s) => s.measurements.shortcutElementTypes) ?? [];

  // data articleTypes

  let articleTypes = article?.types;
  if (!articleTypes) articleTypes = {};

  // helpers

  // helpers

  const elementTypesMap = getItemsMapById(elementTypes);

  // helpers - items

  const data = {elementTypesMap};
  const items = getArticleTypesListItemsProps(articleTypes, data);

  // helpers - input for sortedItems

  const groupsWithItems = elementTypesGroups.map((group) => {
    // data transformation to use the getItemsWithParentsAndGroups
    return {
      ...group,
      items: elementTypes.filter((type) => type.groupId === group.id),
    };
  });

  let sortedItems = getItemsWithParentsAndGroups(items, groupsWithItems);

  // handlers

  function handleIconClick(typeId) {
    console.log("handleIconClick", typeId);
    dispatch(toggleShortcutElementType(typeId));
  }

  return (
    <Box sx={{width: 1}}>
      {/* {scope === "global" && (
        <ListItemArticleTypeGlobalScope
          articleId={article.id}
          filtersStr={article?.types?.filtersStr}
          onFiltersStrChange={handleFiltersStrChange}
        />
      )}
      {scope === "typesGroup" && (
        <ListItemArticleTypeGroupScope
          articleId={article.id}
          groupProxy={selectedGroupProxy}
          filtersStr={article?.types?.filtersStr}
          onFiltersStrChange={handleFiltersStrChange}
        />
      )} */}

      <List sx={{width: 1}}>
        {sortedItems.map((item, index) => {
          // color
          const visible = shortcutElementTypes.includes(item.typeId);
          const color = visible ? item.color : "grey.400";
          // showInput
          let showInput = false;
          if (!item.isGroup && !item.isTitle) {
            showInput = true;
          } else if (
            item.id === "globalScope"
            //(item.filtersStr || sortedItems.length === 1)
          ) {
            showInput = true;
          } else if (index === sortedItems.length - 1) {
            showInput = true;
          } else if (
            // title only.
            item.isTitle &&
            sortedItems[index + 1]?.num &&
            !sortedItems[index + 1]?.num?.startsWith(item.num)
          ) {
            showInput = true;
          }

          return (
            <ListItemArticleType
              key={item.id + index}
              articleId={article?.id}
              typeId={item.typeId}
              color={color}
              drawingShape={item.drawingShape}
              groupId={item.groupId}
              func={item.func}
              filtersStr={item.filtersStr}
              name={item.name}
              isGroup={item.isGroup}
              isTitle={item.isTitle}
              style={item.style}
              num={item.num}
              showInput={showInput}
              viewer={true}
              onIconClick={handleIconClick}
            />
          );
        })}
      </List>
    </Box>
  );
}
