import transferElementTypeArticleQtiesToChildren from "./transferElementTypeArticleQtiesToChildren";

export default function getElementTypesGroupsWithTransferedQties(
  groupsWithSortedItems
) {
  return groupsWithSortedItems.map((group) => {
    return {
      ...group,
      elementTypes: transferElementTypeArticleQtiesToChildren(
        group.elementTypes
      ),
    };
  });
}
