import {
  MeshPhysicalMaterial,
  // MeshPhongMaterial,
  EdgesGeometry,
  LineSegments,
  Color,
  DoubleSide,
  // Mesh,
  // BufferGeometry,
  FrontSide,
  MeshLambertMaterial,
  BufferGeometry,
  BufferAttribute,
  Mesh,
  Vector3,
  Matrix4,
  // AxesHelper,
  Group,
  Box3,
  // BoxHelper,
  // Box3Helper,
} from "three";

import theme from "Styles/theme";
import getCenterAndAngleFromPath3 from "../utils/getCenterAndAngleFromPath3";
import getDim1FromPath3 from "../utils/getDim1FromPath3";

// import {CSG} from "three-csg-ts";

// import store from "App/store";

export default class MeasurementElement {
  constructor({
    modelId,
    // measurementsModel,
    editor3d,
    props3D,
    measurementId,
    material,
    elementTypeId,
    builtAt,
    zoneId,
    phaseId,
    isGhost,
    voids,
    deleteObject,
  }) {
    this.type = "MEASUREMENT_ELEMENT";
    this.entityID = measurementId;
    this.modelId = modelId;
    // this.modelId = measurementsModel.modelId;
    // this.measurementsModel = measurementsModel;
    this.editor3d = editor3d;
    this.props3D = props3D;
    this.elementTypeId = elementTypeId;
    this.builtAt = builtAt;
    this.zoneId = zoneId;
    this.phaseId = phaseId;
    this.isGhost = isGhost;
    this.deleteObject = deleteObject;

    this.hidden = false;
    this.selected = false;
    this.edgesHidden = true;
    this.voidsHidden = false;

    this.pickedMaterial = new MeshPhysicalMaterial({
      color: 0xfab41f, // yellow
      //color: 0xf52585, // pink
      transparent: true,
      opacity: 0.6,
    });

    this.voidMaterial = new MeshLambertMaterial({
      color: 0x89cff0, // baby blue
      transparent: true,
      opacity: 0.25,
      depthTest: true,
      side: DoubleSide,
    });

    this.selectedMaterial = new MeshPhysicalMaterial({
      // color: theme.palette.primary.flash,
      color: 0x39ff14, // green fluo
      transparent: true,
      opacity: 0.6,
      side: DoubleSide,
    });

    this.transparentMaterial = new MeshPhysicalMaterial({
      color: theme.palette.common.caplaBlack,
      transparent: true,
      depthWrite: false,
      opacity: 0.084,
      // side: DoubleSide,
    });

    this.material = material;
    this.object = this.createObject();
    this.voids = voids;
    this.hosts = [];

    this.meshFromFile3d = null; // used to add custom advanced geometry to the project.
  }

  rotateMeshAroundYAxis(mesh, point, angle) {
    const rotationMatrix = new Matrix4();
    const rotationAxis = new Vector3(0, 1, 0);
    mesh.position.copy(point);
    rotationMatrix.makeRotationAxis(rotationAxis, angle);
    mesh.applyMatrix4(rotationMatrix);
    mesh.position.sub(point);
    mesh.updateMatrix();
    mesh.updateMatrixWorld(true);
  }

  createObject(voidMeshes) {
    const {
      path,
      path2,
      path3,
      zInf,
      zSup,
      holesPaths,
      height,
      heights,
      heightE,
      heightN,
      side,
      faces,
      drawingShape,
      slopeH,
      slopingA,
      dim1,
      dim3,
      isNotHoriz,
      file3d,
    } = this.props3D;

    const material = this.selected ? this.selectedMaterial : this.material;

    // state
    // const state = store.getState();
    // const modelsLoaderOptions = state.viewer3D.modelsLoaderOptions;
    // const extrudeOff = modelsLoaderOptions?.extrudeOff;
    // const extrudeOff = false;

    let object;

    // init
    let zI;
    if (typeof zInf === "number") {
      zI = zInf;
    } else {
      zI = zSup - height;
    }

    // if (extrudeOff) {
    //   const polygon = this.editor3d?.drawer3DObjects.drawPolygonV2({
    //     path3,
    //     holesPaths,
    //     zInf: zI,
    //     zSup: zSup,
    //     material,
    //   });
    //   object = polygon.object;
    // } else
    if (drawingShape === "SLOPE") {
      const polygon = this.editor3d?.drawer3DObjects.drawSlope3D({
        path,
        zInf: zI,
        material,
        height: height,
        slopeH: slopeH ? slopeH : 1,
      });
      object = polygon.object;
    } else if (drawingShape === "STAIRS") {
      const polygon = this.editor3d?.drawer3DObjects.drawStairs({
        path,
        zInf: zI,
        zSup: zI + height,
        material,
      });
      object = polygon.object;
    } else if (drawingShape === "SLOPING_POLYGON") {
      const polygon = this.editor3d?.drawer3DObjects.drawSlopingPolygon({
        path3,
        holesPaths,
        zInf: zI,
        material,
        height,
        slopingA: slopingA ? slopingA : 0,
      });
      object = polygon.object;
    } else if (drawingShape === "GABLE") {
      const polygon = this.editor3d?.drawer3DObjects.drawPolyline({
        path,
        zInf: zI,
        material,
        heights,
      });
      object = polygon.object;
    } else if (drawingShape === "BRIDGE") {
      const polygon = this.editor3d?.drawer3DObjects.drawPolyline({
        path,
        zInf: zI,
        material,
        height,
        side,
        faces,
        drawingShape,
      });
      object = polygon.object;
    } else if (
      (!isNotHoriz || !holesPaths?.length > 0) &&
      (drawingShape === "POLYLINE" || drawingShape === "SEGMENT")
    ) {
      const polygon = this.editor3d?.drawer3DObjects.drawPolyline({
        path,
        zInf: zI,
        material,
        height,
        drawingShape,
      });
      object = polygon.object;
    } else if (drawingShape === "BANK" && path2?.length > 0) {
      const polygon = this.editor3d?.drawer3DObjects.drawBank({
        pathSup: path,
        pathInf: path2,
        zInf: zI,
        zSup: zI + height,
        material,
      });
      object = polygon.object;
    } else if (drawingShape === "BEAM") {
      const polygon = this.editor3d?.drawer3DObjects.drawBeam({
        path,
        zInf: zI,
        material,
        height,
        heightE,
        heightN,
        side,
        dim1,
        dim3,
      });
      object = polygon.object;
    } else if (height === 0) {
      const polygon = this.editor3d?.drawer3DObjects.drawPolygon({
        path,
        holesPaths,
        zInf: zI,
        zSup: zSup,
        material,
      });
      object = polygon.object;
    } else {
      const polygon = this.editor3d?.drawer3DObjects.drawPolygon3D3({
        path3,
        holesPaths,
        zInf: zI,
        material,
        height,
      });
      object = polygon.object;
    }

    // // voids
    // if (voidMeshes) {
    //   // const material = object.material;
    //   object.updateMatrix();
    //   voidMeshes.forEach((voidMesh) => {
    //     voidMesh.updateMatrix();
    //     const geomVoid = new BufferGeometry();
    //     geomVoid.copy(voidMesh.geometry);
    //     const voidObject = new Mesh(geomVoid);
    //     voidObject.position.copy(voidMesh.position);
    //     voidObject.rotation.copy(voidMesh.rotation);
    //     voidObject.updateMatrixWorld();
    //     //voidObject.children.forEach((child) => child.removeFromParent());
    //     //voidObject.geometry.center();
    //     //voidObject.updateMatrixWorld();
    //     voidObject.scale.setScalar(1.01);
    //     voidObject.updateMatrix();

    //     // sub
    //     object = CSG.subtract(object, voidObject);
    //   });
    // }

    // objects

    // this.measurementsModel.object.add(object);

    // 3d object

    if (file3d?.scale) {
      try {
        // const options = {
        //   normalizeRGB: true,
        //   //invertTrProperty: true,
        // };
        //this.editor3d.mtlLoader.setMaterialOptions(options);
        //const materials = this.editor3d.mtlLoader.parse(file3d.mtlStr);

        this.editor3d.mtlLoader.load(file3d.mtlUrl, (materials) => {
          materials.preload();
          console.log("debugAFA materials", materials);
          this.editor3d.objLoader.setMaterials(materials);

          this.editor3d.objLoader.load(file3d.url, (group) => {
            console.log("debugAFA group", group);
            let mesh = new Mesh();
            if (group instanceof Mesh) {
              mesh.copy(group);
            } else if (group?.children[0] instanceof Mesh) {
              mesh.copy(group?.children[0]);
            }
            if (mesh instanceof Mesh) {
              //
              console.log("debugAFA", mesh);
              //
              //mesh.material = this.material;
              //
              mesh.layers.enable(1);
              mesh.layers.enable(3);
              //
              const rX = file3d.rotX ? (file3d.rotX * Math.PI) / 180 : 0;
              const rZ = file3d.rotZ ? (file3d.rotZ * Math.PI) / 180 : 0;
              //
              mesh.rotateX(rX);
              mesh.rotateZ(rZ);
              //
              //
              //mesh.updateMatrix();
              //mesh.updateMatrixWorld(true);
              // scale
              // size
              const boundingBox = new Box3();
              boundingBox.setFromObject(mesh);
              //
              const size = new Vector3();
              boundingBox.getSize(size);
              const minDim = Math.min(size.x, size.z);
              const dim1 = getDim1FromPath3(path3);
              const scale = dim1 / minDim;
              mesh.scale.set(scale, scale, scale);
              //
              //mesh.updateMatrix();
              //mesh.updateMatrixWorld(true);
              //mesh.updateWorldMatrix();
              //
              const centr = new Vector3();
              const bbox2 = new Box3();
              bbox2.setFromObject(mesh);

              bbox2.getCenter(centr);
              const bottom = bbox2.min.y;
              //
              const origin = new Vector3(-centr.x, -bottom, -centr.z);
              mesh.position.copy(origin); // translateX and Z doesn't work. Probably because of local transform.
              //
              //mesh.updateMatrixWorld(true);
              //
              const meshContainer = new Group();
              meshContainer.add(mesh);

              //
              const [center, angleRadians] = getCenterAndAngleFromPath3(path3);

              meshContainer.rotateY(angleRadians);

              meshContainer.position.set(center[0], center[1], center[2]);
              //
              object.add(meshContainer);
              //
              this.meshFromFile3d = mesh;
            }
          });
        });
        //this.editor3d.objLoader.setMaterials(materials);
      } catch (e) {
        console.log("error", e);
      }
    }

    if (!this.isGhost) {
      object.layers.enable(1);
      object.layers.enable(3);
    }

    if (!this.edgesHidden) {
      this.addEdges(object); // cf pb to solve
    }

    return object;
  }

  // updateObject() {
  //   this.measurementsModel.object.remove(this.object);
  //   this.object = this.createObject();
  //   if (this.isGhost) {
  //     this.hide();
  //   } else {
  //     this.show();
  //   }
  // }

  // voids

  addVoidObjects(voidsVertices) {
    if (Array.isArray(this.voidObjects)) {
      for (const voidObject of this.voidObjects) {
        this.object.remove(voidObject);
      }
      this.voidObjects = [];
    }
    for (const voidVertices of voidsVertices) {
      const shapeGeometry = new BufferGeometry();
      shapeGeometry.setAttribute(
        "position",
        new BufferAttribute(new Float32Array(voidVertices), 3)
      );
      const indices = [];
      indices.push(0, 3, 2, 2, 1, 0); // bottom
      indices.push(4, 5, 6, 6, 7, 4); // top
      indices.push(3, 7, 6, 6, 2, 3); // left
      indices.push(0, 1, 5, 5, 4, 0); // right
      indices.push(0, 4, 7, 7, 3, 0); // front
      indices.push(2, 6, 5, 5, 1, 2); // back
      shapeGeometry.setIndex(indices);
      shapeGeometry.computeVertexNormals();
      const material = this.voidMaterial;
      const shapeMesh = new Mesh(shapeGeometry, material);
      if (!this.isGhost) {
        shapeMesh.layers.enable(1);
        shapeMesh.layers.enable(3);
      }
      shapeMesh.userData = {isVoid: true, entityID: this.entityID};
      shapeMesh.edges = this.addEdges(shapeMesh, true);
      this.object.add(shapeMesh);
      if (this.voidObjects) {
        this.voidObjects.push(shapeMesh);
      } else {
        this.voidObjects = [shapeMesh];
      }
    }
  }
  substractVoids() {
    if (this.netGeometry) {
      this.object.geometry = this.netGeometry;
      this.addEdges(this.object);
      for (const voidObject of this.voidObjects) {
        this.object.add(voidObject);
      }
    }
  }
  fillVoids() {
    if (this.rawGeometry) {
      this.object.geometry = this.rawGeometry;
      this.addEdges(this.object);
      for (const voidObject of this.voidObjects) {
        this.object.remove(voidObject);
      }
    }
  }
  setVoids(voids) {
    this.voids = voids;
  }
  setHosts(hosts) {
    this.hosts = hosts;
  }
  addHost(hostId) {
    this.hosts.push(hostId);
  }
  removeHost(hostId) {
    this.hosts = this.hosts.filter((h) => h !== hostId);
  }
  showVoids() {
    if (!this.voidObjects) return;
    this.voidsHidden = false;
    for (const voidObject of this.voidObjects) {
      voidObject.layers.enable(1);
      voidObject.layers.enable(3);
      if (!this.edgesHidden) this.showEdges(voidObject.edges, false);
    }
  }
  hideVoids() {
    if (!this.voidObjects) return;
    this.voidsHidden = true;
    for (const voidObject of this.voidObjects) {
      voidObject.layers.disable(1);
      voidObject.layers.disable(3);
      this.hideEdges(voidObject.edges, false);
    }
  }

  // edges

  // PB TO SOLVE : when edges are added, clicked entity is the edge...
  addEdges(object, isVoidEdges = false) {
    const geo = new EdgesGeometry(object.geometry, 20);
    const line = new LineSegments(geo);
    line.material.depthTest = true;
    //line.material.opacity = 0.4;
    //line.material.transpent = true;
    line.material.color = new Color(0x000000);
    if (!this.isGhost && !this.edgesHidden) {
      line.layers.enable(1);
      line.layers.enable(3);
    }
    line.userData = {isEdge: true, entityID: this.entityID};
    //line.position.copy(object.position);
    //line.rotation.copy(object.rotation);
    if (!isVoidEdges) this.edges = line;
    // object.add(line);
    if (object.children.length > 0) {
      object.children[0] = line;
    } else {
      object.add(line);
    }
    return line;
  }

  showEdges(edges, thisEdges = true) {
    if (thisEdges) {
      if (!this.edges || this.isGhost || this.hidden) return;
      this.edgesHidden = false;
      this.edges.layers.enable(1);
      this.edges.layers.enable(3);
      if (!this.voidsHidden) {
        for (const child of this.object.children) {
          this.showEdges(child.edges, false);
        }
      }
    } else if (edges) {
      edges.layers.enable(1);
      edges.layers.enable(3);
    }
  }

  hideEdges(edges, thisEdges = true) {
    if (thisEdges) {
      if (!this.edges) return;
      this.edgesHidden = true;
      this.edges.layers.disable(1);
      this.edges.layers.disable(3);
      for (const child of this.object.children) {
        this.hideEdges(child.edges, false);
      }
    } else if (edges) {
      edges.layers.disable(1);
      edges.layers.disable(3);
    }
  }

  // removeEdges() {
  //   if (this.edges) {
  //     this.object.remove(this.edges);
  //     this.edges = null;
  //   }
  // }

  transparent() {
    if (this.isGhost || this.hidden) return;
    let material = this.object.material;
    if (Array.isArray(material)) {
      const beamMaterial = material[0];
      beamMaterial.opacity = this.editor3d.opacity * 0.5;
      material = material[1];
    }
    material.transparent = true;
    material.opacity = this.editor3d.opacity;
    material.side = DoubleSide;
    if (Array.isArray(this.voidObjects)) {
      for (const voidObject of this.voidObjects) {
        voidObject.material.opacity = this.editor3d.opacity * 0.25;
      }
    }
  }

  opaque() {
    if (this.isGhost || this.hidden) return;
    let material = this.object.material;
    if (Array.isArray(material)) {
      const beamMaterial = material[0];
      beamMaterial.opacity = 0.5;
      material = material[1];
    }
    material.transparent = false;
    material.opacity = 1.0;
    material.side = FrontSide;
    if (["BRIDGE", "BOWL", "BANK"].includes(this.props3D.drawingShape))
      material.side = DoubleSide;
    if (Array.isArray(this.voidObjects)) {
      for (const voidObject of this.voidObjects) {
        voidObject.material.opacity = 0.25;
      }
    }
  }

  // setters

  setBuiltAt(builtAt) {
    this.builtAt = builtAt;
  }

  setZoneId(zoneId) {
    this.zoneId = zoneId;
  }

  setPhaseId(phaseId) {
    this.phaseId = phaseId;
  }
  setIsGhost(bool) {
    this.isGhost = bool;
    if (bool) {
      this.hide();
    } else {
      this.show();
    }
  }

  setMaterial(material) {
    this.material = material;
    this.object.material = material;
    if (this.meshFromFile3d?.material) this.meshFromFile3d.material = material;
  }

  applyMaterial(material) {
    this.object.material = material;
    if (this.meshFromFile3d?.material) this.meshFromFile3d.material = material;
  }

  setElementTypeId(elementTypeId) {
    this.elementTypeId = elementTypeId;
  }

  setProps3D(props3D) {
    console.log(
      "[MeasurementElement] setProps3D",
      props3D,
      "zInf",
      props3D?.zInf,
      "file3d.rotZ",
      props3D?.file3d?.rotZ
    );
    this.props3D = props3D; // this.props3D will be used by this.createObject() (next line)
    // this.updateObject();
    this.object = this.createObject();
    if (this.rawGeometry) {
      this.rawGeometry = this.object.geometry;
    }
    if (this.isGhost) {
      this.hide();
    } else {
      this.show();
    }
    return this.object;
  }
  // show & hide

  hide() {
    this.object.layers.disable(1);
    this.object.layers.disable(3);
    this.object.visible = false;
    this.hidden = true;
    this.hideEdges();
  }

  show() {
    if (this.isGhost) {
      this.hide();
    } else {
      this.object.layers.enable(1);
      this.object.layers.enable(3);
      this.object.visible = true;
      this.hidden = false;
      this.object.material = this.selected
        ? this.selectedMaterial
        : this.material;
      if (this.editor3d.sceneEditor.edges) this.showEdges();
    }
  }

  showFromTransparent() {
    if (this.isGhost) {
      this.hide();
    } else {
      this.object.layers.enable(1);
      this.object.layers.enable(3);
      this.object.visible = true;
      this.hidden = false;
      this.object.material = this.selected
        ? this.selectedMaterial
        : this.material;
      this.showEdges();
    }
  }

  hideAsTransparent() {
    if (this.isGhost) {
      this.hide();
    } else {
      this.object.layers.enable(1);
      this.object.layers.enable(3);
      this.object.visible = true;
      this.hidden = true;
      this.object.material = this.transparentMaterial;
      this.hideEdges();
    }
  }

  // opacity & progress

  setOpacityFromProgress(value) {
    // used to visualize object based on a business process : progress, control,..
    if (!value || value < 0.2) {
      this.object.material = this.transparentMaterial;
    } else {
      this.object.material = this.material;
    }
  }

  // pick & select

  pick() {
    this.object.material = this.pickedMaterial;
  }
  unpick() {
    this.object.material = this.material;
  }

  toggleSelect() {
    if (this.selected) {
      this.unselect();
    } else {
      this.select();
    }
  }
  select() {
    this.object.material = this.selectedMaterial;
    this.meshFromFile3d = this.selectedMaterial;
    this.selected = true;
    console.log("selectObject (measuremnt element)", this.object);
  }
  unselect() {
    if (!this.hidden) {
      this.object.material = this.material;
      this.meshFromFile3d = this.selectedMaterial;
    }

    this.selected = false;
    console.log("unSelectObject (measuremnt element)", this.object);
  }

  // parse

  parse() {
    return {
      type: this.type,
      modelId: this.modelId,
    };
  }

  // delete

  delete() {
    this.deleteObject(this.object);
    // this.measurementsModel.object.remove(this.object);
  }
}
