export default function updateMeasurementsHeightsWithDelta(
  measurements,
  delta
) {
  const updatedMeasurements = measurements.map((meas) => {
    return {
      ...meas,
      zSup: meas.zSup + delta,
      height: meas.height + delta,
    };
  });

  //
  return updatedMeasurements;
}
