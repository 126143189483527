const col1 = {
  label: "Projet",
  modules: ["HOME", "SCOPES", "SURVEYS", "INPUTS", "MEASUREMENTS"],
};
const col1bis = {
  label: "Localisation",
  modules: ["ZONES", "SECTORS", "ROOMS", "MARKERS", "PHASES"],
};
const col2 = {
  label: "Matériaux et ouvrages",
  modules: [
    "MATERIALS",
    "SAMPLES",
    "ELEMENT_TYPES",
    "PACKAGES",
    "EDIT_MEASUREMENTS",
    "PROCEDURES",
  ],
};

const col4 = {
  label: "Rapports",
  modules: ["ARTICLES", "VIEWS", "REPORTS"],
};
const col5 = {
  label: "Autres",
  modules: ["PRICINGS", "PROGRESS"],
};

const modulesByColumn = [col1, col1bis, col2, col4, col5];

export default modulesByColumn;
