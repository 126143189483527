import extractNomenclatureTypesFromGroup from "Features/elementTypes/utils/extractNomenclatureTypesFromGroup";
import articleTypesMapToArray from "./articleTypesMapToArray";
import {nanoid} from "@reduxjs/toolkit";
import getNomenclatureTypesFromMeasurements from "./getNomenclatureTypesFromMeasurementsAndTemplate";
import parseElementTypeArticleQties from "./parseElementTypeArticleQties";
import updateFiltersStrWithFiltersStr from "./updateFiltersStrWithFiltersStr";

import getSubArticlesPropsFromMeasurements from "./getSubArticlesPropsFromMeasurements";

export default function getNomenclatureArticleSubArticles(
  article,
  groupsWithSortedTypes,
  measurements,
  options
) {
  try {
    // options

    const fromMeasurements = options?.fromMeasurements;
    const sectors = options?.sectors;

    //

    const coreTypes = article?.coreTypes ?? {};
    let globalScopeFromCoreTypes = coreTypes["filtersStr"];

    const types = article?.types ?? {};
    const globalScope = types["filtersStr"];
    const globalFunc = types["globalFunc"];

    if (!coreTypes && !(globalScope && globalFunc)) return [];

    const coreTypesArray = articleTypesMapToArray(coreTypes);
    const coreType0 = coreTypesArray[0];
    //
    const typesArray = articleTypesMapToArray(article.types);
    const type0 = typesArray[0];

    console.log("debugafa type0", type0);

    // helper : use the v2 of the func to get subArticlesProps.
    let useV2 = false;
    if (!globalFunc && typesArray?.length >= 1) {
      useV2 = true;
    }
    console.log("useV2", useV2);

    if (!type0) return [];
    //

    //if (!type0 && !globalScope) return []; // we need a func to compute...
    // we need types ie, we wait auto computation to inherit the appropriate filters.

    let items;

    if (useV2) {
      const data = {groupsWithSortedTypes, sectors};
      items = getSubArticlesPropsFromMeasurements(article, measurements, data);
      const subArticles = items.map((subArticleProps, index) => {
        const {name, elementTypes, filtersStrFromFields} = subArticleProps;

        const types = {};
        elementTypes.forEach((elementType) => {
          const articleType =
            article.types[elementType.groupId]?.[elementType.id];
          //
          const filtersStr = updateFiltersStrWithFiltersStr(
            articleType.filtersStr,
            filtersStrFromFields,
            "merge"
          );
          //
          const func = articleType.func;

          if (!types[elementType.groupId]) {
            types[elementType.groupId] = {
              [elementType.id]: {
                func,
                filtersStr,
              },
            };
          } else {
            types[elementType.groupId][elementType.id] = {func, filtersStr};
          }
        });

        return {
          clientId: nanoid(),
          id: article.id + "-" + index, // to get the selection to work
          articlesGroupId: article.articlesGroupId,
          name,
          types,
          coreTypes, // used to prevent inheritance at autocomputation
          unit: article.unit,
          style: "subDetail",
          code: article.code,
          isSubArticle: true,
        };
      });
      console.log("subArticlesV2", subArticles);
      return subArticles;
    } else if (coreType0 || globalScope) {
      const groupId = coreType0?.groupId;
      const typeId = coreType0?.typeId;
      const group = groupsWithSortedTypes.find((g) => g.id === groupId);

      if (fromMeasurements) {
        const parentElementType = group?.elementTypes.find(
          (t) => t.id === typeId
        ); // we don't need a parentElement type for globalScope.
        console.log("debugAFA parentElementTypeA", parentElementType);
        items = getNomenclatureTypesFromMeasurements(measurements, {
          parentElementType,
          articleName: article.name,
          globalScope,
          groupsWithSortedTypes,
          sectors,
        });
      } else {
        items = extractNomenclatureTypesFromGroup(group, typeId);
      }
      console.log("itemsNNN", items, "globalScope", globalScope);

      //
      // remove header
      //const itemsWithoutHeader = items.slice(1);

      // 2 cases : globalScope or not

      let result;
      if (!globalScope) {
        const subArticles = items.map((elementType, index) => {
          let typeItem;
          if (article.types)
            // not the core type because we need here the computationfrom inheritance.
            typeItem =
              article.types[elementType.groupId]?.[elementType.fromTypeId];

          // func & filtersStr

          let func = typeItem?.func;
          let filtersStr = typeItem?.filtersStr ?? [];
          // we add the filters from fields.
          const fields = elementType.fields; // "dim1", "dim2",...
          const filtersFromFieldsChunk = fields.map((field) => {
            const value = elementType[field];
            const fieldKeyMap = {
              dim1: "D1",
              dim2: "D2",
              height: "H",
              length: "L",
            };
            const oneFilter = `${fieldKeyMap[field]}===${value}`;
            return oneFilter;
          });
          const filtersFromFields = filtersFromFieldsChunk.join(" && ");

          const finalFiltersStr = updateFiltersStrWithFiltersStr(
            filtersStr,
            filtersFromFields,
            "merge"
          );

          // elementTypeId, types & coreTypes

          let types;
          let coreTypes;

          if (!globalScope) {
            const elementTypeId = fromMeasurements ? typeId : elementType.newId;
            types = {
              [groupId]: {
                [elementTypeId]: {
                  func: func ?? "y",
                  filtersStr: finalFiltersStr,
                },
              },
            };
            coreTypes = {[groupId]: {[elementType.id]: {func: "x"}}}; // used to prevent inheritance at autocomputation
          } else {
            types = {filtersStr: filtersStr};
            coreTypes = {filtersStr: filtersStr};
          }
          //

          const subArticle = {
            clientId: nanoid(),
            id: article.id + "-" + index, // to get the selection to work
            articlesGroupId: article.articlesGroupId,
            name: elementType.name,
            types,
            coreTypes, // used to prevent inheritance at autocomputation
            unit: article.unit,
            style: "subDetail",
            code: article.code,
            isSubArticle: true,
          };

          return subArticle;
        });
        result = subArticles;
        // case 2 : global scope : we compute elementTypes + func from measurements.
      } else {
        const subArticles = [];

        items.forEach(({filtersStr, name, elementTypes}) => {
          let updatedTypes = {};
          let addItem = false;

          elementTypes.forEach((elementType) => {
            if (elementType) {
              const articleQties = elementType.articleQties;

              if (articleQties) {
                const articleCodeFuncArray =
                  parseElementTypeArticleQties(articleQties);

                articleCodeFuncArray.forEach(({articleCode, func}) => {
                  const props = {func, filtersStr};
                  if (article?.code === articleCode) {
                    const groupId = elementType?.groupId;
                    const typeId = elementType?.id;
                    updatedTypes = {
                      ...updatedTypes,
                      [groupId]: {
                        ...updatedTypes[groupId],
                        [typeId]: props,
                      },
                    };
                    addItem = true;
                  }
                });
              }
            }
          });
          if (addItem) {
            const subArticle = {
              clientId: nanoid(),
              id: article.id + "-" + name, // to get the selection to work
              articlesGroupId: article.articlesGroupId,
              name,
              types: updatedTypes,
              coreTypes: updatedTypes, // used to prevent inheritance at autocomputation
              unit: article.unit,
              style: "subDetail",
              code: article.code,
              isSubArticle: true,
            };
            subArticles.push(subArticle);
          }
        });
        result = subArticles;
      }
      return result;
    } else {
      return [];
    }
  } catch (error) {
    console.log("getNomenclatureArticleSubArticles", error);
  }
}
