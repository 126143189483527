import {useState} from "react";

import {useDispatch} from "react-redux";
import {unwrapResult} from "@reduxjs/toolkit";

import {createScope, setSelectedScopeId} from "../scopesSlice";
import {setOpenDialogFsSelectScope} from "Features/overviewer/overviewerSlice";
import {setOneFirstScopeWasOpened} from "Features/overviewer/overviewerSlice";
import {setScopeIdToOpen} from "Features/overviewer/overviewerSlice";

import {
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
} from "@mui/material";

import useAccessToken from "Features/auth/useAccessToken";

export default function SectionCreateScopeEmpty({scene}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const createS = "Créer";

  const title = "Créez une sous-bimbox";
  const description = `Une sous-bimbox est typiquement un lot de votre opération : gros oeuvre, serrurerie, peinture...
`;
  const clientReferenceStr = "Réf. du dossier";
  const placeholder = "Nom du lot";

  // state

  const [name, setName] = useState("");
  const [clientReference, setClientReference] = useState("");
  const [loading, setLoading] = useState(false);

  // handlers

  function handleNameChange(e) {
    setName(e.target.value);
  }

  async function handleCreateScope() {
    const scope = {name, sceneId: scene.id, data: {}};
    if (clientReference) scope.clientReference = clientReference;

    if (loading) return;
    setLoading(true);
    const result = await dispatch(createScope({scope, accessToken}));
    const {item} = unwrapResult(result);
    dispatch(setSelectedScopeId(item.id));
    dispatch(setScopeIdToOpen(scope.id));
    dispatch(setOpenDialogFsSelectScope(false));
    dispatch(setOneFirstScopeWasOpened(true));
    setLoading(false);
  }
  return (
    <Box
      sx={{
        color: "white",
        p: 3,
        minWidth: 0,
        width: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="h6">{title}</Typography>
      <Typography sx={{whiteSpace: "pre-line", mt: 1}}>
        {description}
      </Typography>
      <Box
        sx={{
          borderRadius: 1,
          mt: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: 1,
        }}
      >
        <TextField
          fullWidth
          autoFocus
          placeholder={placeholder}
          size="small"
          value={name}
          onChange={handleNameChange}
          InputProps={{
            sx: {
              //border: "1px solid white",
              color: "common.white",
              textAlign: "center",
              fontSize: (theme) => theme.typography.h6.fontSize,
            },
          }}
        />
      </Box>
      <Box sx={{mt: 1}}>
        <TextField
          size="small"
          placeholder={clientReferenceStr}
          value={clientReference}
          onChange={(e) => setClientReference(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography sx={{color: "common.white"}}>#</Typography>
              </InputAdornment>
            ),
            sx: {
              fontSize: 13,
              color: "common.white",
            },
          }}
        />
      </Box>
      <Box
        sx={{
          color: "white",
          mt: 1,
          width: 1,
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          color="inherit"
          onClick={handleCreateScope}
          disabled={loading || !accessToken || name.length === 0}
        >
          {createS}
        </Button>
      </Box>
    </Box>
  );
}
