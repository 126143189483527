import {useDispatch} from "react-redux";

import {updateMaterial} from "../materialsSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function useUpdateMaterial() {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const updateGroup = async (material) => {
    await dispatch(
      updateMaterial({
        accessToken,
        material,
      })
    );
  };
  return updateGroup;
}
