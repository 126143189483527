import {useSelector, useDispatch} from "react-redux";

import {Box} from "@mui/material";

import FormMeasurement from "./FormMeasurement";

import useSceneMeasurements from "../useSceneMeasurements";
import useSelectedMeasurementMulti from "../useSelectedMeasurementMulti";

import {setEditedMeasurementMulti} from "Features/measurements/measurementsSlice";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import updateMeasurementZsFromZone from "../utils/updateMeasurementZsFromZone";

export default function SectionMeasurementMultiInSelectionPanel({
  scene,
  caplaEditor,
}) {
  const dispatch = useDispatch();

  // data

  const isEditing = useSelector(
    (s) => s.measurements.isEditingMeasurementMulti
  );
  const editedMeasurementMulti = useSelector(
    (s) => s.measurements.editedMeasurementMulti
  );

  const measurements = useSceneMeasurements(scene);
  const selectedMeasurementIds = useSelector(
    (s) => s.measurements.selectedMeasurementIds
  );

  const selectedMeasurementMulti = useSelectedMeasurementMulti(scene);

  const zonesById = useZonesByScene(scene, {variant: "map"});

  console.log("selectedMeasurementMulti", selectedMeasurementMulti);

  // helpers

  const mnger =
    caplaEditor?.editorPdf.annotationsManager.measurementsPdfManager;
  const selectedMeasurements = measurements.filter((m) =>
    selectedMeasurementIds?.includes(m.id)
  );

  const measurementMulti = isEditing
    ? editedMeasurementMulti
    : selectedMeasurementMulti;

  // handlers

  function handleMeasurementMultiChange(m) {
    const temp = {};
    Object.entries(m).forEach(([key, value]) => {
      if (value !== undefined && value !== null) temp[key] = value;
    });
    if (
      typeof temp.offset === "number" &&
      temp.offset !== measurementMulti.offset
    ) {
      temp.zInf = null;
      temp.zSup = null;
      temp.zFrom = "offset";
    } else if (
      typeof temp.zInf === "number" &&
      temp.zInf !== measurementMulti.zInf
    ) {
      temp.offset = null;
      temp.zSup = null;
      temp.zFrom = "zInf";
    } else if (
      typeof temp.zSup === "number" &&
      temp.zSup !== measurementMulti.zSup
    ) {
      temp.offset = null;
      temp.zInf = null;
      temp.zFrom = "zSup";
    }
    dispatch(setEditedMeasurementMulti(temp));

    for (const measurement of selectedMeasurements) {
      let newM = {...measurement, ...temp};
      newM = updateMeasurementZsFromZone(newM, zonesById[measurement.zoneId]);
      mnger?.updateAnnotation(newM, {multipleUpdates: true, zonesById});
      //caplaEditor?.editor3d.sceneEditor.updateMeasurement3D(newM); // the update is managed in the updateAnnotation
    }
  }

  return (
    <Box sx={{width: 1}}>
      {measurementMulti && (
        <FormMeasurement
          measurement={measurementMulti}
          scene={scene}
          onChange={handleMeasurementMultiChange}
          isEditing={isEditing}
        />
      )}
    </Box>
  );
}
