import {useSelector} from "react-redux";

export default function useShowLoadPdfBimData(scene) {
  const selectedPdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);
  const scope = useSelector((s) => s.pdf.dialogLoadBimDataScope); // array of pdfModelId
  const hasBimData = useSelector((s) => s.pdf.hasBimData);

  const show = hasBimData && scope.includes(selectedPdfModelId);

  return show;
}
