import {useEffect, useState} from "react";

import {useDispatch} from "react-redux";
import {updateArticleTemp} from "../articlesSlice";

import {Box, Typography, FormControlLabel, Checkbox} from "@mui/material";
import {update} from "Features/ifc/ifcSlice";

export default function BlockEditableArticleIsNomenclature({article}) {
  const dispatch = useDispatch();

  // string

  const nomenclatureS = "Nomencl.";

  // state

  const initIsNomenclature = article?.isNomenclature ?? null;

  const [isNomenclature, setIsNomenclature] = useState(initIsNomenclature);

  useEffect(() => {
    setIsNomenclature(initIsNomenclature);
  }, [initIsNomenclature, article?.id]);

  // handlers

  function handleChange(e) {
    const checked = e.target.checked;
    dispatch(updateArticleTemp({id: article.id, isNomenclature: checked}));
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        //bgcolor: "background.default",
        px: 1,
        pl: 0,
        height: "20px",
        borderRadius: "4px",
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={isNomenclature}
            onChange={handleChange}
          />
        }
        label={
          <Typography sx={{color: "text.secondary", fontSize: 12}}>
            {nomenclatureS}
          </Typography>
        }
      />
    </Box>
  );
}
