import {useState} from "react";

import {useSelector, useDispatch} from "react-redux";

import useSelectedScope from "../hooks/useSelectedScope";
import {setSelectedScopeId, setSelectedScopeIdInModule} from "../scopesSlice";
import {fetchScopes, deleteScope} from "../scopesSlice";

import {Box} from "@mui/material";

import SelectorScope from "./SelectorScope";
import useScopesByScene from "../hooks/useScopesByScene";

import DialogFsCreateAndUpdateScope from "./DialogFsCreateAndUpdateScope";
import DeleteDialog from "Features/ui/components/DeleteDialog";

import useAccessToken from "Features/auth/useAccessToken";
import {setScopeIdToOpen} from "Features/overviewer/overviewerSlice";

export default function SelectorScopeInTopBar({scene, viewer}) {
  const dispatch = useDispatch();
  const sceneId = scene?.id;
  const accessToken = useAccessToken();

  // strings

  const noScopeLabel = "Tous les éléments";

  // state

  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [mode, setMode] = useState("CREATE");

  // data

  const selectedScope = useSelectedScope();
  const opt = {sortByName: true, addScopeAll: true};
  const scopes = useScopesByScene(scene, opt);

  // handlers

  function handleScopeChange(scope) {
    dispatch(setSelectedScopeId(scope?.id));
  }

  function handleCreateClick() {
    setOpenDialogEdit(true);
    setMode("CREATE");
  }

  function handleDeleteClick() {
    setOpenDialogDelete(true);
  }

  function handleEditClick(scope) {
    setOpenDialogEdit(true);
    setMode("UPDATE");
    dispatch(setSelectedScopeIdInModule(scope?.id));
  }

  function handleRefreshClick() {
    if (accessToken && sceneId) {
      dispatch(fetchScopes({accessToken, sceneId}));
    }
  }

  function handleDeleteConfirm() {
    if (accessToken && sceneId) {
      dispatch(deleteScope({accessToken, scope: selectedScope}));
    }
    setOpenDialogDelete(false);
  }

  function handleDeleteCancel() {
    setOpenDialogDelete(false);
  }

  function handleOpenClick(scope) {
    dispatch(setScopeIdToOpen(scope?.id));
    dispatch(setSelectedScopeId(scope?.id));
  }

  return (
    <Box sx={{display: "flex", minWidth: 0}}>
      <SelectorScope
        scene={scene}
        selectedScope={selectedScope}
        onChange={handleScopeChange}
        scopes={scopes}
        noScopeLabel={noScopeLabel}
        viewer={viewer}
        onCreateClick={handleCreateClick}
        onDeleteClick={handleDeleteClick}
        onEditClick={handleEditClick}
        onRefreshClick={handleRefreshClick}
        onOpenClick={handleOpenClick}
      />
      <DialogFsCreateAndUpdateScope
        scene={scene}
        mode={mode}
        open={openDialogEdit}
        onClose={() => setOpenDialogEdit(false)}
      />
      <DeleteDialog
        open={openDialogDelete}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        ressource="scope"
      />
    </Box>
  );
}
