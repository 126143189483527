import {Box} from "@mui/material";

import SectionActionsArticles from "./SectionActionsArticles";

export default function SectionLeftPanelActionsArticles({
  scene,
  caplaEditor,
  viewer,
}) {
  // actions - main

  const actionsMainKeys = [
    !viewer ? "createArticlesGroup" : "",
    !viewer ? "createArticle" : "",
    !viewer ? "refreshQties" : "",
    viewer ? "computeQties" : "",
    "exportExcelArticles",
    !viewer && "importExcelArticles",
  ];

  console.log("actionsMainKeys", actionsMainKeys);

  let actionsMoreKeys = [
    "computeQties",
    //
    "divider",
    //
    "resetArticles",
    "clearComputedArticles",
    "syncUpdates",
    //
    "divider",
    "addComputedArticles",
    "updateComputedArticlesTypes",
    //"forceLastUpdate",
    //"resetProxies",
    //"autoUpdateArticles",
    "divider",
    "cleanArticles",
    "divider",
    "deleteArticlesGroup",
    "deleteAllArticles",
    "deleteAllArticleTypes",
    "deleteArticle",
  ];
  if (viewer) actionsMoreKeys = [];

  return (
    <Box>
      <SectionActionsArticles
        scene={scene}
        caplaEditor={caplaEditor}
        actionsMainKeys={actionsMainKeys.filter((key) => key?.length > 0)}
        actionsMoreKeys={actionsMoreKeys}
      />
    </Box>
  );
}
