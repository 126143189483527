import getArticlesUpdatesFromElementTypesGroups from "../utils/getArticlesUpdatesFromElementTypesGroups";
import getTempArticleWithCoreTypesAsTypes from "./getTempArticleWithCoreTypesAsTypes";
import mergeArticlesBatchUpdates from "../utils/mergeArticlesBatchUpdates";
import resetArticlesTypes from "./resetArticlesTypes";

export default function getUpdatesToRefreshQties(
  articles,
  elementTypesGroupsWithSortedItems,
  options
) {
  // to change the qty, we just need to change the types of the articles.
  // we'll keep the change in default updates.

  // options

  // const updateOnlyArticlesFromTemplate =
  //   options?.updateOnlyArticlesFromTemplate;

  // STEP 1 : reset Types

  let [newArticles, batchUpdates1] = resetArticlesTypes(articles);

  // STEP 2 : start with new Types as core Types

  const articlesWithCoreTypes = newArticles.map((article) =>
    getTempArticleWithCoreTypesAsTypes(article)
  );

  let batchUpdates2 = getArticlesUpdatesFromElementTypesGroups(
    articlesWithCoreTypes,
    elementTypesGroupsWithSortedItems
  );

  // STEP 3 : filter on articles from template if necessary

  // if (updateOnlyArticlesFromTemplate) {
  // }

  // STEP 4 :merge

  console.log(
    '"debugafa1709 batchUpdates',
    batchUpdates2,
    articlesWithCoreTypes,
    elementTypesGroupsWithSortedItems
  );

  return mergeArticlesBatchUpdates(batchUpdates1, batchUpdates2);
}
