import {useDispatch, useSelector} from "react-redux";

import {setIsEditing, setEditedScene} from "../scenesSlice";
import {Box} from "@mui/material";

import FormSceneKvf from "./FormSceneKvf";

export default function SectionSceneInSelectionPanel({scene, viewer}) {
  const dispatch = useDispatch();
  // data

  const editedScene = useSelector((s) => s.scenes.editedScene);
  const isEditing = useSelector((s) => s.scenes.isEditing);

  // helper

  const sceneInForm = isEditing ? editedScene : scene;

  // handlers

  function handleSceneChange(changedScene) {
    console.log("scene change", changedScene);
    if (!isEditing) dispatch(setIsEditing(true));
    dispatch(setEditedScene(changedScene));
  }

  return (
    <Box>
      <Box sx={{width: 1, height: 1, overflow: "auto"}}>
        <FormSceneKvf scene={sceneInForm} onChange={handleSceneChange} />
      </Box>
    </Box>
  );
}
