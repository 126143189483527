import {useEffect, useState} from "react";

import {useDispatch} from "react-redux";
import {updateArticleTemp} from "../articlesSlice";

import {Box, Typography, Input} from "@mui/material";
import {update} from "Features/ifc/ifcSlice";

export default function BlockEditableArticleComment({article}) {
  const dispatch = useDispatch();

  // state

  const initComment = article?.comment ?? "";

  const [comment, setComment] = useState(initComment);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setComment(initComment);
  }, [initComment]);

  // helpers

  const noComment = !comment?.length > 0;
  const placeholder = "Ajouter un commentaire";

  // handlers

  function handleBlur() {
    setIsEditing(false);
    dispatch(updateArticleTemp({id: article.id, comment}));
  }

  function handleFocus() {
    setIsEditing(true);
  }

  return (
    <Box
      sx={{
        minHeight: 30,
        display: "flex",
        alignItems: "center",
        //bgcolor: (theme) => lighten(theme.palette.secondary.light, 0.9),
        px: 1,
        borderRadius: "4px",
      }}
    >
      <Input
        multiline
        fullWidth
        disableUnderline
        sx={{
          color: isEditing ? "primary.main" : "error.main",
          p: 0,
          fontSize: 13,
        }}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        onBlur={handleBlur}
        onFocus={handleFocus}
        placeholder={placeholder}
      />
    </Box>
  );
}
