import {useSelector} from "react-redux";

export default function useViewsByScene(scene, options) {
  const viewsMap = useSelector((s) => s.views.itemsMap);

  let views = Object.values(viewsMap);
  views = views.filter((v) => v.sceneId === scene?.id);

  if (options?.filterByScope) {
    views = views.filter((v) => v.scopeId === options.filterByScope);
  }

  if (options?.sortByName) {
    views = views.sort((a, b) => a.name.localeCompare(b.name));
  }

  return views;
}
