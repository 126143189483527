import useSceneModule from "Features/navigation/useSceneModule";

import {Box} from "@mui/material";

import SectionActionsSceneHome from "Features/boxhome/components/SectionActionsSceneHome";

export default function SectionLeftPanelActionsSceneHome({scene, caplaEditor}) {
  // data

  const sceneModule = useSceneModule();

  // actions - main

  const actionsMainKeys = ["shareSceneScope", "editMemberships"];
  const actionsMoreKeys = ["deleteScene", "deleteSceneScope"];

  return (
    <Box>
      <SectionActionsSceneHome
        scene={scene}
        caplaEditor={caplaEditor}
        actionsMainKeys={actionsMainKeys}
        actionsMoreKeys={actionsMoreKeys}
      />
    </Box>
  );
}
