export default function getParentNum(num) {
  if (!num) return null;

  const numArray = num.split(".");
  if (numArray.length === 1) {
    return null;
  } else {
    numArray.pop();
    return [...numArray].join(".");
  }
}
