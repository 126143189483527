import {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import {Box} from "@mui/material";

import AppDesktop from "./AppDesktop";
import AppMobile from "./AppMobile";
import AppDesktopViewer from "./AppDesktopViewer";

import ScreenStartApp from "Features/overviewer/components/ScreenStartApp";

import {
  setAppMode,
  setDesktopOrMobile,
  setOpenSections,
} from "Features/ui/uiSlice";
import useIsViewer from "Features/ui/useIsViewer";

import CaplaEditor from "Features/caplaEditor/js/CaplaEditor";
import usePageType from "Features/navigation/usePageType";
import {setOpenDialogFsOpenScope} from "Features/overviewer/overviewerSlice";
import useIsStagingEnv from "Features/overviewer/hooks/useIsStagingEnv";
import useSceneFromStateAndUrl from "Features/scenes/useSceneFromStateAndUrl";

export default function App() {
  const dispatch = useDispatch();
  const isStagingEnv = useIsStagingEnv();

  const caplaEditorRef = useRef();

  // data

  const desktopOrMobile = useSelector((s) => s.ui.desktopOrMobile);
  const isViewer = useIsViewer();
  useEffect(() => {
    dispatch(setAppMode(isViewer ? "VIEWER" : "EDITOR"));
  }, [isViewer]);

  const appMode = useSelector((s) => s.ui.appMode);

  const scene = useSceneFromStateAndUrl();

  // data - helper - starting App

  const oneFirstScopeWasOpened = useSelector(
    (s) => s.overviewer.oneFirstScopeWasOpened
  );

  const loadingRatioByScopeIdMap = useSelector(
    (s) => s.overviewer.loadingRatioByScopeIdMap
  );

  const selectedScopeId = useSelector((s) => s.scopes.selectedScopeId);

  const outOfDateTestWasDone = useSelector(
    (s) => s.overviewer.lastOpenedScopeIsOutOfDateTestWasDone
  );

  const openDialogFsSelectScope = useSelector(
    (s) => s.overviewer.openDialogFsSelectScope
  );

  const openDialogFsOpenScope = useSelector(
    (s) => s.overviewer.openDialogFsOpenScope
  );

  const openDialogFsInvalidBimbox = useSelector(
    (s) => s.overviewer.openDialogFsInvalidBimbox
  );
  console.log("openDialogFsInvalidBimbox", openDialogFsInvalidBimbox);

  const pageType = usePageType();

  // helper - isRemoteScene

  const isRemoteScene = Boolean(scene?.id);

  // helper - isStartingApp

  let isStartingApp =
    pageType === "SCENE" &&
    !oneFirstScopeWasOpened &&
    !(openDialogFsSelectScope || openDialogFsOpenScope);

  if (selectedScopeId && !loadingRatioByScopeIdMap[selectedScopeId]?.ratio)
    // use case : scope selected but no initialisation of models to load.
    isStartingApp = true;

  // helper - show screen

  // const showScreen =
  //   isStartingApp &&
  //   !isViewer &&
  //   isStagingEnv &&
  //   !openDialogFsInvalidBimbox &&
  //   !outOfDateTestWasDone;
  const showScreen =
    isRemoteScene && isStartingApp && !isViewer && !openDialogFsInvalidBimbox;

  // init

  useEffect(() => {
    // test
    const isMobile = /Android|iPhone/i.test(navigator.userAgent);
    const dOm = isMobile ? "MOBILE" : "DESKTOP";
    dispatch(setDesktopOrMobile(dOm));

    if (isMobile) {
      dispatch(
        setOpenSections({
          outlet: true,
          fixedViewersBox: false,
          viewer3D: true,
          callToAction: false,
        })
      );
    }

    if (!caplaEditorRef.current) {
      caplaEditorRef.current = new CaplaEditor({dispatch, isViewer});
      window.caplaEditor = caplaEditorRef.current; // make editor accessible from the browser
    }
  }, []);

  // return

  return (
    <Box sx={{position: "fixed", top: 0, bottom: 0, left: 0, right: 0}}>
      <Box sx={{width: 1, height: 1}}>
        {desktopOrMobile === "DESKTOP" && !isViewer && appMode === "EDITOR" && (
          <AppDesktop caplaEditor={caplaEditorRef.current} />
        )}
        {desktopOrMobile === "DESKTOP" && isViewer && appMode === "VIEWER" && (
          <AppDesktopViewer caplaEditor={caplaEditorRef.current} />
        )}
        {desktopOrMobile === "MOBILE" && <AppMobile />}
      </Box>
      {showScreen && <ScreenStartApp />}
    </Box>
  );
}
