export default function getItemNameFromTemplate(item, template) {
  if (template === "dim1xheight cm") {
    const d1 = (item.dim1 * 100).toFixed(0);
    const h = (item.height * 100).toFixed(0);

    return `${d1}x${h}ht cm`;
  } else if (template === "dim1 cm") {
    const d1 = (item.dim1 * 100).toFixed(0);
    return `larg. ${d1} cm`;
  } else if (template === "diam cm") {
    const d1 = (item.dim1 * 100).toFixed(0);
    return `diam. ${d1} cm`;
  } else if (template === "height cm") {
    const height = (item.height * 100).toFixed(0);
    return `ép. ${height} cm`;
  } else if (template === "dim1xdim2 cm") {
    const d1 = (item.dim1 * 100).toFixed(0);
    const d2 = (item.dim2 * 100).toFixed(0);
    return `${d1}x${d2} cm`;
  } else if (template === "dim1xL cm" || template === "sectionV") {
    const d1 = (item.dim1 * 100).toFixed(0);
    const L = (item.length * 100).toFixed(0);
    return `${d1}x${L} cm`;
  } else if (template === "height m") {
    const height = item.height.toFixed(2);
    return `ht. ${height} m`;
  } else if (template === "p0") {
    const p0 = item.p0;
    return p0;
  } else if (template === "HA") {
    const p2 = item.p2;
    return `HA ${p2} kg/m3`;
  } else if (template === "TS") {
    const p1 = item.p1;
    return `TS ${p1} kg/m3`;
  } else if (template === "p3") {
    const p3 = item.p3;
    return p3;
  } else if (template === "stairs") {
    const stairsCount = item.p1;
    const stairType = item.p2;
    const heightS = item.height?.toFixed(2);
    const dim1S = (item.dim1 * 100)?.toFixed(0);

    return `[${stairType}] x${stairsCount}mar.(↑${heightS}m) - larg.${dim1S}cm`;
  }
}
