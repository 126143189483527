import React, {useRef, useEffect, useState} from "react";

import {useSelector, useDispatch} from "react-redux";
import useZonesByScene from "../hooks/useZonesByScene";
import useSelectedZone from "../hooks/useSelectedZone";
import {setSelectedZoneId} from "../zonesSlice";

import {Box, IconButton, Paper} from "@mui/material";
import {Refresh} from "@mui/icons-material";

import SelectorAutocomplete from "Components/SelectorAutocomplete";

import ZonesEditor from "../js/ZonesEditor";
import LayerZoneViewer from "./LayerZoneViewer";

export default function ViewerZone({scene, caplaEditor}) {
  const dispatch = useDispatch();
  const containerRef = useRef();
  const zonesEditorRef = useRef();

  // strings

  const zonesS = "Fonds de plan";

  // size

  const containerWidth = containerRef.current?.getBoundingClientRect()?.width;
  const containerHeight = containerRef.current?.getBoundingClientRect()?.height;

  // state

  const [zonesEditorIsLoaded, setZonesEditorIsLoaded] = useState(false);

  // data

  const zones = useZonesByScene(scene);
  const selectedZone = useSelectedZone(scene);
  const os = useSelector((s) => s.ui.openSections);
  const selectedFixedViewer = useSelector((s) => s.ui.selectedFixedViewer);

  // data - depth

  const openNavigation = useSelector((s) => s.navigation.open);
  const openSelection = useSelector((s) => s.selection.openSelectionPanel);
  const openLeftPanel = useSelector((s) => s.navigation.openLeftPanel);
  const navPanelSceneModulesWidth = useSelector(
    (s) => s.navigation.navPanelSceneModulesWidth
  );

  // helper

  const url = selectedZone?.imageUrl;
  const zoneImageWidth = selectedZone?.imageSize?.width;
  const zoneImageHeight = selectedZone?.imageSize?.height;

  const showLayer = os.fixedViewersBox && selectedFixedViewer === "ZONE";

  // handlers

  function handleSelectedZoneChange(zone) {
    dispatch(setSelectedZoneId(zone?.id));
  }

  // init

  function init() {
    zonesEditorRef.current = new ZonesEditor({
      caplaEditor,
      container: "container",
      width: containerWidth,
      height: containerHeight,
      containerElement: containerRef.current,
    });

    setZonesEditorIsLoaded(true);

    caplaEditor.zonesEditor = zonesEditorRef.current;

    window.addEventListener("resize", () => {
      zonesEditorRef.current.updateStageSize();
    });
  }

  useEffect(() => {
    if (caplaEditor) {
      init();
      return () => {
        zonesEditorRef.current.clear();
      };
    }
  }, [caplaEditor]);

  useEffect(() => {
    if (url && containerRef.current) {
      zonesEditorRef.current.resetLayers();
      zonesEditorRef.current.addImage({
        url,
        width: zoneImageWidth,
        height: zoneImageHeight,
      });
    }
  }, [url, containerRef.current]);

  // update stage size

  useEffect(() => {
    if (zonesEditorIsLoaded) {
      zonesEditorRef.current.updateStageSize();
    }
  }, [
    openNavigation,
    openSelection,
    openLeftPanel,
    navPanelSceneModulesWidth,
    selectedFixedViewer,
    zonesEditorIsLoaded,
  ]);

  return (
    <Box sx={{width: 1, height: 1, position: "relative"}}>
      {showLayer && <LayerZoneViewer scene={scene} caplaEditor={caplaEditor} />}

      <Box
        sx={{
          width: 1,
          height: 1,
          position: "relative",
          bgcolor: "background.default",
          boxSizing: "borderBox",
          overflow: "hidden",
        }}
      >
        <div
          id="container"
          ref={containerRef}
          style={{
            //border: "1px solid black",
            boxSizing: "border-box",
            width: "100%",
            height: "100%",
            //zIndex: 1,
          }}
        ></div>
      </Box>
    </Box>
  );
}
