export default function getScaledBbox(x, y, width, height, scale) {
  const centerX = x + width / 2;
  const centerY = y + height / 2;
  const newWidth = width * scale;
  const newHeight = height * scale;
  const newX = centerX - newWidth / 2;
  const newY = centerY - newHeight / 2;

  return {x: newX, y: newY, width: newWidth, height: newHeight};
}
