import computeMeasurementQtyFromFunc from "./computeMeasurementQtyFromFunc";

export default function getArticleQtyMapFromArticleMeasurementsMap({
  articleMeasurementsMap,
  noVoids,
  voidsById,
}) {
  // articleMeasurementsMap = {articleId:{elementTypeId:{measIds:[],measurements:[],func,measAggregated}}}
  //
  const articleQtyMap = {}; // {articleId:{qty,measCount}}
  //
  Object.entries(articleMeasurementsMap).forEach(
    ([articleId, elementTypes]) => {
      let qty = 0;
      let measCount = 0;
      Object.values(elementTypes).forEach(({measurements, measIds, func}) => {
        // qty
        measurements.forEach((measurement) => {
          qty += computeMeasurementQtyFromFunc({
            measurement,
            func,
            noVoids,
            voidsById,
          });
        });
        // measCount
        measCount += measIds.length;
      });
      articleQtyMap[articleId] = {qty, measCount};
    }
  );
  //
  return articleQtyMap;
}
