import {useSelector} from "react-redux";

import sortByNum from "Features/elementTypes/utils/sortByNum";

import getItemsMapById from "Utils/getItemsMapById";
import getElementTypesWithQtiesV2 from "Features/elementTypes/utils/getElementTypesWithQtiesV2";

export default function getMeasurementsByTypeForExcelByType(
  measurements,
  elementTypes
) {
  // data

  let typesWithMeasurements = getElementTypesWithQtiesV2(
    elementTypes,
    measurements
  );

  // export - restricted to created measurements

  let rows = typesWithMeasurements.map((t) => {
    return {
      id: t?.id,
      num: t?.num ? t.num.toString() : "0",
      type: t?.name ? t.name : "-",
      name: t?.name ? t.name : "-",
      count: t?.measCount ?? 0,
      unit: t?.unit,
      quantity: t.mainQty,
      mainQuantity: t.mainQty,
      group: t?.group ? t.group : "-",
      style: t?.style,
      isTitle: t?.isTitle,
    };
  });

  // sort & filter
  rows = rows
    .filter((r) => r.id && (r.isTitle || r.count > 0))
    .sort((a, b) => sortByNum(a.num, b.num))
    .sort((a, b) => a.group.localeCompare(b.group));

  // add groups
  const groups = [];
  const rowsWithGroups = [];
  rows.forEach((row) => {
    if (groups.includes(row.group)) {
      rowsWithGroups.push(row);
    } else if (row.group) {
      groups.push(row.group);
      rowsWithGroups.push({id: row.group, type: row.group, isGroup: true});
      rowsWithGroups.push(row);
    }
  });

  // export - all types

  return rowsWithGroups;
}
