import {forwardRef, useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {fetchRemoteModel} from "Features/viewer3D/viewer3DSlice";

import {setOpenDialogFsLoadingScope} from "../overviewerSlice";

import {
  Dialog,
  IconButton,
  Typography,
  Box,
  Fade,
  LinearProgress,
} from "@mui/material";
import {TransitionProps} from "@mui/material/transitions";
import theme from "Styles/theme";
import {Close, OpenWith} from "@mui/icons-material";

import useScopesByScene from "Features/scopes/hooks/useScopesByScene";
import SectionSelectorScopeGrid from "./SectionSelectorScopeGrid";
import SectionLoadingScene from "./SectionLoadingScene";
import ContainerLoadingBimbox from "./ContainerLoadingBimbox";
import {useParams} from "react-router-dom";
import ContainerScopeInGrid from "Features/scopes/components/ContainerScopeInGrid";
import SectionModelsInScopeLoadingOverview from "./SectionModelsInScopeLoadingOverview";
import ButtonBackToSelectScope from "./ButtonBackToSelectScope";
import ButtonOpenScope from "./ButtonOpenScope";

import useAccessToken from "Features/auth/useAccessToken";
import getModelsInScope from "Features/scopes/utils/getModelsInScope";
import getModelLoadingStatusNum from "../utils/getModelLoadingStatusNum";
import getScopeIsInState from "../utils/getScopeIsInState";
import useSelectedScope from "Features/scopes/hooks/useSelectedScope";
import useFetchSceneModels from "../hooks/useFetchSceneModels";
import getModelIdsInScope from "Features/scopes/utils/getModelIdsInScope";
import getModelsLoadingRatio from "../utils/getModelsLoadingRatio";
import useScopeToOpen from "Features/scopes/hooks/useScopeToOpen";
import {updateLoadingRatioByScopeIdMap} from "../overviewerSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} timeout={{enter: 0, exit: 1000}} />;
});

export default function DialogFsLoadingScope({scene}) {
  const dispatch = useDispatch();
  const {sceneId} = useParams();
  const accessToken = useAccessToken();
  const sceneClientId = scene?.clientId;

  // strings

  const packageS = "Calque de repérage";
  const countS = "# éléments";

  // data - open

  const open = useSelector((s) => s.overviewer.openDialogFsLoadingScope);
  //const open = true;

  // local state

  const [delayedOpen, setDelayedOpen] = useState(false);

  function setOpenWithDelay(open) {
    setTimeout(() => {
      setDelayedOpen(open);
      console.log("CLOSE LOADING DIALOG WITH DELAY");
    }, 1000);
  }

  useEffect(() => {
    if (!open) {
      setOpenWithDelay(open); // close with delay
    } else {
      setDelayedOpen(open); // open without delay
    }
  }, [open]);

  // data

  const models = useSelector((s) => s.viewer3D.models);
  const loadingStatusModelIdsMap = useSelector(
    (s) => s.overviewer.loadingStatusModelIdsMap
  );

  const scopeToOpen = useScopeToOpen({useFixedScope: true});

  // helpers - models in scope

  const modelsInScope = getModelsInScope(models, scopeToOpen);

  // helpers - ratio

  const ratio = getModelsLoadingRatio(modelsInScope, loadingStatusModelIdsMap);

  useEffect(() => {
    dispatch(
      updateLoadingRatioByScopeIdMap({
        scopeId: scopeToOpen.id,
        ratio,
      })
    );
  }, [ratio, scopeToOpen?.id]);
  // helpers - title

  const scopeName = scopeToOpen?.name;

  const title = scopeName;

  // handlers

  function handleClose() {
    dispatch(setOpenDialogFsLoadingScope(false));
  }

  return (
    <Dialog open={delayedOpen} fullScreen TransitionComponent={Transition}>
      <Box
        sx={{
          display: "flex",
          width: 1,
          height: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "common.caplaBlack",
          position: "relative",
          color: "common.white",
        }}
      >
        <Box sx={{color: "common.white"}}>
          <Box
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              zIndex: 10,
              color: "grey.900",
            }}
          >
            <IconButton color="inherit" onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <ContainerLoadingBimbox />
            <Box sx={{width: 1, color: "grey.400", mt: 4, px: 3}}>
              <LinearProgress
                color="inherit"
                variant="determinate"
                value={ratio}
              />
            </Box>
          </Box>

          {/* <Typography variant="h6">{title}</Typography> */}
        </Box>
      </Box>
    </Dialog>
  );
}
