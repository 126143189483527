import {useState} from "react";

import {useDispatch, useSelector} from "react-redux";

import useSelectedArticle from "../hooks/useSelectedArticle";
import {updateArticleTemp} from "../articlesSlice";
import useSelectedArticlesGroup from "../hooks/useSelectedArticlesGroup";

import {Box, Typography} from "@mui/material";

import BlockEditableArticleStyle from "./BlockEditableArticleStyle";
import SectionArticleDetail from "./SectionArticleDetail";
import SectionArticleTypes from "./SectionArticleTypes";
import SectionArticleCoreTypesManagement from "./SectionArticleCoreTypesManagement";
import BlockEditableArticleIsNomenclature from "./BlockEditableArticleIsNomenclature";
import BlockEditableArticleIsTemplate from "./BlockEditableArticleIsTemplate";
import BlockEditableArticleIndex from "./BlockEditableArticleIndex";
import BlockEditableArticleMainPdfLink from "./BlockEditableArticleMainPdfLink";
import BlockEditableArticleDescription from "./BlockEditableArticleDescription";

export default function SectionArticleInSelectionPanel({scene, caplaEditor}) {
  const dispatch = useDispatch();

  // string

  const computationS =
    "Détail du calcul à partir des éléments/ouvrages repérés";

  // data

  const article = useSelectedArticle(scene);
  const articlesGroup = useSelectedArticlesGroup();

  const panelIsLarge = useSelector((s) => s.selection.panelIsLarge);

  // state

  const [showCoreTypes, setShowCoreTypes] = useState(false);

  // handlers

  function handleSaveAsCore() {
    dispatch(updateArticleTemp({id: article.id, coreTypes: article.types}));
  }

  //

  if (!article?.id) return <Box></Box>;

  //
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          width: 1,
          display: "flex",
          flexDirection: "column",
          p: 1,
        }}
      >
        <Box sx={{width: 1}}>
          <BlockEditableArticleDescription article={article} />
        </Box>
        <Box sx={{display: "flex", justifyContent: "end"}}>
          <BlockEditableArticleMainPdfLink
            article={article}
            caplaEditor={caplaEditor}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: 2,
        }}
      >
        <Box sx={{display: "flex", alignItems: "center"}}>
          <BlockEditableArticleIsTemplate article={article} />
          <BlockEditableArticleIsNomenclature article={article} />
        </Box>

        <Box sx={{display: "flex", alignItems: "center"}}>
          <BlockEditableArticleIndex
            article={article}
            articlesGroup={articlesGroup}
          />
          <BlockEditableArticleStyle article={article} />
        </Box>
      </Box>
      <SectionArticleDetail
        article={article}
        variant={panelIsLarge ? "wide" : "normal"}
      />
      <Box
        sx={{
          p: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: 13,
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          {computationS}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          minHeight: 0,
          justifyItems: "stretch",
        }}
      >
        <SectionArticleTypes
          scene={scene}
          options={{showCoreTypes, variant: panelIsLarge ? "wide" : "normal"}}
        />
      </Box>
      <Box>
        <SectionArticleCoreTypesManagement
          article={article}
          showCoreTypes={showCoreTypes}
          onShowCoreTypesChange={setShowCoreTypes}
          onSaveAsCore={handleSaveAsCore}
        />
      </Box>
    </Box>
  );
}
