// import {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {
  Box,
  alpha,
  CircularProgress,
  Button,
  Typography,
  // FormControlLabel,
  // Dialog,
  // DialogContent,
  // Checkbox,
} from "@mui/material";

import {
  setIs3dEnabled,
  // setLoaderScreen,
  // setModelsLoaderOptions,
  setShowScreenModelsLoaderHelper,
  updateScenesStatus,
} from "Features/viewer3D/viewer3DSlice";
// import useSceneModule from "Features/navigation/useSceneModule";
// import useSceneIsLoaded from "Features/scenes/useSceneIsLoaded";
// import Stretch from "Features/ui/components/Stretch";
import useIsMobile from "Features/ui/useIsMobile";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";

export default function ScreenLoadingModelsInViewer3D({scene, caplaEditor}) {
  const dispatch = useDispatch();

  // strings

  const titleS = "Espace 3D";

  const descriptionS = `- Zoom : molette de la souris, 
    - Translation : drag n drop,
    - Orbit: click molette (ou ctrl) + drag n drop,
    - Cible de l'orbit / zoom : click sur un objet`;
  const loadS = "Voir les modèles 3D";

  // const optionsS = "Options";

  // const includeImagesS = "Charger les images";
  // const extrudeS = "Extruder les repérages";

  // data

  // const sceneModule = useSceneModule();

  const showScreenDatagridHelper = useSelector(
    (s) => s.leftPanel.showScreenDatagridHelper
  );

  const allStatus = useSelector((s) => s.viewer3D.scenesStatus);

  // const allModels = useSelector((s) => s.viewer3D.models);
  // const models = allModels.filter((m) => m.sceneClientId === scene?.clientId);
  // const detailedModels = useSelector((s) => s.viewer3D.detailedModels);
  // const modelsToLoad = models.filter((m) => !detailedModels.includes(m.id));

  // const loaderScreen = useSelector((s) => s.viewer3D.loaderScreen);

  // const modelsLoaderOptions = useSelector(
  //   (s) => s.viewer3D.modelsLoaderOptions
  // );
  const groupViewer = useSelector((s) => s.measurements.selectedRessourceGroup);
  const ressources = useRessourcesByScene(scene);

  const showScreenModelsLoaderHelper = useSelector(
    (s) => s.viewer3D.showScreenModelsLoaderHelper
  );

  const isMobile = useIsMobile();

  // useState

  // const initCheckedModels = models.map((m) => m.id);

  // const [_, setCheckedModels] = useState(initCheckedModels);
  // // const [open, setOpen] = useState(false);

  // useEffect(() => {
  //   setCheckedModels(initCheckedModels);
  // }, [models?.length]);

  // helpers

  const status = allStatus[scene?.clientId];
  //
  let display = status !== "loaded" || showScreenModelsLoaderHelper;
  display = false;

  const showDetail = !showScreenDatagridHelper;

  // handlers - dialog

  // function handleOpen() {
  //   setOpen(true);
  // }
  // function handleClose() {
  //   setOpen(false);
  // }
  // function handleImagesOffChange() {
  //   dispatch(
  //     setModelsLoaderOptions({
  //       ...modelsLoaderOptions,
  //       imagesOff: !modelsLoaderOptions?.imagesOff,
  //     })
  //   );
  // }
  // function handleExtrudeOffChange() {
  //   dispatch(
  //     setModelsLoaderOptions({
  //       ...modelsLoaderOptions,
  //       extrudeOff: !modelsLoaderOptions?.extrudeOff,
  //     })
  //   );
  // }
  // handlers

  // function handleLoadEnds() {
  //   dispatch(setLoaderScreen(false));
  // }

  // function handleCloseClick() {
  //   dispatch(setLoaderScreen(false));
  // }

  // function handleCheckedModelsChange(modelsIds) {
  //   setCheckedModels(modelsIds);
  // }

  async function handleLoadClick() {
    const usedRessources = new Set(
      ressources.filter((r) => r.group === groupViewer).map((r) => r.id)
    );
    // await caplaEditor?.measDataManager?.setModelsFromGroup(
    //   groupViewer,
    //   "RESSOURCES",
    //   true,
    //   usedRessources
    // );
    caplaEditor?.measDataManager?.setModelsFromGroup(
      groupViewer,
      "RESSOURCES",
      true,
      usedRessources
    );
    dispatch(setIs3dEnabled(true));
    dispatch(setShowScreenModelsLoaderHelper(false));
    dispatch(
      updateScenesStatus({sceneClientId: scene.clientId, status: "loaded"})
    );
  }

  return (
    <Box
      sx={{
        display: display ? "flex" : "none",
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 60,
        color: "common.white",
        bgcolor: (theme) =>
          alpha(theme.palette.common.caplaBlack, showDetail ? 0.9 : 1),
      }}
    >
      <Box
        sx={{
          width: 1,
          height: 1,
          display: "flex",
          flexDirection: "column",
          position: "relative",
          alignItems: "center",
        }}
      >
        {/* <Box sx={{position: "absolute", bottom: "8px", left: "8px"}}>
          <ButtonDialogSettings editor={editor} />
        </Box> */}
        {/* <IconButton
          color="inherit"
          size="small"
          sx={{position: "absolute", top: "8px", right: "8px"}}
          onClick={handleCloseClick}
        >
          <Close fontSize="small" />
        </IconButton> */}

        <Box
          sx={{
            p: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pt: "150px",
            flexGrow: 1,
            color: "common.white",
          }}
        >
          <Typography variant="h4" gutterBottom>
            {titleS}
          </Typography>
          {!isMobile && (
            <Typography
              variant="body2"
              sx={{
                my: 6,
                whiteSpace: "pre-line",
                visibility: showDetail ? "visible" : "hidden",
              }}
            >
              {descriptionS}
            </Typography>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              visibility: showDetail ? "visible" : "hidden",
            }}
          >
            <Button
              onClick={handleLoadClick}
              variant="outlined"
              color="inherit"
              sx={{mt: 4}}
            >
              {loadS}
            </Button>
            {/* <Button size="small" color="inherit" onClick={handleOpen}>
              {optionsS}
            </Button> */}
          </Box>

          {status === "loading" && <CircularProgress color="inherit" />}
        </Box>
      </Box>

      {/* <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Box sx={{display: "flex", flexDirection: "column"}}>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    onChange={handleImagesOffChange}
                    checked={!modelsLoaderOptions.imagesOff}
                  />
                }
                label={<Typography>{includeImagesS}</Typography>}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    onChange={handleExtrudeOffChange}
                    checked={!modelsLoaderOptions.extrudeOff}
                  />
                }
                label={<Typography>{extrudeS}</Typography>}
              />
            </Box>
          </Box>
        </DialogContent>
      </Dialog> */}
    </Box>
  );
}
