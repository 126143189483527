/*
 * 4 cameras.
 * 2 for the scene, and 2 for the configCenter
 */

import {
  OrthographicCamera,
  PerspectiveCamera,
  Vector3,
  Plane,
  MOUSE,
  Box3,
} from "three";

import fitCameraToObject from "../utils/fitCameraToObject";
import fitOrthoCameraToImageModelObject from "../utils/fitOrthoCameraToImageModelObject";

import Animator from "./Animator";
import Vector from "./Vector";

class Cameras {
  constructor(size, configCenter) {
    this.animator = new Animator();
    const aspect = size.width / size.height;

    this.cam1deltaY = 0.2; // delta with respect to cam2

    this.activeCameraName = "CAMERA"; // by default
    this.canvasSize = size;
    this.savedCamerasState = {}; // used to reset one camera position.

    // perspective camera
    this.camera = new PerspectiveCamera(75, aspect);
    this.camera.position.set(-8, 10, 8);
    this.camera.layers.enable(2); // axis & grid
    this.camera.layers.enable(1); // visible elements
    this.camera.layers.disable(0); // hidden elements
    this.camera.far = 11111;
    this.camera.userData = {isCam: true};

    // perspective camera (config center)
    this.cameraConfig = new PerspectiveCamera(75, aspect);
    this.cameraConfig.position.set(-8, 10, 8);
    this.cameraConfig.layers.enable(2); // axis & grid
    this.cameraConfig.layers.enable(1); // visible elements
    this.cameraConfig.layers.disable(0); // hidden elements

    // ortho cameras
    const width = 100;
    const height = width / aspect;
    this.orthoCamera = new OrthographicCamera(
      width / -2,
      width / 2,
      height / 2,
      height / -2,
      0,
      1000
    );
    this.orthoCamera.position.z = -10;
    this.orthoCamera.layers.enable(2); // axis & grid & helpers
    this.orthoCamera.layers.enable(1); // visible elements
    this.orthoCamera.layers.disable(0); // hidden elements

    /*
     *  ORTHO CONFIG
     */

    this.orthoCameraConfig = new OrthographicCamera(
      width / -2,
      width / 2,
      height / 2,
      height / -2,
      0,
      1000
    );
    const cameraPosition = new Vector3().copy(configCenter.object.position);
    cameraPosition.sub(new Vector3(0, 0, 10));
    this.orthoCameraConfig.position.copy(cameraPosition);

    this.orthoCameraConfig.layers.enable(2); // axis & grid
    this.orthoCameraConfig.layers.enable(1); // visible elements
    this.orthoCameraConfig.layers.disable(0); // hidden elements

    /*
     *  ORTHO MULTIVIEW
     */

    // init with cutCam1 to false
    this.camera1 = new OrthographicCamera(
      width / -2,
      width / 2,
      height / 2,
      height / -2,
      0.1,
      11111
    );
    this.camera1.position.y = 500;

    this.camera1.layers.enable(2); // axis & grid & helpers
    this.camera1.layers.enable(3); // 2D view. Elements visible in 2D view.
    //
    this.camera1.layers.disable(1); // visible elements
    this.camera1.layers.disable(0); // hidden elements
    this.camera1.userData = {isCam1: true};

    // perspective camera 2
    this.camera2 = new PerspectiveCamera(75, aspect);
    this.camera2.position.set(-8, 10, 8);
    this.camera2.layers.enable(2); // axis & grid
    this.camera2.layers.enable(1); // visible elements for camera2 3D view.
    this.camera2.layers.disable(0); // hidden elements
    this.camera2.far = 11111;
    this.camera2.userData = {isCam2: true};
  }

  get isEnabledForConfig() {
    return (
      this.activeCameraName === "CAMERA_CONFIG" ||
      this.activeCameraName === "ORTHO_CAMERA_CONFIG"
    );
  }

  cutCam1(value) {
    if (value) {
      this.camera1.near = 0.1;
      this.camera1.far = 3 + 10.73;
      this.camera1.position.setY(
        this.camera2.position.y + this.cam1deltaY + 10.73
      );
    } else {
      this.camera.near = 0.1;
      this.camera1.far = 11111;
      this.camera1.position.setY(500);
    }
    this.camera1.updateProjectionMatrix();
  }

  updateControls(name, controls) {
    if (name === "ORTHO_CAMERA_CONFIG") {
      controls.minPolarAngle = Math.PI / 2;
      controls.maxPolarAngle = Math.PI / 2;
      controls.minAzimuthAngle = 0;
      controls.maxAzimuthAngle = 0;
    } else if (name === "ORTHO_CAMERA") {
      controls.minPolarAngle = 0;
      controls.maxPolarAngle = 0;
      controls.minAzimuthAngle = Infinity;
      controls.maxAzimuthAngle = Infinity;
    } else {
      controls.minPolarAngle = 0;
      controls.maxPolarAngle = Math.PI;
      controls.minAzimuthAngle = Infinity;
      controls.maxAzimuthAngle = Infinity;
    }

    controls.update();
  }

  freeControls(controls) {
    controls.minPolarAngle = 0;
    controls.maxPolarAngle = Math.PI;
    controls.minAzimuthAngle = Infinity;
    controls.maxAzimuthAngle = Infinity;
  }

  enable(name, controls) {
    if (controls) {
      // at camera initialization, controls is not defined yet.
      // this.saveCameras(controls); // we first save the active camera status.
    }

    const camerasMap = {
      CAMERA: this.camera,
      ORTHO_CAMERA: this.orthoCamera,
      CAMERA_CONFIG: this.cameraConfig,
      ORTHO_CAMERA_CONFIG: this.orthoCameraConfig,
    };
    this.activeCamera = camerasMap[name];
    this.activeCameraName = name;
    if (controls) {
      controls.object = this.activeCamera;
      this.updateControls(name, controls);
      this.resetCamera(name, controls);
    }
  }

  saveCameras(controls) {
    const target = new Vector3();
    const cameraP = new Vector3();
    target.copy(controls.target);
    cameraP.copy(this.activeCamera.position);
    this.savedCamerasState[this.activeCameraName] = {target, cameraP};
  }

  /*
   * restore saved camera
   */
  resetCamera(name, controls) {
    const camerasMap = {
      CAMERA: this.camera,
      ORTHO_CAMERA: this.orthoCamera,
      CAMERA_CONFIG: this.cameraConfig,
      ORTHO_CAMERA_CONFIG: this.orthoCameraConfig,
    };
    const camera = camerasMap[name];
    const prevCamera = this.savedCamerasState[name];
    if (prevCamera) {
      camera.position.copy(prevCamera.cameraP);
      controls.target.copy(prevCamera.target);
      controls.update();
    }
  }

  focusActiveCameraOnEntity(entity, controls) {
    if (this.activeCamera.type === "PerspectiveCamera") {
      fitCameraToObject(this.activeCamera, controls, [entity.object]);
    } else {
      if (this.activeCameraName === "ORTHO_CAMERA") this.freeControls(controls);

      fitOrthoCameraToImageModelObject(
        this.activeCamera,
        entity,
        this.canvasSize,
        controls
      );
    }
  }

  updateOrthoCamera(camera, H, W) {
    camera.left = -W / 2;
    camera.right = W / 2;
    camera.top = H / 2;
    camera.bottom = -H / 2;

    camera.zoom = 1;

    camera.updateProjectionMatrix();
  }

  updateOrthoCameraHeight(camera, height, width) {
    console.log("updateOrthoH", camera, camera.right, camera.top);
    if (camera.right && camera.top) {
      const prevWidth = Math.abs(camera.right - camera.left);
      const prevHeight = Math.abs(camera.top - camera.bottom);
      const aspect = prevWidth / prevHeight;

      console.log("ASPECT1", aspect);

      let W, H;
      if (height && !width) {
        H = height;
        W = height * aspect;
      } else if (width && !height) {
        W = width;
        H = W / aspect;
      }

      camera.left = -W / 2;
      camera.right = W / 2;
      camera.top = H / 2;
      camera.bottom = -H / 2;

      camera.zoom = 1;

      camera.updateProjectionMatrix();
    }
  }

  switchToOrthoCamera({originV, target, normal, width, controls}) {
    // we first save the current camera
    this.saveCameras(controls);

    // we compute the camera
    const aspect = this.canvasSize.width / this.canvasSize.height;

    const height = width / aspect;
    this.orthoCamera.left = -width / 2;
    this.orthoCamera.right = width / 2;
    this.orthoCamera.top = height / 2;
    this.orthoCamera.bottom = -height / 2;

    this.orthoCamera.near = 0; // these parameters are changed when focusing on one image.
    this.orthoCamera.far = 1000; // idem

    this.orthoCamera.updateProjectionMatrix();

    this.orthoCamera.position.copy(originV);

    // we update the controls. The target is the projection of the position to the plane.
    this.freeControls(controls);
    const plane = new Plane(normal.normalize());
    const distance = plane.distanceToPoint(
      new Vector3(target.x, target.y, target.z)
    );
    plane.constant = -distance;

    const focus = new Vector3();
    plane.projectPoint(originV, focus);

    controls.target.copy(focus);
    controls.object = this.orthoCamera;
    controls.update();

    // we update the cameras object

    this.activeCamera = this.orthoCamera;
    this.activeCameraName = "ORTHO_CAMERA";
  }

  updateCamerasAspect(canvasSize) {
    const aspect = canvasSize.width / canvasSize.height;
    this.canvasSize = canvasSize;
    // perspective
    this.cameraConfig.aspect = aspect;
    this.camera.aspect = aspect;

    // ortho
    let width = this.orthoCamera.right - this.orthoCamera.left;
    let height = width / aspect;
    this.orthoCamera.bottom = -height / 2;
    this.orthoCamera.top = +height / 2;

    width = this.orthoCameraConfig.right - this.orthoCameraConfig.left;
    height = width / aspect;
    this.orthoCameraConfig.bottom = -height / 2;
    this.orthoCameraConfig.top = +height / 2;

    // update
    this.camera.updateProjectionMatrix();
    this.cameraConfig.updateProjectionMatrix();
    this.orthoCamera.updateProjectionMatrix();
    this.orthoCameraConfig.updateProjectionMatrix();
  }

  // REMOVE

  exportCurrentCamera(controls) {
    const t = controls.target;
    const c = this.activeCamera.position;
    return {
      name: this.activeCameraName,
      target: {x: t.x, y: t.y, z: t.z},
      position: {x: c.x, y: c.y, z: c.z},
    };
  }

  // use to move the camera

  setActiveCameraTo({name, position, target, controls}) {
    const camerasMap = {
      CAMERA: this.camera,
      ORTHO_CAMERA: this.orthoCamera,
      CAMERA_CONFIG: this.cameraConfig,
      ORTHO_CAMERA_CONFIG: this.orthoCameraConfig,
    };
    if (this.activeCameraName !== name) {
      this.activeCamera = camerasMap[name];
      this.activeCameraName = name;
    }
    const positionV = new Vector3(position.x, position.y, position.z);
    const targetV = new Vector3(target.x, target.y, target.z);

    controls.object = this.activeCamera;
    controls.update();

    this.animator.move(this.activeCamera.position, positionV);
    this.animator.move(controls.target, targetV);
    //this.activeCamera.position.set(position.x, position.y, position.z);
    //controls.target.set(target.x, target.y, target.z);
  }

  initSceneCameraTo(position, controls) {
    this.setActiveCameraTo({
      name: "CAMERA",
      position: position ? position : {x: -20, y: 20, z: 10},
      target: {x: 0, y: 0, z: 0},
      controls: controls,
    });
  }

  focusCameraToImageObject({object, model, controls}) {
    this.activeCamera = this.orthoCamera;
    this.activeCameraName = "ORTHO_CAMERA";

    const cameraP = new Vector3(0, 0, 10); // we look at the image 10 m away from it.
    object.localToWorld(cameraP);
    console.log("cameraP", cameraP);

    const aspect = this.canvasSize.width / this.canvasSize.height;
    const width = model.width;
    const height = width / aspect;
    this.orthoCamera.position.copy(cameraP);

    this.orthoCamera.left = -width / 2;
    this.orthoCamera.right = width / 2;
    this.orthoCamera.top = height / 2;
    this.orthoCamera.bottom = -height / 2;
    this.orthoCamera.near = 9.5;
    this.orthoCamera.far = 10.5;

    this.orthoCamera.updateProjectionMatrix();

    this.freeControls(controls); // what for ?
    controls.object = this.activeCamera;
    controls.target.copy(object.position);
    controls.update();
  }

  fitCameraTo({objects, models, controls}) {
    // either objects or models are not null.
    if (controls?.target) {
      // with camera controls lib, no target...
      const fitOffset = 1.2;
      //
      const bb = new Box3();
      if (objects) for (const object of objects) bb.expandByObject(object);
      if (models) {
        if (models.length > 0) {
          models.forEach((model) => {
            let center = new Vector3(0, 0, 0);
            if (model.center)
              center.set(model.center.x, model.center.y, model.center.z);
            let size = new Vector3(10, 12, 10);
            if (model.width && model.height && model.depth)
              size.set(model.width, model.height, model.depth);
            const BB = new Box3().setFromCenterAndSize(center, size);
            bb.union(BB);
          });
        } else {
          bb.setFromCenterAndSize(
            new Vector3(0, 0, 0),
            new Vector3(10, 12, 10)
          );
        }
      }
      //
      const position = new Vector3();
      const target = new Vector3();
      //
      const size = bb.getSize(new Vector3());
      const center = bb.getCenter(new Vector3());
      //
      const maxSize = Math.max(size.x, size.y, size.z);
      const fitHeightDistance =
        maxSize / (2 * Math.atan((Math.PI * this.camera.fov) / 360));
      const fitWidthDistance = fitHeightDistance / this.camera.aspect;
      const distance =
        fitOffset * Math.max(fitHeightDistance, fitWidthDistance);
      //
      const direction = controls.target
        .clone()
        .sub(this.camera.position)
        .normalize()
        .multiplyScalar(distance);
      //
      target.copy(center);
      position.copy(center.sub(direction));
      //
      this.setActiveCameraTo({
        name: "CAMERA",
        position,
        target,
        controls: controls,
      });
    }
  }

  getActiveCameraProps(controls) {
    let p, t, n;
    try {
      p = this.camera.position.toArray();
      t = controls?.target?.toArray();
      n = this.activeCameraName;
    } catch (e) {
      console.log(e);
    }
    return {p, t, n};
  }

  // used when creating a note
  // TO DO : remove getActiveCameraProps
  getActiveCamera(controls) {
    const p = this.camera.position;
    const t = controls?.target;
    const name = this.activeCameraName;
    return {
      name,
      position: {x: p.x, y: p.y, z: p.z},
      target: {x: t.x, y: t.y, z: t.z},
    };
  }

  resetCameraToOrigin(controls) {
    // perspective camera

    this.camera.far = 10000;
    this.camera.near = 0.1;
    this.camera.updateProjectionMatrix();

    this.camera.position.set(10, 10, 10);
    controls.target.copy(new Vector3(0, 0, 0));
    controls.update();
    console.log(this.camera.projectionMatrix.toArray());

    this.activeCamera = this.camera;
    this.activeCameraName = "CAMERA";
  }
}

export default Cameras;
