import {Vector3, Object3D} from "three";

function get2DPointsInHorizontalImageModelCoord(points, imageModel) {
  const imageY = imageModel.position.y;

  const newPoints = points.map(([x, y]) => {
    const P = new Vector3(x, imageY, y);
    const imageObject = new Object3D();
    imageObject.position.set(
      imageModel.position.x,
      imageModel.position.y,
      imageModel.position.z
    );
    imageObject.rotation.set(
      imageModel.rotation.x,
      imageModel.rotation.y,
      imageModel.rotation.z
    );
    imageObject.updateWorldMatrix();
    imageObject.worldToLocal(P);
    return [P.x, P.y];
  });

  return newPoints;
}

export default function getCoordinatesFromZone1InZone2(
  x,
  y,
  zone1,
  zone2,
  caplaEditor
) {
  const annotMnger = caplaEditor?.editorPdf?.annotationsManager;

  const zone1CenterInPdf = {
    x: zone1.x + zone1.width / 2,
    y: zone1.y + zone1.height / 2,
  };
  const zone1CenterIn3d = {
    x: zone1.position.x,
    y: zone1.position.y,
    z: zone1.position.z,
  };

  // coordinantes in bimbox

  const pointIn3d = annotMnger.getBimboxPoint({
    pagePoint: {x, y}, // the point in the page to localize in 3D
    refPagePoint: zone1CenterInPdf, // point with known position in 3D & page
    refBimboxPoint: zone1CenterIn3d,
    zone: zone1,
    useMarkerHeight: false,
    isVertical: false,
  });

  // coordinates in image zone2
  const polygonInImageModelCoord = get2DPointsInHorizontalImageModelCoord(
    [[pointIn3d.x, pointIn3d.z]],
    zone2
  );

  console.log(
    "debugAFA210",
    x,
    y,
    "zone1",
    zone1,
    "zone2",
    zone2,
    polygonInImageModelCoord
  );

  // coordinates in PDF zone2
  const polygon =
    caplaEditor.editorPdf.annotationsManager.getImagePointsCoordsInPdfRef({
      points: polygonInImageModelCoord,
      zone: zone2,
    });

  // result
  const pointInPdf2 = polygon[0];
  //
  return pointInPdf2;
}
