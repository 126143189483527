import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Box} from "@mui/material";

import DeleteDialog from "Features/ui/components/DeleteDialog";

import useDeleteArticlesGroup from "../hooks/useDeleteArticlesGroup";
import useSelectedArticlesGroup from "../hooks/useSelectedArticlesGroup";

export default function DialogDeleteArticlesGroup({scene, open, onClose}) {
  // state

  const [loading, setLoading] = useState(false);

  // data

  const selectedGroup = useSelectedArticlesGroup();
  const deleteArticlesGroup = useDeleteArticlesGroup();

  // handler

  function handleClose() {
    onClose();
    setLoading(false);
  }

  async function handleDeleteConfirm() {
    console.log("handleDeleteConfirm");
    if (loading) return;
    setLoading(true);
    await deleteArticlesGroup(selectedGroup);
    setLoading(false);
    onClose();
  }

  return (
    <DeleteDialog
      open={open}
      onCancel={handleClose}
      onConfirm={handleDeleteConfirm}
      ressource="articlesGroup"
      loading={loading}
    />
  );
}
