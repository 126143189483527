import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import {Box} from "@mui/material";

import {
  setSelectedFormGroup,
  setIsEditingMeasurement,
  setEditedMeasurement,
} from "../measurementsSlice";
import useDrawingShapeImage from "../useDrawingShapeImage";
import useSelectedElementTypeFromMeasurements from "../useSelectedElementTypeFromMeasurements";
import useFilteredSceneMeasurements from "../useFilteredSceneMeasurements";
import useMeasurementsScopeMode from "../useMeasurementsScopeMode";
// import useSelectedMeasurement from "../useSelectedMeasurement";

import useKvtMeasurementV2 from "Features/kvform/hooks/useKvtMeasurementV2";
import KeyValueForm from "Features/kvform/components/KeyValueForm";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import usePhasesByScene from "Features/phases/hooks/usePhasesByScene";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import useMaterialsByScene from "Features/materials/hooks/useMaterialsByScene";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import Stretch from "Features/ui/components/Stretch";
import useVersionsByScene from "Features/versions/hooks/useVersionsByScene";
import useSceneModule from "Features/navigation/useSceneModule";

import getItemsMapById from "Utils/getItemsMapById";

export default function FormMeasurementInViewerEdit({
  measurement,
  scene,
  caplaEditor,
  viewer,
}) {
  const dispatch = useDispatch();
  const {modelId} = useParams();

  // data

  const sceneModule = useSceneModule();
  const mode = useMeasurementsScopeMode(scene, viewer);
  const drawingShape = useDrawingShapeImage(measurement?.drawingShape);

  const isEditing = useSelector((s) => s.measurements.isEditingMeasurement);
  const isEditingGeometry = useSelector(
    (s) => s.measurements.isEditingMeasurementGeometry
  );
  const zonesById = useZonesByScene(scene, {variant: "map"});
  const sectors = useSectorsByScene(scene);
  const rooms = useRoomsByScene(scene);
  const materials = useMaterialsByScene(scene);
  const phases = usePhasesByScene(scene);
  const ressources = useRessourcesByScene(scene);
  const measurements = useSelector((s) => s.measurements.items);
  const selectedFormGroup = useSelector(
    (s) => s.measurements.selectedFormGroup
  );
  const elementTypes = useElementTypesBySceneProxy(scene);
  const versions = useVersionsByScene(scene);

  // helper - elementType

  const elementTypesById = getItemsMapById(elementTypes);
  const elementType = elementTypesById[measurement?.elementTypeId];

  // helpers

  let zone;
  if (measurement?.zoneId) zone = zonesById[measurement?.zoneId];
  let sector;
  if (measurement?.sectorId)
    sector = sectors.find((s) => s.id === measurement?.sectorId);
  let room;
  if (measurement?.roomId)
    room = rooms.find((r) => r.id === measurement?.roomId);
  let material;
  if (measurement?.materialId)
    material = materials.find((m) => m.id === measurement?.materialId);
  let phase;
  if (measurement?.phaseId)
    phase = phases.find((p) => p.id === measurement?.phaseId);
  let ressource;
  if (measurement?.ressourceId)
    ressource = ressources.find((r) => r.id === measurement?.ressourceId);
  let relatedM;
  if (measurement?.relatedMId)
    relatedM = measurements.find((m) => m.id === measurement?.relatedMId);
  relatedM = {
    ...relatedM,
    name: relatedM?.codeName ? relatedM.codeName : "- ? -",
  };
  let mType;
  if (measurement?.elementTypeId)
    mType = elementTypes.find((t) => t.id === measurement?.elementTypeId);
  let version;
  if (measurement?.versionId)
    version = versions.find((v) => v.id === measurement?.versionId);

  const zFrom = measurement.zFrom;

  const item = {
    ...measurement,
    helperImage: drawingShape,
    zone,
    sector,
    room,
    material,
    relatedM,
    phase,
    ressource,
    offset: !zFrom || zFrom === "offset" ? measurement.offset : null,
    zInf: !zFrom || zFrom === "zInf" ? measurement.zInf : null,
    zSup: !zFrom || zFrom === "zSup" ? measurement.zSup : null,
    elementType: mType,
    version: measurement.version ?? version,
    versionId: measurement.versionId ?? measurement.version?.id,
  };

  // helper - manager

  const annotMnger = caplaEditor?.editorPdf?.annotationsManager;
  const mnger = annotMnger?.measurementsPdfManager;

  // helper - kvt

  const kvt = useKvtMeasurementV2(scene, item);

  // helper - net quantities

  if (item.voids?.length > 0) {
    kvt.fields = [
      ...kvt.fields.slice(0, 36),
      {
        group: "QUANTITIES",
        type: "section",
        key: "voids",
        name: "Quantités nettes",
      },
      {
        group: "QUANTITIES",
        type: "number",
        key: "voidsCount",
        name: "Nombre de vides",
      },
      {
        group: "QUANTITIES",
        type: "number",
        key: "lengthNet",
        name: "Longueur nette (m)",
      },
      {
        group: "QUANTITIES",
        type: "number",
        key: "areaNet",
        name: "Aire nette (m²)",
      },
      {
        group: "QUANTITIES",
        type: "number",
        key: "volumeNet",
        name: "Volume net (m³)",
      },
      ...kvt.fields.slice(36),
    ];
  }

  // helper - hidden fields

  const hiddenFields = [];

  // helper - lockedFields

  const lockedFields = [];
  lockedFields.push("zone");
  if (elementType?.dim1 && !isEditing) lockedFields.push("dim1");
  if (elementType?.dim2 && !isEditing) lockedFields.push("dim2");

  // helper - canEdit

  // const canEdit =
  //   !isEditingGeometry &&
  //   (sceneModule === "MEASUREMENTS" ||
  //     (sceneModule === "EDIT_MEASUREMENTS" &&
  //       measurement.measurementsModelId === modelId));

  const canEdit = !isEditingGeometry;

  // handlers

  function handleChange(m) {
    console.log(
      "[DEBUG 0204-01] update m",
      m
      //m?.path3d3[0] && m.path3d3[0][1]
    );

    if (!isEditing) {
      let res = [];
      if (elementType?.res)
        res = elementType.res.map(({resId}) => ({resId, off: false}));
      const editedMeasurement = {
        ...measurement,
        res,
      };

      //
      mnger.setIsEditing(true);
      mnger.setEditedMeasurement(editedMeasurement);
      dispatch(setIsEditingMeasurement(true));
      dispatch(setEditedMeasurement(editedMeasurement));
    }

    const applyColor = m.color !== measurement.color;
    if (m.elementTypeId !== measurement.elementTypeId) {
      const newElementType = elementTypes.find((t) => t.id === m.elementTypeId);
      m.elementType = newElementType;
      m.drawingShape = newElementType?.drawingShape;
      m.color = newElementType?.color;

      let maxCode = measurements
        ?.filter((m) => m?.codeName.startsWith(newElementType?.num))
        ?.map((m) => parseInt(m?.codeName.split("-")[1]));
      if (maxCode)
        maxCode = `${newElementType?.num}-${Math.max(...maxCode) + 1}`;
      else maxCode = `${newElementType.num}-001`;
      if (maxCode === `${newElementType.num}--Infinity`)
        maxCode = `${newElementType.num}-001`;
      m.codeName = maxCode;
      m.res = m.elementType.res;
      m.unit = m.elementType.unit;
      m.material = m.elementType?.material ? m.elementType?.material : null;
      // if (!applyColor) {
      //   m.color = m.material
      //     ? m.material.color
      //     : m.elementType?.color
      //     ? m.elementType.color
      //     : m.color;
      // }
    }
    if (m.material !== measurement.material) {
      if (!applyColor)
        m.color = m.material ? m.material.color : m.elementType?.color;
    }
    const newM = {
      ...m,
      relatedMId: m.relatedM?.id,
      phaseId: m.phase?.id,
      sectorId: m.sector?.id,
      roomId: m.room?.id,
      materialId: m.material?.id,
      ressourceId: m.ressource?.id,
      versionId: m.version?.id,
    };

    if (typeof newM.offset === "number" && newM.offset !== item.offset) {
      newM.zInf = null;
      newM.zSup = null;
      newM.zFrom = "offset";
    } else if (typeof newM.zInf === "number" && newM.zInf !== item.zInf) {
      newM.offset = null;
      newM.zSup = null;
      newM.zFrom = "zInf";
    } else if (typeof newM.zSup === "number" && newM.zSup !== item.zSup) {
      newM.offset = null;
      newM.zInf = null;
      newM.zFrom = "zSup";
    }
    if (newM.heightE || newM.heightN) {
      if (!newM.height) {
        if (newM.heightE) {
          if (newM.heightN) newM.height = newM.heightE + newM.heightN;
          else newM.height = newM.heightE;
        } else newM.height = newM.heightN;
      } else if (!newM.heightE) {
        newM.heightE = newM.height - newM.heightN;
      } else if (!newM.heightN) {
        newM.heightN = newM.height - newM.heightE;
      }
      if (newM.height !== item.height && typeof newM.height === "number") {
        newM.heightE = null;
        newM.heightN = null;
      } else if (
        newM.heightE !== item.heightE &&
        typeof newM.heightE === "number"
      ) {
        newM.heightN = newM.height - newM.heightE;
      } else if (
        newM.heightN !== item.heightN &&
        typeof newM.heightN === "number"
      ) {
        newM.heightE = newM.height - newM.heightN;
      }
    }
    console.log("[DEBUG 0204] meas change", newM?.zInf, newM);

    mnger.updateAnnotation(newM, {zonesById}); // the editedMeasurement is updated in state by the mnger as well
  }

  function handleGroupChange(group) {
    dispatch(setSelectedFormGroup(group));
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: 1,
      }}
    >
      <Stretch>
        <KeyValueForm
          caplaEditor={caplaEditor}
          scene={scene}
          item={item}
          template={kvt}
          onChange={handleChange}
          hiddenFields={hiddenFields}
          lockedFields={lockedFields}
          canEdit={canEdit}
          group={selectedFormGroup}
          onGroupChange={handleGroupChange}
        />
      </Stretch>
    </Box>
  );
}
