import useSceneModule from "Features/navigation/useSceneModule";
import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import SectionActionsMeasurements from "./SectionActionsMeasurements";

export default function SectionLeftPanelActionsMeasurements({
  scene,
  caplaEditor,
}) {
  // data

  const sceneModule = useSceneModule();
  const selectedTab = useSelector((s) => s.leftPanel.tabInEditMeasurements);

  // actions - main

  const actionsMainKeys = [
    selectedTab === "ELEMENT_TYPES" ? "createElementType" : "",
    // "updateRessourcesFromTypes",
    selectedTab === "ARTICLES" ? "refreshArticlesQties" : "",
    "refresh3D",
    "automation",
  ];
  const actionsMoreKeys = [
    "copyMeasurements",
    "pasteMeasurements",
    "divider",
    "extractMeasFromPdf",
    "divider",
    "useTypeColor",
    "cleanMeasurements",
  ];

  return (
    <Box>
      {sceneModule === "EDIT_MEASUREMENTS" && (
        <SectionActionsMeasurements
          scene={scene}
          caplaEditor={caplaEditor}
          actionsMainKeys={actionsMainKeys.filter((k) => k)}
          actionsMoreKeys={actionsMoreKeys}
        />
      )}
    </Box>
  );
}
