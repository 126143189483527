import {useSelector, useDispatch} from "react-redux";

import {Box} from "@mui/material";

import {setSelectedMeasurementIds} from "Features/measurements/measurementsSlice";

import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";

import SectionArticlesInLeftPanel from "Features/articles/components/SectionArticlesInLeftPanel";
import SectionElementTypesInLeftPanel from "Features/elementTypes/components/SectionElementTypesInLeftPanel";
import ListMeasurements from "Features/measurements/components/ListMeasurements";

import getItemsMapById from "Utils/getItemsMapById";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import ButtonDialogDownloadExcelMeasurements from "Features/measurements/components/ButtonDialogDownloadExcelMeasurements";

export default function SectionMeasurementsInLeftPanelV2({scene, caplaEditor}) {
  const dispatch = useDispatch();

  // data

  const tab = useSelector((s) => s.leftPanel.tabInEditMeasurements);

  const measurements = useSelector((s) => s.measurements.measShowedInViewer3d);
  const elementTypes = useElementTypesBySceneProxy(scene);
  const zonesById = useZonesByScene(scene, {variant: "map"});

  const selectedMeasurementIds = useSelector(
    (s) => s.measurements.selectedMeasurementIds
  );

  // helper - measurementsWithType

  const elementTypesMap = getItemsMapById(elementTypes);

  const measurementsWithType = measurements.map((m) => ({
    ...m,
    elementType: elementTypesMap[m.elementTypeId],
  }));

  // handlers

  function handleSelectionChangeMeasurements(ids) {
    dispatch(setSelectedMeasurementIds(ids));
    //
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        //border: "2px solid red",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        //justifyItems: "stretch",
        minHeight: 0, // make it works !!!
      }}
    >
      {tab === "ELEMENT_TYPES" && (
        <SectionElementTypesInLeftPanel
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
      {tab === "ARTICLES" && (
        <SectionArticlesInLeftPanel scene={scene} caplaEditor={caplaEditor} />
      )}
      {tab === "MEASUREMENTS" && (
        <ListMeasurements
          caplaEditor={caplaEditor}
          multiple={true}
          measurements={measurementsWithType}
          zonesById={zonesById}
          selection={selectedMeasurementIds}
          onSelectionChange={handleSelectionChangeMeasurements}
        />
      )}
      {tab !== "ARTICLES" && (
        <Box sx={{display: "flex"}}>
          <ButtonDialogDownloadExcelMeasurements scene={scene} />
        </Box>
      )}
    </Box>
  );
}
