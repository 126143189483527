import {useParams, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";

import {setSelectedPdfModelId} from "Features/viewer3D/viewer3DSlice";
import {setSelectedMeasurementsModelId} from "Features/measurements/measurementsSlice";

import useSelectedScope from "Features/scopes/hooks/useSelectedScope";
import useIsStagingEnv from "./useIsStagingEnv";
import useSceneModule from "Features/navigation/useSceneModule";
import useSceneModulePathMap from "Features/navigation/useSceneModulePathMap";

export default function useUpdateInitialEntitiesSelectionByScope() {
  const isStagingEnv = useIsStagingEnv();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {modelId: modelIdFromPath, sceneId} = useParams();
  //
  const pathMap = useSceneModulePathMap(sceneId);
  //
  const selectedScope = useSelectedScope();
  const sceneModule = useSceneModule();
  //
  const selectedPdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);

  const selectedMeasurementsModelId = useSelector(
    (s) => s.measurements.selectedMeasurementsModelId
  );
  //

  // helpers - should change selection

  const changePdf =
    selectedPdfModelId &&
    !selectedScope?.data?.inputIds?.includes(selectedPdfModelId);

  const changePackage =
    (selectedMeasurementsModelId || modelIdFromPath) &&
    selectedScope &&
    !selectedScope?.data?.packageIds?.includes(selectedMeasurementsModelId) &&
    !selectedScope?.data?.packageIds?.includes(modelIdFromPath);

  const goToPackages = sceneModule === "EDIT_MEASUREMENTS" && changePackage;

  // helpers - default selection

  const defaultSelectedPdfModelId = Array.isArray(selectedScope?.data?.inputIds)
    ? selectedScope?.data.inputIds[0]
    : null;

  // v warning : should depend on the pdf...
  const defaultPackageId = Array.isArray(selectedScope?.data?.packageIds)
    ? selectedScope.data.packageIds[0]
    : null;

  // update

  useEffect(() => {
    if (isStagingEnv) {
      if (goToPackages) {
        console.log("DEBUG 23-05 AA");
        //navigate(pathMap["PACKAGES"]);
      }
      if (changePdf) {
        //dispatch(setSelectedPdfModelId(defaultSelectedPdfModelId));
      }
      if (changePackage) {
        //dispatch(setSelectedMeasurementsModelId(null));
      }
    }
  }, [changePackage, changePdf]);
}
