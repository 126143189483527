import {client} from "API/capla360";

export async function fetchReportsService({accessToken, sceneId}) {
  const response = await client.get(`/scenes/${sceneId}/bimboxreports/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const sharedReports = response.data;
  const reports = sharedReports.map((r) => {
    return {...r, sceneId: r.scene};
  });
  return {items: reports, sceneId};
}

export async function createViewService({accessToken, view}) {
  const newView = {...view};
  const sceneId = view.sceneId;
  delete newView.scene;
  newView.scene = view.sceneId;
  const response = await client.post(
    `scenes/${sceneId}/bimboxreports/`,
    newView,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {item: {...response.data, sceneId}};
}

export async function fetchViewService({accessToken, sceneId, viewId}) {
  const response = await client.get(
    `/scenes/${sceneId}/bimboxreports/${viewId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {item: {...response.data, sceneId}};
}

export async function updateViewService({
  accessToken,
  viewId,
  sceneId,
  updates,
}) {
  const response = await client.put(
    `/scenes/${sceneId}/bimboxreports/${viewId}/`,
    updates,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const updatedView = response.data;
  return {item: {...updatedView, sceneId: updatedView.scene}};
}

export const deleteViewService = async ({accessToken, viewId, sceneId}) => {
  await client.delete(`/scenes/${sceneId}/bimboxreports/${viewId}/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return viewId;
};
