import getItemRankFromNum from "./getItemRankFromNum";
import getItemsMapById from "./getItemsMapById";

export default function flattenItemsWithChildren(items) {
  let flatItems = [];
  const itemIdOkMap = {};
  //
  const itemsMap = getItemsMapById(items);
  //
  console.log("debugafa flattenItemsWithChildren", items);
  //
  function flattenItems(items) {
    items.forEach((item) => {
      if (item && item.children) {
        if (!itemIdOkMap[item.id + item.num]) {
          flatItems.push(item);
          itemIdOkMap[item.id + item.num] = true;
          const childrenWithChildren = item.children.map(
            (child) => itemsMap[child.id] || child // if child is not in items, keep it (other)
          );
          flattenItems(childrenWithChildren);
        }
      } else if (!itemIdOkMap[item?.id + item?.num]) {
        flatItems.push(item);
        itemIdOkMap[item.id + item.num] = true;
      }
    });
  }

  const itemsOfRank1 = items.filter((item) => getItemRankFromNum(item) === 1);

  flattenItems(itemsOfRank1);

  console.log("flattenItems", items, flatItems);

  return flatItems;
}
