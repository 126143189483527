import {useDispatch, useSelector} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {setMainSection, setScreenshot} from "Features/viewer3D/viewer3DSlice";
import useMeasurementsFiltersAndShortcuts from "Features/measurements/useMeasurementsFilters";

export default function useTakeEditorScreenshot({
  caplaEditor,
  scene,
  target = {},
  position = {},
  openAnnotator = true,
}) {
  // target = {listingType}
  const dispatch = useDispatch();

  // data

  const viewMode = useSelector((state) => state.viewer3D.viewMode);
  const models = useSelector((s) => s.viewer3D.models).filter(
    (m) => m.sceneClientId === scene?.clientId && m.enabled
  );
  const loadedImageModelsIds = useSelector(
    (s) => s.viewer3D.modelsWithInitialTexture
  );

  // used in POIs
  const visibleModelsIds = models
    .filter((m) => !m.hidden && loadedImageModelsIds.includes(m.id))
    .map((m) => m.id);

  const visibleModels = models.filter(
    (m) => !m.hidden && loadedImageModelsIds.includes(m.id)
  );

  const imageModelIds = models
    .filter((m) => !m.hidden && m.type === "IMAGE")
    .map((m) => m.id);

  const [measurementsFilters] = useMeasurementsFiltersAndShortcuts(scene);

  const os = useSelector((s) => s.ui.openSections);

  // handlers

  async function handleClick() {
    // first render
    if (
      caplaEditor?.editor3d &&
      os.viewer3D &&
      (viewMode === "3D" || viewMode === "MULTIVIEWS")
    ) {
      let camera;
      let mode;
      if (viewMode === "3D") {
        camera = caplaEditor?.editor3d.cameras.camera;
        mode = "3D";
        console.log("viewMode3", camera);
      } else if (viewMode === "MULTIVIEWS") {
        camera = caplaEditor?.editor3d.cameras.camera1;
        mode = "2D";
      }
      caplaEditor?.editor3d.renderer.render(
        caplaEditor?.editor3d.scene,
        camera
      );

      const imagesTransfos = visibleModels.map((model) => {
        const entity = caplaEditor?.editor3d.getEntity(model.id);
        const showMainPart = model?.showMainPart;
        const delta = entity?.delta;
        const opacity = entity?.opacity;
        return {imageModelId: model.id, showMainPart, delta, opacity};
      });

      const options = {
        target,
        fromViewMode: mode,
        position,
        camera: caplaEditor?.editor3d.sceneEditor.parseCamera(mode),
        sceneContext: {
          visibleModelsIds,
          measurementsFilters,
          imageModelIds,
          imagesTransfos,
        },
      };

      // then cature
      const canvas = caplaEditor?.editor3d.canvas;
      console.log("canvas", canvas);
      canvas.toBlob(async (blob) => {
        const fileName = `bb-${nanoid()}.png`;
        const newFile = new File([blob], fileName, {type: "image/png"});
        if (openAnnotator) dispatch(setMainSection("SCREENSHOTVIEWER"));
        console.log("screenshotURL", URL.createObjectURL(newFile), newFile);
        dispatch(setScreenshot({url: URL.createObjectURL(newFile), options}));
      });
    } else if (caplaEditor?.editorPdf && os.fixedViewersBox) {
      let pdfContext = caplaEditor?.editorPdf.getPdfContext();
      pdfContext = {...pdfContext, measurementsFilters};
      const url = await caplaEditor?.editorPdf.loadViewportImageAsync();
      const options = {
        fromViewMode: "PDF",
        position,
        pdfContext,
        target,
      };
      if (openAnnotator) dispatch(setMainSection("SCREENSHOTVIEWER"));
      dispatch(setScreenshot({url, options}));
    } else if (viewMode === "MAP" && caplaEditor?.editor3d) {
      const canvas =
        await caplaEditor?.editor3d.mapEditor.getScreenshotCanvas();
      canvas.toBlob(async (blob) => {
        const fileName = `bb-${nanoid()}.png`;
        const newFile = new File([blob], fileName, {type: "image/png"});
        if (openAnnotator) dispatch(setMainSection("SCREENSHOTVIEWER"));
        dispatch(setScreenshot({url: URL.createObjectURL(newFile)}));
      });
    }
  }

  return handleClick;
}
