import {useState} from "react";

import {Box, Button} from "@mui/material";
import {Download} from "@mui/icons-material";

import DialogDownloadExcelMeasurements from "./DialogDownloadExcelMeasurements";

export default function ButtonDialogDownloadExcelMeasurements({scene}) {
  // string

  const label = "Télécharger l'excel";

  // state

  const [open, setOpen] = useState(false);

  // handlers

  function handleClose() {
    setOpen(false);
  }
  function handleClick() {
    setOpen(true);
  }

  return (
    <>
      {open && (
        <DialogDownloadExcelMeasurements
          open={open}
          onClose={handleClose}
          scene={scene}
        />
      )}
      <Box sx={{p: 1, width: 1}}>
        <Button
          startIcon={<Download />}
          fullWidth
          onClick={handleClick}
          color="secondary"
          variant="contained"
        >
          {label}
        </Button>
      </Box>
    </>
  );
}
