import {useSelector} from "react-redux";
import useSelectedScope from "Features/scopes/hooks/useSelectedScope";
import useSelectedVersionForMeasurements from "Features/versions/hooks/useSelectedVersionForMeasurements";
import usePackagesByScene from "Features/elementPackages/usePackagesByScene";

import getMeasurementsFromPackages from "./utils/getMeasurementsFromPackages";
import useSelectedMeasurementsModel from "./useSelectedMeasurementsModel";

export default function useFilteredMeasurementsFromPackages({
  scene,
  filterPackagesByScope,
  filterPackagesByVersion,
  filterPackagesBySelectedPdf,
  filterPackagesBySelectedMeasurementsModel,
}) {
  //

  console.time("debugtime filteredMeasurements");

  // data inital packages

  const options = {
    filterByScope: filterPackagesByScope,
    filterByVersion: filterPackagesByVersion,
    filterByPdf: filterPackagesBySelectedPdf,
  };
  let packages = usePackagesByScene(scene, options);

  // data

  const selectedPdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);
  const selectedVersion = useSelectedVersionForMeasurements();
  const selectedScope = useSelectedScope();
  const selectedMeasurementsModel = useSelectedMeasurementsModel();

  // filter scope

  if (filterPackagesByScope && selectedScope?.data) {
    packages = packages.filter((p) =>
      selectedScope.data.packageIds?.includes(p.id)
    );
  }

  // filter version

  if (filterPackagesByVersion && selectedVersion?.id) {
    packages = packages.filter(
      (p) => !p.revisionIds || p?.revisionIds?.includes(selectedVersion.id)
    );
  }

  // filter by pdf

  if (filterPackagesBySelectedPdf) {
    packages = packages.filter(
      (model) => model?.fromModel.modelId === selectedPdfModelId
    );
  }

  // filter measurementsModel(edition)

  if (
    filterPackagesBySelectedMeasurementsModel &&
    selectedMeasurementsModel?.id
  ) {
    packages = [selectedMeasurementsModel];
  }

  // measurements

  const measurements = getMeasurementsFromPackages(packages);

  console.timeEnd("debugtime filteredMeasurements");

  return measurements;
}
