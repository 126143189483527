import {useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";

import useGetArticleQtyMap from "./useGetArticleQtyMap";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";

import {
  setArticleQtyMap,
  setArticleQtiesMapBySector,
  triggerLastComputedQties,
} from "../articlesSlice";

import getSectorMeasurementsMap from "../utils/getSectorMeasurementsMap";
import getArticlesForDebug from "../utils/getArticlesForDebug";

export default function useAutoUpdateArticleQtyMap({
  scene,
  articles,
  measurements,
  bySector = false,
}) {
  const dispatch = useDispatch();
  //
  const [getArticleQtyMap, functionUpdatedAt] = useGetArticleQtyMap(scene); // /!\ getArticleQtyMap may change.Base on elementTypes & sectors.
  //
  const lastComputedQtiesAt = useSelector(
    (s) => s.articles.lastComputedQtiesAt
  );

  // first computation = set lastComputedQtiesAt as not null

  const selectedArticlesGroupId = useSelector(
    (s) => s.articles.selectedArticlesGroupId
  );
  const dataAreReady =
    articles.filter(
      (article) => article.articlesGroupId === selectedArticlesGroupId
    ).length > 0 && measurements.length > 0;

  useEffect(() => {
    if (dataAreReady) {
      dispatch(triggerLastComputedQties());
    }
  }, [dataAreReady, selectedArticlesGroupId]);

  //

  useEffect(() => {
    if (lastComputedQtiesAt) {
      // global
      console.log(
        "debugafa getArticleQtyMap",
        articles.filter((a) => a.fromTemplate)
      );
      const articleQtyMap = getArticleQtyMap({articles, measurements});

      // by sector

      const articlesQtiesMapBySector = {};

      if (bySector) {
        const sectorMeasurementsMap = getSectorMeasurementsMap(measurements);
        const measSectorIds = Object.keys(sectorMeasurementsMap);

        Object.entries(sectorMeasurementsMap).forEach(
          ([sectorId, measurements]) => {
            articlesQtiesMapBySector[sectorId] = getArticleQtyMap({
              articles,
              measurements,
            });
          }
        );
        articlesQtiesMapBySector["total"] = articleQtyMap;
      }

      // dispatch

      dispatch(setArticleQtyMap(articleQtyMap));
      if (bySector) {
        dispatch(setArticleQtiesMapBySector(articlesQtiesMapBySector));
      }
    }
  }, [lastComputedQtiesAt]); // articles.length : to trigger with subArticles.
}
