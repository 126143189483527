import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {
  fetchSamplesService,
  createSampleService,
  fetchSampleService,
  updateSampleService,
  deleteSampleService,
} from "./services";

export const createSample = createAsyncThunk(
  "samples/createSample",
  createSampleService
);

export const fetchSamples = createAsyncThunk(
  "samples/fetchSamples",
  fetchSamplesService
);

export const fetchSample = createAsyncThunk(
  "samples/fetchSample",
  fetchSampleService
);

export const updateSample = createAsyncThunk(
  "samples/updateSample",
  updateSampleService
);
export const deleteSample = createAsyncThunk(
  "samples/deleteSample",
  deleteSampleService
);

const samplesSlice = createSlice({
  name: "samples",
  initialState: {items: [], itemsMap: {}, selectedSampleId: null},
  reducers: {
    setSelectedSampleId: (state, action) => {
      state.selectedSampleId = action.payload;
    },
  },
  extraReducers: {
    [createSample.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.items.push(item);
    },
    [fetchSamples.fulfilled]: (state, action) => {
      const {items} = action.payload;
      state.items = items;
    },
    [fetchSample.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.items = state.items.filter((i) => i.id !== item.id);
      state.items.push(item);
    },
    [updateSample.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.items = state.items.filter((i) => i.id !== item.id);
      state.items.push(item);
    },
    [deleteSample.fulfilled]: (state, action) => {
      const sampleId = action.payload;
      state.items = state.items.filter((i) => i.id !== sampleId);
    },
  },
});
export const {setSelectedSampleId} = samplesSlice.actions;

export default samplesSlice.reducer;
