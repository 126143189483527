import getQtyWithDec from "Features/ressources/utils/getQtyWithDec";
import stringifyArticleTypes from "./stringifyArticleTypes";

export default function articlesToExcelArticlesQties(
  articles,
  articlesQtiesMapBySector,
  sectors,
  elementTypesGroups
) {
  const articleQtyMapTotal = articlesQtiesMapBySector["total"];

  return articles.map((article) => {
    // compute qties by sector

    const articleQtiesBySectorArray = [];
    sectors.forEach((sector, index) => {
      const articleQtyMap = articlesQtiesMapBySector[sector.id];
      if (
        typeof articleQtyMap === "object" &&
        Object.entries(articleQtyMap).length > 0
      ) {
        const qty = articleQtyMap[article.id]?.qty;
        articleQtiesBySectorArray[index] = getQtyWithDec(qty, article.dec);
      }
    });

    const qtyTotal = articleQtyMapTotal
      ? articleQtyMapTotal[article.id]?.qty
      : null;

    const articleQty =
      article.hardcodedQty ?? getQtyWithDec(qtyTotal, article.dec);

    article = {...article, qty: articleQty};

    const excelRow = [
      article.id,
      article.num,
      article.name,
      article.isSubArticle ? "-" : article.code,
      stringifyArticleTypes(article.coreTypes, elementTypesGroups),
      article.description,
      article.unit,
      articleQty,
      article.refQty,
      "-",
      article.comment,
      "",
      ...articleQtiesBySectorArray,
    ];
    return {excelRow, article};
  });
}
