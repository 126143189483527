/*
 * used in dialog when displaying more info
 */

import {useSelector} from "react-redux";

import {Box, alpha, Typography} from "@mui/material";

import FieldIssueTitleInSelectionPanel from "./FieldIssueTitleInSelectionPanel";
import FieldIssueImageInSelectionPanel from "./FieldIssueImageInSelectionPanel";
import ButtonShowIssueImageOverlay from "./ButtonShowIssueImageOverlay";
// import SectionIssueDetailCardNoContent from "./SectionIssueDetailCardNoContent";
// import {updateIssue} from "../issuesSlice";

import useAccessToken from "Features/auth/useAccessToken";
// import usePageType from "Features/navigation/usePageType";

const defaultContent = [
  {
    type: "paragraph",
    children: [
      {
        text: "",
      },
    ],
  },
];

export default function FormIssueDetailInSelectionPanel({
  scene,
  caplaEditor,
  issue,
  // onClose,
  viewer,
  isEditing,
  onChange,
}) {
  // const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const noSelectedIssueS = "Pas de note sélectionnée";

  // data

  const selectedIssuesListingId = useSelector(
    (s) => s.issues.selectedIssuesListingId
  );
  const os = useSelector((s) => s.ui.openSections);

  // const extractedText = useSelector((s) => s.pdf.extractedText);

  // helpers

  const title = issue?.title;
  const content = issue?.content ? issue.content : defaultContent;

  // helper - noContent

  const noContent =
    content.length === 1 &&
    content[0].children.length === 1 &&
    Object.keys(content[0].children[0]).length === 1 &&
    Object.keys(content[0].children[0])[0] === "text" &&
    content[0].children[0].text.length === 0;
  // helpers - listingId

  let listingId = issue?.listingId ? issue.listingId : issue?.listing;
  if (!listingId) listingId = selectedIssuesListingId;

  // helper - showImageOverview

  const showImageOverlayButton =
    !isEditing &&
    (issue?.fromViewMode === "2D" || issue?.fromViewMode === "3D");

  // handlers

  function handleTitleChange(title) {
    onChange({...issue, title});
  }

  function handleImageChange(imageUrl) {
    onChange({...issue, imageUrl});
  }

  function handleContentChange(content) {
    let c = content;
    if (!c) c = defaultContent;
    onChange({...issue, content: c});
  }
  return (
    <Box>
      {!issue && (
        <Box
          sx={{
            p: 1,
            pl: 2,
            display: "flex",
            alignItems: "top",
            width: 1,
            //justifyContent: "space-between",
          }}
        >
          <Typography sx={{p: 1, fontSize: 13, color: "text.secondary"}}>
            {noSelectedIssueS}
          </Typography>
        </Box>
      )}
      {issue && (
        <Box
          sx={{width: 1, pb: 2, overflow: "auto"}}
          onClick={(e) => e.stopPropagation()}
        >
          <Box
            sx={{
              display: "flex",
              width: 1,
              justifyContent: "space-between",
              alignItems: "top",
            }}
          >
            {(isEditing || (!isEditing && issue?.imageUrl) || !issue?.id) && (
              <Box sx={{mb: 2, width: 1, position: "relative"}}>
                <FieldIssueImageInSelectionPanel
                  imageUrl={issue?.imageUrl}
                  onChange={handleImageChange}
                  // issueId={issue?.id}
                  listingId={listingId}
                  isEditing={isEditing}
                  fromViewMode={issue?.fromViewMode}
                  context={issue?.context}
                  editor3d={caplaEditor?.editor3d}
                  scene={scene}
                />

                {showImageOverlayButton && (
                  <Box
                    sx={{
                      position: "absolute",
                      zIndex: 1,
                      bottom: 0,
                      left: 0,
                      bgcolor: (theme) =>
                        alpha(theme.palette.common.white, 0.8),
                    }}
                  >
                    <ButtonShowIssueImageOverlay
                      editor3d={caplaEditor?.editor3d}
                      issue={issue}
                    />
                  </Box>
                )}
              </Box>
            )}
          </Box>

          {
            <FieldIssueTitleInSelectionPanel
              title={title}
              onChange={handleTitleChange}
              isEditing={isEditing}
            />
          }

          {(!noContent || isEditing) && (
            <Box
              sx={{
                px: 2,
                minHeight: isEditing ? 200 : "unset",
                display: "flex",
                flexDirection: "column",
              }}
            ></Box>
          )}
        </Box>
      )}
    </Box>
  );
}
