import {green, blue} from "@mui/material/colors";

const bimboxModesMap = {
  BASIC: {key: "BASIC", name: "Basic", bgcolor: "secondary.main"},
  STANDARD: {key: "STANDARD", name: "Standard", bgcolor: "common.purple"},
  PRO: {key: "PRO", name: "Pro", bgcolor: "primary.flash"},
  DIAG: {key: "DIAG", name: "Diagnostic", bgcolor: green[800]},
  EXPERT: {key: "EXPERT", name: "Expert", bgcolor: "common.yellow"},
};

export default bimboxModesMap;
