import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {fetchMaterialsGroups} from "../materialsSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function useInitFetchMaterialsGroups(scene) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();
  //
  const sceneId = scene?.id;
  //
  async function getMaterialsGroups() {
    await dispatch(fetchMaterialsGroups({accessToken, sceneId}));
  }
  //
  useEffect(() => {
    if (accessToken && sceneId) {
      getMaterialsGroups();
    }
  }, [accessToken, sceneId]);
}
