const markersSample = [
  {id: "0", x: 0, y: 0, label: "00000"},

  {id: "1", x: 0.1, y: 0.1, label: "014310"},
  {id: "2", x: 0.2, y: 0.2, label: "024310"},
  {id: "3", x: 0.3, y: 0.3, label: "034310"},
  {id: "4", x: 1, y: 1, label: "111111"},

  {id: "5", x: 0.5, y: 0.5, label: "M1----"},
  {id: "6", x: 0.7, y: 0.5, label: "-----M2"},
];

export default markersSample;
