import {useState} from "react";

import {useDispatch, useSelector} from "react-redux";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box,
  Button,
} from "@mui/material";

import excelArticlesToArticles from "../utils/excelArticlesToArticles";

import CardFileSelector from "Features/files/components/CardFileSelector";
import getArticlesFromFile from "Features/excel/utils/getArticlesFromFile";

import {setSnackbarMessage} from "Features/ui/uiSlice";

import useCreateArticlesBatch from "../hooks/useCreateArticlesBatch";
import useUpdateArticlesBatch from "../hooks/useUpdateArticlesBatch";
import useDeleteArticlesBatch from "../hooks/useDeleteArticlesBatch";
import useUpdateArticlesGroup from "../hooks/useUpdateArticlesGroup";
import useElementTypesGroupsProxyBySceneWithElementTypes from "Features/elementTypes/hooks/useElementTypesGroupsProxyBySceneWithElementTypes";
import useSelectedArticlesGroup from "../hooks/useSelectedArticlesGroup";

export default function DialogImportArticlesFromExcel({open, onClose, scene}) {
  const dispatch = useDispatch();

  // strings

  const title = "Importez des articles";

  const saveS = "Enregistrer";
  const cancelS = "Annuler";

  const buttonName = "Choisir un fichier";

  // data

  const articlesMap = useSelector((s) => s.articles.articlesMap);

  const createArticlesBatch = useCreateArticlesBatch();
  const updateArticlesBatch = useUpdateArticlesBatch();
  const deleteArticlesBatch = useDeleteArticlesBatch();
  const updateArticlesGroup = useUpdateArticlesGroup();
  const groups = useElementTypesGroupsProxyBySceneWithElementTypes(scene, {
    filterByScope: true,
  });

  const selectedArticlesGroupId = useSelector(
    (s) => s.articles.selectedArticlesGroupId
  );
  const selectedArticlesGroup = useSelectedArticlesGroup();

  // state

  const [articles, setArticles] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  // handlers

  async function handleFileChange(file) {
    const defaultValues = {articlesGroupId: selectedArticlesGroupId};
    setFile(file);
    const excelArticles = await getArticlesFromFile(file, groups);
    const articles = excelArticlesToArticles(
      excelArticles,
      articlesMap,
      defaultValues
    );
    setArticles(articles);
    //
    console.log(
      "articles to load",
      articles,
      "pb with",
      articles?.filter((a) => typeof a.name !== "string"),
      "toDelete",
      articles?.filter((a) => a.toDelete && a.id)
    );
  }

  function handleCancelClick() {
    onClose();
  }
  async function handleSaveClick() {
    if (loading) return;
    setLoading(true);
    // batchCreate
    const articlesToCreate = articles.filter((a) => !a.id && a.name);
    await createArticlesBatch(articlesToCreate);
    // batchUpdate
    const articlesToUpdate = articles.filter((a) => a.id && !a.toDelete);
    await updateArticlesBatch(articlesToUpdate);
    // batchDelete
    const articlesToDelete = articles.filter((a) => a.id && a.toDelete);
    const deleteProps = {
      groupId: selectedArticlesGroupId,
      articlesIds: articlesToDelete.map((a) => a.id),
    };
    if (articlesToDelete?.length > 0) await deleteArticlesBatch(deleteProps);
    // update groups
    const sortedArticles = articles
      .filter((article) => !article.toDelete)
      .map((article) => article.clientId)
      .filter((clientId) => clientId);
    const articlesGroupId = selectedArticlesGroupId;
    const groupUpdates = {
      sceneId: scene?.id,
      id: articlesGroupId,
      sortedArticles,
    };
    await updateArticlesGroup(groupUpdates);

    setLoading(false);
    const triggeredAt = Date.now();
    const message = `Tableau ${selectedArticlesGroup?.name} mis à jour`;
    dispatch(setSnackbarMessage({message, triggeredAt}));
    onClose();
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{p: 1}}>
          <Box sx={{width: 1, display: "flex", justifyContent: "center", p: 2}}>
            <CardFileSelector
              file={file}
              onFileChange={handleFileChange}
              buttonName={buttonName}
              accept=".xlsx"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelClick}>{cancelS}</Button>
        <Button disabled={loading} onClick={handleSaveClick}>
          {saveS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
