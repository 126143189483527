import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

import {
  createCommentService,
  fetchCommentsService,
  updateCommentService,
  deleteCommentService,
} from "./services";

export const createComment = createAsyncThunk(
  "comments/createComment",
  createCommentService
);
export const updateComment = createAsyncThunk(
  "comments/updateComment",
  updateCommentService
);
export const deleteComment = createAsyncThunk(
  "comments/deleteComment",
  deleteCommentService
);

export const fetchComments = createAsyncThunk(
  "comments/fetchComments",
  fetchCommentsService
);

export const commentsSlice = createSlice({
  name: "comments",
  initialState: {
    items: [],

    selectedCommentId: null,

    isEditingComment: false,
    editedComment: {},
    tempComment: {},
  },
  reducers: {
    setSelectedCommentId: (state, action) => {
      state.selectedCommentId = action.payload;
    },
    setIsEditingComment: (state, action) => {
      state.isEditingComment = action.payload;
    },
    setEditedComment: (state, action) => {
      state.editedComment = action.payload;
    },
    setTempComment: (state, action) => {
      state.tempComment = action.payload;
    },
  },
  extraReducers: {
    [createComment.fulfilled]: (state, action) => {
      const {item} = action.payload;
      console.log("[STATE] create comment", item);
      state.items = [...state.items, item];
    },
    [updateComment.fulfilled]: (state, action) => {
      const {item} = action.payload;
      const newItems = state.items.filter((i) => i.id !== item.id);
      state.items = [...newItems, item];
    },
    [deleteComment.fulfilled]: (state, action) => {
      const commentId = action.payload;
      const newItems = state.items.filter((i) => i.id !== commentId);
      state.items = [...newItems];
    },
    [fetchComments.fulfilled]: (state, action) => {
      const {items} = action.payload;
      state.items = items;
    },
  },
});

export const {
  setSelectedCommentId,
  setIsEditingComment,
  setEditedComment,
  setTempComment,
} = commentsSlice.actions;

export default commentsSlice.reducer;
