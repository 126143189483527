import {useSelector, useDispatch} from "react-redux";

import {Add, LibraryAdd as AddGroup} from "@mui/icons-material";

export default function useActionsMaterialsMap({scene, caplaEditor}) {
  const dispatch = useDispatch();

  // handlers

  // actions map

  const actionsMap = {
    createMaterialsGroup: {
      icon: <AddGroup fontSize="small" />,
      name: "Liste de matériaux",
      label: "Créer une liste de matériaux",
    },
    createMaterial: {
      icon: <Add fontSize="small" />,
      name: "Créer un matériau",
      label: "Créer un matériau",
    },
    deleteMaterial: {
      name: "Supprimer",
      label: "Supprimer le matériau",
    },
  };

  return actionsMap;
}
