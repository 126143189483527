import {Box, IconButton, Tooltip} from "@mui/material";
import {Add} from "@mui/icons-material";

import markersSample from "../data/markersSample";

export default function ButtonTestAddMarkers({caplaEditor}) {
  // strings

  const addS = "Ajouter des marqueurs";

  // handlers

  function handleClick() {
    console.log("ButtonTestAddMarkers handleClick");
    //
    caplaEditor.zonesEditor.addMarkers(markersSample);
  }

  return (
    <Box>
      <Tooltip title={addS}>
        <IconButton sx={{borderRadius: 0}} onClick={handleClick}>
          <Add fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
