import {useDispatch} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {Box, Typography, Button, Chip} from "@mui/material";
import {FlashOn} from "@mui/icons-material";

import useAccessToken from "Features/auth/useAccessToken";
import {uploadFileService} from "Features/files/services";
import {updateModel} from "Features/viewer3D/viewer3DSlice";
import FileSelectorButton from "Features/files/components/FileSelectorButton";
import {getFileSizeString} from "Features/files/utils";
import SwitchOptimizedPdfFile from "Features/navigation/components/SwitchOptimizedPdfFile";

export default function SectionOptimizedPdf({model, sceneId}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const title = "PDF optimisé";
  const noOptimized = "Utilisez un PDF optimisé pour faire vos repérages";
  const withOptimized = "Ce PDF est associé à un PDF optimisé";
  const deleteS = "Supprimer";
  const updateS = "Mettre à jour";

  // helpers

  const optiSize = model.fileLowResoSize
    ? ` (${getFileSizeString(model.fileLowResoSize)})`
    : "";

  // handlers

  const onFileUpload = async (file) => {
    const fileName = `${nanoid()}.pdf`;
    const newFile = new File([file], fileName);
    const optiUrl = await uploadFileService({
      sceneId: sceneId,
      accessToken,
      file: newFile,
      container: "scene-files",
    });
    const updatedModel = {
      ...model,
      fileLowResoRemoteUrl: optiUrl,
      fileLowResoSize: newFile.size,
    };
    dispatch(updateModel({updatedModel}));
  };

  const onFileDelete = async () => {
    const updatedModel = {
      ...model,
      fileLowResoRemoteUrl: null,
      fileLowResoSize: null,
    };
    dispatch(updateModel({updatedModel}));
  };

  return (
    <Box
      sx={{
        width: 1,
        p: 1,
        borderRadius: "4px",
        color: "common.caplaBlack",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: "text.secondary",
          mr: "auto",
          mb: 1,
        }}
      >
        <FlashOn />
        <Typography sx={{ml: 1}} variant="body2">
          {title}
        </Typography>
      </Box>
      {!model?.fileLowResoRemoteUrl ? (
        <Typography variant="body2">{noOptimized}</Typography>
      ) : (
        <Chip
          label={
            <Typography variant="body2">{withOptimized + optiSize}</Typography>
          }
          sx={{backgroundColor: "secondary.main", color: "common.white"}}
        />
      )}
      <Box sx={{width: 1, p: 1}}>
        <SwitchOptimizedPdfFile pdfModel={model} />
      </Box>
      <Box sx={{ml: "auto", mt: 2}}>
        {!model?.fileLowResoRemoteUrl ? (
          <FileSelectorButton
            startIcon="none"
            buttonColor="inherit"
            onFileChange={onFileUpload}
            accept=".pdf"
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "&>*:not(:last-child)": {mr: 1},
            }}
          >
            <Button
              variant="outlined"
              color="inherit"
              onClick={onFileDelete}
              size="small"
            >
              <Typography variant="body2">{deleteS}</Typography>
            </Button>
            <FileSelectorButton
              startIcon="none"
              buttonColor="inherit"
              onFileChange={onFileUpload}
              accept=".pdf"
              buttonName={updateS}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
