import {useDispatch} from "react-redux";

import {deleteMaterialsGroup} from "../materialsSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function useDeleteMaterialsGroup() {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  const deleteGroup = async (materialsGroup) => {
    await dispatch(
      deleteMaterialsGroup({
        accessToken,
        materialsGroup,
      })
    );
  };

  return deleteGroup;
}
