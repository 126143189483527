import {useSelector} from "react-redux";

import {useState, useEffect} from "react";

import {Box, IconButton, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";
import ListElementTypes from "./ListElementTypes";
import ListElementTypesMultipleWithGroups from "./ListElementTypesMultipleWithGroups";
import useElementTypesGroupsProxyByScene from "../hooks/useElementTypesGroupsProxyByScene";

export default function SelectorElementTypeInSelectionPanel({
  scene,
  onClose,
  selectedElementType,
  elementTypes,
  onChange,
}) {
  // strings

  const title = "Sélectionnez un ouvrage / élément";

  // data

  const width = useSelector((s) => s.selection.selectionPanelWidth);
  const selectedGroup = useSelector(
    (s) => s.elementTypes.selectedElementTypesGroupProxyInEditMeasurements
  );
  const groupsProxy = useElementTypesGroupsProxyByScene(scene, {
    filterByScope: true,
  });

  // state

  const [selectedGroupProxy, setSelectedGroupProxy] = useState(selectedGroup);
  useEffect(() => {
    setSelectedGroupProxy(selectedGroup);
  }, [selectedGroup?.id]);

  // helpers

  let filteredElementTypes;
  // if (selectedElementType?.drawingShape) {
  //   filteredElementTypes = elementTypes.filter(
  //     (t) => t.isTitle || t.drawingShape === selectedElementType.drawingShape
  //   );
  // } else {
  //   filteredElementTypes = elementTypes;
  // }
  filteredElementTypes = elementTypes;

  //data transformation

  const filteredElementTypesById = filteredElementTypes?.reduce((acc, t) => {
    acc[t.id] = t;
    return acc;
  }, {});

  // handlers

  function handleClose() {
    onClose();
  }

  function handleGroupProxyChange(newGroupProxy) {
    setSelectedGroupProxy(newGroupProxy);
  }

  function handleClick(selection) {
    console.log("selection", selection);
    if (!selection?.length > 0) return; // avoid to close when changing group with empty selection.
    const elementType = filteredElementTypesById[selection[0]];
    onChange(elementType);
    onClose();
  }
  return (
    <Box
      sx={{
        position: "fixed",
        bgcolor: "common.white",
        top: 0,
        bottom: 0,
        right: 0,
        width,
        zIndex: 100,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: 1,
          p: 1,
        }}
      >
        <Typography variant="body2">{title}</Typography>
        <IconButton size="small" onClick={handleClose}>
          <Close fontSize="small" />
        </IconButton>
      </Box>

      <Box sx={{flex: 1, display: "flex", flexDirection: "column"}}>
        <ListElementTypesMultipleWithGroups
          selectedElementTypeIds={[selectedElementType?.id]}
          elementTypes={filteredElementTypes}
          onSelectionChange={handleClick}
          multipleSelection={false}
          groupsProxy={groupsProxy}
          selectedGroupId={selectedGroupProxy?.id}
          onSelectedGroupProxyChange={handleGroupProxyChange}
          variant="chips"
        />
      </Box>
    </Box>
  );
}
