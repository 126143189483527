import {useNavigate, useSearchParams} from "react-router-dom";

import {useSelector, useDispatch} from "react-redux";

import {
  ListItemButton,
  ListItemIcon,
  Avatar,
  ListItemText,
  Tooltip,
  Box,
  Icon,
  Typography,
} from "@mui/material";
import {AppRegistration as SceneModuleConfig} from "@mui/icons-material";
import theme from "Styles/theme";
import {lighten} from "@mui/material/styles";
import useSceneModulePathMap from "../useSceneModulePathMap";
import useSceneModule from "../useSceneModule";

export default function ListItemButtonSceneModuleConfig({scene}) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const sceneId = scene?.id ? scene.id : scene?.clientId;

  // string

  const configS = "Modules";

  // data

  const sceneModule = useSceneModule();
  const pathMap = useSceneModulePathMap(sceneId);
  const navPanelSceneModuleWidth = useSelector(
    (s) => s.navigation.navPanelSceneModulesWidth
  );

  // helpers

  const suffixUrl = "";

  // helpers - isSmallSize

  const isSmallSize = navPanelSceneModuleWidth < 80;

  // handlers

  function handleClick() {
    //console.log("pathMap", pathMap["MODULES"], scene);
    navigate(pathMap["MODULES"] + suffixUrl);
  }

  return (
    <ListItemButton
      selected={sceneModule === "MODULES"}
      onClick={handleClick}
      color="common.white"
      sx={{
        minHeight: 32,
        color: "white",
        py: 1.5,
        //width: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: isSmallSize ? "center" : "start",
        "&.Mui-selected": {bgcolor: "secondary.main"},
      }}
    >
      <Tooltip
        title={configS}
        placement="right"
        disableFocusListener={!isSmallSize}
        disableHoverListener={!isSmallSize}
      >
        <Icon
          sx={{
            color: "inherit",
            fontSize: 20,
            py: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mr: isSmallSize ? 0 : 3,
          }}
        >
          <SceneModuleConfig color="inherit" fontSize="small" />
        </Icon>
      </Tooltip>

      {!isSmallSize && (
        <ListItemText
          primary={configS}
          primaryTypographyProps={{
            color: "common.white",
            fontSize: "body2.fontSize",
          }}
        />
      )}
    </ListItemButton>
  );
}
