import {useEffect} from "react";
import {useOutletContext} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import useIsMobile from "Features/ui/useIsMobile";

import {setPdfViewerMode} from "Features/pdf/pdfSlice";

import {Box} from "@mui/material";

import Stretch from "Features/ui/components/Stretch";
import ListContainerSceneDatasets from "Features/scenes/components/ListContainerSceneDatasets";
import ListIssuesBySceneImageGrid from "Features/issues/components/ListIssuesBySceneImageGrid";
import SectionMeasurementsInHome from "Features/measurements/components/SectionMeasurementsInHome";

export default function SceneHome() {
  const dispatch = useDispatch();
  const {caplaEditor, scene, viewer} = useOutletContext();

  // data

  const homeViewMode = useSelector((s) => s.ui.homeViewMode);

  const isMobile = useIsMobile();

  // helpers - fullscreenHAsh

  const fullscreen = useSelector((s) => s.ui.fullscreen);
  const fullscreenHash = fullscreen ? "1" : "0";

  // helpers - openSections

  let os = useSelector((s) => s.ui.openSections);
  const osHash = Object.values(os).join("-");

  useEffect(() => dispatch(setPdfViewerMode("DEFAULT")), []);

  return (
    <Box
      sx={{
        bgcolor: "background.default",
        width: 1,
        display: "flex",
        flexDirection: "column",
        minWidth: 0,
        minHeight: 0,
      }}
    >
      {!isMobile && <Box sx={{height: "60px"}} />}
      {homeViewMode === "ISSUES" && os.outlet && (
        <Box>
          <Stretch>
            <Box sx={{height: 1}}>
              <Box
                sx={{
                  overflowY: "auto",
                  height: 1,
                  mr: "-50px",
                  pr: "50px",
                  pb: 10,

                  boxSizing: "content-box",
                }}
              >
                <ListIssuesBySceneImageGrid
                  scene={scene}
                  caplaEditor={caplaEditor}
                />
                <Box sx={{height: "30px"}} />
              </Box>
            </Box>
          </Stretch>
        </Box>
      )}

      {homeViewMode === "MEASUREMENTS" && os.outlet && (
        <SectionMeasurementsInHome
          caplaEditor={caplaEditor}
          scene={scene}
          viewer={viewer}
        />
      )}
      {homeViewMode === "DATA" && os.outlet && (
        <Box sx={{display: "flex", mt: 2}}>
          <Box sx={{flexGrow: 1}}>
            {/* <Box sx={{height: "150px", mb: 2}}>
            <ImageScene scene={scene} />
          </Box> */}
            <Stretch propDependency={osHash + fullscreenHash}>
              <Box
                sx={{
                  width: 1,
                  height: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <ListContainerSceneDatasets
                  scene={scene}
                  caplaEditor={caplaEditor}
                  viewer={viewer}
                />
              </Box>
            </Stretch>
          </Box>
        </Box>
      )}
    </Box>
  );
}
