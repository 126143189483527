import {clientPublic} from "API/capla360";

export async function fetchScenesServicePublic({apiKey}) {
  try {
    const response = await clientPublic.get("/scenes/", {
      headers: {
        "X-API-KEY": apiKey,
      },
    });
    const remoteScenes = response.data;
    console.log("fetchOrganisationScenes", remoteScenes);
    return remoteScenes;
  } catch (e) {
    console.log(e);
    throw e;
  }
}
