import {useEffect, useState, useRef} from "react";

import {useDispatch} from "react-redux";

import {
  Box,
  List,
  ListItemButton,
  IconButton,
  // ListItemText,
  Typography,
  Button,
} from "@mui/material";
import {
  ArrowDropDown as Down,
  ArrowDropUp as Up,
  Close,
} from "@mui/icons-material";

import {VariableSizeList} from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import ListItemArticle from "./ListItemArticle";
import sortByNum from "Utils/sortByNum";
import SelectorGenericItemWithChips from "Components/SelectorGenericItemWithChips";
import SelectorArticlesGroup from "./SelectorArticlesGroup";
import {
  setGroupsViewVariant,
  setSelectedArticlesGroupId,
} from "../articlesSlice";

import BlockEditableArticlesGroupName from "./BlockEditableArticlesGroupName";
import SearchBarObjectsInLeftPanel from "Features/search/components/SearchBarObjectsInLeftPanel";
import getFilteredNomenclatureWithItems from "Utils/getFilteredNomenclatureWithItems";
import getItemsMapById from "Utils/getItemsMapById";

export default function ListArticlesMultipleWithGroups({
  scene,
  scope,
  caplaEditor,
  articles = [],
  articlesGroups = [],
  articleQtyMap = {},
  selectedArticleIds,
  multipleSelection,
  onSelectionChange,
  selectedArticlesGroupId,
  onSelectedArticlesGroupIdChange, // {id,name,fromScene}
  resetSelectionOnGroupChange = true,
  forceSelection, // if true, return one default selection
  maxHeightGroups,
  variant = "chips",
  options = {},
}) {
  const dispatch = useDispatch();

  // ref

  const listRef = useRef(null);

  // data transfo

  const articlesById = getItemsMapById(articles);

  // state

  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [showChildren, setShowChildren] = useState(false);

  // options

  const canEditArticlesGroupName = options?.canEditArticlesGroupName;

  // strings

  const groupS = "Tableau d'articles";
  const groupsS = "Tableaux d'articles";

  const groupsViewLabel =
    variant === "list" ? "Tous les tableaux" : "Sélection seule";

  // helpers

  const filteredArticles = articles.filter(
    (r) =>
      !selectedArticlesGroupId || r.articlesGroupId === selectedArticlesGroupId
  );

  // helper - enableShowChildren

  const enableShowChildren = searchText.length > 0;

  // build nomenclature

  const nomenclature = filteredArticles.filter((article) => article.tempNum);

  let selectedArticlesGroup = articlesGroups.find(
    (g) => g.id === selectedArticlesGroupId
  );

  if (!selectedArticlesGroup)
    selectedArticlesGroup = {
      id: "undefined",
      name: "Sélectionnez un tableau",
    };

  // helpers - items

  let items = filteredArticles;
  if (
    // searchResult?.length !== 0 &&
    // searchResult?.length !== filteredArticles?.length
    searchText.length > 0
  ) {
    let itemsInScope = searchResult.filter(
      (article) => article.tempNum && !selectedArticleIds?.includes(article.id)
    );
    selectedArticleIds?.forEach((id) => {
      if (articlesById[id] && articlesById[id].tempNum)
        itemsInScope.push(articlesById[id]);
    });
    const options = {
      numField: "tempNum",
      selectedItemIds: selectedArticleIds,
      showChildren,
    };
    items = getFilteredNomenclatureWithItems(
      nomenclature,
      searchResult.filter((article) => article.tempNum),
      options
    );
  }

  // virtualized list

  const itemData = items.map((article) => {
    let selected = false;
    const qtyObject = articleQtyMap[article?.id];
    const qty = qtyObject?.qty;
    if (Array.isArray(selectedArticleIds))
      selected = selectedArticleIds?.includes(article?.id);
    return {...article, selected, qty};
  });

  function getItemSize(index) {
    return 28;
  }

  function renderRow(props) {
    const {index, style} = props;
    const article = itemData[index];

    return (
      <ListItemButton
        style={{
          ...style,
          marginTop: 0,
          marginBottom: 0,
          paddingBottom: 0,
          paddingTop: 0,
          minHeight: 0,
        }}
        divider
        disableGutters
        sx={{p: 0}}
        key={article.id}
        selected={article.selected}
        onClick={() => handleClick(article)}
      >
        <ListItemArticle
          key={article.id}
          scene={scene}
          article={article}
          selected={article.selected}
          qty={article.qty}
          showChildren={showChildren}
          onShowChildrenChange={handleShowChildrenChange}
          enableShowChildren={enableShowChildren}
        />
      </ListItemButton>
    );
  }

  // init - forceSelection

  const groupIdNotInScope =
    selectedArticlesGroupId &&
    !scope?.data.articlesGroupsIds.includes(selectedArticlesGroupId);

  useEffect(() => {
    if (
      forceSelection &&
      (!selectedArticlesGroupId || groupIdNotInScope) &&
      articlesGroups.length > 0
    ) {
      const id0 = articlesGroups[0].id;
      dispatch(setSelectedArticlesGroupId(id0));
    }
  }, [selectedArticlesGroupId, articlesGroups.length, groupIdNotInScope]);

  // state

  const [openGroups, setOpenGroups] = useState(false);

  // handlers

  function handleGroupClick(group, options) {
    //
    const closeGroupsList = options?.closeGroupsList;
    //
    if (group?.id && selectedArticlesGroupId !== group?.id) {
      // used to avoid to have one hidden selection...
      if (resetSelectionOnGroupChange) onSelectionChange([]);
    }
    onSelectedArticlesGroupIdChange(group.id);
    //
    if (closeGroupsList) setOpenGroups(false);
  }

  function handleGroupsViewChange() {
    if (variant === "list") {
      dispatch(setGroupsViewVariant("chips"));
    } else {
      dispatch(setGroupsViewVariant("list"));
    }
  }

  function handleSearch(result) {
    setSearchResult(result);
  }

  function handleClick(article) {
    console.log("click on article", article);
    const id = article?.id;
    const idIsSelected = selectedArticleIds?.includes(id);
    let newIds = selectedArticleIds?.filter((typeId) => typeId !== id);
    if (multipleSelection) {
      if (!newIds) newIds = [];
      if (article.isTitle) {
        return;
      } else {
        if (!selectedArticleIds?.includes(id)) newIds.push(id);
      }
    } else {
      newIds = idIsSelected ? [] : [id];
    }
    if (onSelectionChange) onSelectionChange(newIds);
  }

  function handleShowChildrenChange(show) {
    setShowChildren((show) => !show);
  }

  function handleSearchReset() {
    setSearchResult([]);
    if (listRef.current && selectedArticleIds.length > 0) {
      const selectedIndex = itemData.findIndex(
        (article) => article.id === selectedArticleIds[0]
      );
      listRef.current.scrollToItem(selectedIndex, "center");
    }
  }

  // auto scroll when resetting search or change articles map
  useEffect(() => {
    if (listRef.current && selectedArticleIds.length > 0) {
      const selectedIndex = itemData.findIndex(
        (article) => article.id === selectedArticleIds[0]
      );
      listRef.current.scrollToItem(selectedIndex, "center");
    }
  }, [itemData?.length]);

  return (
    <Box
      sx={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: 0}}
    >
      <Box sx={{py: 2}}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: 13,
              color: "secondary.main",
              p: 0.5,
              px: 1,
              visibility: openGroups ? "hidden" : "visible",
            }}
          >
            {groupS}
          </Typography>
          <Box sx={{color: "text.secondary", height: "30px"}}>
            <Button
              color="inherit"
              size="small"
              onClick={handleGroupsViewChange}
            >
              {groupsViewLabel}
            </Button>
          </Box>
        </Box>
        {variant === "chips" && (
          <Box sx={{width: 1, pt: 2}}>
            <SelectorGenericItemWithChips
              items={articlesGroups}
              selectedItem={selectedArticlesGroup}
              onClick={handleGroupClick}
              maxHeight={maxHeightGroups}
            />
          </Box>
        )}
        {variant === "list" && (
          <Box
            sx={{
              p: 1,
              bgcolor: openGroups ? "common.white" : "secondary.main",
            }}
          >
            <Box
              sx={{
                px: 1,
                //py: 0.5,
                bgcolor: "common.white",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
                color: "text.secondary",
              }}
            >
              <BlockEditableArticlesGroupName
                articlesGroup={selectedArticlesGroup}
                defaultName={openGroups ? groupsS : ""}
                canEdit={canEditArticlesGroupName && !openGroups}
              />
              {/* <Typography sx={{fontSize: 13}}>
                  {!openGroups ? selectedArticlesGroup?.name : groupsS}
                </Typography> */}
              <IconButton
                onClick={() => setOpenGroups((open) => !open)}
                size="small"
              >
                {!openGroups ? <Down /> : <Close color="inherit" />}
              </IconButton>
            </Box>
          </Box>
        )}
      </Box>
      {!openGroups && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: 0,
            flexGrow: 1,
          }}
        >
          <SearchBarObjectsInLeftPanel
            searchText={searchText}
            onSearchTextChange={setSearchText}
            onSearch={handleSearch}
            objects={filteredArticles}
            keys={["name", "num"]}
            onClose={handleSearchReset}
          />
          <Box
            sx={{
              bgcolor: "common.white",
              //overflowY: "auto",
              borderTop: (theme) => `1px solid ${theme.palette.divider}`,
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <AutoSizer>
              {({height, width}) => (
                <VariableSizeList
                  ref={listRef}
                  height={height}
                  width={width}
                  itemSize={getItemSize}
                  itemCount={items.length}
                  overscanCount={5}
                  itemData={itemData}
                >
                  {renderRow}
                </VariableSizeList>
              )}
            </AutoSizer>
          </Box>
        </Box>
      )}
      {openGroups && variant === "list" && (
        <Box sx={{overflowY: "auto"}}>
          <SelectorArticlesGroup
            items={articlesGroups}
            selectedItem={selectedArticlesGroup}
            onClick={handleGroupClick}
          />
        </Box>
      )}
    </Box>
  );
}
