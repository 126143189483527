import {Box, Typography, Paper, Tooltip} from "@mui/material";

import HeaderSelectionMoreButton from "./HeaderSelectionMoreButton";
import HeaderSelectionBackButton from "./HeaderSelectionBackButton";

import useSelection from "Features/selection/useSelection";

export default function HeaderSelection({
  caplaEditor,
  scene,
  viewer,
  forceSelectionType,
}) {
  // data

  const selection = useSelection({
    scene,
    caplaEditor,
    viewer,
    forceSelectionType,
  });

  // helpers

  let name = selection?.item?.name;

  // helpers • bg

  const gradient = (theme) =>
    `linear-gradient(to right,${theme.palette.secondary.main},${theme.palette.primary.main})`;

  const flash = [
    "NEW_SCENE",
    "TEMP_INPUT",
    "NEW_RESSOURCE",
    "TEMP_ROOM",
    "NEW_MATERIAL",
    "NEW_BIMAPP",
    "TEMP_MEASUREMENT",
    "TEMP_SECTOR",
    "NEW_ELEMENT_TYPE",
    "NEW_MARKER",
    "NEW_ISSUE",
    "NEW_PACKAGE",
    "NEW_SHAREDBOX",
    "NEW_PHASE",
  ].includes(selection.type);

  const background = flash ? gradient : "unset";
  const color = flash ? "common.white" : "common.black";

  return (
    <Box
      sx={{
        p: 1,
        width: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {selection.type && selection.type !== "PROGRESS_LISTING" && (
        <Paper
          elevation={3}
          sx={{
            display: "flex",
            p: 0.5,
            height: (theme) => theme.spacing(5),
            width: 1,
            alignItems: "center",
            justifyContent: "space-between",
            overflow: "hidden",
            background,
            color,
          }}
        >
          <HeaderSelectionBackButton
            caplaEditor={caplaEditor}
            scene={scene}
            selection={selection}
          />
          <Tooltip title={name}>
            <Typography
              variant="body2"
              sx={{fontWeight: "bold", minWidth: 0, px: 0.5}}
              noWrap
            >
              {name}
            </Typography>
          </Tooltip>
          {viewer ? (
            <Box />
          ) : (
            <HeaderSelectionMoreButton
              caplaEditor={caplaEditor}
              scene={scene}
              selection={selection}
            />
          )}
        </Paper>
      )}
    </Box>
  );
}
