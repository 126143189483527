import {useSelector} from "react-redux";

import {Box, Typography, Button, Paper} from "@mui/material";

import updateMeasurementsHeightsWithContinuity from "../utils/updateMeasurementsHeightsWithContinuity";

export default function SectionAutoMeasUpdateHeights({caplaEditor, onUpdated}) {
  // strings

  const title = "Mise à jour des hauteurs";

  const description =
    "Pour les repérages sélectionnés, les hauteurs sont mises à jour pour avoir une continuité verticale.";

  const updateS = "Mettre à jour";

  // data

  const selectedMeasurementIds = useSelector(
    (s) => s.measurements.selectedMeasurementIds
  );
  const measurements = useSelector((s) => s.measurements.measShowedInViewer3d);

  // helpers

  const countLabel = selectedMeasurementIds.length + " repérages";

  // helpers - selectedMeasurements

  const measMap = measurements.reduce((acc, m) => {
    acc[m.id] = m;
    return acc;
  }, {});
  const selectedMeasurements = selectedMeasurementIds.map((id) => measMap[id]);

  // handlers

  function handleClick() {
    const updatedMeasurements =
      updateMeasurementsHeightsWithContinuity(selectedMeasurements);
    caplaEditor?.measDataManager.updateMeasurements(updatedMeasurements);

    onUpdated();
  }

  return (
    <Paper>
      <Box sx={{p: 1}}>
        <Typography sx={{mb: 1}}>{title}</Typography>
        <Box sx={{p: 1, bgcolor: "background.default"}}>
          <Typography variant="body2">{description}</Typography>
        </Box>
        <Box sx={{p: 1}}>
          <Typography variant="body2">{countLabel}</Typography>
        </Box>

        <Box sx={{display: "flex", justifyContent: "end"}}>
          <Button size="small" onClick={handleClick}>
            {updateS}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}
