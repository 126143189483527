import getItemRankFromNum from "./getItemRankFromNum";
import getParentNum from "./getParentNum";

export default function getItemsWithChildrenFromNum(items) {
  //
  const itemsByNum = items.reduce((acc, item) => {
    acc[item.num] = {...item};
    return acc;
  }, {});
  //
  const itemsNums = items.map((item) => item.num);
  //
  items.forEach((item) => {
    const parentNum = getParentNum(item.num);
    if (parentNum && itemsByNum[parentNum]) {
      const children = itemsByNum[parentNum].children || [];
      itemsByNum[parentNum].children = [...children, item];
    }
  });
  //
  const itemsWithChildren = itemsNums.map((num) => itemsByNum[num]);

  return itemsWithChildren;
}
