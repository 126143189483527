import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
  Box,
  Divider,
  Button,
  Paper,
  IconButton,
  alpha,
  Tooltip,
} from "@mui/material";
import {
  Edit,
  Add,
  ExpandMore as More,
  ExpandLess as Less,
  Brush,
  FormatPaint,
} from "@mui/icons-material";

import ToolbarMeasurementPdfButtons from "./ToolbarMeasurementPdfButtons";
import SelectorElementTypeInToolbar from "./SelectorElementTypeInToolbar";
// import ButtonAutoMeasure from "../../measurementsAuto/components/ButtonAutoMeasure";
// import ButtonCreateMeasurementTutorial from "./ButtonCreateMeasurementTutorial";
import FormDefaultMeasurement from "./FormDefaultMeasurement";
import useTempMeasurement from "../useTempMeasurement";

import {
  updateTempMeasurement,
  setShowDefaultMeasurementForm,
  setShowQuantitiesInViewer,
  setIsEditingMeasurementGeometry,
  // updateMeasurements,
  setEditedMeasurement,
  setSelectedMeasurementIds,
  setSelectedElementTypeId,
} from "Features/measurements/measurementsSlice";
import useIsDrawing from "Features/measurements/useIsDrawing";
import {setOpenCreateElementTypeFastDialog} from "Features/elementTypes/elementTypesSlice";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import ButtonSetScaleFromMeasurement from "Features/pdf/components/ButtonSetScaleFromMeasurement";
import zoomInOut from "Features/ui/zoomInOut";
import useSelectedMeasurement from "../useSelectedMeasurement";
import ButtonDialogVoids from "./ButtonDialogVoids";
import ButtonDialogProcedureInToolbar from "Features/procedures/components/ButtonDialogProceduresInToolbar";

import CheckboxIsDrawingOneByOne from "./CheckboxIsDrawingOneByOne";
import CheckboxIsSnapping from "./CheckboxIsSnapping";

import ButtonStopDrawing from "./ButtonStopDrawing";

import ToolbarPasteMeasurements from "./ToolbarPasteMeasurements";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";

export default function ToolbarMeasurementPdf({caplaEditor, scene}) {
  const dispatch = useDispatch();

  // strings

  const createS = "Ajouter un nouveau type d'élément";
  const editS = "Modifier la géométrie";
  const cancelS = "Annuler";
  const saveS = "Sauvegarder";
  const copyTypeS = "Copier le type";

  // data

  const copiedMeasurements = useSelector(
    (s) => s.measurements.copiedMeasurements
  );

  const isEditing = useSelector(
    (s) => s.measurements.isEditingMeasurementGeometry
  );

  const isDrawing = useIsDrawing();
  const isDrawingOneByOne = useSelector(
    (s) => s.measurements.isDrawingOneByOne
  );

  const isCopying = copiedMeasurements?.measurements?.length > 0;

  const selectedMeasurement = useSelectedMeasurement();
  const editedMeasurement = useSelector(
    (s) => s.measurements.editedMeasurement
  );
  const types = useElementTypesBySceneProxy(scene);
  const tempMeasurement = useTempMeasurement({
    editorPdf: caplaEditor?.editorPdf,
    scene,
  });

  let showDefaultMeasurementForm = useSelector(
    (s) => s.measurements.showDefaultMeasurementForm
  );
  const openDialog = useSelector(
    (s) => s.elementTypes.openCreateElementTypeFastDialog
  );

  const zonesById = useZonesByScene(scene, {variant: "map"});

  // helper - bgcolor

  const background = (theme) =>
    isEditing
      ? "common.white"
      : `linear-gradient(to right,${theme.palette.secondary.main},${theme.palette.primary.main})`;

  // helper - display

  const display = isDrawing || isCopying ? "none" : "flex";
  //const display = isDrawing ? "none" : "flex";

  // helpers - noTypes

  const noTypes = types.length === 0;

  // helper - manager

  const mnger =
    caplaEditor?.editorPdf?.annotationsManager?.measurementsPdfManager;

  // handlers

  function handleCancelClick() {
    mnger.resetSelectedMeasurement();
    mnger.lockAnnot({annotationId: selectedMeasurement.id});
    mnger.setIsEditing(false);
    dispatch(setIsEditingMeasurementGeometry(false));
  }

  function handleSaveClick() {
    mnger.lockAnnot({annotationId: selectedMeasurement.id});
    mnger.setEditedMeasurement(selectedMeasurement);
    mnger.updateAnnotation(editedMeasurement, {zonesById});
    mnger.setIsEditing(false);
    dispatch(setEditedMeasurement(selectedMeasurement));
    // dispatch(updateMeasurements([editedMeasurement]));
    caplaEditor?.measDataManager.updateMeasurements([editedMeasurement]);
    dispatch(setIsEditingMeasurementGeometry(false));
  }

  function handleEditGeometryClick() {
    mnger.setEditedMeasurement(selectedMeasurement);
    mnger.setSelectedMeasurement(selectedMeasurement);
    mnger.unlockAnnot({annotationId: selectedMeasurement.id});
    mnger.setIsEditing(true);
    dispatch(setEditedMeasurement(selectedMeasurement));
    dispatch(setIsEditingMeasurementGeometry(true));
  }

  function handleCreateClick() {
    dispatch(setOpenCreateElementTypeFastDialog(true));
  }

  function handleDefaultMeasurementChange(m) {
    dispatch(updateTempMeasurement(m));
    mnger.setTempMeasurement(m);
  }

  function handleToggleShowDefaultMeasurementForm() {
    if (!selectedMeasurement)
      dispatch(setShowDefaultMeasurementForm(!showDefaultMeasurementForm));
  }

  function handleCopyType() {
    const newM = {
      ...selectedMeasurement,
    };

    if (newM.voids) delete newM.voids;
    dispatch(updateTempMeasurement(newM));
    dispatch(setSelectedMeasurementIds([]));
    dispatch(setSelectedElementTypeId(selectedMeasurement.elementTypeId));
  }

  // effects

  useEffect(() => {
    if (mnger) {
      dispatch(setShowQuantitiesInViewer(true));
      mnger.updateMeasurementTools();
    }
  }, [mnger]);

  useEffect(() => {
    return () => {
      dispatch(setShowQuantitiesInViewer(false));
    };
  }, []);

  useEffect(() => {
    if (isEditing) {
      mnger.resetSelectedMeasurement();
      mnger.lockAnnot({annotationId: editedMeasurement.id});
      mnger.setIsEditing(false);
      dispatch(setIsEditingMeasurementGeometry(false));
    }
  }, [selectedMeasurement]);

  // AI FEATURE
  // const displayAI = true;

  return (
    <Box>
      {isDrawing && <ButtonStopDrawing caplaEditor={caplaEditor} />}
      {isCopying && <ToolbarPasteMeasurements scene={scene} />}
      <Box
        sx={{
          bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        }}
      >
        <Paper
          elevation={12}
          sx={{
            display,
            background,
            color: "common.white",
            p: 0.5,
            borderRadius: "4px",
            alignItems: "center",
            "&>*:not(:last-child)": {
              mr: 1,
            },
            ...(noTypes &&
              !openDialog && {
                border: (theme) => `1px solid ${theme.palette.primary.flash}`,
                animation: `${zoomInOut} 1s infinite ease`,
              }),
          }}
        >
          {selectedMeasurement && !isEditing && (
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Button
                startIcon={<Edit />}
                color="inherit"
                size="small"
                onClick={handleEditGeometryClick}
              >
                {editS}
              </Button>
              <Divider
                flexItem
                orientation="vertical"
                sx={{mx: 0.5, my: 0.5, borderColor: "common.white"}}
              />
              <Tooltip title={copyTypeS}>
                <IconButton
                  onClick={handleCopyType}
                  size="small"
                  sx={{
                    width: 24,
                    height: 24,
                    borderRadius: 0,
                    color: "inherit",
                  }}
                >
                  <FormatPaint fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          {selectedMeasurement && isEditing && (
            <Box
              sx={{
                display,
                alignItems: "center",
              }}
            >
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancelClick}
              >
                {cancelS}
              </Button>
              <Divider
                flexItem
                orientation="vertical"
                sx={{mx: 0.5, my: 0.5, borderColor: "common.white"}}
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSaveClick}
              >
                {saveS}
              </Button>
            </Box>
          )}
          {noTypes && !selectedMeasurement && !isEditing && (
            <Button
              startIcon={<Add />}
              color="inherit"
              size="small"
              onClick={handleCreateClick}
              sx={{whiteSpace: "nowrap"}}
            >
              {createS}
            </Button>
          )}
          {!noTypes && !selectedMeasurement && !isEditing && (
            <Box
              sx={{
                color: "common.white",
                display,
                alignItems: "center",
                width: 400,
                //border: "1px solid white",
              }}
            >
              <Box sx={{display: "flex", alignItems: "center", minWidth: 0}}>
                <ButtonSetScaleFromMeasurement
                  editorPdf={caplaEditor?.editorPdf}
                />
                {/* <Divider
                flexItem
                orientation="vertical"
                sx={{mx: 0.5, my: 0.5, borderColor: "common.white"}}
              /> */}
              </Box>
              <Box sx={{flex: 1, px: 0.5, minWidth: 0}}>
                <SelectorElementTypeInToolbar
                  editorPdf={caplaEditor?.editorPdf}
                  scene={scene}
                />
              </Box>

              <Box sx={{display: "flex", alignItems: "center"}}>
                <ToolbarMeasurementPdfButtons
                  editorPdf={caplaEditor?.editorPdf}
                />
                {/* {displayAI && ( */}
                <Box sx={{display: "flex"}}>
                  <Divider
                    flexItem
                    orientation="vertical"
                    sx={{mx: 0.5, my: 0.5, borderColor: "common.white"}}
                  />
                  <ButtonDialogVoids scene={scene} caplaEditor={caplaEditor} />
                  <ButtonDialogProcedureInToolbar
                    scene={scene}
                    caplaEditor={caplaEditor}
                  />
                  {/* <ButtonCreateMeasurementTutorial /> */}
                  {/* {<ButtonAutoMeasure editor={editor} viewer={viewer} />} */}
                  {/* {false && (
                <ButtonAutoMeasureFullPage editor={editor} viewer={viewer} />
              )} */}
                </Box>

                <Box sx={{display: "flex"}}>
                  <Divider
                    flexItem
                    orientation="vertical"
                    sx={{mx: 0.5, my: 0.5, borderColor: "common.white"}}
                  />
                  <IconButton
                    color="inherit"
                    size="small"
                    onClick={handleToggleShowDefaultMeasurementForm}
                  >
                    {showDefaultMeasurementForm ? <More /> : <Less />}
                  </IconButton>
                </Box>
              </Box>
            </Box>
          )}
        </Paper>
        {!selectedMeasurement && !isDrawing && !isCopying && (
          <Box
            sx={{
              px: 2,
              color: "common.black",
              bgcolor: "background.default",
              display: "flex",
              alignItems: "center",
              //border: "1px solid grey",
            }}
          >
            <CheckboxIsDrawingOneByOne editorPdf={caplaEditor.editorPdf} />
            <CheckboxIsSnapping editorPdf={caplaEditor.editorPdf} />
          </Box>
        )}
        {showDefaultMeasurementForm && !selectedMeasurement && !isEditing && (
          <Box
            sx={{
              display,
              width: 1,
              borderRadius: "0 0 4px 4px",
              border: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            <FormDefaultMeasurement
              scene={scene}
              defaultMeasurement={tempMeasurement}
              onChange={handleDefaultMeasurementChange}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
