import {createSlice} from "@reduxjs/toolkit";

import theme from "Styles/theme";

const defaultCategories = [
  {id: "1", name: "21.1 Voiles"},
  {id: "2", name: "22.2 Dalles"},
];

function symmetricDifference(setA, setB) {
  const _difference = new Set(setA);
  for (const elem of setB) {
    if (_difference.has(elem)) {
      _difference.delete(elem);
    } else {
      _difference.add(elem);
    }
  }
  return _difference;
}

export const measurementsSlice = createSlice({
  name: "measurements",
  initialState: {
    showMeasurements: true,

    measIdsShowedInViewer3d: [],
    measShowedInViewer3d: [], // for automations.
    measurementsShowedInViewer3dWithoutShortcut: [],
    //
    measIdsShowedInViewerPdf: [],

    measuredLength: {length: null, scale: null}, // used to compute scale from measurement.

    modifiedMeasurements: [], // [{modelId,measId}], used to track modifications to save (display save button)

    pdfModelId: null,
    selectedMeasurementsModelId: null,

    selectedElementTypeGroup: "", // group selected in toolbar
    selectedElementTypeGroupInScope: "", // group selected in datagrid

    editedMeasurement: null,
    showDefaultMeasurementForm: true,
    measurementInPopover: null, // measurement in Popover, used when editing measurement.

    editedMeasurementMulti: {},
    isEditingMeasurementMulti: false,
    editedTimestamp: "",

    codeName: "",
    dim1: null,
    dim2: null,
    dim3: null,
    rot: 0,
    offset: 0,
    zFrom: "offset",

    selectedCategoryId: null,
    zoneId: null,
    sectorId: null,
    roomId: null,
    materialId: null,
    ressourceId: null,
    versionId: null,

    selectedPresetId: null,
    selectedElementTypeId: null,
    color: theme.palette.primary.flash,
    drawDash: false, // related to this.drawDash in measurementsEditor. => dashed annotation.
    drawVoid: false, // draw a "void" (= isVoid)
    dotted: false,
    drawingShape: "POLYGON", // "POLYLINE", "SEGMENT", "RECTANGLE"
    builtAt: null,
    phaseId: null,
    exeComment: "",
    geoCat: "",
    isRoom: false,
    isGhost: false,
    offset2d: null,
    unit: "UN",
    count: 0,
    length: null,
    area: null,
    volume: null,
    quantityTemplates: [], // {quantityId,mode,value}, quantities from the quantities state. /!\ != "measurement.quantities"
    // mode: "DEFAULT","AUTO"
    measurementsMode: null, // "POLYLINE","POLYGON" // TO DELETE
    annotCreationTool: null, //tool used to create the annotation. "POLYGON","POLYLINE_INT","AUTO_MEASURE"

    loadedMeasurementsModels: [], // ids of models for which measurements are loaded in items.
    items: [], // {id,type,quantities }

    showQuantitiesInViewer: false,
    categories: defaultCategories, // category of the measurement. {id, name, sceneClientId: scene.clientId})

    measurementsInCard: [],

    selectedMeasurementIds: [],
    selectedMeasurementId: null, // used to display detail.
    selectedFormGroup: "PROPS", // "QUANTITIES","EXE"

    selectedRessourceGroup: null, // used to display relevant ressources in the datagrid byRessource.

    selectedTypesInDatagrid: [], // used to display measurements by type.
    selectedRessourcesInDatagrid: [],
    selectedRoomsInDatagrid: [],
    selectedMaterialsInDatagrid: [],
    oboInDatagrid: false, // if true, one by one selection

    isSnapping: false,

    isDrawingOneByOne: true,
    dataGridMode: "BY_ELEMENT_TYPE", // "BY_RESSOURCE", "ALL", "BY_CATEGORY", "BY_CATEGORY_MODEL"

    openDrawingDetail: true, // open detail section when drawing.
    canEditMeasurement: false,
    isEditingMeasurement: false,

    scopeSelectionMode: null,
    scopeDefaultSelectionMode: null,
    scopeMeasurementsCount: 0,

    filterRefreshDate: null, // used as a dependency in applyFilters.
    filterByBuiltAt: null,
    filterByBuiltAtMin: null,
    filterByBuiltAtMax: null,
    filtersCategoryIds: [], //
    filtersPresetIds: [],

    filterSectorId: null,
    filterRoomId: null,
    filterMaterialId: null,
    filterGeoCat: null,
    filterElementTypeId: null,
    filterNoVoids: false,
    filterZoneId: null,
    filterRessourceId: null,
    filterPhaseId: null,
    filterP1: null,
    filterP2: null,
    filterP3: null,

    scopeInputId: null,
    measurementsModelsInScope: [], // used to specify the scope of the measurements.
    elementTypesInScope: [],
    phasesInScope: [],
    keywordsInScope: [],
    todoInScope: false, // true : builtAt =null only
    doneInScope: false, // true : built != null only
    zonesInScope: [],
    zonesInScopeObo: false,
    sectorsInScope: [],
    sectorsInScopeObo: false,
    moreFilters: false,
    applyFilters: true,
    roomsInScope: [],
    materialsInScope: [],
    versionsInScope: [],

    presets: [], // [{id,sceneClientId,name,lockedFields}]
    tempPresetLockedFields: [], // ['width','categoryId',...] locked field.

    shortcutBuiltAts: [null], // active dates to filter by builtat date. null = to do measurements
    shortcutCategories: [], // active filtered categories
    shortcutPresets: [],
    shortcutElementTypes: null,
    shortcutSectors: [],
    shortcutPhases: [],
    shortcutElementTypeGroups: null,

    shortcutMobileZoneId: null,

    moduleViewMode: null, // PDF, 3D.. : used to keep the view mode in memory when unmounting the page.
    // avoid to redraw shapes in pdf if 3D is active.
    filteredMeasurementsViewMode: "IN_AND_OUT", // "IN_AND_OUT","IN"
    filteredMeasurementsViewModeBckp: null,

    autoGeo3D: false, // used to compute height of walls.

    loadedModels: [], // updated one shortcuts are first updated. Used to display measurements on inital loading.
    initHomeMeasurementsCamera: false, // set to true when camera first initialized with focus on all measurments.
    filtersAppliedToMeasurementsIn3DAt: null, // used for initial 3D camera

    copiedMeasurements: {},
    pasteDeltaX: "", // can't be null (controlled input)
    pasteDeltaY: "",
    pasteFlipH: false,
    pasteFlipV: false,
    pasteMulti: false,
    pasteToZone: null,
    measurementsEditedFromForm: false,
    hideSavedMeasurements: false,
    modifications: {},
  },
  reducers: {
    setShowDefaultMeasurementForm: (state, action) => {
      state.showDefaultMeasurementForm = action.payload;
    },
    setSelectedRessourceGroup: (state, action) => {
      state.selectedRessourceGroup = action.payload;
    },
    setSelectedFormGroup: (state, action) => {
      state.selectedFormGroup = action.payload;
    },
    setModuleViewMode: (state, action) => {
      state.moduleViewMode = action.payload;
    },
    setFilteredMeasurementsViewMode: (state, action) => {
      state.filteredMeasurementsViewMode = action.payload;
    },
    setFilteredMeasurementsViewModeBckp: (state, action) => {
      state.filteredMeasurementsViewModeBckp = action.payload;
    },
    setShowMeasurements: (state, action) => {
      state.showMeasurements = action.payload;
    },
    setMeasuredLength: (state, action) => {
      state.measuredLength = action.payload;
    },
    setEditedMeasurement: (state, action) => {
      state.editedMeasurement = action.payload;
    },
    setMeasurementInPopover: (state, action) => {
      state.measurementInPopover = action.payload;
    },
    setStrokeM: (state, action) => {
      state.strokeM = action.payload;
    },
    setWidth: (state, action) => {
      state.width = action.payload;
    },
    setDim1: (state, action) => {
      state.dim1 = action.payload;
    },
    setDepth: (state, action) => {
      state.depth = action.payload;
    },
    setMeasurementsMode: (state, action) => {
      state.measurementsMode = action.payload;
    },
    setColor: (state, action) => {
      state.color = action.payload;
    },
    setDrawingShape: (state, action) => {
      state.drawingShape = action.payload;
    },
    setQuantityTemplates: (state, action) => {
      state.quantityTemplates = action.payload;
    },
    setSelectedMeasurementsModelId: (state, action) => {
      state.selectedMeasurementsModelId = action.payload;
    },
    setSelectedElementTypeId: (state, action) => {
      state.selectedElementTypeId = action.payload;
    },
    setSelectedElementTypeGroup: (state, action) => {
      state.selectedElementTypeGroup = action.payload;
    },
    setSelectedElementTypeGroupInScope: (state, action) => {
      state.selectedElementTypeGroupInScope = action.payload;
    },
    setAnnotCreationTool: (state, action) => {
      state.annotCreationTool = action.payload;
    },
    addLoadedMeasurementsModel: (state, action) => {
      state.loadedMeasurementsModels.push(action.payload);
    },
    setModificationsForModelById: (state, action) => {
      const {modelId, modifiedIds} = action.payload;
      state.modifications[modelId] = modifiedIds;
    },
    removeModificationsForModelById: (state, action) => {
      const modelId = action.payload;
      delete state.modifications[modelId];
    },
    setMeasurements: (state, action) => {
      state.items = action.payload;
    },
    updateTempMeasurement: (state, action) => {
      const {
        elementTypeId,
        codeName,
        category,
        measurementsModelId,
        measurementsModel,
        preset,
        color,
        isVoid,
        dotted,
        height,
        heightE,
        heightN,
        heights,
        side,
        faces,
        slopeH,
        depth,
        width,
        rot,
        dim1,
        dim2,
        dim3,
        offset,
        offset2d,
        zInf,
        zSup,
        zFrom,
        strokeM,
        drawingShape,
        builtAt,
        exeComment,
        geoCat,
        isRoom,
        isGhost,
        unit,
        quantityTemplates,
        zone,
        sector,
        room,
        material,
        ressource,
        hostedTypes,
        voids,
        phase,
        version,
      } = action.payload;
      state.versionId = version?.id;
      state.selectedElementTypeId = elementTypeId;
      state.codeName = codeName;
      state.selectedCategoryId = category?.id;
      state.selectedMeasurementsModelId = measurementsModelId
        ? measurementsModelId
        : measurementsModel?.id;
      state.zoneId = zone?.id;
      state.sectorId = sector?.id;
      state.roomId = room?.id;
      state.materialId = material?.id;
      state.ressourceId = ressource?.id;
      state.phaseId = phase?.id;
      state.selectedPresetId = preset?.id;
      state.drawVoid = isVoid;
      state.dotted = dotted;
      state.color = color;
      state.strokeM = strokeM;
      state.height = height;
      state.heightE = heightE;
      state.heightN = heightN;
      state.heights = heights;
      state.side = side;
      state.faces = faces;
      state.slopeH = slopeH;
      state.width = width;
      state.dim1 = dim1;
      state.dim2 = dim2;
      state.dim3 = dim3;
      state.rot = rot;
      state.offset2d = offset2d;
      //
      state.zFrom = zFrom;
      if (zFrom === "offset") {
        state.offset = offset;
        state.zInf = null;
        state.zSup = null;
      } else if (zFrom === "zInf") {
        state.offset = null;
        state.zInf = zInf;
        state.zSup = null;
      } else if (zFrom === "zSup") {
        state.offset = null;
        state.zInf = null;
        state.zSup = zSup;
      }
      state.depth = depth;
      state.drawingShape = drawingShape;
      state.builtAt = builtAt;
      state.exeComment = exeComment;
      state.geoCat = geoCat;
      state.isRoom = isRoom;
      state.isGhost = isGhost;
      state.unit = unit;
      state.quantityTemplates = quantityTemplates;
      state.hostedTypes = hostedTypes;
      state.voids = voids;
    },
    updateTempMeasurementFromElementType: (state, action) => {
      const type = action.payload;
      if (type) {
        state.selectedElementTypeId = type.id;
        state.drawingShape = type.drawingShape;
        state.drawVoid = type.isVoid;
        state.dotted = type.dotted;
        state.color = type.color;
        state.materialId = type.materialId;
        // v comment to avoid weird behavior (fixed dim2 for ex) when changing type v
        if (typeof type.dim1 === "number") state.dim1 = type.dim1;
        if (typeof type.dim2 === "number") state.dim2 = type.dim2;
        if (typeof type.height === "number") state.height = type.height;
        //state.dim1 = type.dim1; // we remove the condition if(type.dim1) to reset dim1
        //state.dim2 = type.dim2;
        //state.height = type.height;
        state.dim3 = type.dim3;
        if (typeof type.offset === "number") state.offset = type.offset;
        //state.offset = type.offset;

        state.heights = type.heights;
        state.unit = type.unit;
      }
    },
    setEditedTimeStamp: (state, action) => {
      state.editedTimestamp = action.payload;
    },
    setShowQuantitiesInViewer: (state, action) => {
      state.showQuantitiesInViewer = action.payload;
    },
    // resetMeasurements: (state) => {
    //   state.items = [];
    //   state.measurementsMode = null;
    // },
    setSelectedCategoryId: (state, action) => {
      state.selectedCategoryId = action.payload;
    },
    addCategory: (state, action) => {
      state.categories.push(action.payload);
    },
    addCategories: (state, action) => {
      const newCs = action.payload;
      const oldCIds = state.categories.map((c) => c.id);
      const filteredCs = newCs.filter((c) => !oldCIds.includes(c.id));
      state.categories.push(...filteredCs);
    },
    deleteCategory: (state, action) => {
      const category = action.payload;
      state.categories = state.categories.filter((c) => c.id !== category.id);
    },
    setHeight: (state, action) => {
      state.height = action.payload;
    },
    setSelectedMeasurementIds: (state, action) => {
      state.selectedMeasurementIds = action.payload;
    },
    updateSelectedMeasurementIds: (state, action) => {
      const setA = new Set(state.selectedMeasurementIds);
      const setB = new Set(action.payload);
      state.selectedMeasurementIds = [...symmetricDifference(setA, setB)];
      // const ids = action.payload;
      // const oldIds = state.selectedMeasurementIds;
      // const a =  new Set(oldIds);
      // const b =  new Set(ids);
      // state.selectedMeasurementIds = [
      //   ...ids.filter((x) => !a.has(x)),
      //   ...oldIds.filter((x) => !b.has(x)),
      // ];
    },
    addSelectedMeasurementIds: (state, action) => {
      const oldIds = state.selectedMeasurementIds;
      const newIds = action.payload;
      const ids = [...new Set([...oldIds, ...newIds])];
      state.selectedMeasurementIds = ids;
    },
    removeSelectedMeasurementIds: (state, action) => {
      state.selectedMeasurementIds = state.selectedMeasurementIds.filter(
        (id) => !action.payload.includes(id)
      );
    },
    setIsSnapping: (state, action) => {
      state.isSnapping = action.payload;
    },
    setIsDrawingOneByOne: (state, action) => {
      state.isDrawingOneByOne = action.payload;
    },
    setDataGridMode: (state, action) => {
      state.dataGridMode = action.payload;
    },
    setDrawVoid: (state, action) => {
      state.drawVoid = action.payload;
    },
    setOpenDrawingDetail: (state, action) => {
      state.openDrawingDetail = action.payload;
    },
    setCanEditMeasurement: (state, action) => {
      state.canEditMeasurement = action.payload;
    },
    setIsEditingMeasurement: (state, action) => {
      state.isEditingMeasurement = action.payload;
    },
    setMeasurementsEditedFromForm: (state, action) => {
      state.measurementsEditedFromForm = action.payload;
    },
    setIsEditingMeasurementGeometry: (state, action) => {
      state.isEditingMeasurementGeometry = action.payload;
    },
    // scope
    setScopeSelectionMode: (state, action) => {
      state.scopeSelectionMode = action.payload;
    },
    setScopeDefaultSelectionMode: (state, action) => {
      state.scopeDefaultSelectionMode = action.payload;
    },
    setScopeMeasurementsCount: (state, action) => {
      state.scopeMeasurementsCount = action.payload;
    },
    setMeasurementsModelsInScope: (state, action) => {
      state.measurementsModelsInScope = action.payload;
    },
    addMeasurementsModelToScope: (state, action) => {
      state.measurementsModelsInScope.push(action.payload);
    },
    setScopeInputId: (state, action) => {
      state.scopeInputId = action.payload;
    },
    setElementTypesInScope: (state, action) => {
      state.elementTypesInScope = action.payload;
    },
    addElementTypesInScope: (state, action) => {
      const elementTypes = action.payload;
      const newElementTypes = state.elementTypesInScope.filter(
        (s) => !elementTypes.includes(s)
      );
      state.elementTypesInScope = [...newElementTypes, ...elementTypes];
    },
    setElementTypesInScopeByModels: (state, action) => {
      const modelIds = action.payload;
      const ms = state.items.filter((m) =>
        modelIds.includes(m.measurementsModelId)
      );
      const types = [...new Set(ms.map((m) => m.elementTypeId))];
      state.elementTypesInScope = types;
    },
    setKeywordsInScope: (state, action) => {
      state.keywordsInScope = action.payload;
    },
    // mobile shortcuts
    setShortcutMobileZoneId: (state, action) => {
      state.shortcutMobileZoneId = action.payload;
    },
    // filters
    setFilters: (state, action) => {
      const {
        // filterNoVoids,
        filterZoneId,
        filterSectorId,
        filterRoomId,
        filterMaterialId,
        filterElementTypeId,
        filterRessourceId,
        filterPhaseId,
        filterP1,
        filterP2,
        filterP3,
        reset,
      } = action.payload;
      if (filterElementTypeId || reset)
        state.filterElementTypeId = filterElementTypeId;
      if (filterSectorId || reset) state.filterSectorId = filterSectorId;
      if (filterZoneId || reset) state.filterZoneId = filterZoneId;
      if (filterRoomId || reset) state.filterRoomId = filterRoomId;
      if (filterMaterialId || reset) state.filterMaterialId = filterMaterialId;
      if (filterRessourceId || reset)
        state.filterRessourceId = filterRessourceId;
      if (filterPhaseId || reset) state.filterPhaseId = filterPhaseId;
      if (filterP1 || reset) state.filterP1 = filterP1;
      if (filterP2 || reset) state.filterP2 = filterP2;
      if (filterP3 || reset) state.filterP3 = filterP3;
    },
    setFilterNoVoids: (state, action) => {
      state.filterNoVoids = action.payload;
    },
    setFilterZoneId: (state, action) => {
      state.filterZoneId = action.payload;
    },
    setFilterSectorId: (state, action) => {
      state.filterSectorId = action.payload;
    },
    setFilterRoomId: (state, action) => {
      state.filterRoomId = action.payload;
    },
    setFilterMaterialId: (state, action) => {
      state.filterMaterialId = action.payload;
    },
    setFilterRessourceId: (state, action) => {
      state.filterRessourceId = action.payload;
    },
    setFilterPhaseId: (state, action) => {
      state.filterPhaseId = action.payload;
    },
    setFilterP1: (state, action) => {
      state.filterP1 = action.payload;
    },
    setFilterP2: (state, action) => {
      state.filterP2 = action.payload;
    },
    setFilterP3: (state, action) => {
      state.filterP3 = action.payload;
    },
    setFilterGeoCat: (state, action) => {
      state.filterGeoCat = action.payload;
    },
    setFilterElementTypeId: (state, action) => {
      state.filterElementTypeId = action.payload;
    },
    setFilterByBuiltAt: (state, action) => {
      state.filterByBuiltAt = action.payload;
    },
    setFilterByBuiltAtMin: (state, action) => {
      state.filterByBuiltAtMin = action.payload;
    },
    setFilterByBuiltAtMax: (state, action) => {
      state.filterByBuiltAtMax = action.payload;
    },
    setTodoInScope: (state, action) => {
      state.todoInScope = action.payload;
    },
    setZonesInScope: (state, action) => {
      state.zonesInScope = action.payload;
    },
    addZonesInScope: (state, action) => {
      const zones = action.payload;
      const newZones = state.zonesInScope.filter((s) => !zones.includes(s));
      state.zonesInScope = [...newZones, ...zones];
    },
    setZonesInScopeObo: (state, action) => {
      state.zonesInScopeObo = action.payload;
    },
    setSectorsInScope: (state, action) => {
      state.sectorsInScope = action.payload;
    },
    addSectorsInScope: (state, action) => {
      const sectors = action.payload;
      const newSectors = state.sectorsInScope.filter(
        (s) => !sectors.includes(s)
      );
      state.sectorsInScope = [...newSectors, ...sectors];
    },
    setSectorsInScopeObo: (state, action) => {
      state.sectorsInScopeObo = action.payload;
    },
    setRoomsInScope: (state, action) => {
      state.roomsInScope = action.payload;
    },
    setDoneInScope: (state, action) => {
      state.doneInScope = action.payload;
    },
    setMoreFilters: (state, action) => {
      state.moreFilters = action.payload;
    },
    addDateToShortcutBuiltAts: (state, action) => {
      const date = action.payload;
      const exists = state.shortcutBuiltAts.includes(date);
      if (!exists) state.shortcutBuiltAts.push(date);
    },
    removeDateFromShortcutBuiltAts: (state, action) => {
      state.shortcutBuiltAts = state.shortcutBuiltAts.filter(
        (d) => d !== action.payload
      );
    },
    toggleShortcutBuiltAt: (state, action) => {
      const builtAt = action.payload;
      const exists = state.shortcutBuiltAts.includes(builtAt);
      if (exists) {
        state.shortcutBuiltAts = state.shortcutBuiltAts.filter(
          (d) => d !== builtAt
        );
      } else {
        state.shortcutBuiltAts.push(builtAt);
      }
    },
    initShortcutBuiltAts: (state) => {
      const builtAts = [
        ...new Set(state.items.map((m) => (m.builtAt ? m.builtAt : null))),
      ];
      state.shortcutBuiltAts = builtAts;
    },
    setFiltersCategoryIds: (state, action) => {
      state.filtersCategoryIds = action.payload;
    },
    setFiltersPresetIds: (state, action) => {
      state.filtersPresetIds = action.payload;
    },
    initShortcutCategories: (state) => {
      state.shortcutCategories = state.items.map((m) => m.categoryId);
    },
    addCategoryToShortcut: (state, action) => {
      state.shortcutCategories.push(action.payload);
    },
    removeCategoryFromShortcut: (state, action) => {
      state.shortcutCategories = state.shortcutCategories.filter(
        (id) => id !== action.payload
      );
    },
    toggleShortcutCategory: (state, action) => {
      const categoryId = action.payload;
      const exists = state.shortcutCategories.includes(categoryId);
      if (exists) {
        state.shortcutCategories = state.shortcutCategories.filter(
          (id) => id !== categoryId
        );
      } else {
        state.shortcutCategories.push(categoryId);
      }
    },
    setSelectedPresetId: (state, action) => {
      state.selectedPresetId = action.payload;
    },
    addPreset: (state, action) => {
      state.presets.push(action.payload);
    },
    addPresets: (state, action) => {
      const newPs = action.payload;
      const newPIds = newPs.map(
        (p) => p.measurementsModelName + "-" + p.typeCode
      );
      const filteredPs = state.presets.filter(
        (p) => !newPIds.includes(p.measurementsModelName + "-" + p.typeCode)
      );
      state.presets = [...filteredPs, ...newPs];
    },
    deletePresets: (state) => {
      state.presets = [];
    },
    addTempPresetLockedField: (state, action) => {
      let r = [...state.tempPresetLockedFields, action.payload];
      return [...new Set(r)];
    },
    removeTempPresetLockedField: (state, action) => {
      state.tempPresetLockedFields = state.tempPresetLockedFields.filter(
        (r) => r !== action.payload
      );
    },
    resetTempPresetLockedFields: (state) => {
      state.tempPresetLockedFields = [];
    },
    setTempPresetLockedFields: (state, action) => {
      state.tempPresetLockedFields = action.payload;
    },
    // preset
    addPresetToShortcut: (state, action) => {
      state.shortcutPresets.push(action.payload);
    },
    removePresetFromShortcut: (state, action) => {
      state.shortcutPresets = state.shortcutPresets.filter(
        (id) => id !== action.payload
      );
    },
    toggleShortcutPreset: (state, action) => {
      const presetId = action.payload;
      const exists = state.shortcutPresets.includes(presetId);
      if (exists) {
        state.shortcutPresets = state.shortcutPresets.filter(
          (id) => id !== presetId
        );
      } else {
        state.shortcutPresets.push(presetId);
      }
    },
    initShortcutPresets: (state) => {
      state.shortcutPresets = state.items.map((m) => m.presetId);
    },
    // sectors
    setShortcutSectors: (state, action) => {
      state.shortcutSectors = action.payload;
    },
    addToShortcutSectors: (state, action) => {
      const sectors = action.payload;
      const newSectors = state.shortcutSectors.filter(
        (s) => !sectors.includes(s)
      );
      state.shortcutSectors = [...newSectors, ...sectors];
    },
    // phases
    setPhasesInScope: (state, action) => {
      state.phasesInScope = action.payload;
    },
    toggleShortcutPhase: (state, action) => {
      const phaseId = action.payload;
      const exists = state.shortcutPhases.includes(phaseId);
      if (exists) {
        state.shortcutPhases = state.shortcutPhases.filter(
          (id) => id !== phaseId
        );
      } else {
        state.shortcutPhases.push(phaseId);
      }
    },
    setShortcutPhases: (state, action) => {
      state.shortcutPhases = action.payload;
    },
    addToShortcutPhases: (state, action) => {
      const phases = action.payload;
      const newPhases = state.shortcutPhases.filter((s) => !phases.includes(s));
      state.shortcutPhases = [...newPhases, ...phases];
    },
    // elementType
    initShortcutElementTypes: (state) => {
      let types = state.items.map((m) => m.elementTypeId);
      types = [...new Set(types)];
      state.shortcutElementTypes = types;
    },
    setShortcutElementTypeGroups: (state, action) => {
      state.shortcutElementTypeGroups = action.payload;
    },
    setShortcutElementTypes: (state, action) => {
      state.shortcutElementTypes = action.payload;
    },
    toggleShortcutElementType: (state, action) => {
      const elementTypeId = action.payload;
      if (!state.shortcutElementTypes) state.shortcutElementTypes = [];
      const exists = state.shortcutElementTypes.includes(elementTypeId);
      if (exists) {
        state.shortcutElementTypes = state.shortcutElementTypes.filter(
          (id) => id !== elementTypeId
        );
      } else {
        state.shortcutElementTypes.push(elementTypeId);
      }
    },
    addElementTypeToShortcut: (state, action) => {
      const elementTypeId = action.payload;
      if (!state.shortcutElementTypes) state.shortcutElementTypes = [];
      const existing = state.shortcutElementTypes.includes(elementTypeId);
      if (!existing) state.shortcutElementTypes.push(action.payload);
    },
    addToShortcutElementTypes: (state, action) => {
      const elementTypes = action.payload;
      if (!state.shortcutElementTypes) state.shortcutElementTypes = [];
      const newElementTypes = state.shortcutElementTypes.filter(
        (s) => !elementTypes.includes(s)
      );
      state.shortcutElementTypes = [...newElementTypes, ...elementTypes];
    },
    removeElementTypeFromShortcut: (state, action) => {
      if (!state.shortcutElementTypes) state.shortcutElementTypes = [];
      state.shortcutElementTypes = state.shortcutElementTypes.filter(
        (id) => id !== action.payload
      );
    },
    setAutoGeo3D: (state, action) => {
      state.autoGeo3D = action.payload;
    },
    updateMeasurementsShortcuts: (state, action) => {
      const {elementTypes, builtAts} = action.payload;
      state.shortcutElementTypes = elementTypes;
      state.shortcutBuiltAts = builtAts;
    },
    setSelectedTypesInDatagrid: (state, action) => {
      state.selectedTypesInDatagrid = action.payload;
    },
    setSelectedRessourcesInDatagrid: (state, action) => {
      state.selectedRessourcesInDatagrid = action.payload;
    },
    setSelectedRoomsInDatagrid: (state, action) => {
      state.selectedRoomsInDatagrid = action.payload;
    },
    setSelectedMaterialsInDatagrid: (state, action) => {
      state.selectedMaterialsInDatagrid = action.payload;
    },
    setOboInDatagrid: (state, action) => {
      state.oboInDatagrid = action.payload;
    },
    loadedModel: (state, action) => {
      state.loadedModels.push(action.payload);
    },
    setInitHomeMeasurementsCamera: (state, action) => {
      state.initHomeMeasurementsCamera = action.payload;
    },
    setFiltersAppliedToMeasurementsIn3DAt: (state, action) => {
      state.filtersAppliedToMeasurementsIn3DAt = action.payload;
    },
    setApplyFilters: (state, action) => {
      state.applyFilters = action.payload;
    },
    setFilterRefreshDate: (state, action) => {
      state.filterRefreshDate = action.payload;
    },
    // multi selection
    setIsEditingMeasurementMulti: (state, action) => {
      state.isEditingMeasurementMulti = action.payload;
    },
    setEditedMeasurementMulti: (state, action) => {
      state.editedMeasurementMulti = action.payload;
    },
    setCopiedMeasurements: (state, action) => {
      state.copiedMeasurements = action.payload;
    },
    setPasteDeltaX: (state, action) => {
      state.pasteDeltaX = action.payload;
    },
    setPasteDeltaY: (state, action) => {
      state.pasteDeltaY = action.payload;
    },
    setPasteFlipV: (state, action) => {
      state.pasteFlipV = action.payload;
    },
    setPasteFlipH: (state, action) => {
      state.pasteFlipH = action.payload;
    },
    setPasteMulti: (state, action) => {
      state.pasteMulti = action.payload;
    },
    setPasteToZone: (state, action) => {
      state.pasteToZone = action.payload;
    },
    setHideSavedMeasurements: (state, action) => {
      state.hideSavedMeasurements = action.payload;
    },
    // show - hide
    setMeasIdsShowedInViewer3d: (state, action) => {
      state.measIdsShowedInViewer3d = action.payload;
    },
    setMeasShowedInViewer3d: (state, action) => {
      state.measShowedInViewer3d = action.payload;
    },
    setMeasurementsShowedInViewer3dWithoutShortcut: (state, action) => {
      state.measurementsShowedInViewer3dWithoutShortcut = action.payload;
    },
    setMeasIdsShowedInViewerPdf: (state, action) => {
      state.measIdsShowedInViewerPdf = action.payload;
    },
  },
  extraReducers: {
    // "viewer3D/setSelectedPdfModelId": (state, action) => {
    //   state.pdfModelId = action.payload;
    // },
    "colorings/fetchColoringScene/fulfilled": (state, action) => {
      if (action.payload) {
        const {model} = action.payload;
        state.selectedMeasurementsModelId = model?.clientId;
      }
    },
  },
});

export const {
  setMeasIdsShowedInViewer3d,
  setMeasShowedInViewer3d,
  setMeasIdsShowedInViewerPdf,
  setMeasurementsShowedInViewer3dWithoutShortcut,
  // removeModifications,
  setShowDefaultMeasurementForm,
  setModuleViewMode,
  setApplyFilters,
  setFilteredMeasurementsViewMode,
  setFilteredMeasurementsViewModeBckp,
  setShowMeasurements,
  setMeasuredLength,
  setStrokeM,
  setMeasurementsMode,
  setColor,
  setWidth,
  setDim1,
  setDepth,
  setDrawingShape,
  setShowQuantitiesInViewer,
  setAnnotCreationTool,
  updateTempMeasurement,
  updateTempMeasurementFromElementType,
  setSelectedCategoryId,
  addCategory,
  addCategories,
  deleteCategory,
  setHeight,
  setSelectedMeasurementIds,
  updateSelectedMeasurementIds,
  addSelectedMeasurementIds,
  setSelectedElementTypeId,
  removeSelectedMeasurementIds,
  setQuantityTemplates,
  setIsSnapping,
  setIsDrawingOneByOne,
  setDataGridMode,
  setDrawVoid,
  setOpenDrawingDetail,
  setCanEditMeasurement,
  setIsEditingMeasurement,
  setIsEditingMeasurementGeometry,
  setScopeSelectionMode,
  setScopeDefaultSelectionMode,
  setScopeMeasurementsCount,
  setMeasurementsModelsInScope,
  addMeasurementsModelToScope,
  setFilterByBuiltAt,
  setFilterByBuiltAtMin,
  setFilterByBuiltAtMax,
  setTodoInScope,
  setZonesInScope,
  setZonesInScopeObo,
  setSectorsInScope,
  setSectorsInScopeObo,
  setMoreFilters,
  setDoneInScope,
  setScopeInputId,
  setElementTypesInScope,
  setElementTypesInScopeByModels,
  setKeywordsInScope,
  addDateToShortcutBuiltAts,
  removeDateFromShortcutBuiltAts,
  initShortcutBuiltAts,
  toggleShortcutBuiltAt,
  setSelectedRessourceGroup,
  setFilterNoVoids,
  setFilterSectorId,
  setFilterRoomId,
  setFilterMaterialId,
  setFilterGeoCat,
  setFilterElementTypeId,
  setFilterZoneId,
  setFilterRessourceId,
  setFilterPhaseId,
  setFilterP1,
  setFilterP2,
  setFilterP3,
  setFilters,
  setFiltersCategoryIds,
  setFiltersPresetIds,
  initShortcutCategories,
  addCategoryToShortcut,
  removeCategoryFromShortcut,
  toggleShortcutCategory,
  setSelectedPresetId,
  addTempPresetLockedField,
  removeTempPresetLockedField,
  resetTempPresetLockedFields,
  setTempPresetLockedFields,
  addPreset,
  addPresets,
  deletePresets,
  addPresetToShortcut,
  removePresetFromShortcut,
  toggleShortcutPreset,
  initShortcutPresets,
  setSelectedMeasurementsModelId,
  setShortcutSectors,
  toggleShortcutPhase,
  initShortcutElementTypes,
  setShortcutElementTypes,
  setShortcutElementTypeGroups,
  toggleShortcutElementType,
  addElementTypeToShortcut,
  removeElementTypeFromShortcut,
  setEditedMeasurement,
  setMeasurementInPopover,
  setAutoGeo3D,
  updateMeasurementsShortcuts,
  setSelectedTypesInDatagrid,
  setSelectedRessourcesInDatagrid,
  setSelectedRoomsInDatagrid,
  setSelectedMaterialsInDatagrid,
  setOboInDatagrid,
  addLoadedModel,
  setFiltersAppliedToMeasurementsIn3DAt,
  addLoadedMeasurementsModel,
  setFilterRefreshDate,
  addToShortcutSectors,
  addSectorsInScope,
  addElementTypesInScope,
  addToShortcutElementTypes,
  addZonesInScope,
  setPhasesInScope,
  setShortcutPhases,
  addToShortcutPhases,
  setSelectedFormGroup,
  setIsEditingMeasurementMulti,
  setEditedMeasurementMulti,
  setSelectedElementTypeGroup,
  setSelectedElementTypeGroupInScope,
  setShortcutMobileZoneId,
  setRoomsInScope,
  //
  setCopiedMeasurements,
  setPasteDeltaX,
  setPasteDeltaY,
  setPasteMulti,
  setPasteToZone,
  setPasteFlipH,
  setPasteFlipV,
  //
  setMeasurementsEditedFromForm,
  setEditedTimeStamp,
  setHideSavedMeasurements,
  setMeasurements,
  setModificationsForModelById,
  removeModificationsForModelById,
} = measurementsSlice.actions;

export default measurementsSlice.reducer;
