import {Box} from "@mui/material";
import SectionConfigModulesColumn from "./SectionConfigModulesColumn";

import modulesByColumn from "../data/modulesByColumn";

export default function SectionConfigModules({scene}) {
  const cols = modulesByColumn;

  return (
    <Box sx={{display: "flex", justifyContent: "space-between"}}>
      {cols.map((col) => {
        return (
          <Box sx={{p: 1, px: 2, minWidth: 0}}>
            <SectionConfigModulesColumn
              title={col.label}
              moduleKeys={col.modules}
              scene={scene}
            />
          </Box>
        );
      })}
    </Box>
  );
}
