import {Box} from "@mui/material";
import {lighten} from "@mui/material/styles";
import theme from "Styles/theme";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import useSceneModule from "../useSceneModule";
import useVersionsByScene from "Features/versions/hooks/useVersionsByScene";

import TogglePdf3d from "./TogglePdf3d";
import ButtonOpenViewers from "./ButtonOpenViewers";
import usePageType from "../usePageType";

import ButtonDialogSync from "Features/scenes/components/ButtonDialogSync";
import SelectorPdfModelInAppTopBar from "Features/pdf/components/SelectorPdfModelInAppTopBar";

import SectionContextOverviewInTopBar from "Features/overviewer/components/SectionContextOverviewInTopBar";
import useSelectedPdfModel from "Features/pdf/useSelectedPdfModel";

import ContainerDisconnectedMode from "./ContainerDisconnectedMode";
import usePackagesByScene from "Features/elementPackages/usePackagesByScene";
import ContainerCallToActionEditMeasurementsInTopBar from "Features/measurements/components/ContainerCallToActionEditMeasurementsInTopBar";

import useIsStagingEnv from "Features/overviewer/hooks/useIsStagingEnv";
import useInputsByScene from "Features/inputs/hooks/useInputsByScene";
import SelectorPackageInTopbarOverview from "Features/elementPackages/components/SelectorPackageInTopbarOverview";

export default function TopBarOverview({scene, caplaEditor, viewer}) {
  const sceneModule = useSceneModule();
  const dispatch = useDispatch();
  const isStagingEnv = useIsStagingEnv();
  const navigate = useNavigate();

  const os = useSelector((s) => s.ui.openSections);
  const pageType = usePageType();
  const versions = useVersionsByScene(scene);

  const selectedPdfModel = useSelectedPdfModel();
  const packages = usePackagesByScene(scene);
  const pdfPackages = packages.filter(
    (model) =>
      selectedPdfModel?.id && model.fromModel?.modelId === selectedPdfModel.id
  );
  const firstPdfPackage = pdfPackages?.length > 0 ? pdfPackages[0] : null;

  const inputs = useInputsByScene(scene);
  const lastPackageId = useSelector((s) => s.packages.lastPackageId);

  // helpers

  const noVersions = !versions.length > 0;

  const showPdfSelector =
    os.fixedViewersBox &&
    !(
      ["ONBOARDING"].includes(sceneModule) &&
      !selectedPdfModel &&
      inputs.length === 0
    );

  const isRemoteScene = Boolean(scene?.id);

  // helpers - show

  const hideTopBar = ["MODULES"].includes(sceneModule);
  const showScopeAndCounter = !["INPUTS"].includes(sceneModule);

  const showCallToActionEdit =
    !["EDIT_MEASUREMENTS", "HOME", "ONBOARDING"].includes(sceneModule) &&
    lastPackageId; //&&
  //firstPdfPackage &&
  //!firstPdfPackage.measCount;

  const showContextOverview = !["HOME", "ONBOARDING"].includes(sceneModule);

  const showPackageSelector = packages?.length > 0;

  // handlers

  return (
    <Box
      sx={{
        width: 1,
        height: "36px",
        boxSizing: "border-box",
        display: hideTopBar ? "none" : "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => lighten(theme.palette.common.caplaBlack, 0.2),
        color: "white",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          minWidth: 0,
        }}
      >
        {showPdfSelector && (
          <Box
            sx={{
              display: "flex",
              minWidth: 0,
              bgcolor: "common.caplaBlack",
              height: "36px",
              alignItems: "center",
              justifyContent: "center",
              px: 1,
            }}
          >
            <SelectorPdfModelInAppTopBar model={selectedPdfModel} />
          </Box>
        )}
        {showPackageSelector && <SelectorPackageInTopbarOverview />}
        <Box sx={{px: 1}} />
        {showCallToActionEdit && (
          <Box sx={{mx: 1}}>
            <ContainerCallToActionEditMeasurementsInTopBar scene={scene} />
          </Box>
        )}
        {/* {showScopeAndCounter && !showCallToActionEdit && (
          <Box sx={{px: 1, display: "flex", minWidth: 0}}>
            <SectionScopeAndCounterInTopBar />
          </Box>
        )} */}
        {showContextOverview && (
          <Box>
            <SectionContextOverviewInTopBar
              scene={scene}
              caplaEditor={caplaEditor}
              shrink
            />
          </Box>
        )}
        {isRemoteScene && (
          <Box sx={{display: "flex"}}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                px: 1,
                ml: 1,
              }}
            >
              <ButtonDialogSync scene={scene} size="small" />
            </Box>
          </Box>
        )}
        {!isRemoteScene && (
          <Box sx={{ml: 2}}>
            <ContainerDisconnectedMode />
          </Box>
        )}
      </Box>
      {!viewer &&
        pageType !== "HOME" &&
        !["LIBRARY", "PRICINGS", "MODULES"].includes(sceneModule) && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: 0,
            }}
          >
            {os.outlet ? (
              <ButtonOpenViewers />
            ) : (
              <TogglePdf3d caplaEditor={caplaEditor} />
            )}
          </Box>
        )}
    </Box>
  );
}
