import {useNavigate, useSearchParams} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {
  setSelectedSceneClientId,
  setNewScene,
  createScene,
  setCreatedSceneClientId,
} from "./scenesSlice";
import {createRemoteScene} from "./scenesSlice";
import {setOpenSections} from "Features/ui/uiSlice";

import {unwrapResult} from "@reduxjs/toolkit";

import {setOpenSelectionPanel} from "Features/selection/selectionSlice";

import elementTypesDefault from "Features/elementTypes/data/elementTypesDefault";

import useAccessToken from "Features/auth/useAccessToken";

export default function useCreateScene() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useAccessToken();
  const [searchParams] = useSearchParams();

  // helpers

  const suffixUrl = "";

  // handlers

  async function createNewScene(newScene, options) {
    try {
      const connectSceneOnCreation = options?.connectSceneOnCreation;
      const sectors = options?.sectors
        ? options.sectors
        : [{id: nanoid(), name: "Général", code: "GEN", z: 0}];

      const elementTypes = options?.elementTypes
        ? options.elementTypes
        : [...elementTypesDefault];

      const openWhenCreated = options?.openWhenCreated;

      let remoteScene;

      const d = new Date();
      const createdAt = d.toISOString();

      const result = await dispatch(
        createScene({...newScene, createdAt, data: {sectors, elementTypes}})
      );
      const {scene} = unwrapResult(result);

      // connect scene
      if (connectSceneOnCreation && accessToken) {
        const result = await dispatch(
          createRemoteScene({sceneClientId: scene.clientId, accessToken})
        );
        remoteScene = unwrapResult(result);
        console.log("remoteScene created", remoteScene);
      }

      if (openWhenCreated) {
        dispatch(setNewScene({}));
        dispatch(setSelectedSceneClientId(scene.clientId));

        dispatch(setCreatedSceneClientId(scene.clientId));

        dispatch(setOpenSelectionPanel(false));
        dispatch(
          setOpenSections({
            outlet: false,
            fixedViewersBox: true,
            viewer3D: false,
            callToAction: false,
          })
        );

        const sceneId = remoteScene?.id ? remoteScene.id : scene.clientId;
        navigate(`/bimboxes/${sceneId}/onboarding` + suffixUrl);
      }
    } catch (e) {
      console.log("error creating scene");
    }
  }

  return createNewScene;
}
