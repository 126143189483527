export default function testBboxesOverlap(bBoxA, bBoxB) {
  // bBoxs are defined by the top left corner coordinates (x,y), width and height
  const tolerance = 0.05; // 5% tolerance

  const ax1 = bBoxA.x;
  const ay1 = bBoxA.y;
  const ax2 = bBoxA.x + bBoxA.width;
  const ay2 = bBoxA.y + bBoxA.height;

  const bx1 = bBoxB.x;
  const by1 = bBoxB.y;
  const bx2 = bBoxB.x + bBoxB.width;
  const by2 = bBoxB.y + bBoxB.height;

  const widthA = bBoxA.width;
  const heightA = bBoxA.height;
  const widthB = bBoxB.width;
  const heightB = bBoxB.height;

  const overlapX = Math.max(0, Math.min(ax2, bx2) - Math.max(ax1, bx1));
  const overlapY = Math.max(0, Math.min(ay2, by2) - Math.max(ay1, by1));

  const overlapArea = overlapX * overlapY;
  const areaA = widthA * heightA;
  const areaB = widthB * heightB;

  const toleranceAreaA = areaA * tolerance;
  const toleranceAreaB = areaB * tolerance;

  return overlapArea > toleranceAreaA || overlapArea > toleranceAreaB;
}
