import {
  Badge,
  Box,
  Typography,
  ListItemButton,
  IconButton,
} from "@mui/material";

import ContainerSelectedVersionsOverview from "Features/versions/components/ContainerSelectedVersionsOverview";
import getListItemStyle from "Styles/getListItemStyle";

export default function ListItemButtonPackage({
  model,
  loadingStatusNum,
  selected,
  onClick,
  onLinkClick,
  versions,
  selectedVersionForMeasurements,
  modificationsCount,
}) {
  //
  let {bgcolor, color, fontWeight} = getListItemStyle(model);

  if (loadingStatusNum !== 4) {
    color = "text.secondary";
  }
  let numColor = "text.primary";
  if (loadingStatusNum === 1) numColor = "divider";
  if (loadingStatusNum === 2) numColor = "text.secondary";

  if (model.archived) {
    numColor = "divider";
    color = "divider";
  }

  // handlers

  function handleLinkClick(e) {
    e.stopPropagation();
    e.preventDefault();
    onLinkClick(model);
  }

  function handleClick(e) {
    console.log("debug 2305 click");
    onClick(model);
  }

  return (
    <ListItemButton
      onClick={handleClick}
      disableGutters
      divider={true}
      selected={selected}
      dense
      sx={{bgcolor, color, fontWeight}}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: 1,
        }}
      >
        <Box sx={{display: "flex", alignItems: "center"}}>
          <Box sx={{px: 1}}>
            {!model.isPdf && (
              <ContainerSelectedVersionsOverview
                disabled={model.archived}
                selectedVersionIds={model.revisionIds}
                versions={
                  !selectedVersionForMeasurements
                    ? versions
                    : [selectedVersionForMeasurements]
                }
              />
            )}
          </Box>
          <Typography
            sx={{
              fontSize: 12,
              fontWeight,
              ":hover": {textDecoration: "underline"},
            }}
            onClick={handleLinkClick}
          >
            {model.name}
          </Typography>
        </Box>
        {!model.isPdf && (
          <Box sx={{pr: 2, display: "flex", alignItems: "baseline"}}>
            <Typography sx={{fontSize: 12, color: numColor}}>
              {model.count}
            </Typography>
            {modificationsCount > 0 && (
              <Box
                sx={{
                  height: 4,
                  width: 4,
                  bgcolor: "primary.main",
                  ml: 0.5,
                  borderRadius: "50%",
                }}
              />
            )}
          </Box>
        )}
      </Box>
    </ListItemButton>
  );
}
