import {useDispatch} from "react-redux";

import {setZonesInBuffer} from "Features/zones/zonesSlice";
import {setUseBufferInShortcut} from "Features/zones/zonesSlice";

import useZonesByScene from "Features/zones/hooks/useZonesByScene";

import {IconButton, Box, Typography, Tooltip} from "@mui/material";
import {
  HighlightAlt as Zone,
  TextFields as Text,
  FilterFrames as Pov,
} from "@mui/icons-material";

import {setShortcutElementTypes} from "Features/measurements/measurementsSlice";

export default function IconButtonPoi({
  poi,
  onClick,
  caplaEditor,
  scene,
  index,
  selected,
}) {
  const dispatch = useDispatch();

  // data

  const zones = useZonesByScene(scene);

  // helpers

  const background = (theme) =>
    `linear-gradient(to right,${theme.palette.secondary.main},${theme.palette.primary.main})`;

  // handlers

  function handleClick() {
    console.log("click on poi", poi);
    onClick(poi);
    // if (poi.type === "POV_3D") {
    //   // camera
    //   const camera = poi?.camera;
    //   //if (camera) caplaEditor?.editor3d?.sceneEditor.updateCamera(camera);
    //   // shortcults
    //   if (poi?.shortcutTypeIds && poi.shortcutTypeIds.length > 0) {
    //     dispatch(setShortcutElementTypes(poi.shortcutTypeIds));
    //   }
    //   // context
    //   const issueModelIds = poi.sceneContext?.imageModelIds;
    //   const zonesForBuffer = issueModelIds
    //     ?.map((mid) => zones.find((z) => z.imageModelId === mid))
    //     .filter((z) => z);
    //   dispatch(setZonesInBuffer(zonesForBuffer ? zonesForBuffer : []));
    //   const sceneContext = poi?.sceneContext;
    //   //
    //   const hideAllImageModels =
    //     !zonesForBuffer || zonesForBuffer?.length === 0;
    //   if (sceneContext) {
    //     caplaEditor?.editor3d?.sceneEditor.hideAllImageModels();
    //     caplaEditor?.editor3d?.sceneEditor.applyContext(
    //       sceneContext,
    //       !hideAllImageModels
    //     );
    //   }
    //   dispatch(setUseBufferInShortcut(true));
    //   //dispatch(setShortcutToolViewer3D("ZONES"));
    // }
  }
  return (
    <IconButton size="small" onClick={handleClick}>
      {/* {poi.type === "PDF_ZONE" && <Zone />}
      {poi.type === "PDF_TEXT" && <Text />}
      {poi.type === "POV_3D" && <Pov />} */}
      <Tooltip title={poi?.description}>
        <Box
          sx={{
            borderRadius: "50%",
            width: "24px",
            height: "24px",
            //background,
            bgcolor: selected ? "primary.flash" : "primary.main",
            color: "common.white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="body2" sx={{fontWeight: "bold"}}>
            {index}
          </Typography>
        </Box>
      </Tooltip>
    </IconButton>
  );
}
