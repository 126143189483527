export function rawPointToPointWithRot(point, rot, width, height) {
  //
  // rot = documentViewer.getCompleteRotation(pageNumber);
  // height = documentViewer.getPageHeight(pageNumber);
  // width = documentViewer.getPageWidth(pageNumber);

  // coordinates with rotation.
  let pointWithRot = {...point};
  if (rot === 1) {
    pointWithRot.x = height - point.y;
    pointWithRot.y = point.x;
  } else if (rot === 3) {
    pointWithRot.x = point.y;
    pointWithRot.y = width - point.x;
  } else if (rot === 2) {
    pointWithRot.x = width - point.x;
    pointWithRot.y = height - point.y;
  }

  return pointWithRot;
}
