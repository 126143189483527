import React, {useState} from "react";

import {useSelector} from "react-redux";

import {Box, CircularProgress, IconButton, Typography} from "@mui/material";
import {CloudUpload, Delete, CloudUpload as Upload} from "@mui/icons-material";

import FileSelectorButton from "Features/files/components/FileSelectorButton";

import DialogAddInputs from "./DialogAddInputs";

import useAccessToken from "Features/auth/useAccessToken";
import {uploadFileService} from "Features/files/services";

export default function SectionAddInputs({
  url,
  onChange,
  caplaEditor,
  onInputsAdded,
}) {
  const accessToken = useAccessToken();

  //strings

  const selectPdf = "Depuis mon ordinateur";
  const dragS = "Glisser & déposer des PDFs";
  const addFilesS = "Ajouter des fichiers PDFs";

  // state

  const [loading, setLoading] = useState(false);
  const [tempUrl, setTempUrl] = useState(null);
  const [hover, setHover] = useState();

  const [tempFiles, setTempFiles] = useState([]);
  const open = tempFiles?.length > 0;

  // data

  const scopeId = useSelector((s) => s.scopes.selectedScopeId);

  // handlers

  function handleFilesChange(files) {
    setTempFiles(files);
  }
  function handleClose() {
    setTempFiles([]);
    if (onInputsAdded) onInputsAdded();
  }

  // helper

  let showedUrl = url ? url : tempUrl;
  if (loading) showedUrl = tempUrl;

  // handlers - drag & drop

  function handleDragEnter() {
    setHover(true);
  }
  function handleDragLeave() {
    setHover(false);
  }

  function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    console.log("DROP");
    setHover(false);
    try {
      const files = [];
      if (e.dataTransfer.files instanceof FileList) {
        for (let i = 0; i < e.dataTransfer.items.length; i++) {
          const item = e.dataTransfer.items[i];
          files.push(item.getAsFile());
        }
        setTempFiles(files);
      }
    } catch (e) {
      console.log(e);
    }
  }

  function handleDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
  }
  // handlers - files

  function handleDelete() {
    onChange(null);
  }

  return (
    <>
      <DialogAddInputs
        editor3d={caplaEditor?.editor3d}
        open={open}
        onClose={handleClose}
        tempFiles={tempFiles}
        fromScopeId={scopeId}
      />
      <Box
        sx={{
          width: 1,
          height: 1,
          bgcolor: "common.white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Box
          sx={{
            px: 1,
            py: 0.5,
            bgcolor: "background.default",
            width: 1,
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography variant="body2" color="text.secondary">
            {addFilesS}
          </Typography>
        </Box>

        {!url && !loading && (
          <Box sx={{flex: 1, p: 2, width: 1}}>
            <Box
              sx={{
                bgcolor: "background.default",
                position: "relative",
                width: 1,
                height: 1,
                py: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Box
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                sx={{
                  position: "absolute",
                  height: 1,
                  width: 1,
                  border: (theme) => `2px dashed ${theme.palette.divider}`,
                  borderColor: (theme) =>
                    hover ? theme.palette.primary.flash : theme.palette.divider,
                }}
              />
              <CloudUpload color="action" />
              <Typography variant="body2" color="text.secondary" sx={{mt: 1}}>
                {dragS}
              </Typography>
            </Box>
          </Box>
        )}
        {!url && !loading && (
          <Box
            sx={{
              width: 1,
              display: "flex",
              justifyContent: "left",
              color: "text.secondary",
            }}
          >
            <FileSelectorButton
              onFileChange={handleFilesChange}
              buttonName={selectPdf}
              startIcon="computer"
              buttonVariant="inline"
              buttonColor="inherit"
              multiple={true}
              accept=".pdf"
            />
          </Box>
        )}
        {loading && <CircularProgress />}
        {url && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              bgcolor: "white",
              //border: (theme) => `1px solid ${theme.palette.primary.main}`,
              position: "absolute",
              top: 4,
              right: 4,
              zIndex: 100,
            }}
          >
            <IconButton size="small" color="primary" onClick={handleDelete}>
              <Delete fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Box>
    </>
  );
}
