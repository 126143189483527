import {useDispatch, useSelector} from "react-redux";

import {
  Edit,
  Add,
  LibraryAdd as AddGroup,
  Download,
  Upload,
  Replay10 as RefreshQtiesIcon,
} from "@mui/icons-material";

import useSelectedArticlesGroup from "./useSelectedArticlesGroup";
import useArticlesByScene from "./useArticlesByScene";

import sanitizeString from "Utils/sanitizeString";
import downloadExcelFileFromWorkbook from "Features/excel/utils/downloadExcelFileFromWorkbook";
import createExcelWorkbook from "Features/excel/utils/createExcelWorkbook";
import useElementTypesGroupsProxyBySceneWithElementTypes from "Features/elementTypes/hooks/useElementTypesGroupsProxyBySceneWithElementTypes";
import useArticlesByGroup from "./useArticlesByGroup";
import {
  triggerLastUpdate,
  triggerLastComputedQties,
  triggerLastComputedArticles,
  updateArticlesTemp,
  resetProxies,
  clearComputedArticles,
  triggerLastComputedArticlesStep1,
  triggerLastComputedArticlesStep2,
  triggerLastComputedArticlesStep3,
  syncUpdates,
} from "../articlesSlice";
import getElementTypesByGroupIdById from "Features/elementTypes/utils/getElementTypesByGroupIdById";
import getUpdatesRemovingUnknownArticleTypes from "../utils/getUpdatesRemovingUnknownArticleTypes";
import {update} from "Features/ifc/ifcSlice";

export default function useActionsArticlesMap({scene, caplaEditor}) {
  const dispatch = useDispatch();

  // data

  const selectedArticlesGroupId = useSelector(
    (s) => s.articles.selectedArticlesGroupId
  );
  const selectedArticleId = useSelector((s) => s.articles.selectedArticleId);

  const articlesGroup = useSelectedArticlesGroup();

  const articles = useArticlesByGroup(scene, articlesGroup);

  const elementTypesGroups = useElementTypesGroupsProxyBySceneWithElementTypes(
    scene,
    {filterByScope: true, filterByTypesGroupsIds: articlesGroup?.typesGroupsIds}
  );

  // handlers

  async function handleExportExcelArticles() {
    let fileName = "articles.xlsx";
    if (articlesGroup) {
      fileName = sanitizeString(articlesGroup.name) + "_articles.xlsx";
      const wb = await createExcelWorkbook({
        template: "ARTICLES_WITH_IDS",
        data: {
          articles: articles.filter((a) => !a.isSubArticle && !a.fromTemplate),
          elementTypesGroups,
        },
      });
      console.log(
        "download articles",
        articles,
        "with elementTypesGroups",
        elementTypesGroups
      );
      downloadExcelFileFromWorkbook(wb, fileName);
    }
  }

  function handleDeleteAllArticleTypes() {
    const updatesBatch = [];
    articles.forEach((article) => {
      if (article.types) {
        updatesBatch.push({
          id: article.id,
          types: {},
        });
      }
    });

    dispatch(updateArticlesTemp(updatesBatch));
  }

  function handleRefreshQties() {
    dispatch(triggerLastComputedArticles());
  }

  function handleComputeQties() {
    dispatch(triggerLastComputedQties());
  }

  function forceUpdate() {
    dispatch(triggerLastUpdate());
  }

  function handleResetProxies() {
    dispatch(resetProxies({groupId: selectedArticlesGroupId}));
  }

  // computed articles

  function handleResetArticles() {
    dispatch(triggerLastComputedArticles());
  }
  function handleClearComputedArticles() {
    dispatch(clearComputedArticles({groupId: selectedArticlesGroupId}));
  }
  function handleAddComputedArticles() {
    dispatch(triggerLastComputedArticlesStep1());
  }
  function handleUpdateComputedArticlesTypes() {
    dispatch(triggerLastComputedArticlesStep2());
  }
  function handleAddSubArticles() {
    dispatch(triggerLastComputedArticlesStep3());
  }

  //

  function handleCleanArticles() {
    const elementTypesByGroupIdById =
      getElementTypesByGroupIdById(elementTypesGroups);
    const updatesBatch = getUpdatesRemovingUnknownArticleTypes(
      articles,
      elementTypesByGroupIdById
    );
    if (updatesBatch.length > 0) {
      console.log("Clean articles", updatesBatch.length);
      dispatch(updateArticlesTemp(updatesBatch));
    }
  }

  function handleSyncUpdates() {
    dispatch(syncUpdates());
  }

  // actions map

  const actionsMap = {
    createArticlesGroup: {
      icon: <AddGroup fontSize="small" />,
      name: "Tableau d'articles",
      label: "Créer un tableau d'articles",
    },
    createArticle: {
      icon: <Add fontSize="small" />,
      name: "Article",
      label: "Créer un article",
      diabled: !selectedArticlesGroupId,
    },
    refreshQties: {
      icon: <RefreshQtiesIcon fontSize="small" />,
      name: "Quantités",
      label: "Rafraîchir les quantités",
      handler: handleRefreshQties,
    },
    exportExcelArticles: {
      icon: <Download fontSize="small" />,
      name: "Excel",
      label: "Exporter au format Excel",
      handler: handleExportExcelArticles,
    },
    importExcelArticles: {
      icon: <Upload fontSize="small" />,
      name: "Excel",
      label: "Importer au format Excel",
    },
    deleteArticlesGroup: {
      label: "Supprimer le tableau",
      disabled: !selectedArticlesGroupId,
    },
    deleteAllArticles: {
      label: "Supprimer tous les articles",
      disabled: !selectedArticlesGroupId,
    },

    editArticlesGroup: {
      icon: <Edit fontSize="small" />,
      name: "Editer",
      label: "Editer le tableau",
    },
    autoUpdateArticles: {
      label: "Mise à jour automatique",
    },
    deleteAllArticleTypes: {
      label: "Supprimer les formules",
      handler: handleDeleteAllArticleTypes,
    },
    deleteArticle: {
      label: "Supprimer l'article sélectionné",
      disabled: !selectedArticleId,
    },
    forceLastUpdate: {
      label: "Force la mise à jour",
      handler: forceUpdate,
    },
    // qties
    computeQties: {
      icon: <RefreshQtiesIcon fontSize="small" />,
      label: "Calculer les quantités",
      handler: handleComputeQties,
    },
    // computed articles
    resetArticles: {
      label: "Initialiser les articles",
      handler: handleResetArticles,
    },
    clearComputedArticles: {
      label: "Retirer les articles calculés",
      handler: handleClearComputedArticles,
    },
    addComputedArticles: {
      label: "Ajouter les articles calculés",
      handler: handleAddComputedArticles,
    },
    updateComputedArticlesTypes: {
      label: "Mettre à jour les relations",
      handler: handleUpdateComputedArticlesTypes,
    },
    updateAddSubArticles: {
      label: "Ajouter les sous-articles",
      handler: handleAddSubArticles,
    },
    //
    resetProxies: {
      label: "Initialiser la copie des templates",
      handler: handleResetProxies,
    },
    cleanArticles: {
      label: "Nettoyer les articles",
      handler: handleCleanArticles,
    },
    syncUpdates: {
      label: "Enregistrer les modifications",
      handler: handleSyncUpdates,
    },
  };

  return actionsMap;
}
