import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {unwrapResult} from "@reduxjs/toolkit";

import {Box, Button, InputBase, IconButton, Paper} from "@mui/material";
import {Delete, ExpandMore, ExpandLess} from "@mui/icons-material";

import {updateIssue, createIssue, setSelectedIssueId} from "../issuesSlice";
import useIssuesetsInScope from "../useIssuesetsInScope";

import useAccessToken from "Features/auth/useAccessToken";
import {uploadFileService} from "Features/files/services";
import Stretch from "Features/ui/components/Stretch";
import FileSelectorButton from "Features/files/components/FileSelectorButton";

const defaultContent = [
  {
    type: "paragraph",
    children: [
      {
        text: "",
      },
    ],
  },
];

export default function SectionIssueInOutletEdit({
  caplaEditor,
  scene,
  viewer,
  issue,
  onCancel,
  onSave,
}) {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  // string
  const titlePlaceholder = "Title of the note";
  const saveS = "Save";
  const cancelS = "Cancel";

  const selectImageS = "Select an image";

  // data

  const os = useSelector((s) => s.ui.openSections);
  const [selectedIssuesets] = useIssuesetsInScope();
  const selectedIssuesetId = selectedIssuesets[0].id;

  // state

  const [expand, setExpand] = useState(false);

  const initTitle = issue?.title ? issue.title : "";
  const initImageUrl = issue?.imageUrl;
  const initContent = issue?.content ? issue.content : defaultContent;

  const [tempTitle, setTempTitle] = useState(initTitle);
  const [tempImageUrl, setTempImageUrl] = useState(initImageUrl);
  const [tempContent, setTempContent] = useState(initContent);

  useEffect(() => {
    setTempTitle(initTitle);
  }, [initTitle]);
  useEffect(() => {
    setTempImageUrl(initImageUrl);
  }, [initImageUrl]);
  useEffect(() => {
    setTempContent(initContent);
  }, [issue?.id]);

  // state - content

  const [loading, _] = useState(false);

  // helper

  let listingId = issue?.listingId ? issue.listingId : issue?.listing;
  if (!listingId) listingId = selectedIssuesetId;

  // helpers - buttons in richEditor

  const showViewpointButton = os.viewer3D;

  // handlers

  function handleCancelClick() {
    onCancel();
  }

  async function handleSaveClick() {
    const newIssue = {
      ...issue,
      title: tempTitle,
      imageUrl: tempImageUrl,
      content: tempContent,
    };
    if (accessToken && issue?.id) {
      dispatch(updateIssue({accessToken, issue: newIssue}));
    } else if (accessToken && !issue?.id) {
      const result = await dispatch(
        createIssue({
          accessToken,
          listingId,
          sceneId: scene.id,
          issue: newIssue,
        })
      );
      const {item} = unwrapResult(result);
      dispatch(setSelectedIssueId(item.id));
    }

    onSave();
  }

  // handlers - image

  function handleDeleteImage() {
    setTempImageUrl(null);
  }

  async function handleImageChange(file) {
    if (file) {
      const fileName = `issue-image-${issue?.id}.png`;
      const newFile = new File([file], fileName);
      const remoteUrl = await uploadFileService({
        listingId,
        accessToken,
        file: newFile,
        container: "listing-files",
      });
      setTempImageUrl(remoteUrl);
    }
  }

  // handlers - content

  function handleContentChange(content) {
    setTempContent(content);
  }

  return (
    <Box>
      <Box sx={{height: "60px"}} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 1,
          //bgcolor: "background.default",
          mb: 2,
        }}
      >
        <Box sx={{flexGrow: 1}}>
          <InputBase
            placeholder={titlePlaceholder}
            value={tempTitle}
            onChange={(e) => setTempTitle(e.target.value)}
            sx={{width: "100%", fontWeight: "bold"}}
          />
        </Box>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <Button onClick={handleCancelClick} size="small" sx={{mr: 1}}>
            {cancelS}
          </Button>
          <Button variant="contained" size="small" onClick={handleSaveClick}>
            {saveS}
          </Button>
        </Box>
      </Box>

      <Stretch>
        <Box sx={{width: 1, height: 1, overflow: "auto"}}>
          <Box sx={{px: 4}}>
            <Paper
              elevation={12}
              sx={{width: 1, position: "relative", minHeight: 100}}
            >
              {tempImageUrl && (
                <IconButton
                  sx={{position: "absolute", top: 0, left: 0}}
                  size="small"
                  onClick={handleDeleteImage}
                >
                  <Delete fontSize="small" />
                </IconButton>
              )}

              <IconButton
                size="small"
                sx={{position: "absolute", top: 0, right: 0}}
                onClick={() => {
                  setExpand((expand) => !expand);
                }}
              >
                {expand ? (
                  <ExpandLess size="small" />
                ) : (
                  <ExpandMore size="small" />
                )}
              </IconButton>

              <Box
                sx={{
                  position: "absolute",
                  bottom: "24px",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <FileSelectorButton
                  buttonName={selectImageS}
                  accept={"image/png, image/jpeg"}
                  onFileChange={handleImageChange}
                  buttonVariant="contained"
                />
              </Box>

              {tempImageUrl && (
                <img
                  src={tempImageUrl}
                  width="100%"
                  height={expand ? "auto" : 400}
                  style={{objectFit: "cover"}}
                />
              )}
            </Paper>
          </Box>

          <Box
            sx={{
              px: 2,
              minHeight: 200,
              display: "flex",
              flexDirection: "column",
              mt: 4,
            }}
          ></Box>
        </Box>
      </Stretch>
    </Box>
  );
}
