export default function getCoordsFromMarkerNode(groupNode, stage) {
  const triangleNode = groupNode.children[1];

  if (!triangleNode) return {x: 0, y: 0};

  const triangleAbsolutePosition = triangleNode.absolutePosition();

  // Get the stage transformations
  const stageScale = stage.scaleX(); // Assuming uniform scaling
  const stagePosition = stage.position();

  // Calculate the position of nodeA in the context of the stage
  const newX = (triangleAbsolutePosition.x - stagePosition.x) / stageScale;
  const newY = (triangleAbsolutePosition.y - stagePosition.y) / stageScale;

  return {x: newX, y: newY};
}
