import React from "react";

import {Box, Paper, Typography, IconButton, Button} from "@mui/material";
import {Close} from "@mui/icons-material";

import ButtonPoiAction from "./ButtonPoiAction";
import ButtonMoreMenuPoi from "./ButtonMoreMenuPoi";

export default function ContainerPoiDetail({
  viewer,
  poi,
  onClose,
  onDelete,
  canEdit,
  scene,
  caplaEditor,
}) {
  // strings

  const deleteS = "Supprimer";
  //
  const title = poi?.description;
  //
  const actions = ["IMAGE", "FILTER", "CAMERA"];
  //
  function handleDelete() {
    onDelete();
  }
  return (
    <Box sx={{width: "240px"}}>
      <Paper sx={{width: 1}} elevation={12}>
        <Box
          sx={{
            width: 1,
            p: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body2">{title}</Typography>
          <IconButton size="small" onClick={onClose}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
        <Box
          sx={{
            width: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              p: 1,
              display: "flex",
              alignItems: "center",
              "&>*:not(:last-child)": {mr: 0.5},
            }}
          >
            {actions.map((action) => (
              <ButtonPoiAction
                key={action}
                action={action}
                caplaEditor={caplaEditor}
                scene={scene}
                poi={poi}
              />
            ))}
          </Box>
          {!viewer && canEdit && <ButtonMoreMenuPoi scene={scene} />}
        </Box>
      </Paper>
    </Box>
  );
}
