import getTemplateProxies from "./getTemplateProxies";
import getSubArticlesMap from "./getSubArticlesMap";
import getElementTypesGroupsWithTransferedQties from "./getElementTypesGroupsWithTransferedQties";
import getUpdatesToRefreshQties from "./getUpdatesToRefreshQties";
import articlesWithStyleToArticlesWithTempNum from "./articlesWithStyleToArticlesWithTempNum";

export default function getArticlesFromTemplateAndSubArticles({
  articlesMap,
  articlesGroup,
  elementTypesGroupsWithSortedItems,
  measurements,
  sectors,
}) {
  // step 0 : data transfo

  const elementTypesGroupsWithTransferedQties =
    getElementTypesGroupsWithTransferedQties(elementTypesGroupsWithSortedItems);

  // step 1 : load articles from template

  const groupId = articlesGroup.id;
  const sortedArticles = articlesGroup.sortedArticles ?? [];

  const {articlesFromTemplate, sortedArticlesProxy} = getTemplateProxies({
    groupId,
    sortedArticles,
    articlesMap,
  });

  const articlesMap_step1 = {
    ...articlesMap,
    ...articlesFromTemplate.reduce((acc, article) => {
      acc[article.id] = article;
      return acc;
    }, {}),
  };

  // data transfo : sort & add tempNum

  let articles_step1 = Object.values(articlesMap_step1);
  let articles_step1_byClientId = articles_step1.reduce((acc, article) => {
    acc[article.clientId] = article;
    return acc;
  }, {});

  //

  const sortedArticles_step1 = [];
  sortedArticlesProxy.forEach((clientId) => {
    const article = articles_step1_byClientId[clientId];
    if (article) sortedArticles_step1.push(article);
  });

  articles_step1 = articlesWithStyleToArticlesWithTempNum(sortedArticles_step1);

  // step 2 : compute types for articles

  const batchUpdates_step1 = getUpdatesToRefreshQties(
    articles_step1,
    elementTypesGroupsWithSortedItems
  );

  const batchUpdates = []; // updates of original articles.
  batchUpdates_step1.forEach((update) => {
    const article = articlesMap[update.id];
    if (article && !article.fromTemplate) {
      batchUpdates.push(update);
    }
  });

  const batchUpdatesMap_step1 = batchUpdates_step1.reduce((acc, update) => {
    acc[update.id] = update;
    return acc;
  }, {});

  const articles_step2 = articles_step1.map((article) => {
    const updates = batchUpdatesMap_step1[article.id];
    return {
      ...article,
      ...(updates ?? {}),
    };
  });

  // step 3 : get subArticles

  const subArticlesMap = getSubArticlesMap({
    articles: articles_step2,
    elementTypesGroupsWithTransferedQties,
    measurements,
    sectors,
  });

  console.log("subArticlesMap", subArticlesMap);

  // return

  const articlesFromTemplate_step2 = articles_step2.filter(
    (article) => article.fromTemplate
  );

  return {
    articlesFromTemplate: articlesFromTemplate_step2,
    subArticlesMap,
    sortedArticlesProxy,
    batchUpdates,
  };
}
