export default function flipPath({path, variant}) {
  // path = [[x, y], [x, y], ...]

  const pointA = path[0];
  const pointAV = [pointA[0], pointA[1] + 1];
  const pointAH = [pointA[0] + 1, pointA[1]];

  //

  const newPath = path.map((point) => {
    const [x, y] = point;
    const [xA, yA] = pointA;
    const [xB, yB] = variant === "vertical" ? pointAV : pointAH;

    const dx = xB - xA;
    const dy = yB - yA;

    if (dx === 0) {
      // Handle vertical line case
      const xSym = 2 * xA - x;
      return [xSym, y];
    } else {
      const a = dy / dx;
      const b = yA - a * xA;

      const d = (x + (y - b) * a) / (1 + a * a);
      const xSym = 2 * d - x;
      const ySym = 2 * d * a - y + 2 * b;

      return [xSym, ySym];
    }
  });

  return newPath;
}
