import {useSelector, useDispatch} from "react-redux";

import {Box, Button, alpha} from "@mui/material";
import {Save} from "@mui/icons-material";

import {
  // setEditedTimeStamp,
  setIsEditingMeasurement,
  // setMeasurementsEditedFromForm,
  // setSelectedMeasurementIds,
  // updateMeasurements,
} from "../measurementsSlice";
import useSelectedMeasurement from "../useSelectedMeasurement";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";

export default function ActionsMeasurementInSelectionPanel({
  scene,
  caplaEditor,
}) {
  const dispatch = useDispatch();
  // strings

  const saveS = "Enregistrer";
  const cancelS = "Annuler";

  // data

  const isEditing = useSelector((s) => s.measurements.isEditingMeasurement);
  const selectedMeasurement = useSelectedMeasurement();
  const editedMeasurement = useSelector(
    (s) => s.measurements.editedMeasurement
  );
  const zones = useZonesByScene(scene);

  // helpers

  const mnger =
    caplaEditor?.editorPdf.annotationsManager.measurementsPdfManager;

  // helpers

  const zonesById = {};
  for (const item of zones) {
    zonesById[item.id] = item;
  }

  // handlers

  function handleCancelClick() {
    // mnger?.resetSelectedMeasurement();
    mnger?.updateAnnotation(selectedMeasurement, {zonesById});
    mnger?.setIsEditing(false);
    // mnger?.lockAnnot({annotationId: selectedMeasurement.id});
    dispatch(setIsEditingMeasurement(false));
  }

  function handleSaveClick() {
    const quantities = caplaEditor?.computeQuantitiesFromPaths({
      drawingShape: editedMeasurement.drawingShape,
      path: editedMeasurement.drawingProps?.path,
      path3D: editedMeasurement.path3D,
      path3d2: editedMeasurement.path3d2,
      height: editedMeasurement.height,
      heights: editedMeasurement.heights,
      heightE: editedMeasurement.heightE,
      heightN: editedMeasurement.heightN,
      slopeH: editedMeasurement.slopeH,
      slopingA: editedMeasurement.slopingA,
      dim1: editedMeasurement.dim1,
      dim2: editedMeasurement.dim2,
      mpd: zonesById[editedMeasurement.zoneId]?.scale,
    });
    const newMeasurement = {...editedMeasurement, ...quantities};
    caplaEditor?.measDataManager.updateMeasurements([newMeasurement]);
    // mnger?.updateAnnotation(newMeasurement);
    // caplaEditor?.editor3d.sceneEditor.updateMeasurement3D(newMeasurement);
    // if (Array.isArray(newMeasurement.voids))
    //   caplaEditor?.measDataManager.makeMeasurementVoids(newMeasurement);
    // dispatch(setEditedTimeStamp(`${Date.now()}`));
    // dispatch(setMeasurementsEditedFromForm(true));
    mnger?.setIsEditing(false);
    dispatch(setIsEditingMeasurement(false));
    // dispatch(setSelectedMeasurementIds([newMeasurement.id]));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button variant="outlined" fullWidth onClick={handleCancelClick}>
            {cancelS}
          </Button>
        </Box>
      )}
      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Save />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSaveClick}
          >
            {saveS}
          </Button>
        </Box>
      )}
    </Box>
  );
}
