import {IconButton} from "@mui/material";
import {Download} from "@mui/icons-material";

export default function ButtonDownloadStageImage({caplaEditor}) {
  // handler

  function handleClick() {
    caplaEditor.zonesEditor.downloadStageImage();
  }
  return (
    <IconButton onClick={handleClick}>
      <Download />
    </IconButton>
  );
}
