import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import getItemsMapById from "Utils/getItemsMapById";
import cleanElementType from "../utils/cleanElementType";

import resetElementTypesGroupNums from "../utils/resetElementTypesGroupNums";

export default function useCleanElementTypesGroup(scene) {
  const ressources = useRessourcesByScene(scene);
  const ressourcesMap = getItemsMapById(ressources);

  let newElementTypes = [];
  const modifiedElementTypesSet = new Set();

  return (group) => {
    group.elementTypes.forEach((elementType) => {
      if (elementType.res) {
        let resArray = [...elementType.res];
        elementType.res.forEach(({resId}) => {
          const ressource = ressourcesMap[resId];
          if (!ressource) {
            resArray = resArray.filter((item) => item.resId !== resId);
            modifiedElementTypesSet.add(elementType.id);
          }
        });
        const newElementType = {...elementType, res: resArray};
        newElementTypes.push(newElementType);
      } else {
        newElementTypes.push(elementType);
      }
    });

    console.log(
      "[WARNING] modified elementTypes",
      modifiedElementTypesSet.size
    );

    // convert string => num

    console.log("cleanGroup1", newElementTypes);
    newElementTypes = newElementTypes.map((type) => cleanElementType(type));
    console.log("cleanGroup2", newElementTypes);

    // remove duplicated elementTypes

    const typesWithoutDuplicates = [];
    const typesMap = {};
    newElementTypes.forEach((type) => {
      if (!typesMap[type.id]) {
        typesMap[type.id] = "ok";
        typesWithoutDuplicates.push(type);
      }
    });
    newElementTypes = typesWithoutDuplicates;

    let updatedGroup = {...group, elementTypes: newElementTypes};

    // reset nums

    updatedGroup = resetElementTypesGroupNums(updatedGroup);

    return updatedGroup;
  };
}
