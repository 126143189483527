import {Box} from "@mui/material";

import useSceneModule from "Features/navigation/useSceneModule";

import TabSelectorForEditMeasurementsInLeftPanel from "Features/measurements/components/TabSelectorForEditMeasurementsInLeftPanel";

export default function SectionSubHeaderInLeftPanel({
  caplaEditor,
  scene,
  viewer,
}) {
  const sceneModule = useSceneModule();

  return (
    <Box
      sx={{
        width: 1,
      }}
    >
      {["EDIT_MEASUREMENTS", "MEASUREMENTS"].includes(sceneModule) && (
        <Box
          sx={{flexGrow: 1, display: "flex", justifyContent: "center", py: 2}}
        >
          <TabSelectorForEditMeasurementsInLeftPanel />
        </Box>
      )}
    </Box>
  );
}
