import applyStyleToRow from "./applyStyleToRow";

export default function fillWorksheetWithElementTypesWithIds({
  worksheet,
  elementTypesWithIds,
  rowStartIndex,
}) {
  //
  const rowLength = 11;
  //
  elementTypesWithIds.forEach((item, index) => {
    const row = worksheet.getRow(rowStartIndex + index); //

    applyStyleToRow({row, item, rowType: "ELEMENT_TYPE", rowLength});

    if (typeof item.id === "string") row.getCell(1).value = item.id;
    if (typeof item.name === "string") row.getCell(2).value = item.name;
    if (typeof item.code === "string") row.getCell(3).value = item.code;
    if (typeof item.drawingShape === "string" && !item.isTitle)
      row.getCell(4).value = item.drawingShape;

    if (typeof item.unit === "string" && !item.isTitle)
      row.getCell(5).value = item.unit;
    if (typeof item.mainQty === "number") row.getCell(6).value = item.mainQty;

    if (typeof item.articleQties === "string")
      row.getCell(7).value = item.articleQties;
    if (typeof item.description === "string")
      row.getCell(8).value = item.description;
    //
    if (typeof item.dim1 === "number") row.getCell(9).value = item.dim1;
    if (typeof item.dim2 === "number") row.getCell(10).value = item.dim2;
    if (typeof item.height === "number") row.getCell(11).value = item.height;
  });
}
