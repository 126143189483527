import {Box, Typography, IconButton} from "@mui/material";
import {Refresh} from "@mui/icons-material";

import ListWithHeaderAndSearchGeneric from "Components/ListWithHeaderAndSearchGeneric";

export default function SectionSelectorEntitiesInScope({
  scene,
  //
  surveys,
  selectionSurveys,
  onSelectionSurveysChange,
  //
  roomsGroups,
  selectionRoomsGroups,
  onSelectionRoomsGroupsChange,
  //
  materialsGroups,
  selectionMaterialsGroups,
  onSelectionMaterialsGroupsChange,
  //
  typesGroups,
  selectionTypesGroups,
  onSelectionTypesGroupsChange,
  //
  ressourcesGroups,
  selectionRessourcesGroups,
  onSelectionRessourcesGroupsChange,
  //
  articlesGroups,
  selectionArticlesGroups,
  onSelectionArticlesGroupsChange,
  //
  packages,
  selectionPackages,
  onSelectionPackagesChange,
  //
  inputs,
  selectionInputs,
  onSelectionInputsChange,
  //
  maps,
  selectionMaps,
  onSelectionMapsChange,
}) {
  // helpers

  let surveysS = "Visites";
  let materialsGroupsS = "Liste de matériaux";
  let roomsGroupsS = "Liste de locaux";
  let typesGroupsS = `Liste d'éléments`;
  let ressourcesGroupsS = `Tableaux d'articles`;
  let articlesGroupsS = "Tableaux d'articles v2";
  let inputsS = "Fichiers PDF";
  let mapsS = "Fonds de plan";
  let packagesS = "Calques de repérage";

  // helpers data

  const data = [
    {
      title: surveysS,
      data: surveys,
      selection: selectionSurveys,
      onSelectionChange: onSelectionSurveysChange,
      show: surveys?.length > 0,
    },
    {
      title: materialsGroupsS,
      data: materialsGroups,
      selection: selectionMaterialsGroups,
      onSelectionChange: onSelectionMaterialsGroupsChange,
      show: materialsGroups?.length > 0,
    },
    {
      title: roomsGroupsS,
      data: roomsGroups,
      selection: selectionRoomsGroups,
      onSelectionChange: onSelectionRoomsGroupsChange,
      show: roomsGroups?.length > 0,
    },
    {
      title: typesGroupsS,
      data: typesGroups,
      selection: selectionTypesGroups,
      onSelectionChange: onSelectionTypesGroupsChange,
      show: typesGroups?.length > 0,
    },
    {
      title: ressourcesGroupsS,
      data: ressourcesGroups,
      selection: selectionRessourcesGroups,
      onSelectionChange: onSelectionRessourcesGroupsChange,
      show: ressourcesGroups?.length > 0,
    },
    {
      title: articlesGroupsS,
      data: articlesGroups,
      selection: selectionArticlesGroups,
      onSelectionChange: onSelectionArticlesGroupsChange,
      show: articlesGroups?.length > 0,
    },
    {
      title: inputsS,
      data: inputs,
      selection: selectionInputs,
      onSelectionChange: onSelectionInputsChange,
      show: inputs?.length > 0,
    },
    {
      title: mapsS,
      data: maps,
      selection: selectionMaps,
      onSelectionChange: onSelectionMapsChange,
      show: maps?.length > 0,
    },
    {
      title: packagesS,
      data: packages,
      selection: selectionPackages,
      onSelectionChange: onSelectionPackagesChange,
      show: packages?.length > 0,
    },
  ];

  const showData = data.filter((dataItem) => dataItem.show);

  return (
    <Box
      sx={{
        height: 1,
        width: 1,
        display: "flex",
        alignItems: "top",
        justifyContent: "stretch",
      }}
    >
      {showData.map((dataItem) => {
        return (
          <Box key={dataItem.title}>
            <ListWithHeaderAndSearchGeneric
              title={dataItem.title}
              items={dataItem.data}
              selectedItemIds={dataItem.selection}
              onSelectionChange={dataItem.onSelectionChange}
            />
          </Box>
        );
      })}
    </Box>
  );
}
