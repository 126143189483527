import React from "react";

import {useSelector} from "react-redux";

import {Box} from "@mui/material";
import SelectorZoneInViewer from "./SelectorZoneInViewer";
import ButtonRefreshZoneInViewer from "./ButtonRefreshZoneInViewer";
import useIsFullscreen from "Features/ui/useIsFullscreen";
import SelectorZonesInZoneViewer from "./SelectorZonesInZoneViewer";
import ButtonDownloadStageImage from "./ButtonDownloadStageImage";
import SectionZoneDifferences from "./SectionZoneDifferences";
import SectionPatternRecognition from "./SectionPatternRecognition";
import ToolbarZoneViewerTest from "./ToolbarZoneViewerTest";

export default function LayerZoneViewer({scene, caplaEditor}) {
  //
  const fullscreen = useIsFullscreen();
  //
  //const top = fullscreen ? "16px" : "68px";
  const top = "16px";

  return (
    <>
      {/* <Box
        sx={{
          position: "absolute",
          top,
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 20,
        }}
      >
        <SelectorZoneInViewer scene={scene} />
      </Box> */}
      <Box
        sx={{
          position: "absolute",
          bottom: "16px",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 20,
        }}
      >
        <ToolbarZoneViewerTest caplaEditor={caplaEditor} scene={scene} />
      </Box>

      <Box
        sx={{
          position: "absolute",
          top,
          left: "8px",
          zIndex: 20,
        }}
      >
        {/* <ButtonRefreshZoneInViewer scene={scene} caplaEditor={caplaEditor} /> */}
      </Box>

      <Box
        sx={{
          position: "absolute",
          bottom: "16px",
          left: "16px",
          zIndex: 20,
        }}
      >
        <SectionZoneDifferences scene={scene} caplaEditor={caplaEditor} />
      </Box>

      <Box
        sx={{
          position: "absolute",
          bottom: "16px",
          right: "16px",
          zIndex: 20,
        }}
      >
        <SectionPatternRecognition scene={scene} caplaEditor={caplaEditor} />
      </Box>
    </>
  );
}
