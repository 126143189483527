import {useEffect, useState} from "react";

import {useDispatch, useSelector} from "react-redux";

import getArticlesFromTemplateAndSubArticles from "../utils/getArticlesFromTemplateAndSubArticles";

import {initFromComputedArticles} from "../articlesSlice";
import {all} from "axios";
import computeArticlesQties from "../utils/computeArticlesQties";

export default function useAutoComputeArticles({
  articlesGroup,
  elementTypesGroupsWithSortedItems,
  measurements,
  sectors,
}) {
  const dispatch = useDispatch();

  // data

  const articlesMap = useSelector((s) => s.articles.articlesMap);

  const sortedArticlesProxyByGroupId = useSelector(
    (s) => s.articles.sortedArticlesProxyByGroupId
  );

  const lastComputedArticlesAt = useSelector(
    (s) => s.articles.lastComputedArticlesAt
  );

  // state

  const [sectorsAndMeasAreLoaded, setSectorsAndMeasAreLoaded] = useState(false);
  useEffect(() => {
    if (sectors && measurements) {
      setSectorsAndMeasAreLoaded(true);
    }
  }, [sectors, measurements]);

  // helper - initial articles

  const initialArticlesMap = Object.values(articlesMap)
    .filter((a) => !a.fromTemplate && a.articlesGroupId === articlesGroup?.id)
    .reduce((acc, article) => {
      const rawArticle = {...article};
      delete rawArticle.updates;
      delete rawArticle.types;
      acc[article.id] = rawArticle;
      return acc;
    }, {});

  // helper - noComputationYet

  const noComputationYet =
    !sortedArticlesProxyByGroupId[articlesGroup?.id]?.length > 0;

  // helper - canComputeArticles

  const canComputeArticles =
    Object.entries(initialArticlesMap ?? {}).length > 0 &&
    elementTypesGroupsWithSortedItems?.length > 0 &&
    articlesGroup &&
    sectorsAndMeasAreLoaded;

  // deps

  useEffect(() => {
    if (canComputeArticles && lastComputedArticlesAt) {
      const {
        articlesFromTemplate,
        sortedArticlesProxy,
        subArticlesMap,
        batchUpdates,
      } = getArticlesFromTemplateAndSubArticles({
        articlesMap: initialArticlesMap,
        articlesGroup,
        elementTypesGroupsWithSortedItems,
        measurements,
        sectors,
      });
      // console.log(
      //   "computedArticles",
      //   articlesFromTemplate.map((a) => ({
      //     name: a.name,
      //     fromTemplate: a.fromTemplate,
      //   })),
      //   sortedArticlesProxy?.length,
      //   subArticlesMap
      // );
      dispatch(
        initFromComputedArticles({
          articlesFromTemplate,
          sortedArticlesProxy,
          subArticlesMap,
          groupId: articlesGroup.id,
          batchUpdates,
          triggerComputeArticlesQties: true,
        })
      );
      // dispatch(
      //   setArticlesProxies({
      //     articlesFromTemplate,
      //     sortedArticlesProxy,
      //     groupId: articlesGroup.id,
      //   })
      // );
      // dispatch(addSubArticles(subArticlesMap));
    }
  }, [articlesGroup?.id, lastComputedArticlesAt, canComputeArticles]);
}
