import createExcelWorkbookFromTemplateUrl from "./createExcelWorkbookFromTemplateUrl";
import fillWorksheetWithRessources from "./fillWorksheetWithRessources";
import fillWorksheetWithMeasurements from "./fillWorksheetWithMeasurements";
import fillWorksheetWithRooms from "./fillWorksheetWithRooms";
import fillWorksheetWithSectors from "./fillWorksheetWithSectors";
import fillWorksheetWithMeasurementsByRoom from "./fillWorksheetWithMeasurementsByRoom";
import fillWorksheetWithMeasurementsByTypes from "./fillWorksheetWithMeasurementsByTypes";
import fillWorksheetWithRessourcesWithIds from "./fillWorksheetWithRessourcesWithIds";
import fillWorksheetWithArticlesWithIds from "./fillWorksheetWithArticlesWithIds";
import fillWorksheetWithElementTypesWithIds from "./fillWorksheetWithElementTypesWithIds";
import fillWorksheetWithTypeRessourceMap from "./fillWorksheetWithTypeRessourceMap";
import fillWorksheetWithSubjectTypeMap from "./fillWorksheetWithSubjectTypeMap";
import fillWorksheetWithMaterials from "./fillWorksheetWithMaterials";
import getTemplateUrl from "./getTemplateUrl";
import fillWorkbookWithAllData from "./fillWorkbookWithAllData";
import fillWorksheetWithTypesRessourcesRelations from "./fillWorksheetWithTypesRessourcesRelations";
import fillWorksheetWithRessourcesPU from "./fillWorksheetWithRessourcesPU";
import fillWorksheetWithRessourcesTypesRelations from "./fillWorksheetWithRessourcesTypesRelations";
import fillWorksheetWithArticlesQties from "./fillWorksheetWithArticlesQties";

export default async function createExcelWorkbook({template, data}) {
  const url = getTemplateUrl(template);

  switch (template) {
    case "ALL": {
      const wb = await createExcelWorkbookFromTemplateUrl({url});
      fillWorkbookWithAllData({workbook: wb, data});
      return wb;
    }
    case "RESSOURCES": {
      const wb = await createExcelWorkbookFromTemplateUrl({url});
      const ws = wb.worksheets[0];
      fillWorksheetWithRessources({
        worksheet: ws,
        ressources: data,
        rowStartIndex: 3,
      });
      return wb;
    }
    case "RESSOURCES_WITH_IDS": {
      const wb = await createExcelWorkbookFromTemplateUrl({url});
      const ws = wb.worksheets[0];
      fillWorksheetWithRessourcesWithIds({
        worksheet: ws,
        ressourcesWithIds: data,
        rowStartIndex: 2,
      });
      return wb;
    }
    case "ARTICLES_WITH_IDS": {
      const wb = await createExcelWorkbookFromTemplateUrl({url});
      const ws = wb.worksheets[0];
      fillWorksheetWithArticlesWithIds({
        worksheet: ws,
        articlesWithIds: data.articles,
        elementTypesGroups: data.elementTypesGroups,
        rowStartIndex: 4,
      });
      return wb;
    }
    case "ARTICLES_WITH_QTIES": {
      const wb = await createExcelWorkbookFromTemplateUrl({url});
      const ws = wb.worksheets[0];
      fillWorksheetWithArticlesQties({
        worksheet: ws,
        data,
        rowStartIndex: 4,
      });
      return wb;
    }
    case "ELEMENT_TYPES_WITH_IDS": {
      const wb = await createExcelWorkbookFromTemplateUrl({url});
      const ws = wb.worksheets[0];
      fillWorksheetWithElementTypesWithIds({
        worksheet: ws,
        elementTypesWithIds: data,
        rowStartIndex: 4,
      });
      return wb;
    }
    case "TYPE_RESSOURCE_MAP": {
      const wb = await createExcelWorkbookFromTemplateUrl({url});
      const ws = wb.worksheets[0];
      fillWorksheetWithTypeRessourceMap({
        worksheet: ws,
        typeRessourceMap: data,
        rowStartIndex: 3,
      });
      return wb;
    }
    case "MEASUREMENTS": {
      const wb = await createExcelWorkbookFromTemplateUrl({url});
      const ws = wb.worksheets[0];
      fillWorksheetWithMeasurements({
        worksheet: ws,
        measurements: data,
        rowStartIndex: 3,
      });
      return wb;
    }
    case "MEASUREMENTS_BY_ROOM": {
      const wb = await createExcelWorkbookFromTemplateUrl({url});
      const ws = wb.worksheets[0];
      fillWorksheetWithMeasurementsByRoom({
        worksheet: ws,
        measurementsByRoom: data,
        rowStartIndex: 3,
      });
      return wb;
    }
    case "MEASUREMENTS_BY_TYPE": {
      const wb = await createExcelWorkbookFromTemplateUrl({url});
      const ws = wb.worksheets[0];
      fillWorksheetWithMeasurementsByTypes({
        worksheet: ws,
        rowStartIndex: 3,
        byType: data.byType,
        elementTypes: data.elementTypes,
        buildingTypes: data.buildingTypes,
      });
      return wb;
    }
    case "ROOMS": {
      const wb = await createExcelWorkbookFromTemplateUrl({url});
      const ws = wb.worksheets[0];
      fillWorksheetWithRooms({
        worksheet: ws,
        rooms: data,
        rowStartIndex: 4,
      });
      return wb;
    }
    case "MATERIALS": {
      const wb = await createExcelWorkbookFromTemplateUrl({url});
      const ws = wb.worksheets[0];
      fillWorksheetWithMaterials({
        worksheet: ws,
        materials: data,
        rowStartIndex: 4,
      });
      return wb;
    }
    case "SECTORS": {
      const wb = await createExcelWorkbookFromTemplateUrl({url});
      const ws = wb.worksheets[0];
      fillWorksheetWithSectors({
        worksheet: ws,
        sectors: data,
        rowStartIndex: 4,
      });
      return wb;
    }
    case "SUBJECT_TYPE_MAP": {
      const wb = await createExcelWorkbookFromTemplateUrl({url});
      const ws = wb.worksheets[0];
      fillWorksheetWithSubjectTypeMap({
        worksheet: ws,
        subjectTypeMap: data,
        rowStartIndex: 3,
      });
      return wb;
    }
    case "ELEMENT_TYPES_RESSOURCES": {
      const wb = await createExcelWorkbookFromTemplateUrl({url});
      const ws = wb.worksheets[0];
      fillWorksheetWithTypesRessourcesRelations({
        worksheet: ws,
        rowStartIndex: 6,
        data,
      });
      return wb;
    }
    case "RESSOURCES_ELEMENT_TYPES": {
      const wb = await createExcelWorkbookFromTemplateUrl({url});
      const ws = wb.worksheets[0];
      fillWorksheetWithRessourcesTypesRelations({
        worksheet: ws,
        rowStartIndex: 6,
        data,
      });
      return wb;
    }
    case "RESSOURCES_PU": {
      const wb = await createExcelWorkbookFromTemplateUrl({url});
      const ws = wb.worksheets[0];
      fillWorksheetWithRessourcesPU({
        worksheet: ws,
        data,
      });
      return wb;
    }
  }
}
