import {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {setOpenDialogCreatePackage} from "../packagesSlice";

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
  Box,
  Button,
} from "@mui/material";
import {PictureAsPdf as Pdf} from "@mui/icons-material";
import useSelectedPdfModel from "Features/pdf/useSelectedPdfModel";
import useElementTypesGroupsProxyByScene from "Features/elementTypes/hooks/useElementTypesGroupsProxyByScene";
import {addModelIdToMeasDataManager} from "Features/overviewer/overviewerSlice";

import SelectorAutocomplete from "Components/SelectorAutocomplete";
import useSelectedScope from "Features/scopes/hooks/useSelectedScope";
import useAddItemToScope from "Features/scopes/hooks/useAddItemToScope";
import {add} from "date-fns";

export default function DialogCreatePackage({
  scene,
  caplaEditor,
  initialRestrictedGroupIds,
  onPackageCreated,
}) {
  const dispatch = useDispatch();

  const sceneId = scene?.id ? scene.id : scene?.clientId;

  // strings

  const title = "Nouveau calque";
  const descriptionS = "Ajoutez un nouveau calque de repérage à votre PDF";
  const placeholderS = "Calque #0, Repérages R+1, ...";
  const createS = "Créer";
  const nameS = "Nom du calque";

  const groupS = "Liste d'éléments";
  const linkedGroupsS = "Listes d'éléments reliés au calque ";

  // data

  const open = useSelector((s) => s.packages.openDialogCreatePackage);
  const selectedPdfModel = useSelectedPdfModel();
  const groups = useElementTypesGroupsProxyByScene(scene, {
    filterByScope: true,
    sortByName: true,
  });
  const groupProxy = useSelector(
    (s) => s.elementTypes.selectedElementTypesGroupProxyInEditMeasurements
  );

  // data - scope update
  const selectedScope = useSelectedScope();
  const addItemToScope = useAddItemToScope();

  // init state

  let initTempName = selectedPdfModel?.name;
  if (groupProxy?.name) initTempName = groupProxy?.name + " • " + initTempName;

  // state

  const [tempName, setTempName] = useState(initTempName);

  const [loading, setLoading] = useState(false);

  const initRestrictedGroupsProxy = groups.filter((group) =>
    initialRestrictedGroupIds?.includes(group.id)
  );
  const [restrictedGroupProxy, setRestrictedGroupProxy] = useState(
    initRestrictedGroupsProxy[0]
  );

  // state update
  useEffect(() => {
    setTempName(initTempName);
  }, [initTempName]);

  useEffect(() => {
    setRestrictedGroupProxy(initRestrictedGroupsProxy[0]);
  }, [initRestrictedGroupsProxy[0]?.id]);

  // helpers

  const canCreate = tempName.length > 0 && Boolean(selectedPdfModel?.id);

  // handlers

  function handleClose() {
    dispatch(setOpenDialogCreatePackage(false));
  }

  function handleGroupChange(group) {
    console.log("[DEBUG] handleGroupsChange", group);
    setRestrictedGroupProxy(group);
  }

  async function handleCreateClick() {
    if (canCreate) {
      setLoading(true);
      // create model
      const restrictedGroups = restrictedGroupProxy?.id
        ? [restrictedGroupProxy.id]
        : null;
      const fromModel = {type: "PDF", modelId: selectedPdfModel.id};
      const input = {name: tempName, fromModel};
      if (restrictedGroups?.length > 0)
        input.restrictedTypes = restrictedGroups;
      const packageModel =
        await caplaEditor?.editor3d?.loader.createMeasurementsModel(input);

      caplaEditor?.measDataManager.createOrUpdateModelInManager(packageModel);

      console.log("create package v2 input:", input, "output", packageModel);
      const packageId = packageModel?.id;

      // update scope
      addItemToScope({
        itemType: "PACKAGE",
        itemId: packageId,
        scope: selectedScope,
      });
      setLoading(false);

      // to trigger the updateDataManegrWithMeasurement hook.
      dispatch(addModelIdToMeasDataManager(packageId));
      //

      if (onPackageCreated) onPackageCreated(packageModel);

      dispatch(setOpenDialogCreatePackage(false));
      //navigate(`bimboxes/${sceneId}/${packageId}/measurements`);
      //dispatch(setSelectedMeasurementsModelId(packageId));
      // caplaEditor?.measDataManager.setSelectedModelById(packageId);
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={false}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box>
          <Typography>{descriptionS}</Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "top",
              mt: 3,
              bgcolor: "background.default",
              p: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "text.secondary",
              }}
            >
              <Pdf color="inherit" />
            </Box>

            <Box sx={{ml: 2}}>
              <Typography variant="body2">{selectedPdfModel?.name}</Typography>
              {/* <Typography
                sx={{
                  fontSize: 13,
                  fontStyle: "italic",
                  color: "text.secondary",
                }}
              >
                {selectedPdfModel?.fileName}
              </Typography> */}
            </Box>
          </Box>

          <Box sx={{py: 3}}>
            <TextField
              fullWidth
              value={tempName}
              placeholder={placeholderS}
              onChange={(e) => setTempName(e.target.value)}
              size="small"
              label={<Typography variant="body2">{nameS}</Typography>}
              InputProps={{
                sx: {fontSize: (theme) => theme.typography.body2.fontSize},
              }}
              //sx={{width: 100}}
            />
          </Box>

          <Box sx={{p: 1, bgcolor: "background.default"}}>
            <Typography sx={{color: "text.secondary", fontSize: 13, mb: 1}}>
              {linkedGroupsS}
            </Typography>
            <Box sx={{display: "flex", width: 1}}>
              {/* <SelectorAutocompleteMultipleWithDialog
                value={
                  restrictedGroupsProxy ? restrictedGroupsProxy : undefined
                }
                onChange={handleGroupsChange}
                options={groups ? groups : []}
              /> */}
              <SelectorAutocomplete
                value={restrictedGroupProxy ? restrictedGroupProxy : null}
                onChange={handleGroupChange}
                options={groups ? groups : []}
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading || !canCreate} onClick={handleCreateClick}>
          {createS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
