import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import theme from "Styles/theme";

import {
  createElementTypesGroupService,
  fetchElementTypesGroupsService,
  updateElementTypesGroupService,
  deleteElementTypesGroupService,
} from "./services";

import getStateElementTypesGroupsFromRemoteItems from "./utils/getStateElementTypesGroupsFromRemoteItems";
import getStateElementTypesGroupFromRemoteItem from "./utils/getStateElementTypesGroupFromRemoteItem";
import refreshGroupedItemsNums from "Utils/refreshGroupedItemsNums";
import getSortedItemsArrayByNum from "Utils/getSortedItemsArrayByNum";

export const createElementTypesGroup = createAsyncThunk(
  "elementTypes/createElementTypesGroup",
  createElementTypesGroupService
);
export const fetchElementTypesGroups = createAsyncThunk(
  "elementTypes/fetchElementTypesGroups",
  fetchElementTypesGroupsService
);
export const updateElementTypesGroup = createAsyncThunk(
  "elementTypes/updateElementTypesGroup",
  updateElementTypesGroupService
);
export const deleteElementTypesGroup = createAsyncThunk(
  "elementTypes/deleteElementTypesGroup",
  deleteElementTypesGroupService
);

export const elementTypesSlice = createSlice({
  name: "elementTypes",
  initialState: {
    selectedElementTypeId: null,
    selectedElementTypeIds: [],
    //
    selectedFormGroup: "IDENTITY", // "QUANTITIES","ARTICLES"
    isEditingElementType: false,
    editedElementType: null,
    //
    selectedElementTypeGroup: null, // the name. Too dangerous => moved to groupProxy
    selectedElementTypesGroupProxy: null, //{id,name,fromScene} group.id = for types from scene = the name
    //
    selectedElementTypesGroupProxyInEditMeasurements: null, // the group proxy ({id,name,fromScene}) used when creating measurement.
    //
    openDialogSelectElementType: false,
    //
    newElementType: {
      drawingShape: "POLYLINE",
      color: theme.palette.secondary.main,
    },
    isCreatingElementType: false,
    tempElementType: null,
    openCreateElementTypeFastDialog: false,
    //
    elementTypesGroups: [],
    // datagrid
    datagridIsInEditMode: false,
    editedElementTypesGroup: null,
    showElementTypesReorderHelpers: false,
    // elementType selected in the selectionPanel from the FieldItem. (replaced with more explicit selection mangt)
    elementTypeInSelectionPanelOrigin: null,
    elementTypeInSelectionPanelTarget: null,
    filterByGroupInMeasurements: false,
  },
  reducers: {
    //
    addElementTypesGroups: (state, action) => {
      // used when loading bim data
      const groups = action.payload ?? [];
      state.elementTypesGroups = [...state.elementTypesGroups, ...groups];
    },
    //
    setFilterByGroupInMeasurements: (state, action) => {
      state.filterByGroupInMeasurements = action.payload;
    },
    setSelectedElementTypeId: (state, action) => {
      state.selectedElementTypeId = action.payload;
    },
    setIsCreatingElementType: (state, action) => {
      state.isCreatingElementType = action.payload;
    },
    setSelectedElementTypeIds: (state, action) => {
      const ids = action.payload;
      state.selectedElementTypeIds = ids;
      if (Array.isArray(ids) && ids[0]) {
        state.selectedElementTypeId = ids[0];
      } else {
        state.selectedElementTypeId = null;
      }
    },
    setLastElementTypeId: (state, action) => {
      state.lastElementTypeId = action.payload;
    },
    setSelectedFormGroup: (state, action) => {
      state.selectedFormGroup = action.payload;
    },
    setIsEditingElementType: (state, action) => {
      state.isEditingElementType = action.payload;
    },
    setEditedElementType: (state, action) => {
      state.editedElementType = action.payload;
    },
    setSelectedElementTypeGroup: (state, action) => {
      state.selectedElementTypeGroup = action.payload;
    },
    setSelectedElementTypesGroupProxy: (state, action) => {
      const groupProxy = action.payload;
      state.selectedElementTypesGroupProxy = groupProxy;
      if (groupProxy?.fromScene)
        state.selectedElementTypeGroup = groupProxy.name;
    },
    setSelectedElementTypesGroupProxyInEditMeasurements: (state, action) => {
      const groupProxy = action.payload;
      console.log("[STATE] typesGroupProxy in EditMeas", groupProxy);
      state.selectedElementTypesGroupProxyInEditMeasurements = groupProxy;
    },
    setNewElementType: (state, action) => {
      state.newElementType = action.payload;
    },
    setTempElementType: (state, action) => {
      state.tempElementType = action.payload;
    },
    setOpenCreateElementTypeFastDialog: (state, action) => {
      state.openCreateElementTypeFastDialog = action.payload;
    },
    //
    setDatagridIsInEditMode: (state, action) => {
      state.datagridIsInEditMode = action.payload;
    },
    setEditedElementTypesGroup: (state, action) => {
      if (!action.payload) return;
      const {group, sortByNum} = action.payload;
      if (!group) return;

      let elementTypes = group?.elementTypes;
      if (sortByNum) elementTypes = getSortedItemsArrayByNum(elementTypes);

      state.editedElementTypesGroup = {...group, elementTypes};
    },
    setShowElementTypesReorderHelpers: (state, action) => {
      state.showElementTypesReorderHelpers = action.payload;
    },
    updateElementTypeInEditedElementTypesGroup: (state, action) => {
      const {elementType} = action.payload;
      const updatedGroup = {...state.editedElementTypesGroup};

      const newTypes = updatedGroup.elementTypes.map((type) => {
        if (type.id === elementType.id) {
          return elementType;
        } else {
          return type;
        }
      });
      updatedGroup.elementTypes = newTypes;
      state.editedElementTypesGroup = updatedGroup;
    },
    addElementTypeToEditedElementTypesGroup: (state, action) => {
      // we add the elementType after the selected elementType
      const elementType = action.payload;
      const updatedGroup = state.editedElementTypesGroup
        ? {...state.editedElementTypesGroup}
        : {};
      let editedElementTypes = updatedGroup.elementTypes
        ? updatedGroup.elementTypes
        : [];

      const selectedElementTypeIndex = editedElementTypes
        .map((r) => r.id)
        .indexOf(state.selectedElementTypeId);

      editedElementTypes.splice(selectedElementTypeIndex + 1, 0, elementType);

      editedElementTypes = refreshGroupedItemsNums(editedElementTypes);
      updatedGroup.elementTypes = editedElementTypes;

      state.editedElementTypesGroup = updatedGroup;
    },
    moveEditedElementType: (state, action) => {
      const {elementTypeId, prevElementTypeId, updateNums, isFirstItem} =
        action.payload;

      const updatedGroup = state.editedElementTypesGroup
        ? {...state.editedElementTypesGroup}
        : {};
      const editedElementTypes = updatedGroup.elementTypes
        ? updatedGroup.elementTypes
        : [];
      const elementType = editedElementTypes.find(
        (r) => r.id === elementTypeId
      );
      let newEditedElementTypes = editedElementTypes.filter(
        (r) => r.id !== elementTypeId
      );

      if (isFirstItem) {
        newEditedElementTypes = [elementType, ...newEditedElementTypes];
      } else {
        const prevIndex = newEditedElementTypes
          .map((r) => r.id)
          .indexOf(prevElementTypeId);
        newEditedElementTypes.splice(prevIndex + 1, 0, elementType);
      }

      if (updateNums)
        newEditedElementTypes = refreshGroupedItemsNums(newEditedElementTypes);

      updatedGroup.elementTypes = newEditedElementTypes;
      state.editedElementTypesGroup = updatedGroup;
    },
    deleteElementTypeInEditedElementTypesGroup: (state, action) => {
      const elementTypeId = action.payload;
      const updatedGroup = {...state.editedElementTypesGroup};
      const elementTypes = updatedGroup.elementTypes.filter(
        (t) => t.id !== elementTypeId
      );
      updatedGroup.elementTypes = elementTypes;
      state.editedElementTypesGroup = updatedGroup;
    },
    updateEditedElementTypesGroupNums: (state, action) => {
      const updatedGroup = {...state.editedElementTypesGroup};
      const items = Array.isArray(updatedGroup.elementTypes)
        ? updatedGroup.elementTypes
        : [];
      updatedGroup.elementTypes = refreshGroupedItemsNums(items);
      state.editedElementTypesGroup = updatedGroup;
    },
    setOpenDialogSelectElementType: (state, action) => {
      state.openDialogSelectElementType = action.payload;
    },
  },
  extraReducers: {
    [createElementTypesGroup.fulfilled]: (state, action) => {
      const {item} = action.payload;
      console.log("[state] new elementTypeGroup", item);
      const newGroup = {...item};
      newGroup.sceneId = item.scene;
      delete newGroup.scene;
      state.elementTypesGroups.push(newGroup);
    },
    [fetchElementTypesGroups.fulfilled]: (state, action) => {
      const {items} = action.payload;
      console.log("[state] fetch scene elementTypesGroups", items);
      const stateGroups = getStateElementTypesGroupsFromRemoteItems(items);
      state.elementTypesGroups = stateGroups;
    },
    [updateElementTypesGroup.fulfilled]: (state, action) => {
      const {item} = action.payload;
      console.log("[state] update scene elementTypesGroup", item);
      const elementTypesGroups = state.elementTypesGroups.filter(
        (g) => g.id !== item.id
      );
      state.elementTypesGroups = [
        ...elementTypesGroups,
        getStateElementTypesGroupFromRemoteItem(item),
      ];
      // handle rename
      if (state.selectedElementTypesGroupProxy?.id === item?.id) {
        state.selectedElementTypesGroupProxy.name = item.name;
      }
    },
    [deleteElementTypesGroup.fulfilled]: (state, action) => {
      console.log("[STATE] delete elementTypesGroup", action.payload);
      const {elementTypesGroupId} = action.payload;
      const elementTypesGroups = state.elementTypesGroups.filter(
        (g) => g.id !== elementTypesGroupId
      );
      state.elementTypesGroups = elementTypesGroups;
      state.selectedElementTypesGroupProxy = null;
    },
    "scenes/fetchSharedRemoteScene/fulfilled": (state, action) => {
      const {elementTypesGroups} = action.payload;
      let groups = [];
      if (Array.isArray(elementTypesGroups)) {
        groups = elementTypesGroups.map((g) => ({...g, sceneId: g.scene}));
      }
      state.elementTypesGroups = groups;
    },
  },
});

export const {
  addElementTypesGroups,
  setSelectedElementTypeId,
  setSelectedElementTypeIds,
  setSelectedFormGroup,
  setIsEditingElementType,
  setEditedElementType,
  setSelectedElementTypeGroup,
  setNewElementType,
  setOpenCreateElementTypeFastDialog,
  setSelectedElementTypesGroupProxy,
  setDatagridIsInEditMode,
  setEditedElementTypesGroup,
  updateElementTypeInEditedElementTypesGroup,
  addElementTypeToEditedElementTypesGroup,
  setShowElementTypesReorderHelpers,
  moveEditedElementType,
  deleteElementTypeInEditedElementTypesGroup,
  updateEditedElementTypesGroupNums,
  setTempElementType,
  setIsCreatingElementType,
  setFilterByGroupInMeasurements,
  setSelectedElementTypesGroupProxyInEditMeasurements,
  setOpenDialogSelectElementType,
} = elementTypesSlice.actions;

export default elementTypesSlice.reducer;
