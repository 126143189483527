import {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
// import {useSearchParams} from "react-router-dom";

import {Box} from "@mui/material";

import WebViewer from "@pdftron/webviewer";

import Layers from "./Layers";

import PdfEditor from "../js/PdfEditor";
import usePdfModelUrl from "../usePdfModelUrl";

import {
  setClickedObject,
  setPdftronOffset,
} from "Features/viewer3D/viewer3DSlice";
import useFilteredSceneMeasurements from "Features/measurements/useFilteredSceneMeasurements";
// import useFilteredIssues from "Features/issues/useFilteredIssues";
import useTranslation from "Features/translations/useTranslation";
import useSceneModule from "Features/navigation/useSceneModule";
import useMeasurementsScopeMode from "Features/measurements/useMeasurementsScopeMode";
import {
  setSelectedMeasurementIds,
  updateSelectedMeasurementIds,
} from "Features/measurements/measurementsSlice";
import {setSelectedZoneId} from "Features/zones/zonesSlice";
import {setJump, setShowPdfSelector} from "../pdfSlice";
// import { setCurrentSelection } from "Features/selection/selectionSlice";
import {setCopiedMeasurements} from "Features/measurements/measurementsSlice";
//
import usePasteMeasurements from "Features/measurements/usePasteMeasurements";
import useDeleteMeasurements from "Features/measurements/useDeleteMeasurements";
import {flip} from "@techstark/opencv-js";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import getItemsMapById from "Utils/getItemsMapById";

export default function PdfWebViewerModeEditor({
  model,
  // editor,
  isPDF,
  scene,
  caplaEditor,
  viewer,
}) {
  //

  //
  //const viewer = false;
  //
  const {i18n} = useTranslation();
  const language = i18n.language;
  //
  const dispatch = useDispatch();
  const sceneModule = useSceneModule();
  const selectedFixedViewer = useSelector((s) => s.ui.selectedFixedViewer);

  // refs

  const viewerRef = useRef();
  const pdfEditorRef = useRef();
  const copiedIdsRef = useRef();
  const measRef = useRef();
  const pasteRef = useRef();
  const countRef = useRef(1);
  const deleteRef = useRef();

  // helpers - mnger

  const mnger =
    pdfEditorRef.current?.annotationsManager?.measurementsPdfManager;

  const annotationManager =
    pdfEditorRef.current?.webViewer?.Core.annotationManager;

  // data - modifications

  const modifications = useSelector((s) => s.measurements.modifications);

  // data - reset pdf viewer

  const pdfViewerResetAt = useSelector((s) => s.pdf.pdfViewerResetAt);

  // data

  const zones = useZonesByScene(scene);
  const zonesById = getItemsMapById(zones);

  const pdfEditorIsLoadingDoc = useSelector((s) => s.pdf.pdfEditorIsLoadingDoc);
  const pdfModelIdLoaded = useSelector((s) => s.pdf.pdfModelIdLoaded);

  const os = useSelector((s) => s.ui.openSections);

  const urlToUse = usePdfModelUrl(model, viewer);

  const selectedMeasurementIds = useSelector(
    (s) => s.measurements.selectedMeasurementIds
  );

  const jump = useSelector((s) => s.pdf.jump);

  const hideSavedMeasurements = useSelector(
    (s) => s.measurements.hideSavedMeasurements
  );

  const measurementsShowedInViewerPdf = useSelector(
    (s) => s.pdf.measurementsShowedInViewerPdf
  );
  measRef.current = measurementsShowedInViewerPdf;

  const pdfPageNumber = useSelector((s) => s.viewer3D.pdfCurrentPage);

  // data - copy/paste

  const pasteMulti = useSelector((s) => s.measurements.pasteMulti);
  const pasteMeasurements = usePasteMeasurements({caplaEditor, scene});
  const pasteDeltaX = useSelector((s) => s.measurements.pasteDeltaX);
  const pasteDeltaY = useSelector((s) => s.measurements.pasteDeltaY);
  const pasteFlipH = useSelector((s) => s.measurements.pasteFlipH);
  const pasteFlipV = useSelector((s) => s.measurements.pasteFlipV);
  const pasteToZone = useSelector((s) => s.measurements.pasteToZone);

  const copiedMeasurements = useSelector(
    (s) => s.measurements.copiedMeasurements
  );

  useEffect(() => {
    countRef.current = 1;
  }, [
    pasteDeltaX,
    pasteDeltaY,
    pasteFlipH,
    pasteFlipV,
    pasteMulti,
    selectedMeasurementIds?.length,
    pasteToZone?.id,
  ]);

  pasteRef.current = {
    paste: pasteMeasurements,
    measurements: copiedMeasurements?.measurements,
    deltaX: pasteDeltaX,
    deltaY: pasteDeltaY,
    flipH: pasteFlipH,
    flipV: pasteFlipV,
    multi: pasteMulti,
    toZone: pasteToZone,
  };

  // data - delete

  const deleteMeasurements = useDeleteMeasurements(caplaEditor);
  deleteRef.current = deleteMeasurements;

  // helpers

  let modifiedMeasurements = [];
  if (modifications) {
    Object.values(modifications).forEach((measIds) => {
      modifiedMeasurements = [...modifiedMeasurements, ...measIds];
    });
  }

  // helpers - measurements in page

  const measurementsInPage = measurementsShowedInViewerPdf.filter(
    (measurement) => {
      return measurement?.drawingProps?.pageNumber === pdfPageNumber;
    }
  );

  let measIdsToShow = [...measurementsInPage.map((m) => m.id)];
  if (hideSavedMeasurements) {
    const modified = new Set(modifiedMeasurements);
    measIdsToShow = measIdsToShow.filter((i) => modified.has(i));
  }

  const measHash =
    measIdsToShow?.length > 0
      ? measIdsToShow[0]
      : "-" + pdfPageNumber + measurementsInPage.length;

  // helpers - selected annotations

  const selected = new Set(selectedMeasurementIds);
  const selectedAnnots = annotationManager
    ?.getAnnotationsList()
    .filter((a) => selected.has(a.Id));

  // handlers

  const handleCopy = (measIds) => {
    copiedIdsRef.current = measIds;
    const copiedMeasurements = measRef.current.filter((m) =>
      measIds.includes(m.id)
    );

    dispatch(setCopiedMeasurements({measurements: copiedMeasurements}));
  };

  const handlePaste = () => {
    pasteRef.current.paste({
      measurements: pasteRef.current.measurements,
      deltaX: pasteRef.current.deltaX * countRef.current,
      deltaY: -pasteRef.current.deltaY * countRef.current,
      flipH: pasteRef.current.flipH,
      flipV: pasteRef.current.flipV,
      toZone: pasteRef.current.toZone,
    });
    if (!pasteRef.current.multi) {
      dispatch(setCopiedMeasurements({measurements: []}));
      countRef.current = 1;
    } else {
      countRef.current += 1;
    }
  };

  const handleDelete = (ids) => {
    const deletedMeasurements = measRef.current.filter((m) =>
      ids.includes(m.id)
    );
    deleteRef.current(deletedMeasurements);
  };

  const handleSave = () => {};

  // init

  async function init() {
    if (!caplaEditor?.editorPdf) {
      const wv = await WebViewer(
        {
          path: "../../../pdftron/public",
          licenseKey: process.env.REACT_APP_PDFTRON_KEY,
          initialDoc: model?.url,
          extension: "pdf",
          enableMeasurement: true,
          fullAPI: true,
        },
        viewerRef.current
      );
      const {Annotations, annotationManager} = wv.Core;
      annotationManager.removeEventListener("annotationDeselected");
      annotationManager.addEventListener(
        "annotationSelected",
        (annotations, action) => {
          // console.log("haaaaaaaaaaaaaaaaaaaaaa", action, caplaEditor.editorPdf.updateSelection, annotations)
          if (action === "selected") {
            const selectedAnnotation = annotations[0];

            const selectedType = selectedAnnotation?.uk?.type;
            console.log(
              "[DEBUG33] selectedAnnotation",
              selectedType,
              selectedAnnotation
            );

            // caplaEditor.editorPdf.selectedAnnotation = selectedAnnotation;
            if (selectedType !== "MEASUREMENT" && selectedType !== "ISSUE") {
              const annotationEntity =
                caplaEditor.editorPdf.annotationsManager.getAnnotationEntity(
                  selectedAnnotation
                );
              if (!annotationEntity?.temp) {
                const elementAnnotation =
                  caplaEditor.editorPdf.annotationsManager.annotationEntityToModelElement(
                    annotationEntity
                  );
                dispatch(
                  setClickedObject({
                    type: "PDF_ANNOTATION",
                    modelId: selectedAnnotation.uk.measurementsModelId,
                    name: elementAnnotation?.name,
                    elementAnnotation,
                  })
                );
              }
            }
            if (selectedType === "ZONE") {
              const selectionId = selectedAnnotation.uk.annotationId;
              dispatch(setSelectedZoneId(selectionId));
              caplaEditor.editorPdf.annotationsManager.selectAnnotation(
                selectionId
              );
            }
            const measurementsSelected = annotations.filter(
              (a) => a.uk.type === "MEASUREMENT"
            );
            if (
              measurementsSelected.length > 0 &&
              caplaEditor.editorPdf.updateSelection
            ) {
              dispatch(
                updateSelectedMeasurementIds(
                  measurementsSelected.map((m) => m.Id)
                )
              );
              // dispatch(setSelectedMeasurementIds(measurementsSelected.map((m) => m.Id)));
              // 3D
              measurementsSelected.forEach((annot) => {
                caplaEditor?.editor3d.sceneEditor.selectElementEntity({
                  modelId: annot.uk.measurementsModelId,
                  entityID: annot.Id,
                  keepSelection: true,
                  fromPdf: true,
                });
              });
            }
          } else if (
            action === "deselected" &&
            caplaEditor.editorPdf.updateSelection
          ) {
            // if (caplaEditor.editorPdf.updateSelection) {
            const selected = annotationManager.getSelectedAnnotations();
            if (selected.length > 0) {
              const measurementsDeselected = annotations.filter(
                (a) => a.uk.type === "MEASUREMENT"
              );
              if (measurementsDeselected.length > 0) {
                // console.log("haaaaaaaa", selected, measurementsDeselected)
                dispatch(
                  updateSelectedMeasurementIds(
                    measurementsDeselected.map((m) => m.Id)
                  )
                );
                // dispatch(setSelectedMeasurementIds(selected.map((m) => m.Id)));
                // 3D
                measurementsDeselected.forEach((annot) => {
                  caplaEditor.editor3d.sceneEditor.unselectElementEntity({
                    modelId: annot.uk.measurementsModelId,
                    entityID: annot.Id,
                    fromPdf: true,
                  });
                });
              } else if (annotations[0].uk.type === "ZONE") {
                dispatch(setSelectedZoneId(null));
                dispatch(setClickedObject({}));
                caplaEditor.editorPdf.annotationsManager.selectAnnotation(null);
              }
            } else {
              // console.log(
              //   "haaaaaaaaaaaa",
              //   "désélection",
              //   action,
              //   caplaEditor.editorPdf.updateSelection
              // );
              dispatch(setSelectedMeasurementIds([]));
              dispatch(setSelectedZoneId(null));
              dispatch(setClickedObject({}));
              caplaEditor.editorPdf.annotationsManager.selectAnnotation(null);
              caplaEditor.editor3d.sceneEditor.unselect();
            }
          }
        }
      );
      const {
        SelectionModel,
        BoxSelectionModel,
        CalloutSelectionModel,
        FreeTextSelectionModel,
        LineSelectionModel,
        PathSelectionModel,
        PolygonSelectionModel,
        RedactionSelectionModel,
        TextSelectionModel,
      } = Annotations;
      for (const selection of [
        BoxSelectionModel,
        CalloutSelectionModel,
        FreeTextSelectionModel,
        LineSelectionModel,
        PathSelectionModel,
        PolygonSelectionModel,
        RedactionSelectionModel,
        TextSelectionModel,
      ]) {
        SelectionModel.setCustomHandlers(selection, {
          drawSelectionOutline(ctx, annotation) {
            const x = annotation.X;
            const y = annotation.Y;
            const w = annotation.Width;
            const h = annotation.Height;
            ctx.lineWidth = 2;
            ctx.strokeStyle = "rgba(57, 255, 20, 0.5)";
            ctx.beginPath();
            ctx.moveTo(x, y);
            ctx.lineTo(x + w, y);
            ctx.lineTo(x + w, y + h);
            ctx.lineTo(x, y + h);
            ctx.closePath();
            ctx.stroke();
          },
          testSelection(
            annotation,
            x,
            y,
            pageMatrix,
            zoom,
            rotation,
            {originalTestSelection}
          ) {
            // return true;
            if (annotation instanceof Annotations.PolygonAnnotation) {
              return Annotations.SelectionAlgorithm.insidePolygonTest(
                annotation,
                x,
                y,
                pageMatrix,
                zoom
              );
            }
            return originalTestSelection(
              annotation,
              x,
              y,
              pageMatrix,
              zoom,
              rotation
            );
          },
        });
      }
      pdfEditorRef.current = new PdfEditor({
        webViewer: wv,
        viewerEl: viewerRef.current,
        caplaEditor,
        onCopy: handleCopy,
        onPaste: handlePaste,
        onDelete: handleDelete,
        onSave: handleSave,
      });
      caplaEditor?.setEditorPdf(pdfEditorRef.current);
      console.log("a new editorPdf is created");
    }
  }

  // helpers

  let offsetX, offsetY;
  const bounds = viewerRef.current?.getBoundingClientRect();
  if (bounds) {
    offsetX = bounds.left;
    offsetY = bounds.top;
  }

  // handlers

  function handleClick(e) {
    console.log("click", e.clientX, e.clientY);
  }

  // effects

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (urlToUse && model?.url && pdfEditorRef.current && isPDF) {
      pdfEditorRef.current.load(model, urlToUse);
    }
  }, [urlToUse, model?.url, pdfEditorRef.current]);

  useEffect(() => {
    if (language && pdfEditorRef.current) {
      pdfEditorRef.current.setLanguage(language);
    }
  }, [language, pdfEditorRef.current]);

  useEffect(() => {
    dispatch(setPdftronOffset({x: offsetX, y: offsetY}));
  }, [offsetX, offsetY]);

  //case 2 - EDITOR -  effect - display annotations - update in Edit Mode

  useEffect(() => {
    if (
      mnger &&
      model?.id &&
      !pdfEditorIsLoadingDoc &&
      os.fixedViewersBox &&
      model?.id === pdfModelIdLoaded &&
      !["ZONES", "DATA"].includes(sceneModule)
    ) {
      if (jump && pdfPageNumber !== jump.page) {
        // GO TO PAGE

        const {documentViewer} = pdfEditorRef.current.webViewer.Core;
        documentViewer.setCurrentPage(jump.page);
      } else {
        // CREATE (if do not exist)

        mnger.createAnnotationsFromMeasurements(measurementsInPage, {
          zonesById,
        });

        // FILTER BY PAGE

        mnger.applyFilterByMeasurementIds({
          measurementIds: measIdsToShow,
          pageNumber: pdfPageNumber,
        });
      }

      // SELECT AND JUMP (if need = jump object exists & selectedMeasurementIds)

      annotationManager.selectAnnotations(selectedAnnots);

      if (jump && selectedAnnots[0]) {
        pdfEditorRef.current.updateSelection = false;
        annotationManager.jumpToAnnotation(selectedAnnots[0], {
          fitToView: true,
        });
        pdfEditorRef.current.updateSelection = true;
        dispatch(setJump(null));
      }

      if (jump) {
        dispatch(setJump(null));
      }
    }
  }, [
    mnger,
    measHash,
    measurementsShowedInViewerPdf,
    model?.id,
    pdfEditorIsLoadingDoc, // we do not create annotations before loading the doc.
    sceneModule,
    os.fixedViewersBox,
    pdfModelIdLoaded,
    pdfPageNumber,
    jump,
    pdfViewerResetAt,
  ]);

  // effect - zones annotation

  useEffect(() => {
    if (pdfEditorRef.current && os.fixedViewersBox) {
      if (sceneModule === "ZONES" || sceneModule === "EDIT_MEASUREMENTS") {
        console.log("debug 2405 drawzones");
        pdfEditorRef.current.sceneEditor.showZoneAnnotations();
      } else {
        pdfEditorRef.current.sceneEditor.hideZoneAnnotations();
      }
    }
  }, [
    pdfEditorRef.current,
    sceneModule,
    os.fixedViewersBox,
    model?.id,
    //pdfPageNumber, // to change color of current page // comment to avoid conflict with next enhancement effect
  ]);

  useEffect(() => {
    if (pdfEditorRef.current && os.fixedViewersBox) {
      if (sceneModule === "ZONES" || sceneModule === "EDIT_MEASUREMENTS") {
        console.log("debug 2405 enhancezones");
        pdfEditorRef.current.annotationsManager.enhanceZoneAnnotations(
          pdfPageNumber
        );
      }
    }
  }, [
    pdfEditorRef.current,
    sceneModule,
    os.fixedViewersBox,
    model?.id,
    pdfPageNumber, // to change color of current page
  ]);

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        position: "relative",
        //borderLeft: (theme) => `2px solid ${theme.palette.primary.main}`,
      }}
      onClick={handleClick}
    >
      {selectedFixedViewer === "PDF" && (
        <Layers caplaEditor={caplaEditor} scene={scene} viewer={viewer} />
      )}
      <div ref={viewerRef} style={{height: "100%"}} />
    </Box>
  );
}
