import {useSelector} from "react-redux";

export default function useForceSelectionType() {
  const clickedObject = useSelector((s) => s.viewer3D.clickedObject);

  let type = "SCENE";
  if (clickedObject?.type === "MEASUREMENT") {
    type = "MEASUREMENT";
  } else if (
    ["IMAGE_ENTITY", "IMAGE_PART_ENTITY"].includes(clickedObject?.type)
  ) {
    type = "ZONE";
  }

  return type;
}
