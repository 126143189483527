import getStyleAndPropsFromCell from "Features/excel/utils/getStyleFromCell";

export default function excelRowToElementType(row) {
  const et = {};

  const fields = [
    "id",
    "name",
    "code",
    "drawingShape",
    "unit",
    "mainQty",
    "articleQties",
    "description",
    "dim1",
    "dim2",
    "height",
  ];

  for (let i = 1; i <= row.values.length; i++) {
    const value = row.getCell(i).value;
    const {style, props} = getStyleAndPropsFromCell(row.getCell(i));

    // generic computation

    if (value) et[fields[i - 1]] = value === "x" ? null : value;

    // specific computation

    if (i === 2) {
      // name cell
      if (value === "x" || !value) et.toDelete = true;
      if (props.style) {
        et.style = props.style;
        if (["title1", "title2"].includes(props.style)) et.isTitle = true;
      }
      if (style.alignToRight) et.style = "detail";
    } else if (i === 4) {
      // drawingShape cell
      et.color = style.bg ?? "#FF0000";
      if (!value || value === "x") et.isTitle = true;
    } else if (i === 5) {
      // unit
      if (!value && !et.isTitle) et.unit = "UN";
    }
  }

  return et;
}
