import {useEffect, useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import {updateArticlesTemp, resetArticlesTypes} from "../articlesSlice";

import getArticlesFromTemplateAndSubArticlesStep2 from "../utils/getArticlesFromTemplateAndSubArticlesStep2";

export default function useAutoComputeArticlesStep2({
  articlesGroup,
  elementTypesGroupsWithSortedItems,
}) {
  const dispatch = useDispatch();

  // data

  const articlesMap = useSelector((s) => s.articles.articlesMap);

  let sortedArticlesProxyByGroupId = useSelector(
    (s) => s.articles.sortedArticlesProxyByGroupId
  );

  const lastComputedArticlesAtStep2 = useSelector(
    (s) => s.articles.lastComputedArticlesAtStep2
  );

  // helper - sortedArticles

  let sortedArticles = articlesGroup?.sortedArticles;
  let sortedArticlesProxy =
    sortedArticlesProxyByGroupId[articlesGroup?.id] ?? [];
  if (!sortedArticlesProxy?.length > 0)
    sortedArticlesProxyByGroupId = {[articlesGroup?.id]: sortedArticles};

  // helper - initial articles

  const initialArticlesMap = Object.values(articlesMap)
    .filter((a) => a.articlesGroupId === articlesGroup?.id)
    .reduce((acc, article) => {
      acc[article.id] = article;
      return acc;
    }, {});

  // deps

  useEffect(() => {
    if (lastComputedArticlesAtStep2) {
      const batchUpdates = getArticlesFromTemplateAndSubArticlesStep2({
        initialArticlesMap,
        sortedArticlesProxyByGroupId,
        articlesGroup,
        elementTypesGroupsWithSortedItems,
      });
      dispatch(resetArticlesTypes(batchUpdates));
    }
  }, [lastComputedArticlesAtStep2]);
}
