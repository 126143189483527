import {useOutletContext} from "react-router-dom";

import {Box} from "@mui/material";

export default function PageMaterials() {
  // params

  const {scene} = useOutletContext();

  return <Box sx={{width: 1}}></Box>;
}
