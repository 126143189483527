import {useRef, useEffect, useLayoutEffect} from "react";
import {useSelector} from "react-redux";

import {styled} from "@mui/material/styles";

import debounce from "lodash.debounce";

import {getElementSize} from "../utils/sizeUtils";

// import {saveBlob} from "Features/files/utils";
import orbit from "Features/ui/assets/orbit.png";
import pan from "Features/ui/assets/pan.png";

const View = styled("div", {
  shouldForwardProp: (prop) =>
    prop != "positioning" &&
    prop != "orbiting" &&
    prop != "panning" &&
    prop != "hide",
})(({positioning, panning, orbiting, hide, theme}) => ({
  //flexGrow: 1,
  //width: "100%",
  //height: "100%"
  visibility: hide ? "hidden" : "visible",
  background: theme.palette.common.white,
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 0,
  boxSizing: "border-box",
  //overflow: "hidden",
  ...(positioning && {cursor: "crosshair"}),
  ...(!positioning && {cursor: "default"}),
  //...(!positioning && {cursor: "crosshair"}),
  ...(orbiting && {cursor: `url(${orbit}), auto`}),
  ...(panning && {cursor: `url(${pan}), auto`}),
}));

function View3D({editor3d}) {
  const containerRef = useRef(null);
  const timeRef = useRef();
  const enableRender = useRef(true);

  // data

  const rightSection = useSelector((state) => state.viewer3D.rightSection);
  const selectionPanelWidth = useSelector(
    (s) => s.selection.selectionPanelWidth
  );
  const explorerSection = useSelector(
    (state) => state.viewer3D.explorerSection
  );
  const toolData = useSelector((state) => state.viewer3D.toolData);
  const toolGroup = useSelector((state) => state.viewer3D.toolGroup);
  const mainTool = useSelector((state) => state.viewer3D.mainTool);
  const positioningObject = useSelector(
    (state) => state.viewer3D.positioningObject
  );

  const multiviews = useSelector((state) => state.viewer3D.multiviews);
  const viewMode = useSelector((state) => state.viewer3D.viewMode);
  const multiviewsRef = useRef(multiviews);
  multiviewsRef.current = multiviews;

  let positioning = positioningObject.editor;

  const orbiting = useSelector((s) => s.viewer3D.orbiting);
  const panning = useSelector((s) => s.viewer3D.panning);

  const openNavigation = useSelector((s) => s.navigation.open);
  const openSelection = useSelector((s) => s.selection.openSelectionPanel);
  const openLeftPanel = useSelector((s) => s.navigation.openLeftPanel);
  const landingPage = useSelector((s) => s.viewer3D.landingPage);
  const worksitesPage = useSelector((s) => s.viewer3D.worksitesPage);
  const fullscreen = useSelector((s) => s.ui.fullscreen);
  const isViewerVisible = useSelector((s) => s.viewer3D.isViewerVisible);
  const viewerWidth = useSelector((s) => s.viewer3D.viewerWidth);
  const isViewerFullScreen = useSelector((s) => s.viewer3D.isViewerFullScreen);
  const navPanelSceneModulesWidth = useSelector(
    (s) => s.navigation.navPanelSceneModulesWidth
  );

  const explorerWidth = useSelector((s) => s.viewer3D.explorerWidth);
  const dataSectionWidth = useSelector(
    (state) => state.viewer3D.dataSectionWidth
  );

  const os = useSelector((s) => s.ui.openSections);
  const disableRender = useSelector((s) => s.viewer3D.disableRender);

  // effect - render
  useEffect(() => {
    enableRender.current = os.viewer3D && !disableRender;
  }, [os.viewer3D, disableRender]);

  // multiviews

  if (multiviews) {
    editor3d?.multiviews.enableMultiviews();
  } else {
    editor3d?.multiviews.disableMultiviews();
  }

  // helpers

  const hide = worksitesPage;

  // handlers

  // function handleScreenshotClick() {
  //   render();
  //   const canvas = containerRef.current.children[0];
  //   canvas.toBlob((blob) => {
  //     saveBlob(blob, `screencapture-${canvas.width}x${canvas.height}.png`);
  //   });
  // }

  const init = () => {
    console.log("initializing view");
    try {
      // time

      timeRef.current = performance.now();

      // editor3d

      const size = getElementSize(containerRef.current);
      if (editor3d) editor3d.canvasSize = size;
      editor3d?.setDomElement(containerRef.current);

      console.log("initCamera", size);
      editor3d?.initCamera(size);

      editor3d?.multiviews.initViews(); // initControls
      editor3d?.camera2Helper.createObject(); // add helper to the scene

      editor3d?.initRenderer(size, containerRef.current);
      editor3d?.initLabel1Renderer(size, editor3d?.multiviews.el1); // the camera1 helper
      editor3d?.initLabel2Renderer(size, editor3d?.multiviews.el2);
      editor3d?.initLabelRenderer(size, containerRef.current);

      // viewports

      if (editor3d) editor3d.canvas = containerRef.current.children[0];

      // listeners

      document.addEventListener("keydown", (event) => {
        editor3d?.handleKeyDown(event);
      });

      containerRef.current.addEventListener("pointerdown", (event) => {
        editor3d?.handlePointerDown(event);
      });
      containerRef.current.addEventListener("pointermove", (event) => {
        if (editor3d?.controls.activeMode === "ORBIT") {
          editor3d?.handlePointerMove(
            event,
            containerRef.current,
            editor3d?.cameras.activeCamera
          );
        }
      });

      containerRef.current.addEventListener("pointerup", (event) => {
        editor3d?.handlePointerUp(event);
      });
      containerRef.current.addEventListener("click", (event) => {
        //if (!editor3d?.dragging) editor3d?.onClick(event)
        editor3d?.onClick(
          event,
          containerRef.current,
          editor3d?.cameras.activeCamera
        );
      });

      window.addEventListener(
        "resize",
        debounce(() => {
          editor3d?.handleResize(containerRef.current);
        }, 100)
      );

      document.addEventListener("keydown", (e) => {
        if (
          editor3d?.controls.activeMode === "LOCK" ||
          editor3d?.controls.activeMode === "LOCK2"
        )
          editor3d?.controls.onKeyDown(e);
      });

      document.addEventListener("keyup", (e) => {
        if (
          editor3d?.controls.activeMode === "LOCK" ||
          editor3d?.controls.activeMode === "LOCK2"
        )
          editor3d?.controls.onKeyUp(e);
      });
    } catch (e) {
      console.log(e);
    }
  };

  /*
   * Renderer
   */

  function renderView(view, containerEl) {
    const left = Math.floor(containerEl.offsetWidth * view.left);
    const bottom = Math.floor(containerEl.offsetHeight * view.bottom);
    const width = Math.floor(containerEl.offsetWidth * view.width);
    const height = Math.floor(containerEl.offsetHeight * view.height);

    editor3d?.renderer.setViewport(left, bottom, width, height);
    editor3d?.renderer.setScissor(left, bottom, width, height);

    if (view.camera) {
      editor3d?.renderer.render(editor3d?.scene, view.camera);
    }
  }

  function render() {
    if (!multiviewsRef.current) {
      //const camera = editor3d?.cameras.activeCamera;
      const camera = editor3d?.cameras.camera;
      // render
      editor3d?.renderer.setScissorTest(false);
      editor3d?.renderer.render(editor3d?.scene, camera);
      editor3d?.labelRenderer.render(editor3d?.scene, camera);
    } else {
      // v MULTIVIEWS FEATURES v
      // updateSize();
      const camera1 = editor3d?.cameras.camera1;
      // const camera2 = editor3d?.cameras.camera2;

      editor3d?.label1Renderer.render(editor3d?.scene, camera1);

      //editor3d?.label2Renderer.render(editor3d?.scene, camera2); // uncomment : otherwise, the camera2 helper is not rendered.
      renderView(editor3d?.multiviews.view2, editor3d?.multiviews.containerEl); // we render view1 after view2 to keep renderer props for screenshot.
      renderView(editor3d?.multiviews.view1, editor3d?.multiviews.containerEl);
    }
  }

  function updateImagesReso() {
    console.log("update");
    if (editor3d?.context) editor3d?.context.updateImagesReso();
  }

  function animate() {
    if (enableRender.current) {
      const time = performance.now();
      editor3d?.controls.update(time);
      // flash
      if (editor3d?.loading) editor3d?.flash.randomUpdate();
      // flash mode
      if (editor3d?.flashDarkMode.isEnabled)
        editor3d?.flashDarkMode.randomUpdate();
      // updateImageReso
      // if (
      //   editor3d?.context?.imageResoAutoUpdate &&
      //   time - timeRef.current > 1000
      // ) {
      //   timeRef.current = time;
      //   updateImagesReso();
      // }
      // imageOverlay

      if (containerRef?.current) {
        const {top, right, left, bottom, width, height} =
          containerRef.current.getBoundingClientRect();
        const bounds = {top, right, left, bottom, width, height};
        editor3d?.imageOverlay.update({
          camera: editor3d?.cameras.activeCamera,
          bounds,
        });
      }

      // camera2Helper

      if (editor3d?.cameras?.camera2 && editor3d?.camera2Helper) {
        editor3d?.camera2Helper.update();
      }

      // render
      render();
    }

    if (enableRender.current) {
      requestAnimationFrame(animate);
    } else {
      setTimeout(() => {
        requestAnimationFrame(animate);
      }, 1000); // Adjust the delay (in milliseconds) as needed
    }
  }

  useEffect(() => {
    init();
    animate();
    return () => {
      window.addEventListener("resize", () => {
        editor3d?.handleResize(containerRef.current);
      });
    };
  }, []);

  // resize

  useLayoutEffect(() => {
    setTimeout(() => {
      console.log(
        "resize effect 31",
        containerRef.current?.getBoundingClientRect()
      );
      editor3d?.handleResize(containerRef.current);
      editor3d?.multiviews.updateCamerasAspect();
      editor3d?.multiviews.updateStateSliderPositionX();
    }, 10); // to get proper canvas resize...
  }, [
    rightSection,
    mainTool,
    toolData,
    multiviews,
    viewMode,
    explorerSection,
    toolGroup,
    explorerWidth,
    dataSectionWidth,
    landingPage,
    worksitesPage,
    fullscreen,
    openNavigation,
    openSelection,
    isViewerVisible,
    isViewerFullScreen,
    viewerWidth,
    openLeftPanel,
    selectionPanelWidth,
    navPanelSceneModulesWidth,

    os.outlet,
    os.outletDetail, // section on the right of the outlet.(redudant with call to action, keep to false)
    os.viewer3D, // viewer 3D with three.js
    os.fixedViewersBox,
    os.callToAction, // section on the right, for example to invite user to select a model to see it in the viewer
  ]);

  return (
    <View
      ref={containerRef}
      positioning={positioning}
      orbiting={orbiting}
      panning={panning}
      hide={hide}
    />
  );
}

export default View3D;
