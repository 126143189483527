import {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {updateLoadingRatioByScopeIdMap} from "../overviewerSlice";

import useSelectedScope from "Features/scopes/hooks/useSelectedScope";
import getModelIdsInScope from "Features/scopes/utils/getModelIdsInScope";
import useFetchSceneModels from "./useFetchSceneModels";
import getFixedScope from "Features/scopes/utils/getFixedScope";
import useScopeToOpen from "Features/scopes/hooks/useScopeToOpen";
import {update} from "Features/ifc/ifcSlice";
import getModelsInScope from "Features/scopes/utils/getModelsInScope";

export default function useAutoFetchSceneModelsInScope(scene) {
  const dispatch = useDispatch();

  // data

  const sceneIdOfSceneLight = useSelector(
    (s) => s.overviewer.sceneIdOfSceneLight
  ); // used to be sure that models are loaded from the scene.

  const selectedScope = useSelectedScope();

  const scopeIdToOpen = useSelector((s) => s.overviewer.scopeIdToOpen);
  const scopeToOpen = useScopeToOpen({useFixedScope: true});

  const models = useSelector((s) => s.viewer3D.models).filter(
    (m) => m.sceneClientId === scene?.clientId
  );
  const modelsIdsMap = models.reduce((acc, model) => {
    acc[model.id] = model;
    return acc;
  }, {});

  // local state

  const [fetchedScopeIdMap, setFetchedScopeIdMap] = useState({});

  // data - fetchModels

  const modelIdsToFetch = getModelIdsInScope(scopeToOpen).filter((id) =>
    Boolean(modelsIdsMap[id])
  );
  // filter to manage ids in scope of models that were deleted.
  const modelsToFetch = getModelsInScope(models, scopeToOpen);
  const options = {
    modelIdsInScope: modelIdsToFetch,
    fromScopeId: selectedScope?.id,
  };
  const [fetchModels] = useFetchSceneModels(options);

  // helper

  const userWantsToOpen = scopeIdToOpen === selectedScope?.id;
  const initialScopeFromUrl = !scopeIdToOpen && selectedScope?.id;

  const scopeIsNotFetched =
    selectedScope?.id && !fetchedScopeIdMap[selectedScope?.id];

  const canFetch =
    (userWantsToOpen || initialScopeFromUrl) && scopeIsNotFetched;

  const sceneModelsAreLoaded =
    sceneIdOfSceneLight && sceneIdOfSceneLight === scene?.id;

  //

  async function fetchScopeModels() {
    await fetchModels();
    dispatch(
      updateLoadingRatioByScopeIdMap({
        scopeId: scopeIdToOpen,
        scopeWasFetched: true,
      })
    );
  }

  useEffect(() => {
    console.log("EFFECT333", fetchedScopeIdMap, "canFetch", canFetch);
    if (canFetch && sceneModelsAreLoaded) {
      console.log(
        "EFFECT333",
        scopeToOpen?.data,
        "FETCHING MODELS: modelIdsToFetch",
        modelIdsToFetch,
        "modelsToFetch",
        modelsToFetch,
        "models",
        models
      );
      const newMap = {...fetchedScopeIdMap, [selectedScope.id]: "ok"};
      setFetchedScopeIdMap(newMap);
      //
      dispatch(
        updateLoadingRatioByScopeIdMap({
          scopeId: scopeIdToOpen,
          modelIdsToFetch,
        })
      );
      //
      fetchScopeModels();
    }
  }, [scopeToOpen?.id, canFetch, sceneModelsAreLoaded]);
}
