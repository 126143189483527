import {useDispatch, useSelector} from "react-redux";

import {Box} from "@mui/material";

import CardFileName from "./CardFileName";
import SectionPdfZonesInSelectionPanel from "./SectionPdfZonesInSelectionPanel";
import SectionPdfPackagesInSelectionPanel from "./SectionPdfPackagesInSelectionPanel";
import FormPdfModel from "./FormPdfModel";
import ButtonDialogPdfScale from "./ButtonDialogPdfScale";
import SectionDownloadBimData from "./SectionDownloadBimData";

import useSelectedPdfModel from "../useSelectedPdfModel";
import useVersionsByScene from "Features/versions/hooks/useVersionsByScene";

import {setEditedPdfModel} from "../pdfSlice";

import CardCreateZone from "Features/zones/components/CardCreateZone";
import CardCreatePackage from "Features/elementPackages/components/CardCreatePackage";
// import ButtonDownloadBimPdf from "Features/measurements/components/ButtonDownloadBimPdf";
import useIsCreatingZone from "Features/zones/hooks/useIsCreatingZone";
import SectionHelperDrawZone from "Features/zones/components/SectionHelperDrawZone";
import SectionOptimizedPdf from "./SectionOptimizedPdf";
import SelectorVersionsForSceneModel from "Features/versions/components/SelectorVersionsForSceneModel";
import SectionInputNameWithIcon from "Features/inputs/components/SectionInputNameWithIcon";

export default function SectionPdfInSelectionPanel({caplaEditor, scene}) {
  const dispatch = useDispatch();

  // data

  const selectedPdfModel = useSelectedPdfModel();
  const editedPdfModel = useSelector((s) => s.pdf.editedPdfModel);
  const isEditing = useSelector((s) => s.pdf.isEditing);
  const models = useSelector((s) => s.viewer3D.models);
  const isCreatingZone = useIsCreatingZone();
  const os = useSelector((s) => s.ui.openSections);
  const versions = useVersionsByScene(scene);

  // helper - no version

  const noVersions = !versions?.length > 0;

  // helper - model

  const model = isEditing ? editedPdfModel : selectedPdfModel;

  // helper

  const fileName = model?.fileName;

  const noZones = model?.zones?.length === 0 || !model?.zones;
  const noPackages = !Boolean(
    models.find(
      (m) => m.type === "MEASUREMENTS" && m?.fromModel?.modelId === model.id
    )
  );

  // handlers

  function handleEditedPdfModelChange(model) {
    dispatch(setEditedPdfModel(model));
  }

  return (
    <Box sx={{width: 1, height: 1}}>
      {!isEditing && !isCreatingZone && (
        <Box>
          <SectionInputNameWithIcon model={model} variant="FILE_NAME" />

          {model && !noVersions && (
            <Box sx={{display: "flex", p: 2, mt: 1}}>
              <SelectorVersionsForSceneModel
                model={model}
                scene={scene}
                variant="PDF"
              />
            </Box>
          )}
          <Box sx={{width: 1, display: "flex", justifyContent: "center", p: 3}}>
            <ButtonDialogPdfScale
              scene={scene}
              editorPdf={caplaEditor?.editorPdf}
            />
          </Box>

          {os.fixedViewersBox && (
            <Box sx={{p: 2, my: 1}}>
              {noZones ? (
                <CardCreateZone scene={scene} caplaEditor={caplaEditor} />
              ) : (
                <SectionPdfZonesInSelectionPanel scene={scene} />
              )}
            </Box>
          )}

          <Box sx={{p: 2, my: 1}}>
            {noPackages ? (
              <CardCreatePackage
                scene={scene}
                editor3d={caplaEditor?.editor3d}
              />
            ) : (
              <SectionPdfPackagesInSelectionPanel
                scene={scene}
                caplaEditor={caplaEditor}
              />
            )}
          </Box>

          <Box sx={{p: 2, my: 1}}>
            <SectionOptimizedPdf model={model} sceneId={scene?.id} />
          </Box>

          {!noZones && !noPackages && (
            <Box sx={{p: 2, mt: 1}}>
              <SectionDownloadBimData
                scene={scene}
                editorPdf={caplaEditor?.editorPdf}
              />
            </Box>
          )}
        </Box>
      )}
      {isEditing && !isCreatingZone && (
        <Box>
          <FormPdfModel
            pdfModel={editedPdfModel}
            onChange={handleEditedPdfModelChange}
          />
        </Box>
      )}

      {isCreatingZone && (
        <SectionHelperDrawZone editorPdf={caplaEditor?.editorPdf} />
      )}
    </Box>
  );
}
