import {useDispatch} from "react-redux";

import {Button} from "@mui/material";
import {ArrowBackIos as Back} from "@mui/icons-material";

import {setSelectedMaterialClientId} from "../materialsSlice";

export default function ButtonBackMaterialsInSelectionPanel() {
  const dispatch = useDispatch();

  // string

  const backS = "Nouveau matériau";

  // handlers

  function handleClick() {
    dispatch(setSelectedMaterialClientId(null));
  }

  return (
    <Button startIcon={<Back />} size="small" onClick={handleClick}>
      {backS}
    </Button>
  );
}
