import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import useZonesByScene from "Features/zones/hooks/useZonesByScene";

import {setShortcutElementTypes} from "Features/measurements/measurementsSlice";
import {
  setZonesInBuffer,
  setUseBufferInShortcut,
} from "Features/zones/zonesSlice";
import {setEnablePoiFilters} from "Features/pois/poisSlice";

import {Box, Button, IconButton, Tooltip} from "@mui/material";

import {
  Videocam as Camera,
  Image,
  FilterAlt,
  FilterAltOff,
} from "@mui/icons-material";

export default function ButtonPoiAction({action, caplaEditor, scene, poi}) {
  const dispatch = useDispatch();

  // data

  const zones = useZonesByScene(scene, {fitlerByScope: true});
  const enablePoiFilters = useSelector((s) => s.pois.enablePoiFilters);
  const shortcutElementTypes = useSelector(
    (s) => s.measurements.shortcutElementTypes
  );

  // state

  const [prevShortcutElementTypes, setPrevShortcutElementTypes] = useState([]);

  useEffect(() => {
    if (poi?.id) setPrevShortcutElementTypes(shortcutElementTypes);
  }, [poi?.id]);

  // cleanup

  useEffect(() => {
    return () => {
      dispatch(setEnablePoiFilters(false));
      //dispatch(setShortcutElementTypes(prevShortcutElementTypes));
    };
  }, [poi?.id, prevShortcutElementTypes?.length]);

  // handlers

  function handleAppyCamera() {
    const camera = poi?.camera;
    if (camera) caplaEditor?.editor3d?.sceneEditor.updateCamera(camera);
  }

  function handleShowImages() {
    // context
    const imageModelIds = poi.sceneContext?.imageModelIds;
    const zonesForBuffer = imageModelIds
      ?.map((mid) => zones.find((z) => z.imageModelId === mid))
      .filter((z) => z);
    dispatch(setZonesInBuffer(zonesForBuffer ? zonesForBuffer : []));
    const sceneContext = poi?.sceneContext;
    //
    const hideAllImageModels = !zonesForBuffer || zonesForBuffer?.length === 0;
    if (sceneContext) {
      caplaEditor?.editor3d?.sceneEditor.hideAllImageModels();
      caplaEditor?.editor3d?.sceneEditor.applyContext(
        sceneContext,
        !hideAllImageModels
      );
    }
    dispatch(setUseBufferInShortcut(true));
    //dispatch(setShortcutToolViewer3D("ZONES"));
  }

  function handleFilter() {
    if (poi?.shortcutTypeIds && poi.shortcutTypeIds.length > 0) {
      dispatch(setShortcutElementTypes(poi.shortcutTypeIds));
      dispatch(setEnablePoiFilters(!enablePoiFilters));
      if (enablePoiFilters) {
        dispatch(setShortcutElementTypes(prevShortcutElementTypes));
      }
    }
  }

  // maps

  const actionsMap = {
    CAMERA: {
      icon: <Camera fontSize="small" />,
      handler: handleAppyCamera,
      tooltip: "Appliquer la caméra",
    },
    IMAGE: {
      icon: <Image fontSize="small" />,
      handler: handleShowImages,
      tooltip: "Afficher les fonds de plan",
    },
    FILTER: {
      icon: enablePoiFilters ? (
        <FilterAltOff fontSize="small" />
      ) : (
        <FilterAlt fontSize="small" />
      ),
      handler: handleFilter,
      tooltip: "Filtrer",
    },
  };
  return (
    <Box>
      <Tooltip title={actionsMap[action]?.tooltip}>
        <IconButton size="small" onClick={actionsMap[action]?.handler}>
          {actionsMap[action]?.icon}
        </IconButton>
      </Tooltip>
    </Box>
  );
}
