import {useEffect} from "react";
import {useSearchParams} from "react-router-dom";

import {useSelector, useDispatch} from "react-redux";
import {useOutletContext} from "react-router-dom";

import {setOpenSections, newFirstLoadedPage} from "Features/ui/uiSlice";
import {
  setLastComputedArticlesAt,
  setLastComputedArticlesAtStep2,
  setLastComputedQtiesAt,
} from "Features/articles/articlesSlice";

import useSelectedScope from "Features/scopes/hooks/useSelectedScope";

import useAutoFetchArticles from "../hooks/useAutoFetchArticles";

import {Box} from "@mui/material";

import SectionArticleTypes from "./SectionArticleTypes";

export default function PageArticles() {
  console.log("PageArticles");
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  // effect init

  useEffect(() => {
    dispatch(newFirstLoadedPage("ARTICLES"));
  }, []);

  // effect end

  useEffect(() => {
    return () => {
      dispatch(setLastComputedArticlesAt(null));
      dispatch(setLastComputedArticlesAtStep2(null));
      dispatch(setLastComputedQtiesAt(null));
    };
  });

  // params

  const {caplaEditor, scene} = useOutletContext();
  const os = useSelector((s) => s.ui.openSections);
  const firstLoadedPages = useSelector((s) => s.ui.firstLoadedPages);

  // useAutoFetchArticles(scene); // fetching is managed from AppDesktop

  // data

  const selectedScope = useSelectedScope();

  // helpers

  const pageIsLoaded = firstLoadedPages.includes("ARTICLES");

  // effect - ui

  useEffect(() => {
    const newOs = {
      outlet: false,
      fixedViewersBox: false,
      viewer3D: true,
      callToAction: false,
    };
    if (!pageIsLoaded) dispatch(setOpenSections(newOs));
  }, [pageIsLoaded]);

  return (
    <Box sx={{width: 1, height: 1}}>
      <SectionArticleTypes scene={scene} />
    </Box>
  );
}
