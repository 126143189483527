import {useDispatch} from "react-redux";

import {createMaterialsGroup} from "../materialsSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function useCreateMaterialsGroup(options) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const createGroup = async (materialsGroup) => {
    await dispatch(
      createMaterialsGroup({
        accessToken,
        materialsGroup,
        fromScopeId: options?.fromScopeId,
      })
    );
  };
  return createGroup;
}
