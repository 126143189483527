import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import useSceneModule from "Features/navigation/useSceneModule";
import useSelectedRessource from "Features/ressources/hooks/useSelectedRessource";
import useSelectedElementType from "Features/elementTypes/hooks/useSelectedElementType";
import useSelectedElementTypeFromMeasurements from "Features/measurements/useSelectedElementTypeFromMeasurements";
import useAddPoiToRessource from "../hooks/useAddPoiToRessource";
import useAddPoiToElementType from "../hooks/useAddPoiToElementType";
import useAddPoiToArticle from "../hooks/useAddPoiToArticle";
import useSelectedArticle from "Features/articles/hooks/useSelectedArticle";

import {setSnackbarMessage} from "Features/ui/uiSlice";

import {
  Dialog,
  DialogTitle,
  Box,
  DialogContent,
  Button,
  TextField,
  DialogActions,
  Typography,
} from "@mui/material";

import serializePoi from "../utils/serializePois";
import ContainerElementType from "Features/elementTypes/components/ContainerElementType";
import ContainerElementTypeBasic from "Features/elementTypes/components/ContainerElementTypeBasic";

export default function DialogCreatePoi({open, onClose, scene, poiType}) {
  const dispatch = useDispatch();

  // strings

  const title = "Nouveau un point de vue";

  const descriptionS =
    "Ajoutez un point de vue pour décrire l'objet sélectionné";

  const poiDescriptionS = "Description";
  const createS = "Ajouter";

  // data

  const screenshot = useSelector((s) => s.viewer3D.screenshot);

  const ressource = useSelectedRessource(scene);
  const elementType = useSelectedElementType(scene);
  const elementTypeFromMeasurements =
    useSelectedElementTypeFromMeasurements(scene);
  const article = useSelectedArticle(scene);

  const sceneModule = useSceneModule();

  const addPoiToRessource = useAddPoiToRessource(scene);
  const addPoiToElementType = useAddPoiToElementType(scene);
  const addPoiToArticle = useAddPoiToArticle();

  const shortcutTypeIds = useSelector(
    (s) => s.measurements.shortcutElementTypes
  );

  const measIn3d = useSelector((s) => s.measurements.measShowedInViewer3d);

  // state

  const [description, setDescription] = useState("");

  // helpers - type

  const type =
    sceneModule === "ELEMENT_TYPES" ? elementType : elementTypeFromMeasurements;

  // helpers - typeIds from showedMeasurements

  const measTypeIds = [...new Set(measIn3d?.map((m) => m.elementTypeId))];

  // helpers

  const camera = screenshot?.options?.camera;
  const imageModelIds = screenshot?.options?.sceneContext?.visibleModelsIds;
  const imagesTransfos = screenshot?.options?.sceneContext?.imagesTransfos;

  //
  console.log("imageModelIds", imageModelIds, imagesTransfos);
  //
  //
  const sceneContext = {imageModelIds, imagesTransfos};
  //
  const newPoi = serializePoi({
    type: poiType,
    description,
    camera,
    sceneContext,
    savingMode: "full",
    shortcutTypeIds: measTypeIds,
  });

  // helper - canAdd

  const canCreate =
    newPoi &&
    ((ressource && sceneModule === "RESSOURCES") ||
      (article && sceneModule === "ARTICLES") ||
      (elementType && sceneModule === "ELEMENT_TYPES") ||
      (elementTypeFromMeasurements && sceneModule === "EDIT_MEASUREMENTS"));

  // handlers

  async function handleCreate() {
    let message;
    let triggeredAt = Date.now();
    let isError = false;
    try {
      //
      if (sceneModule === "RESSOURCES") {
        await addPoiToRessource({poi: newPoi, ressource});
      } else if (sceneModule === "ELEMENT_TYPES") {
        await addPoiToElementType({poi: newPoi, elementType});
      } else if (sceneModule === "EDIT_MEASUREMENTS") {
        await addPoiToElementType({
          poi: newPoi,
          elementType: elementTypeFromMeasurements,
        });
      } else if (sceneModule === "ARTICLES") {
        await addPoiToArticle({poi: newPoi, article});
      }

      //
      message = "Point d'intérêt ajouté !";
      dispatch(setSnackbarMessage({message, triggeredAt, isError}));
      //
    } catch (e) {
      isError = true;
      message = `Erreur : l'article n'a pas été enrichi`;
      dispatch(setSnackbarMessage({message, triggeredAt, isError}));
    }

    onClose();
  }

  //

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{borderRadius: "8px", bgcolor: "background.default", p: 1}}>
          {type && <ContainerElementTypeBasic elementType={type} />}
          {sceneModule === "RESSOURCES" && (
            <Typography variant="body2">{ressource?.name}</Typography>
          )}
        </Box>
        <Typography variant="body2" color="text.secondary">
          {descriptionS}
        </Typography>
        <Box sx={{mt: 2}}>
          <Box
            sx={{
              border: "1px solid black",
              mb: 2,
              display: "flex",
              aligntItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={screenshot?.url} height="200px" width="auto" />
          </Box>

          <Box sx={{width: 1}}>
            <TextField
              label={poiDescriptionS}
              fullWidth
              size="small"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={!canCreate} onClick={handleCreate}>
          {createS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
