import useSceneModule from "Features/navigation/useSceneModule";
import usePageType from "Features/navigation/usePageType";
import useIsDrawing from "Features/measurements/useIsDrawing";
import useSelection from "Features/selection/useSelection";

export default function useSelectionTypeInSelectionPanel({
  scene,
  caplaEditor,
  forceSelectionType,
  viewer,
}) {
  // data

  const isDrawing = useIsDrawing();

  const selection = useSelection({
    scene,
    caplaEditor,
    viewer,
    forceSelectionType,
  });
  console.log("selection", selection, forceSelectionType);

  // logic

  // HOME

  let selectionType = "HOME";

  // VOID
  if (!selection.type && !viewer) {
    selectionType = "VOID";
  }
  // IS DRAWING
  else if (isDrawing) {
    selectionType = "IS_DRAWING";
  }
  // SCENE
  else if (selection.type === "SCENE") {
    selectionType = "SCENE";
  }
  // MARKERS
  else if (["MARKER", "NEW_MARKER"].includes(selection.type)) {
    selectionType = "MARKER";
  }
  // SECTORS
  else if (["SECTOR", "TEMP_SECTOR"].includes(selection.type)) {
    selectionType = "SECTOR";
  }
  // PHASES
  else if (["PHASE"].includes(selection.type)) {
    selectionType = "PHASE";
  }
  // ROOMS
  else if (["ROOM"].includes(selection.type)) {
    selectionType = "ROOM";
  }
  // MATERIALS
  else if (["MATERIAL"].includes(selection.type)) {
    selectionType = "MATERIAL";
  }
  // TEMP_INPUT
  else if (selection?.type === "TEMP_INPUT") {
    selectionType = "TEMP_INPUT";
  }
  // PDF_MODEL
  else if (selection?.type === "PDF_MODEL") {
    selectionType = "PDF";
  }
  // ZONES
  else if (["ZONE"].includes(selection.type)) {
    selectionType = "ZONE";
  }
  // ELEMENT_TYPES
  else if (["ELEMENT_TYPE", "NEW_ELEMENT_TYPE"].includes(selection.type)) {
    selectionType = "ELEMENT_TYPE";
  }
  // PACKAGES
  else if (["PACKAGE", "NEW_PACKAGE"].includes(selection.type)) {
    selectionType = "PACKAGE";
  }
  // MEASUREMENTS
  else if (["MEASUREMENT", "TEMP_MEASUREMENT"].includes(selection.type)) {
    selectionType = "MEASUREMENT";
  }
  // ARTICLES
  else if (selection?.type === "ARTICLE") {
    selectionType = "ARTICLE";
  }
  // ARTICLES_GROUPS
  else if (selection?.type === "ARTICLES_GROUP") {
    selectionType = "ARTICLES_GROUP";
  }
  // SURVEYS
  else if (selection?.type === "SURVEY") {
    selectionType = "SURVEY";
  }
  // SHARED_BIMBOXES

  // ISSUES
  else if (["ISSUE", "NEW_ISSUE"].includes(selection.type)) {
    selectionType = "ISSUE";
  }

  //

  return selectionType;
}
