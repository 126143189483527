import React from "react";

import {useSelector} from "react-redux";

import {List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {CheckBoxOutlineBlank, CheckBox} from "@mui/icons-material";

export default function SectionSelectorMaterialsNomenclature({
  selectedNomenclatureId,
  onSelectionChange,
  nomenclatures,
}) {
  const items = nomenclatures
    .map((nomenclature) => ({
      ...nomenclature,
      selected: nomenclature.id === selectedNomenclatureId,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  // helpers

  function handleClick(item) {
    onSelectionChange(item.id);
  }
  return (
    <List sx={{width: 1}} dense>
      {items?.map((item) => (
        <ListItemButton key={item.id} onClick={() => handleClick(item)}>
          <ListItemIcon>
            {item.selected ? <CheckBox /> : <CheckBoxOutlineBlank />}
          </ListItemIcon>
          <ListItemText primary={item.name} />
        </ListItemButton>
      ))}
    </List>
  );
}
