const showedModulesByModeMap = {
  BASIC: [
    "INPUTS",
    "ZONES",
    "PACKAGES",
    "ELEMENT_TYPES",
    "EDIT_MEASUREMENTS",
    "MEASUREMENTS",
  ],
  STANDARD: [
    "INPUTS",
    "SECTORS",
    "ZONES",
    "PACKAGES",
    "ELEMENT_TYPES",
    "EDIT_MEASUREMENTS",
    "MEASUREMENTS",
  ],
  PRO: [
    "INPUTS",
    "SECTORS",
    "ZONES",
    "PACKAGES",
    "ELEMENT_TYPES",
    "EDIT_MEASUREMENTS",
    "ARTICLES",
    "MEASUREMENTS",
  ],
  DIAG: [
    "HOME",
    "INPUTS",
    "ZONES",
    "ROOMS",
    "PACKAGES",
    "ELEMENT_TYPES",
    "MEASUREMENTS",
    "SURVEYS",
    "MATERIALS",
    "SAMPLES",
    "REPORTS",
    "VIEWS",
  ],
  EXPERT: [
    "SCOPES",
    "INPUTS",
    "SECTORS",
    "ZONES",
    "MARKERS",
    "PACKAGES",
    "ELEMENT_TYPES",
    "EDIT_MEASUREMENTS",
    "MEASUREMENTS",
    "RESSOURCES",
    "RELATIONS",
    "ROOMS",
    "PHASE",
    "SHAREDBOXES",
    "ISSUES",
    "REPORTS",
    "VIEWS",
  ],
};

export default showedModulesByModeMap;
