import {useSelector, useDispatch} from "react-redux";
import useMaterialsByScene from "../hooks/useMaterialsByScene";

import {setSelectedMaterialsClientIds} from "../materialsSlice";
import {setSelectedMaterialsGroupId} from "../materialsSlice";

import {Box, Typography} from "@mui/material";

import ListMaterialsMultipleWithGroups from "./ListMaterialsMultipleWithGroups";
import useMaterialsGroupsByScene from "../hooks/useMaterialsGroupsByScene";
import useNomenclatureByMaterialsGroupId from "../hooks/useNomenclatureByMaterialGroupId";

export default function SectionMaterialsInLeftPanel({scene, caplaEditor}) {
  const dispatch = useDispatch();

  // string

  const noGroupS = "Aucune liste de matériaux";

  // data

  const materials = useMaterialsByScene(scene);
  const materialsGroups = useMaterialsGroupsByScene(scene, {
    sortByName: true,
    filterByScope: true,
  });
  const selectedMaterialsClientIds = useSelector(
    (s) => s.materials.selectedMaterialsClientIds
  );
  const selectedMaterialsGroupId = useSelector(
    (s) => s.materials.selectedMaterialsGroupId
  );

  const nomenclature = useNomenclatureByMaterialsGroupId(
    selectedMaterialsGroupId
  );
  console.log("nomenclature", nomenclature);

  // handlers

  function handleSelectionChange(ids) {
    dispatch(setSelectedMaterialsClientIds(ids));
  }

  function handleSelectedMaterialsGroupIdChange(id) {
    dispatch(setSelectedMaterialsGroupId(id));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        minHeight: 0,
        justifyItems: "strech",
      }}
    >
      {materialsGroups.length > 0 ? (
        <ListMaterialsMultipleWithGroups
          scene={scene}
          caplaEditor={caplaEditor}
          nomenclature={nomenclature}
          materials={materials}
          selectedMaterialsClientIds={selectedMaterialsClientIds}
          onSelectionChange={handleSelectionChange}
          materialsGroups={materialsGroups}
          forceSelection={true}
          selectedMaterialsGroupId={selectedMaterialsGroupId}
          onSelectedMaterialsGroupIdChange={
            handleSelectedMaterialsGroupIdChange
          }
          multipleSelection={false}
          variant="list"
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" color="text.secondary">
            {noGroupS}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
