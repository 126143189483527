import {useSelector, useDispatch} from "react-redux";

import {
  Box,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import {CenterFocusStrong as Focus, Close, Tune} from "@mui/icons-material";

import {updateModel} from "Features/viewer3D/viewer3DSlice";
import {
  addZoneToBuffer,
  removeZoneFromBuffer,
  setSelectedZoneId,
  setUseBufferInShortcut,
} from "Features/zones/zonesSlice";
import {setSelectedShortcutRight} from "Features/shortcut/shortcutSlice";
import {setSelectionTabInViewer} from "Features/selection/selectionSlice";
import {setSnackbarMessage} from "Features/ui/uiSlice";

export default function ListItemZone({zone, editor3d}) {
  const dispatch = useDispatch();

  // strings

  const tooltipRemove = "Retirer";
  const tooltipZoom = "Zoom";
  const tooltipModify = "Modifier l'image";

  // data

  const os = useSelector((s) => s.ui.openSections);
  const models = useSelector((s) => s.viewer3D.models).filter(
    (m) => m.type === "IMAGE"
  );
  const modelsWithInitialTexture = useSelector(
    (s) => s.viewer3D.modelsWithInitialTexture
  );
  const useBuffer = useSelector((s) => s.zones.useBufferInShortcut);

  // helpers

  const zoneModel = models.find(
    (m) => m.id === zone.imageModelId || m.id === zone.id
  );
  const loaded =
    modelsWithInitialTexture.includes(zone.imageModelId) ||
    modelsWithInitialTexture.includes(zone.id);

  const selected = loaded && zoneModel?.hidden === false;

  // handlers

  async function handleClick() {
    try {
      if (!loaded) {
        const options = {hideImageModels: true, loadTexture: true};
        await editor3d?.loader.loadInitialModel(zoneModel, options);
      }
      const hidden = loaded ? selected : false;
      const updatedModel = {...zoneModel, hidden: hidden};
      dispatch(updateModel({updatedModel, sync: false}));
      const entity = editor3d?.getEntity(zoneModel.id);
      if (!hidden) {
        entity?.show();
        dispatch(addZoneToBuffer(zone));
      } else {
        entity?.hide();
        if (!useBuffer) dispatch(removeZoneFromBuffer(zone));
      }
    } catch (e) {
      const message = `Fond de plan ${zone?.name} non trouvé`;
      const triggeredAt = Date.now();
      dispatch(setSnackbarMessage({message, triggeredAt, isError: true}));
    }
  }
  function handleEditClick(e) {
    e.stopPropagation();
    dispatch(setSelectedZoneId(zone.id));
    dispatch(setSelectedShortcutRight("SELECTION"));
    dispatch(setSelectionTabInViewer("zone"));
  }

  function handleFocusClick(e) {
    e.stopPropagation();
    editor3d?.sceneEditor.focusImageModel(zoneModel);
  }

  function handleRemoveClick(e) {
    e.stopPropagation();
    dispatch(removeZoneFromBuffer(zone));
    if (!zoneModel.hidden) {
      const updatedModel = {...zoneModel, hidden: true};
      dispatch(updateModel({updatedModel, sync: false}));
      const entity = editor3d?.getEntity(zoneModel.id);
      entity?.hide();
    }
  }

  return (
    <ListItem
      key={zone.id}
      disablePadding
      sx={{
        width: 250,
        bgcolor: (theme) => alpha(theme.palette.common.white, 0.85),
      }}
    >
      <ListItemButton
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "stretch",
          py: 0,
        }}
        selected={selected}
        onClick={() => handleClick(zoneModel)}
        disableGutters
        divider={true}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
          }}
        >
          {useBuffer && (
            <Tooltip title={tooltipRemove}>
              <IconButton size="small" onClick={(e) => handleRemoveClick(e)}>
                <Close
                  sx={{
                    color: "text.secondary",
                    visibility: os.viewer3D ? "visible" : "hidden",
                  }}
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <ListItemText
          sx={{
            pl: !useBuffer ? 1 : 0,
          }}
        >
          <Typography sx={{fontSize: 12}}>{zone.name}</Typography>
        </ListItemText>
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
          }}
        >
          {selected && (
            <Tooltip title={tooltipZoom}>
              <IconButton
                size="small"
                onClick={(event) => handleFocusClick(event, zone)}
              >
                <Focus color="text.secondary" fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          {selected && (
            <Tooltip title={tooltipModify}>
              <IconButton
                size="small"
                onClick={(event) => handleEditClick(event, zone)}
              >
                <Tune color="text.secondary" fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </ListItemButton>
    </ListItem>
  );
}
