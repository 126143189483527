import getScaledBbox from "./getScaledBbox";
import testBboxesOverlap from "./testBboxesOverlap";

export default function testMarkersOverlap(markerA, markerB, stageScale) {
  try {
    const bboxA = markerA.bbox;
    const bboxB = markerB.bbox;

    const scaledBboxA = getScaledBbox(
      bboxA.x,
      bboxA.y,
      bboxA.width,
      bboxA.height,
      1 / stageScale
    );
    const scaledBboxB = getScaledBbox(
      bboxB.x,
      bboxB.y,
      bboxB.width,
      bboxB.height,
      1 / stageScale
    );

    return testBboxesOverlap(scaledBboxA, scaledBboxB);
  } catch (error) {
    console.error(error);
  }
}
