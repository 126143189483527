import React, {useState, useEffect} from "react";

import {Link, useNavigate} from "react-router-dom";
import {Button, Typography, Link as MuiLink, Box} from "@mui/material";

import logo from "./assets/capla360-logo-300.png";
import useTranslation from "Features/translations/useTranslation";
import ContainerLoadingBimbox from "Features/overviewer/components/ContainerLoadingBimbox";
import useIsStagingEnv from "Features/overviewer/hooks/useIsStagingEnv";
import {useIsAuthenticated} from "@azure/msal-react";

export default function Auth() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const isStagingEnv = useIsStagingEnv();
  const isAuthenticated = useIsAuthenticated();
  console.log("isAuthenticated", isAuthenticated);

  // strings

  const homeString = "Accueil";

  // init

  useEffect(() => {
    let url = "/";
    //navigate(url);
  }, []);

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        bgcolor: "common.caplaBlack",
      }}
    >
      <Box sx={{display: "flex"}}>
        <ContainerLoadingBimbox />
      </Box>
      <MuiLink
        sx={{marginTop: 2, color: "common.white"}}
        component={Link}
        to="/"
      >
        {homeString}
      </MuiLink>
    </Box>
  );
}
