import {Vector2, ShapeUtils} from "three";
import Polyline from "Features/geometry/Polyline";

import {nanoid} from "@reduxjs/toolkit";

import Color from "color";

import theme from "Styles/theme";

import isDateInScope from "../utils/isDateInScope";
import MeasurementsExtractor from "./MeasurementsExtractor";
import getBankMeasurementQuantities from "../utils/getBankMeasurementQuantities";
import getSlopingPolygonMeasurementQuantities from "../utils/getSlopingPolygonMeasurementQuantities";
import getGableMeasurementQuantities from "Features/measurements/utils/getGableMeasurementQuantities";
import getBridgeMeasurementQuantities from "Features/measurements/utils/getBridgeMeasurementQuantities";

import updateMeasurementZsFromZone from "../utils/updateMeasurementZsFromZone";

import {
  // addMeasurements,
  // addCategories,
  // deleteMeasurement,
  // updateMeasurements,
  setEditedMeasurement,
  // updateMeasurementFromPdf,
  // resetMeasurements,
  // setSelectedMeasurementId,
  // updateSelectedMeasurementIds,
  setAnnotCreationTool,
  setMeasuredLength,
} from "Features/measurements/measurementsSlice";
import {setAutoMeasureZone} from "Features/measurementsAuto/measurementsAutoSlice";
import {getImageSize} from "Features/images/imageUtils";
import cleanMeasurementPaths from "../utils/cleanMeasurementPaths";
import {setSnackbarMessage} from "Features/ui/uiSlice";
import getCoordinatesFromZone1InZone2 from "../utils/getCoordinatesFromZone1InZone2";
// import store from "App/store";

export default class MeasurementsPdfManager {
  caplaEditor;
  webViewer;

  strokeM;
  height;
  dim1;
  dim2;
  dim3;
  zSup;
  zInf;
  depth;
  color;
  categoryId;
  sectorId;
  zoneId;
  measurementsModelId;
  presetId;
  elementTypeId;
  drawingShape;
  builtAt;
  exeComment;
  unit;
  quantityTemplates;

  drawDash; // related to "drawDash" in measurement state.

  isDrawing; // used to know when a new measurement annotation is added (for annotation listener)
  isDrawingAuto; // when the user clicked on auto. used in annotation listener
  isDrawingOneByOne; // if true, stop adding one annotation once it was added.

  measurementCreationMode; // "DEFAULT","POLYLINE_INTERIOR", "SCALE_SEGMENT" : how do we convert the drawn annotation in measurement annotation / handle annotation creation.
  // /!\ set back to default when once created scale segment

  silentSelection; // if yes, do not trigger a onSelection event. Used when selection is triggered programmatically from redux state change.
  isFirstCreation; // if yes, create another annotation from src annotation => used to prevent infinite loop when converting annotation.
  isFirstUpdate; // if yes, do not trigger change annotation event => used to prevent infinite loop.

  constructor({annotationsManager}) {
    this.annotationsManager = annotationsManager;
    this.annotationsIdsMap = {}; // used to manage copy/paste annotations. // cf getMeasurementAnnotationsIds. // need to be reset when changing PDF, otherwise, the annots will be deleted.

    this.color = theme.palette.primary.main; // flash pink

    this.isDrawing = false;
    this.isDrawingOneByOne = true; // state : measurements.isDrawingOneByOne
    this.isEditing = false; // used to prevent unselection when editing one annotation.

    this.addAnnotationsEventListener();

    this.drawDash = false;

    this.measurementCreationMode = "DEFAULT";

    this.isFirstCreation = true;
    this.isFirstUpdate = true;

    this.extractor = new MeasurementsExtractor({
      webViewer: annotationsManager.webViewer,
    });

    this.caplaEditor = annotationsManager.caplaEditor;
  }

  /*
   * setters
   */

  setAutoGeo3D = (bool) => (this.autoGeo3D = bool);
  setTempMeasurement = (tempMeasurement) =>
    (this.tempMeasurement = tempMeasurement);

  setSelectedMeasurement = (measurement) => {
    try {
      const {annotationManager} = this.webViewer.Core;
      this.selectedMeasurement = measurement;
      const annot = annotationManager.getAnnotationById(measurement.id);
      this.selectedMeasurementAnnotationCopy =
        annotationManager.getAnnotationCopy(annot);
      this.caplaEditor.editorPdf.selectedAnnotation = annot;
    } catch (e) {
      console.log(e);
    }
  };

  setEditedMeasurement = (m) => (this.editedMeasurement = m);

  setSelectedMeasurementElementType = (elementType) =>
    (this.selectedMeasurementElementType = elementType);

  setElementTypeId = (id) => (this.elementTypeId = id);

  setDim1 = (dim1) => (this.dim1 = dim1);
  setDim2 = (dim2) => (this.dim2 = dim2);
  setDim3 = (dim3) => (this.dim3 = dim3);
  setHeight = (height) => (this.height = height);

  setColor = (color) => {
    if (color) {
      this.color = color;
      this.updateMeasurementTools();
    }
  };
  setZoneId = (id) => (this.zoneId = id);
  setZone = (zone) => {
    console.log("setZone", zone?.name);
    this.zone = zone;
  }; // to get zone.z to draw 3D by default

  setIsDrawing = (bool) => (this.isDrawing = bool);

  setIsEditing = (bool) => (this.isEditing = bool);

  setIsDrawingOneByOne = (bool) => (this.isDrawingOneByOne = bool);

  setIsDrawingAuto = (bool) => (this.isDrawingAuto = bool);

  setDrawVoid = (bool) => (this.drawVoid = bool); // can't set tool settings with dash...
  setDotted = (bool) => (this.dotted = bool);

  setMeasurementCreationMode = (mode) => (this.measurementCreationMode = mode);

  setSilentSelection = (bool) => (this.silentSelection = bool);

  setIsFirstCreation = (bool) => (this.isFirstCreation = bool);
  setIsFirstUpdate = (bool) => (this.isFirstCreation = bool);

  /*
   * getters
   */

  get mpd() {
    return this.annotationsManager.mpd;
  }

  get scale() {
    const dpi = 72;
    const meterByInch = 0.0254;
    return (this.mpd * dpi) / meterByInch;
  }

  get webViewer() {
    return this.annotationsManager.webViewer;
  }

  get annotationColor() {
    return this.colorToAnnotationColor(this.color);
  }

  get modelId() {
    return this.annotationsManager.caplaEditor?.editorPdf.modelId;
  }

  get measurementAnnotationIds() {
    const {annotationManager} = this.webViewer.Core;
    return annotationManager
      .getAnnotationsList()
      .filter((a) => a.getCustomData("type") === "MEASUREMENT")
      .map((a) => a.Id);
  }

  get pdfEditorIsLoadingDoc() {
    return this.caplaEditor?.editorPdf.isLoadingDoc;
  }

  /*
   * Color
   */

  colorToAnnotationColor(color) {
    const {Annotations} = this.webViewer.Core;
    if (typeof color === "string") {
      try {
        const colorObject = Color(color);
        const {r, g, b} = colorObject.rgb().object();
        return new Annotations.Color(r, g, b);
      } catch {
        const [r, g, b] = color.match(/\w\w/g).map((x) => parseInt(x, 16));
        return new Annotations.Color(r, g, b);
      }
    } else {
      return new Annotations.Color(0, 255, 0);
    }
  }
  /*
   * init
   */

  addAnnotationsEventListener = () => {
    const {annotationManager} = this.webViewer.Core;
    annotationManager.addEventListener(
      "annotationChanged",
      this.handleAnnotationChanged
    );

    // annotationManager.addEventListener(
    //   "annotationSelected",
    //   this.handleAnnotationsSelected
    // );
  };

  /*
   * create annotation from
   */

  createExteriorPolylineMeasurement(annotation) {}

  /*
   * create rectangle annotation from point click
   */

  rotatePoint(x, y, xc, yc, rotation) {
    if (!rotation) return {x, y};
    const angle = (Math.PI * rotation) / 180;
    const cos = Math.cos(angle);
    const sin = Math.sin(angle);
    const X = x - xc;
    const Y = y - yc;
    const _X = X * cos - Y * sin;
    const _Y = X * sin + Y * cos;
    return {x: _X + xc, y: _Y + yc};
  }

  createRectangleAnnotationFromPoint(point) {
    try {
      let {x, y, pageNumber, pageRotation} = point;
      const rotation = -this.tempMeasurement.rot;

      const {annotationManager, Annotations} = this.webViewer.Core;
      const annot = new Annotations.LineAnnotation();
      annot.PageNumber = pageNumber;

      let xStart, xEnd, yStart, yEnd;

      const dim1 = this.tempMeasurement.dim1;
      const dim2 = this.tempMeasurement.dim2;

      const dim1D = dim1 ? dim1 / this.mpd : 0.5 / this.mpd;
      const dim2D = dim2 ? dim2 / this.mpd : 0.5 / this.mpd;

      switch (pageRotation) {
        case 0: {
          xStart = x - dim2D / 2;
          xEnd = x + dim2D / 2;
          yStart = y;
          yEnd = y;
          break;
        }
        case 1: {
          yStart = y - dim2D / 2;
          yEnd = y + dim2D / 2;
          xStart = x;
          xEnd = x;
        }
        case 2: {
          xStart = x - dim2D / 2;
          xEnd = x + dim2D / 2;
          yStart = y;
          yEnd = y;
        }
        case 3: {
          yStart = y - dim2D / 2;
          yEnd = y + dim2D / 2;
          xStart = x;
          xEnd = x;
        }
      }

      const startP = this.rotatePoint(xStart, yStart, x, y, rotation);
      const endP = this.rotatePoint(xEnd, yEnd, x, y, rotation);

      console.log("createRect", startP, endP);

      annot.setStartPoint(startP.x, startP.y);
      annot.setEndPoint(endP.x, endP.y);

      annot.StrokeThickness = dim1D;
      annot.StrokeColor = this.annotationColor;
      annot.Opacity = 0.8;

      annotationManager.addAnnotation(annot);

      annotationManager.redrawAnnotation(annot);
    } catch (e) {
      console.log("error", e);
    }
  }

  createRectangle2AnnotationFromPoint(point) {
    const {x, y, pageNumber, pageRotation} = point;
    const {annotationManager, Annotations} = this.webViewer.Core;
    const annot = new Annotations.RectangleAnnotation();
    annot.PageNumber = pageNumber;

    let X, Y, Width, Height;

    const dim1 = this.tempMeasurement.dim1;
    const dim2 = this.tempMeasurement.dim2;

    const dim1D = dim1 ? dim1 / this.mpd : 0.5 / this.mpd;
    const dim2D = dim2 ? dim2 / this.mpd : 0.5 / this.mpd;

    switch (pageRotation) {
      case 0: {
        X = x - dim1D / 2;
        Y = y - dim2D / 2;
        Width = dim1D;
        Height = dim2D;
        break;
      }
      case 1: {
        X = x + dim2D / 2;
        Y = y + dim1D / 2;
        Width = dim2D;
        Height = dim1D;
      }
      case 2: {
        X = x - dim1D / 2;
        Y = y - dim2D / 2;
        Width = dim1D;
        Height = dim2D;
      }
      case 3: {
        X = x - dim2D / 2;
        Y = y - dim1D / 2;
        Width = dim2D;
        Height = dim1D;
      }
    }
    annot.X = X;
    annot.Y = Y;
    annot.Width = Width;
    annot.Height = Height;

    annot.StrokeThickness = 0;
    annot.FillColor = this.annotationColor;
    annot.Opacity = 0.8;
    annotationManager.addAnnotation(annot);

    annotationManager.redrawAnnotation(annot);
  }

  createCircleAnnotationFromPoint(point) {
    const {x, y, pageNumber, pageRotation} = point;
    const {annotationManager, Annotations} = this.webViewer.Core;
    const annot = new Annotations.EllipseAnnotation();
    annot.PageNumber = pageNumber;

    let X, Y, Width, Height;
    const dim1D = this.dim1 ? this.dim1 / this.mpd : 0.5 / this.mpd;
    const dim2D = dim1D;

    switch (pageRotation) {
      case 0: {
        X = x - dim1D / 2;
        Y = y - dim2D / 2;
        Width = dim1D;
        Height = dim2D;
        break;
      }
      case 1: {
        X = x + dim2D / 2;
        Y = y + dim1D / 2;
        Width = dim2D;
        Height = dim1D;
      }
      case 2: {
        X = x - dim1D / 2;
        Y = y - dim2D / 2;
        Width = dim1D;
        Height = dim2D;
      }
      case 3: {
        X = x - dim2D / 2;
        Y = y - dim1D / 2;
        Width = dim2D;
        Height = dim1D;
      }
    }
    annot.X = X;
    annot.Y = Y;
    annot.Width = Width;
    annot.Height = Height;

    annot.StrokeThickness = 0;
    annot.FillColor = this.annotationColor;
    annot.Opacity = 0.8;
    annot.NoRotate = true;
    annotationManager.addAnnotation(annot);
  }

  /*
   * event handlers
   */

  stopDrawing = () => {
    this.caplaEditor?.dispatch(setAnnotCreationTool(null));
    this.setMeasurementCreationMode("DEFAULT");
    this.setIsDrawing(false);
    setTimeout(() => {
      this.caplaEditor?.editorPdf.enablePopup();
      this.triggerPanTool();
    }, 20); // need delay to draw the annotation before changing tool. otherwise, annotation delete is triggered by pdftron
    //this.annotationsManager.caplaEditor?.editorPdf.sceneEditor.stopProcess(); // for line segment.
  };

  saveNewAnnotAsMeasurement(annot) {
    try {
      annot.setCustomData("type", "MEASUREMENT");
      annot.setCustomData("elementTypeId", this.elementTypeId);
      annot.setCustomData(
        "measurementsModelId",
        this.tempMeasurement.measurementsModelId
      );
      annot.setCustomData("zoneId", this.zoneId);
      annot.setCustomData("id", annot.Id); // used to differentiate copied annotations from added annotations. (copy/paste pb). // copied from pdfTron will have an id that already exists.
      //this.annotationsIdsMap[annot.Id] = Date.now(); // we move this part in the change listener to manage the case where meas are first created.
    } catch (e) {
      console.log("error", e);
    }
  }

  handleAnnotationChanged = async (annotations, action) => {
    const {annotationManager} = this.webViewer.Core;
    const selectedAnnotations = annotationManager.getSelectedAnnotations();
    const annot = annotations[0];
    const type = annot.getCustomData("type");
    const annotId = annot.getCustomData("id");

    // ignore BIM DATA updates
    if (
      [
        "PDF_SCALE",
        "PHASES",
        "SECTORS",
        "ZONES",
        "RESSOURCES",
        "ELEMENT_TYPES",
        "MEASUREMENTS",
      ].includes(type)
    )
      return;

    switch (action) {
      case "add": {
        console.log(
          "add312",
          annot.elementName,
          this.tempMeasurement,
          "measurementCreationModeA",
          this.measurementCreationMode,
          annot
        );

        // ...editing one measurement
        if (this.isEditing) {
          console.log("add triggered when editing");
          break;
        }

        // setting zone from annotation localisation
        const point = {x: annot.X, y: annot.Y, pageNumber: annot.PageNumber};
        const zone = this.annotationsManager.getPointBoundingZone(point);
        this.setZoneId(zone?.id);
        this.setZone(zone);

        const mpd = zone ? zone.scale : this.mpd;

        // creation (other than simple measurements)
        if (
          this.measurementCreationMode === "SCALE_SEGMENT" &&
          annot.getLineLength
        ) {
          // used to compute scale.
          const length = annot.getLineLength() * mpd;
          const angleDeg = (annot.getAngle() * 180) / Math.PI;
          this.caplaEditor?.dispatch(
            setMeasuredLength({length, scale: (mpd * 72) / 0.0254, angleDeg})
          );
          console.log("delete10");
          annotationManager.deleteAnnotation(annot);
          this.stopDrawing();
          this.setIsDrawing(false);
        }

        // void style

        if (this.dotted) {
          annot.Style = "dash";
          annot.Dashes = "5,5";
        }

        // COPY PASTE

        // we check if the annotation was copied from pdfTron. If yes, we delete it.
        const isCopied =
          annotId && Boolean(annotationManager.getAnnotationById(annotId));

        if (isCopied) {
          console.log("isCopied", annotId, annot);
          try {
            annot.NoDelete = false;
            annotationManager.deleteAnnotation(annot, {force: true});
            return; // break here, otherwise, the annotation will be added afterwards from the default mode
          } catch (e) {
            console.log("error", e);
          }
        } else {
          annot.setCustomData("id", annot.Id); // used to differentiate copied annotations from added annotations. (copy/paste pb). // copied from pdfTron will have an id that already exists.
        }

        // resize segment if necessary
        if (
          ["SEGMENT", "BEAM"].includes(this.tempMeasurement?.drawingShape) &&
          this.tempMeasurement?.dim2 &&
          ["SEGMENT", "BEAM"].includes(this.measurementCreationMode)
        ) {
          this.resizeSegmentAnnotation(annot, this.tempMeasurement?.dim2);
        }

        // resize rectangle if necessary
        if (
          this.tempMeasurement?.drawingShape === "RECTANGLE" &&
          this.tempMeasurement?.dim1 &&
          this.tempMeasurement?.dim2 &&
          this.measurementCreationMode === "RECTANGLE"
        ) {
          this.resizeRectangleAnnotation(
            annot,
            this.tempMeasurement.dim1,
            this.tempMeasurement.dim2
          );
          // we lock resizing once done
          annot.NoResize = true;
        }

        // create annotation
        if (this.measurementCreationMode === "DEFAULT") {
          let tempMeasurement = annot.getCustomData("tempMeasurement");
          let fromZone = annot.getCustomData("fromZone");
          let toZone = annot.getCustomData("toZone");
          if (tempMeasurement?.length > 0)
            tempMeasurement = JSON.parse(tempMeasurement);
          if (fromZone?.length > 0) fromZone = JSON.parse(fromZone);
          if (toZone?.length > 0) toZone = JSON.parse(toZone);

          if (tempMeasurement) {
            this.lockAnnot({annotation: annot});
            this.saveNewAnnotAsMeasurement(annot);
            const measurement = await this.annotationToMeasurementFromPdf(
              annot,
              {
                mode: "CREATE_FROM_MEASUREMENT",
                tempMeasurement,
                fromZone,
                toZone,
              }
            );
            if (measurement?.drawingProps?.path?.length > 0) {
              // to remove edge case : line annotation creation when adding segment, when clicking 1st point
              this.caplaEditor?.editor3d.sceneEditor.addMeasurement3D(
                measurement
              );
              this.caplaEditor?.measDataManager.addMeasurements([measurement]);
            }
          }
        } else if (
          [
            //"POLYLINE", => use specific rule to remove last point.
            "GABLE",
            "BRIDGE",
            "BOWL",
            "POLYGON",
            "SLOPING_POLYGON",
            "BANK",
            "SEGMENT",
            "BEAM",
            "SLOPE",
            "STAIRS",
            "SEGMENT_FROM_ZONES",
            "RECTANGLE",
            "RECTANGLE_FROM_POINT",
            "CIRCLE_FROM_POINT",
            "FROM_3D",
          ].includes(this.measurementCreationMode)
        ) {
          this.lockAnnot({annotation: annot});

          this.saveNewAnnotAsMeasurement(annot);

          const measurement = await this.annotationToMeasurementFromPdf(annot, {
            mode: "CREATE",
          });

          // add text content
          annot.setContents(measurement.codeName);
          // this.annotationsManager.addLabelToAnnotation(
          //   annot,
          //   measurement.codeName
          // );
          //

          if (measurement?.drawingProps?.path?.length > 0) {
            // to remove edge case : line annotation creation when adding segment, when clicking 1st point
            this.caplaEditor?.editor3d.sceneEditor.addMeasurement3D(
              measurement
            );
            this.caplaEditor?.measDataManager.addMeasurements([measurement]);
            if (this.isDrawingOneByOne) this.stopDrawing();
          }

          //this.addLabel(annot);
        } else if (this.measurementCreationMode === "POLYLINE_FROM_SEGMENT") {
          console.log(
            "debug 31-05 E create polyline from segment",
            this.isFirstCreation
          );
          if (this.isFirstCreation) {
            const poly = this.segmentToPolyline(annot);
            if (poly) {
              this.setIsFirstCreation(false);
              annotationManager.addAnnotation(poly);
              console.log("delete11");
              annotationManager.deleteAnnotation(annot);
            }
          } else {
            this.lockAnnot({annotation: annot});
            this.saveNewAnnotAsMeasurement(annot);
            const measurement = await this.annotationToMeasurementFromPdf(
              annot,
              {mode: "CREATE"}
            );
            if (this.measurementIsValid(measurement)) {
              console.log("redrawPolyline23");
              annotationManager.redrawAnnotation(annot);
              this.caplaEditor?.measDataManager.addMeasurements([measurement]);
              this.caplaEditor?.editor3d.sceneEditor.addMeasurement3D(
                measurement
              );
              this.setIsFirstCreation(true);
            } else {
              annot.NoDelete = false;
              this.unlockAnnot({annotation: annot});
              annotationManager.deleteAnnotation(annot);
            }
            if (this.isDrawingOneByOne) this.stopDrawing();
          }
        } else if (this.measurementCreationMode === "POLYLINE_FROM_POLYGON") {
          if (this.isFirstCreation) {
            this.setIsFirstCreation(false);
            const poly = this.polygonToPolyline(annot);
            annotationManager.addAnnotation(poly);
            console.log("delete11");
            annotationManager.deleteAnnotation(annot);
          } else {
            this.lockAnnot({annotation: annot});
            this.saveNewAnnotAsMeasurement(annot);
            const measurement = await this.annotationToMeasurementFromPdf(
              annot,
              {mode: "CREATE"}
            );
            if (this.measurementIsValid(measurement)) {
              console.log("redrawPolyline22");
              annotationManager.redrawAnnotation(annot);
              this.caplaEditor?.measDataManager.addMeasurements([measurement]);
              this.caplaEditor?.editor3d.sceneEditor.addMeasurement3D(
                measurement
              );
              this.setIsFirstCreation(true);
            } else {
              annot.NoDelete = false;
              this.unlockAnnot({annotation: annot});
              annotationManager.deleteAnnotation(annot);
            }
            if (this.isDrawingOneByOne) this.stopDrawing();
          }
        } else if (this.measurementCreationMode === "POLYLINE_FROM_RECTANGLE") {
          if (this.isFirstCreation) {
            this.setIsFirstCreation(false);
            const poly = await this.rectangleToPolylineAsync(annot);
            annotationManager.addAnnotation(poly);
            console.log("delete11");
            annotationManager.deleteAnnotation(annot);
          } else {
            this.lockAnnot({annotation: annot});
            this.saveNewAnnotAsMeasurement(annot);
            const measurement = await this.annotationToMeasurementFromPdf(
              annot,
              {mode: "CREATE"}
            );
            if (this.measurementIsValid(measurement)) {
              console.log("redrawPolyline23");
              annotationManager.redrawAnnotation(annot);
              this.caplaEditor?.measDataManager.addMeasurements([measurement]);
              this.caplaEditor?.editor3d.sceneEditor.addMeasurement3D(
                measurement
              );
              this.setIsFirstCreation(true);
            } else {
              annot.NoDelete = false;
              this.unlockAnnot({annotation: annot});
              annotationManager.deleteAnnotation(annot);
            }
            if (this.isDrawingOneByOne) this.stopDrawing();
          }
        } else if (this.measurementCreationMode === "POLYGON_INTERIOR_BANK") {
          const path = annot.getPath().map((p) => ({x: p.x, y: p.y}));
          const d1 = this.tempMeasurement?.dim1;
          const d2 = this.tempMeasurement?.dim2;
          const h = this.tempMeasurement?.height;

          const width = ((d1 / d2) * h) / mpd;
          const exteriorPath = Polyline.getExteriorPolyline(path, width);
          exteriorPath.forEach((point, index) =>
            annot.setPathPoint(index, point.x, point.y)
          );
          annotationManager.redrawAnnotation(annot);

          this.saveNewAnnotAsMeasurement(annot);
          const measurement = await this.annotationToMeasurementFromPdf(annot, {
            mode: "CREATE",
          });
          if (this.measurementIsValid(measurement)) {
            this.lockAnnot({annotation: annot});
            this.caplaEditor?.measDataManager.addMeasurements([measurement]);
          } else {
            annot.NoDelete = false;
            this.unlockAnnot({annotation: annot});
            annotationManager.deleteAnnotation(annot);
          }
          if (this.isDrawingOneByOne) this.stopDrawing();
        } else if (this.measurementCreationMode === "POLYLINE") {
          const path = annot.getPath();
          path.splice(-1, 1);
          annotationManager.redrawAnnotation(annot);
          this.saveNewAnnotAsMeasurement(annot);
          const measurement = await this.annotationToMeasurementFromPdf(annot, {
            mode: "CREATE",
          });
          if (this.measurementIsValid(measurement)) {
            this.lockAnnot({annotation: annot});
            annotationManager.redrawAnnotation(annot);
            this.caplaEditor?.measDataManager.addMeasurements([measurement]);
            this.caplaEditor?.editor3d.sceneEditor.addMeasurement3D(
              measurement
            );
          } else {
            annot.NoDelete = false;
            this.unlockAnnot({annotation: annot});
            annotationManager.deleteAnnotation(annot);
          }
          if (this.isDrawingOneByOne) this.stopDrawing();
        } else if (this.measurementCreationMode === "POLYLINE_INTERIOR") {
          const path = annot.getPath().map((p) => ({x: p.x, y: p.y}));
          const width = (this.dim1 / mpd) * (1 / 2);
          const exteriorPath = Polyline.getExteriorPolyline(path, width);
          exteriorPath.forEach((point, index) =>
            annot.setPathPoint(index, point.x, point.y)
          );
          annot.StrokeThickness = this.dim1 / mpd;
          annotationManager.redrawAnnotation(annot);

          this.saveNewAnnotAsMeasurement(annot);
          const measurement = await this.annotationToMeasurementFromPdf(annot, {
            mode: "CREATE",
          });
          if (this.measurementIsValid(measurement)) {
            this.caplaEditor?.measDataManager.addMeasurements([measurement]);
          } else {
            annot.NoDelete = false;
            this.unlockAnnot({annotation: annot});
            annotationManager.deleteAnnotation(annot);
          }
          if (this.isDrawingOneByOne) this.stopDrawing();
        } else if (this.measurementCreationMode === "POLYLINE_EXTERIOR") {
          const path = annot.getPath().map((p) => ({x: p.x, y: p.y}));
          const width = (this.dim1 / mpd) * (1 / 2);
          const interiorPath = Polyline.getExteriorPolyline(path, -width);
          interiorPath.forEach((point, index) =>
            annot.setPathPoint(index, point.x, point.y)
          );
          annot.StrokeThickness = this.dim1 / mpd;
          annotationManager.redrawAnnotation(annot);

          this.saveNewAnnotAsMeasurement(annot);
          const measurement = await this.annotationToMeasurementFromPdf(annot, {
            mode: "CREATE",
          });
          if (this.measurementIsValid(measurement)) {
            this.caplaEditor?.measDataManager.addMeasurements([measurement]);
          } else {
            annot.NoDelete = false;
            this.unlockAnnot({annotation: annot});
            annotationManager.deleteAnnotation(annot);
          }
          if (this.isDrawingOneByOne) this.stopDrawing();
        } else if (this.measurementCreationMode === "POLYGON_FROM_RECTANGLE") {
          if (this.isFirstCreation) {
            this.setIsFirstCreation(false);
            const poly = await this.rectangleToPolygonAsync(annot);
            annotationManager.addAnnotation(poly);
            console.log("delete11");
            annotationManager.deleteAnnotation(annot);
          } else {
            this.lockAnnot({annotation: annot});
            this.saveNewAnnotAsMeasurement(annot);
            const measurement = await this.annotationToMeasurementFromPdf(
              annot,
              {mode: "CREATE"}
            );
            if (this.measurementIsValid(measurement)) {
              console.log("redrawPolygon21");
              annotationManager.redrawAnnotation(annot);
              this.caplaEditor?.measDataManager.addMeasurements([measurement]);
              this.caplaEditor?.editor3d.sceneEditor.addMeasurement3D(
                measurement
              );
              this.setIsFirstCreation(true);
            } else {
              annot.NoDelete = false;
              this.unlockAnnot({annotation: annot});
              annotationManager.deleteAnnotation(annot);
            }
            if (this.isDrawingOneByOne) this.stopDrawing();
          }
        } else if (this.isDrawingAuto) {
          annot.FillColor.A = 0;
          annot.Stroke = 0.1;
          annot.Opacity = 0;
          const {url, pageCoords, pageRotation, pageNumber, zoom, zoneId} =
            await this.getAnnotationImageUrl(annot);
          const size = await getImageSize({fileURL: url});
          this.caplaEditor?.dispatch(
            setAutoMeasureZone({
              url,
              pageRotation,
              pageCoords,
              pageNumber,
              zoom,
              size,
              mpd,
              zoneId,
            })
          );
          // delete rectangle annotation once the image is generated.
          annotationManager.deleteAnnotation(annot);
          this.setIsDrawingAuto(false);
        }

        break;
      }
      // case "delete": {
      //   console.log("deleteMeasurement31", annot.getCustomData("type"));
      //   if (type === "MEASUREMENT" && !this.isEditing) {
      //     this.caplaEditor?.dispatch(deleteMeasurement(annot.Id));
      //   }
      //   break;
      // }
      case "modify": {
        const dim1 = this.editedMeasurement?.dim1;
        const dim2 = this.editedMeasurement?.dim2;
        const drawingShape = this.editedMeasurement?.drawingShape;
        console.log("Modifying measurement", drawingShape, dim1, dim2);

        if (["SEGMENT", "BEAM"].includes(drawingShape) && dim2) {
          //this.resizeSegmentAnnotation(annot, width); =>infinit loop !
          this.resizeSegmentAnnotation(annot, dim2);
          const measurement = await this.annotationToMeasurementFromPdf(annot, {
            mode: "EDITING",
          });
          this.setEditedMeasurement(measurement);
          this.caplaEditor?.editor3d.sceneEditor.updateMeasurement3D(
            measurement
          );
          this.caplaEditor?.dispatch(setEditedMeasurement(measurement));
          // if (this.isFirstUpdate) {
          //   this.setIsFirstUpdate(false);
          //   this.resizeSegmentAnnotation(annot, dim2);
          // } else {
          //   this.setIsFirstUpdate(true);
          //   const measurement = this.annotationToMeasurementFromPdf(
          //     annot,
          //     "EDITING"
          //   );
          //   this.caplaEditor?.dispatch(updateMeasurementFromPdf(measurement));
          //   this.caplaEditor?.editor3d.sceneEditor.updateMeasurement3D(measurement);
          //   this.caplaEditor?.dispatch(setEditedMeasurement(measurement));
          // }
        } else if (drawingShape === "RECTANGLE2" && dim1 && dim2) {
          if (this.isFirstUpdate) {
            console.log("1st update");
            //this.setIsFirstUpdate(false);
            //this.resizeRectangleAnnotation(annot, dim1, dim2); // no need to resize the rectangle when rotating...
            const measurement = await this.annotationToMeasurementFromPdf(
              annot,
              {mode: "EDITING"}
            );
            this.setEditedMeasurement(measurement);
            this.caplaEditor?.editor3d.sceneEditor.updateMeasurement3D(
              measurement
            );
            this.caplaEditor?.dispatch(setEditedMeasurement(measurement));
          } else {
            console.log("2nd update"); // never called ...
            this.setIsFirstUpdate(true);
            console.log("change rectangle with rotation", annot.Rotation);
            const measurement = await this.annotationToMeasurementFromPdf(
              annot,
              {mode: "EDITING"}
            );
            this.setEditedMeasurement(measurement);
            this.caplaEditor?.editor3d.sceneEditor.updateMeasurement3D(
              measurement
            );
            this.caplaEditor?.dispatch(setEditedMeasurement(measurement));
          }
        } else if (type === "MEASUREMENT") {
          const measurement = await this.annotationToMeasurementFromPdf(annot, {
            mode: "EDITING",
          });
          this.setEditedMeasurement(measurement);
          this.caplaEditor?.dispatch(setEditedMeasurement(measurement));
          this.caplaEditor?.editor3d.sceneEditor.updateMeasurement3D(
            measurement
          );

          //this.deleteLabel(annot);
          //this.addLabel(annot);
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  /*
   * selection
   */

  colorSelectedAnnotation(annotation) {
    const {annotationManager} = this.webViewer.Core;
    const annotColor = annotation.Color;
    const rgb = {r: annotColor.R, g: annotColor.G, b: annotColor.B};
    annotation.setCustomData("rgb", JSON.stringify(rgb));
    annotation.Color = this.colorToAnnotationColor(theme.palette.primary.flash);
    annotationManager.redrawAnnotation(annotation);
  }

  uncolorSelectedAnnotation(annotation) {
    const {Annotations, annotationManager} = this.webViewer.Core;
    const rgbS = annotation.getCustomData("rgb");
    if (rgbS) {
      const rgb = JSON.parse(rgbS);
      annotation.Color = new Annotations.Color(rgb.r, rgb.g, rgb.b);
      annotationManager.redrawAnnotation(annotation);
    }
  }

  selectMeasurements(ids) {
    this.setSilentSelection(true);
    this.caplaEditor.editorPdf.updateSelection = false;
    const {annotationManager} = this.webViewer.Core;
    annotationManager.deselectAllAnnotations();
    const annotations = annotationManager
      .getAnnotationsList()
      .filter((a) => ids.includes(a.Id));
    annotationManager.selectAnnotations(annotations);
    this.setSilentSelection(false);
    this.caplaEditor.editorPdf.updateSelection = true;
  }

  unselectMeasurements(ids) {
    this.setSilentSelection(true);
    this.caplaEditor.editorPdf.updateSelection = false;
    const {annotationManager} = this.webViewer.Core;
    annotationManager.deselectAllAnnotations();
    // const annotations = annotationManager
    //   .getAnnotationsList()
    //   .filter((a) => ids.includes(a.Id));
    // annotations.forEach((annot) => this.uncolorSelectedAnnotation(annot));
    this.setSilentSelection(false);
    this.caplaEditor.editorPdf.updateSelection = true;
  }

  jumpToAnnotationId(id, options) {
    const fitToView = options?.fitToView;
    const {annotationManager, documentViewer} = this.webViewer.Core;
    if (options?.pageNumber) documentViewer.setCurrentPage(options.pageNumber);
    const annotation = annotationManager
      .getAnnotationsList()
      .find((a) => a.Id === id);
    console.log("jumpToAnnotation", annotation);
    if (annotation) annotationManager.jumpToAnnotation(annotation, {fitToView});
  }

  // handleAnnotationsSelected = (annotations, action) => {
  //   console.trace()
  //   console.time("haaaaaaaaaaaaa handleAnnotationsSelected")
  //   const {annotationManager} = this.webViewer.Core;
  //   if (this.silentSelection) {
  //     console.log("silentSelection");
  //     console.timeEnd("haaaaaaaaaaaaa handleAnnotationsSelected")
  //     console.log("haaaaaaaaaaaaaaaa silent")
  //     return;
  //   } else {
  //     if (action === "selected") {
  //       // 3D
  //       annotations.forEach((annot) => {
  //         const modelId = annot.getCustomData("measurementsModelId");
  //         const entityID = annot.Id;
  //         this.caplaEditor?.editor3d.sceneEditor.selectElementEntity({
  //           modelId,
  //           entityID,
  //         });
  //       });
  //       // color
  //       annotations.forEach((annot) => this.colorSelectedAnnotation(annot));
  //       // state
  //       const ids = annotations.map((a) => a.Id);
  //       console.log("selectAnnotations", ids);
  //       if (ids.length === 1) {
  //         this.caplaEditor?.dispatch(setSelectedMeasurementId(ids[0]));
  //         const measAnnots = annotations.filter(
  //           (a) => a.getCustomData("type") === "MEASUREMENT"
  //         );
  //         this.caplaEditor?.dispatch(
  //           updateSelectedMeasurementIds(measAnnots.map((a) => a.Id))
  //         );
  //       } else {
  //         const measAnnots = annotations.filter(
  //           (a) => a.getCustomData("type") === "MEASUREMENT"
  //         );
  //         this.caplaEditor?.dispatch(
  //           updateSelectedMeasurementIds(measAnnots.map((a) => a.Id))
  //         );
  //       }
  //       console.timeEnd("haaaaaaaaaaaaa handleAnnotationsSelected")
  //       console.log("haaaaaaaaaaaaaaaa selected")
  //     } else if (action === "deselected" && !this.isEditing) {
  //       // color
  //       annotations.forEach((annot) => this.uncolorSelectedAnnotation(annot));
  //       // state
  //       const ids = annotations.map((a) => a.Id);
  //       console.log("unselectAnnotations", ids);
  //       this.caplaEditor?.dispatch(updateSelectedMeasurementIds(ids));
  //       this.caplaEditor?.dispatch(setSelectedMeasurementId(null));
  //       console.timeEnd("haaaaaaaaaaaaa handleAnnotationsSelected")
  //       console.log("haaaaaaaaaaaaaaaa deselected not editing")
  //     } else if (action === "deselected" && this.isEditing) {
  //       annotationManager.selectAnnotations(annotations);
  //       console.timeEnd("haaaaaaaaaaaaa handleAnnotationsSelected")
  //       console.log("haaaaaaaaaaaaaaaa deselected editing")
  //     }
  //   }

  //   // remove silent selection
  // };

  /*
   * triggers
   */

  setSnapping(bool) {
    const {documentViewer} = this.webViewer.Core;
    const toolPolyline = documentViewer.getTool(
      this.webViewer.Core.Tools.ToolNames.POLYLINE
    );
    const toolPolygon = documentViewer.getTool(
      this.webViewer.Core.Tools.ToolNames.POLYGON
    );
    const toolRectangle = documentViewer.getTool(
      this.webViewer.Core.Tools.ToolNames.RECTANGLE
    );

    const tools = [toolPolygon, toolPolyline, toolRectangle];

    tools.forEach((tool) => {
      console.log("tool", tool);
      if (bool) {
        tool.setSnapMode(this.webViewer.Core.Tools.SnapModes.DEFAULT);
      } else {
        tool.setSnapMode(null);
      }
    });
  }

  setMeasurementToolsTempStyle() {
    const {documentViewer} = this.webViewer.Core;
    const tool1 = documentViewer.getTool(
      this.webViewer.Core.Tools.ToolNames.POLYLINE
    );
    tool1.setStyles({
      StrokeThickness: 0.05 / this.mpd,
      StrokeColor: this.annotationColor,
      Style: "dash",
      Dashes: "5,5",
      Opacity: 0.8,
    });
  }

  updateMeasurementTools() {
    console.log("update measurement tools");
    this.updateDrawPolygonTool();
    this.updateDrawPolylineTool();
    this.updateDrawRectangleTool();
  }

  updateDrawPolylineTool() {
    const {documentViewer} = this.webViewer.Core;
    const tool = documentViewer.getTool(
      this.webViewer.Core.Tools.ToolNames.POLYLINE
    );
    //tool.setSnapMode(this.webViewer.Core.Tools.SnapModes.DEFAULT);
    tool.enableCreationOverAnnotation();
    tool.setStyles({
      StrokeThickness: this.dim1 / this.mpd,
      StrokeColor: this.annotationColor,
      Opacity: 0.8,
    });
    tool.mouseRightDown = tool.mouseDoubleClick;
  }

  updateDrawRectangleTool() {
    const {documentViewer, Annotations} = this.webViewer.Core;
    const tool = documentViewer.getTool(
      this.webViewer.Core.Tools.ToolNames.RECTANGLE
    );
    if (this.tempMeasurement?.drawingShape === "POLYLINE") {
      tool.setStyles({
        StrokeThickness: this.dim1 / this.mpd,
        StrokeColor: this.annotationColor,
        Opacity: 0.8,
      });
    } else {
      tool.setStyles({
        StrokeThickness: 0.5,
        FillColor: this.annotationColor,
        StrokeColor: new Annotations.Color(0, 0, 0),
        Opacity: 0.8,
      });
    }
  }

  updateDrawPolygonTool() {
    const {documentViewer, Annotations} = this.webViewer.Core;
    const tool = documentViewer.getTool(
      this.webViewer.Core.Tools.ToolNames.POLYGON
    );

    //tool.setSnapMode(this.webViewer.Core.Tools.SnapModes.DEFAULT);
    if (this.tempMeasurement?.drawingShape === "POLYLINE") {
      tool.setStyles({
        StrokeThickness: this.dim1 / this.mpd,
        StrokeColor: this.annotationColor,
        Opacity: 0.8,
      });
    } else {
      tool.setStyles({
        StrokeThickness: 0.5,
        FillColor: this.annotationColor,
        StrokeColor: new Annotations.Color(0, 0, 0),
        Opacity: 0.8,
      });
    }
    tool.enableCreationOverAnnotation();
  }

  triggerDrawPolyline() {
    this.webViewer.UI.setToolMode(["AnnotationCreatePolyline"]);
    this.caplaEditor?.editorPdf.disablePopup();
    this.setIsDrawing(true);
    this.setIsDrawingAuto(false);
    this.updateMeasurementTools(); // update style
    console.log("triggerDrawPolyline");
  }

  triggerDrawPolylineInterior() {
    this.webViewer.UI.setToolMode(["AnnotationCreatePolyline"]);
    this.setIsDrawing(true);
    this.setIsDrawingAuto(false);
    this.setMeasurementToolsTempStyle(); // update style
    console.log("triggerDrawPolylineInterior");
  }

  triggerDrawPolylineExterior() {
    this.webViewer.UI.setToolMode(["AnnotationCreatePolyline"]);
    this.setIsDrawing(true);
    this.setIsDrawingAuto(false);
    this.setMeasurementToolsTempStyle(); // update style
    console.log("triggerDrawPolylineExterior");
  }

  triggerDrawPolygon() {
    this.webViewer.UI.setToolMode(["AnnotationCreatePolygon"]);
    this.setIsDrawing(true);
    this.setIsDrawingAuto(false);
    this.updateMeasurementTools(); // update style
  }

  triggerDrawSegment() {
    console.log("draw segment");
    this.setIsDrawing(true);
    this.setIsDrawingAuto(false);
    this.webViewer.UI.setToolMode(["AnnotationCreateMeasurement"]);
    this.annotationsManager.caplaEditor?.editorPdf.sceneEditor.startDrawSegment();
    this.setIsFirstCreation(true); // to trigger annotation metamorphose if necessary.
  }

  triggerDrawSegmentFromZones() {
    // cf trigger measurement in PdfEditor
    console.log("draw segment");
    this.setIsDrawing(true);
    this.setIsDrawingAuto(false);
    this.webViewer.UI.setToolMode(["AnnotationCreateMeasurement"]);
    this.annotationsManager.caplaEditor?.editorPdf.setSnapToZones(true);
    this.annotationsManager.caplaEditor?.editorPdf.sceneEditor.getZoneCorners();
    this.annotationsManager.caplaEditor?.editorPdf.sceneEditor.startProcessMeasure();
  }

  triggerDrawRectangle() {
    this.setIsDrawing(true);
    this.setIsDrawingAuto(false);
    this.webViewer.UI.setToolMode([
      this.webViewer.Core.Tools.ToolNames.RECTANGLE,
    ]);
    this.setIsFirstCreation(true); // to trigger annotation metamorphose if necessary.
  }

  triggerAddPoint() {
    this.setIsDrawing(true);
    this.setIsDrawingAuto(false);
    this.webViewer.UI.setToolMode(["AnnotationCreateMarker"]); // to display a crosshair pointer.
    // RECTANGLE & CIRCLE are created from the pdfEdtior click event listener.
  }

  triggerPanTool() {
    this.webViewer.UI.setToolMode(["Pan"]);
    this.setIsDrawing(false);
    this.setIsDrawingAuto(false);
  }

  /*
   * Create measurement
   * from polylines drawn in image
   */

  async createMeasurementsFromImagePolylines(polylines) {
    polylines.forEach((polyline) =>
      this.createMeasurementFromImagePolyline(polyline)
    );
  }
  async createMeasurementFromImagePolyline(polyline) {
    const _measurement = {...this.tempMeasurement};

    _measurement.id = nanoid();
    _measurement.zoneId = polyline.zoneId;
    _measurement.drawingProps = polyline.drawingProps;

    const annot = this.createAnnotationFromMeasurement(_measurement);
    const measurement = await this.annotationToMeasurementFromPdf(annot, {
      mode: "CREATE_FROM_IMAGE",
      tempMeasurement: _measurement,
    });
    this.caplaEditor?.measDataManager.addMeasurements([measurement]);
    this.caplaEditor?.editor3d.sceneEditor.addMeasurement3D(measurement);
  }

  /*
   * Loader
   * Load annotations from measurementsModel
   */

  distanceA(a, b) {
    if (Array.isArray(a) && Array.isArray(b)) {
      const [x1, y1] = a;
      const [x2, y2] = b;
      return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);
    } else {
      console.log("ERROR341");
    }
  }

  createAnnotationFromMeasurement(m, options) {
    //
    const defaultMode = options?.defaultMode;
    const fromZone = options?.fromZone;
    const toZone = options?.toZone;
    //
    try {
      console.log(
        "createAnnotationMeasurement41",
        m,
        this.measurementAnnotationIds
      ); // , m);

      if (this.annotationsIdsMap[m.id]) return; // avoid duplicates. This case is also managed by the createAnnotationsMeasurements method.

      const {Annotations, annotationManager} = this.webViewer.Core;
      let {path, pageNumber, rect} = m.drawingProps;
      if (!path) path = [];
      const annotColor = this.colorToAnnotationColor(m.color);

      // const zoneScale = store.getState().viewer3D.models.find(
      //   (a) => a.id === m.pdfModelId)?.zones.find(
      //     (z) => z.id === m.zoneId
      //   )?.scale;
      const mpd = toZone ? toZone.scale : this.mpd;

      let strokeThickness = Math.trunc((m.dim1 / mpd) * 10) / 10;

      let annot;

      switch (m.drawingShape) {
        case "SEGMENT": {
          if (path[0]?.length > 0) {
            annot = new Annotations.LineAnnotation();
            annot.Id = m.id;
            annot.PageNumber = pageNumber;
            annot.setStartPoint(path[0][0], path[0][1]);
            annot.setEndPoint(path[1][0], path[1][1]);
            annot.StrokeColor = annotColor;
            annot.StrokeThickness = strokeThickness;
            break;
          } else {
            console.log("ERR error adding segment", m);
          }
        }
        case "BEAM": {
          if (path[0]?.length > 0) {
            annot = new Annotations.LineAnnotation();
            annot.Id = m.id;
            annot.PageNumber = pageNumber;
            annot.setStartPoint(path[0][0], path[0][1]);
            annot.setEndPoint(path[1][0], path[1][1]);
            annot.StrokeColor = annotColor;
            annot.StrokeThickness = strokeThickness;
            break;
          } else {
            console.log("ERR error adding segment", m);
          }
        }
        case "POLYLINE": {
          annot = new Annotations.PolylineAnnotation();
          annot.Id = m.id;
          annot.PageNumber = pageNumber;
          path.forEach((point, index) => {
            if (Array.isArray(point)) {
              annot.setPathPoint(index, point[0], point[1]);
            }
          });
          annot.StrokeColor = annotColor;
          annot.StrokeThickness = strokeThickness;
          break;
        }
        case "GABLE": {
          annot = new Annotations.PolylineAnnotation();
          annot.Id = m.id;
          annot.PageNumber = pageNumber;
          path.forEach((point, index) => {
            if (Array.isArray(point)) {
              annot.setPathPoint(index, point[0], point[1]);
            }
          });
          annot.StrokeColor = annotColor;
          annot.StrokeThickness = strokeThickness;
          break;
        }
        case "BRIDGE": {
          annot = new Annotations.PolylineAnnotation();
          annot.Id = m.id;
          annot.PageNumber = pageNumber;
          path.forEach((point, index) => {
            if (Array.isArray(point)) {
              annot.setPathPoint(index, point[0], point[1]);
            }
          });
          annot.StrokeColor = annotColor;
          annot.StrokeThickness = strokeThickness;
          break;
        }
        case "SLOPE": {
          annot = new Annotations.PolylineAnnotation();
          annot.Id = m.id;
          annot.PageNumber = pageNumber;
          path.forEach((point, index) =>
            annot.setPathPoint(index, point[0], point[1])
          );
          annot.StrokeColor = annotColor;
          annot.StrokeThickness = strokeThickness;
          break;
        }
        case "STAIRS": {
          annot = new Annotations.PolylineAnnotation();
          annot.Id = m.id;
          annot.PageNumber = pageNumber;
          path.forEach((point, index) => {
            annot.setPathPoint(index, point[0], point[1]);
          });
          annot.StrokeColor = annotColor;
          annot.StrokeThickness = strokeThickness;
          break;
        }
        case "POLYGON": {
          annot = new Annotations.PolygonAnnotation();
          annot.Id = m.id;
          annot.PageNumber = pageNumber;
          path.forEach((point, index) =>
            annot.setPathPoint(index, point[0], point[1])
          );
          annot.StrokeColor = annotColor;
          annot.StrokeThickness = 0.5;
          annot.FillColor = annotColor;
          break;
        }
        case "BOWL": {
          annot = new Annotations.PolygonAnnotation();
          annot.Id = m.id;
          annot.PageNumber = pageNumber;
          path.forEach((point, index) =>
            annot.setPathPoint(index, point[0], point[1])
          );
          annot.StrokeColor = annotColor;
          annot.StrokeThickness = 0.5;
          annot.FillColor = annotColor;
          break;
        }
        case "SLOPING_POLYGON": {
          annot = new Annotations.PolygonAnnotation();
          annot.Id = m.id;
          annot.PageNumber = pageNumber;
          path.forEach((point, index) =>
            annot.setPathPoint(index, point[0], point[1])
          );
          annot.StrokeColor = annotColor;
          annot.StrokeThickness = 0.5;
          annot.FillColor = annotColor;
          break;
        }
        case "BANK": {
          annot = new Annotations.PolygonAnnotation();
          annot.Id = m.id;
          annot.PageNumber = pageNumber;
          path.forEach((point, index) =>
            annot.setPathPoint(index, point[0], point[1])
          );
          annot.StrokeColor = annotColor;
          annot.StrokeThickness = 0.5;
          annot.FillColor = annotColor;
          break;
        }
        case "RECTANGLE": {
          annot = new Annotations.LineAnnotation();
          annot.Id = m.id;
          annot.PageNumber = pageNumber;
          annot.StrokeColor = annotColor;

          // v Path of the rectangle drawing shape is 2 points... v => below is commented because we can't use a 4 points path.

          // const width = this.distanceA(path[0], path[1]);
          // const height = this.distanceA(path[1], path[2]);
          // const side = width > height ? "WIDTH" : "HEIGHT";
          // let start, end, thickness;

          // if (side === "WIDTH") {
          //   start = {
          //     x: (path[0][0] + path[3][0]) / 2,
          //     y: (path[0][1] + path[3][1]) / 2,
          //   };
          //   end = {
          //     x: (path[1][0] + path[2][0]) / 2,
          //     y: (path[1][1] + path[2][1]) / 2,
          //   };
          //   thickness = this.distanceA(path[0], path[3]);
          // } else {
          //   start = {
          //     x: (path[0][0] + path[1][0]) / 2,
          //     y: (path[0][1] + path[1][1]) / 2,
          //   };
          //   end = {
          //     x: (path[2][0] + path[3][0]) / 2,
          //     y: (path[2][1] + path[3][1]) / 2,
          //   };
          //   thickness = this.distanceA(path[0], path[1]);
          // }

          // annot.setStartPoint(start.x, start.y);
          // annot.setEndPoint(end.x, end.y);
          // annot.StrokeThickness = Math.trunc(thickness * 10) / 10;

          annot.setStartPoint(path[0][0], path[0][1]);
          annot.setEndPoint(path[1][0], path[1][1]);
          annot.StrokeThickness = strokeThickness;

          // lock resize
          if (m.dim1 && m.dim2) annot.NoResize = true;
          break;
        }
        case "RECTANGLE2": {
          annot = new Annotations.RectangleAnnotation();
          annot.Id = m.id;
          annot.PageNumber = pageNumber;
          annot.X = rect.x;
          annot.Y = rect.y;
          annot.Width = rect.width;
          annot.Height = rect.height;
          annot.Rotation = rect.rotation;
          annot.StrokeColor = annotColor;
          annot.StrokeThickness = 0.5;
          annot.FillColor = annotColor;

          // lock resize
          if (m.dim1 && m.dim2) annot.NoResize = true;
          break;
        }
        case "CIRCLE": {
          if (!rect) return;
          // annot = new Annotations.EllipseAnnotation();
          // annot.Id = m.id;
          // annot.PageNumber = pageNumber;
          // annot.X = rect.x;
          // annot.Y = rect.y;
          // annot.Width = rect.width;
          // annot.Height = rect.height;
          // annot.Rotation = rect.rotation;
          // annot.StrokeColor = annotColor;
          // annot.StrokeThickness = 0.5;
          // annot.FillColor = annotColor;

          // we draw a segment instead...
          const cx = rect.x + rect.width / 2;
          const cy = rect.y + rect.height / 2;
          const side = rect.width > rect.height ? "WIDTH" : "HEIGHT";

          let diam, start, end, thickness;

          if (side === "WIDTH") {
            diam = rect.width;
            thickness = rect.height;
            start = {x: cx - diam / 2, y: cy};
            end = {x: cx + diam / 2, y: cy};
          } else {
            diam = rect.height;
            thickness = rect.width;
            start = {x: cx, y: cy - diam / 2};
            end = {x: cx, y: cy + diam / 2};
          }

          annot = new Annotations.LineAnnotation();
          annot.Id = m.id;
          annot.PageNumber = pageNumber;
          annot.setStartPoint(start.x, start.y);
          annot.setEndPoint(end.x, end.y);
          annot.StrokeColor = annotColor;
          annot.StrokeThickness = Math.trunc(thickness * 10) / 10;

          // lock resize
          if (m.dim1) annot.NoResize = true;
          break;
        }
      }

      if (annot) {
        annot.Opacity = 0.8;
        annot.adjustRect();

        annot.setCustomData("type", "MEASUREMENT");
        annot.setCustomData("elementTypeId", m.elementTypeId);
        annot.setCustomData("builtAt", m.builtAt ? m.builtAt : "");
        annot.setCustomData("measurementsModelId", m.measurementsModelId);
        annot.setCustomData("zoneId", m.zoneId);
        annot.setCustomData("phaseId", m.phaseId ? m.phaseId : "");
        annot.setCustomData("geoCat", m.geoCat);
        //annot.setCustomData("id", annot.Id); // this props is set once the listener "onChange" triggered.

        if (defaultMode) {
          annot.setCustomData("tempMeasurement", JSON.stringify(m));
        }
        if (fromZone) {
          annot.setCustomData("fromZone", JSON.stringify(fromZone));
        }
        if (toZone) {
          annot.setCustomData("toZone", JSON.stringify(toZone));
        }

        this.lockAnnot({annotation: annot});

        annot.Listable = true;

        annotationManager.addAnnotation(annot);
        //annotationManager.redrawAnnotation(annot);
      }

      // isVoid

      // dash

      if (m.dotted) {
        annot.Style = "dash";
        annot.Dashes = "5,5";
      } else {
        annot.Style = "solid";
      }

      // 3D

      // if (update3D) this.updateMeasurement3D(m); // it creates a pb : the dataManager is updated from the 3D update.

      return annot;
    } catch (e) {
      console.log("error411", e);
    }
  }

  // KEY METHOD
  // called by PdfViewer component to create pdfTron annotations

  createAnnotationsFromMeasurements(measurements, options) {
    // options
    const zonesById = options?.zonesById ?? {};
    //
    if (this.pdfEditorIsLoadingDoc) return;
    const sortedMeasurements = measurements
      .map((m) => ({
        ...m,
        offset2d: m?.offset2d === 0 || m.offset2d ? m.offset2d : 100,
      }))
      .sort((a, b) => a.offset2d - b.offset2d);
    //
    // do not re-create existing measurement Annotations.
    const ms = sortedMeasurements.filter(
      (m) => !this.measurementAnnotationIds.includes(m.id)
    );

    // create annotations
    ms.forEach((m) =>
      this.createAnnotationFromMeasurement(m, {toZone: zonesById[m.zoneId]})
    );
  }

  /*
   * Annotation Color
   */

  getAnnotationColorHex(annotation) {
    const elementName = annotation.elementName;
    let color;
    if (elementName === "polygon" || elementName === "square")
      color = annotation.FillColor;
    if (elementName === "polyline" || elementName === "line")
      color = annotation.StrokeColor;
    const {R, G, B} = color ? color : {};
    const colorObject = Color.rgb(R, G, B);
    return colorObject.hex();
  }

  /*
   * Annotation drawingProps
   */

  rotateDeg(point, angle) {
    const x = point[0];
    const y = point[1];
    const i = (angle * Math.PI) / 180;
    const sin = Math.sin(i);
    const cos = Math.cos(i);
    return [cos * x - sin * y, sin * x + cos * y];
  }
  subAtoB(A, B) {
    return [B[0] - A[0], B[1] - A[1]];
  }
  addAtoB(A, B) {
    return [B[0] + A[0], B[1] + A[1]];
  }
  centerRect(x, y, width, height) {
    return [x + width / 2, y + height / 2];
  }
  rectangleToPath(x, y, width, height, rotation) {
    const sin = Math.sin((-rotation * Math.PI) / 180);
    const cos = Math.cos((-rotation * Math.PI) / 180);

    const X = width;
    const Y = height;

    let a = (X * sin ** 2 - Y * sin * cos) / (sin ** 2 - cos ** 2);
    let c = (Y * sin ** 2 - X * sin * cos) / (sin ** 2 - cos ** 2);
    //a = Math.abs(a);
    //c = Math.abs(c);

    const P1 = [x + a, y];
    const P2 = [x + width, y + c];
    const P3 = [x + width - a, y + height];
    const P4 = [x, y + height - c];

    const center = this.centerRect(x, y, width, height);
    const points = [P1, P2, P3, P4];
    // let path = points.map((point) => {
    //   let p = this.subAtoB(center, point);
    //   p = this.rotateDeg(p, rotation);
    //   p = this.addAtoB(p, center);
    //   return p;
    // });

    let path = points.map((p) => [this.round(p[0], 0), this.round(p[1], 0)]);
    const dst = [...path, path[0]];
    return dst;
  }
  circleToPath(x, y, width, height, num) {
    const rot = 360 / num;
    const P = [x + width, y + height / 2];
    const center = this.centerRect(x, y, width, height);
    let path = [P];
    for (let i = 1; i < num; i += 1) {
      let Pi = this.subAtoB(center, P);
      Pi = this.rotateDeg(Pi, rot * i);
      Pi = this.addAtoB(Pi, center);
      path.push(Pi);
    }
    path = path.map((p) => [this.round(p[0], 0), this.round(p[1], 0)]);
    const dst = [...path, path[0]];
    return dst;
  }

  async getAnnotationDrawingPropsV2(annot, drawingShape) {
    const pageNumber = annot.PageNumber;
    let path;
    const props = {pageNumber};

    try {
      switch (annot.elementName) {
        case "line": {
          if (drawingShape === "CIRCLE") {
            const width = annot.StrokeThickness;
            const length = await annot.getLineLength();
            const startP = await annot.getStartPoint();
            //const endP = annot.getEndPoint();

            const X = startP.x;
            const Y = startP.y - width / 2;
            const Width = length;
            const Height = width;

            props.path = this.circleToPath(X, Y, Width, Height, 8);
            props.rect = {
              x: X,
              y: Y,
              width: Width,
              height: Height,
            };
          } else {
            const sp = await annot.getStartPoint();
            const ep = await annot.getEndPoint();
            path = [
              [sp.x, sp.y],
              [ep.x, ep.y],
            ];
            props.path = path;
          }

          break;
        }
        case "square": {
          const rect = {
            x: annot.X,
            y: annot.Y,
            width: annot.Width,
            height: annot.Height,
            rotation: annot.Rotation,
          };
          const path = this.rectangleToPath(
            annot.X,
            annot.Y,
            annot.Width,
            annot.Height,
            annot.Rotation
          );

          if (drawingShape === "_RECTANGLE") {
            // _RECTANGLE : never called. Use to keep historic RECTANGLE management (25/06/2024)
            props.rect = rect;
            props.path = path;
            console.log("RECTANGLE99", rect, path);
          } else if (
            drawingShape === "SEGMENT" ||
            drawingShape === "RECTANGLE"
          ) {
            // path for rectangle is 2 points.
            const side = annot.Width > annot.Height ? "WIDTH" : "HEIGHT";
            let start, end;
            if (side === "WIDTH") {
              start = {
                x: (path[0][0] + path[3][0]) / 2,
                y: (path[0][1] + path[3][1]) / 2,
              };
              end = {
                x: (path[1][0] + path[2][0]) / 2,
                y: (path[1][1] + path[2][1]) / 2,
              };
            } else {
              start = {
                x: (path[0][0] + path[1][0]) / 2,
                y: (path[0][1] + path[1][1]) / 2,
              };
              end = {
                x: (path[2][0] + path[3][0]) / 2,
                y: (path[2][1] + path[3][1]) / 2,
              };
            }
            props.path = [
              [start.x, start.y],
              [end.x, end.y],
            ];
            props.rect = rect;
          }

          break;
        }
        case "polyline": {
          path = annot.getPath().map((p) => [p.x, p.y]);
          props.path = path;
          break;
        }
        case "polygon": {
          path = annot.getPath().map((p) => [p.x, p.y]);
          props.path = path;
          break;
        }
        case "circle": {
          if (drawingShape === "SEGMENT") {
            const path = this.rectangleToPath(
              annot.X,
              annot.Y,
              annot.Width,
              annot.Height,
              annot.Rotation
            );
            const width = this.distanceA(path[0], path[1]);
            const height = this.distanceA(path[1], path[2]);
            const side = width > height ? "WIDTH" : "HEIGHT";
            let start, end;

            if (side === "WIDTH") {
              start = {
                x: (path[0][0] + path[3][0]) / 2,
                y: (path[0][1] + path[3][1]) / 2,
              };
              end = {
                x: (path[1][0] + path[2][0]) / 2,
                y: (path[1][1] + path[2][1]) / 2,
              };
            } else {
              start = {
                x: (path[0][0] + path[1][0]) / 2,
                y: (path[0][1] + path[1][1]) / 2,
              };
              end = {
                x: (path[2][0] + path[3][0]) / 2,
                y: (path[2][1] + path[3][1]) / 2,
              };
            }
            props.path = [
              [start.x, start.y],
              [end.x, end.y],
            ];
          } else {
            props.path = this.circleToPath(
              annot.X,
              annot.Y,
              annot.Width,
              annot.Height,
              8
            );
            props.rect = {
              x: annot.X,
              y: annot.Y,
              width: annot.Width,
              height: annot.Height,
              rotation: annot.Rotation,
            };
          }
        }
      }
    } catch (e) {
      console.log("error", e);
    }

    return props;
  }
  /*
   * Annotation Quantities
   */

  round(value, num) {
    if (value || value === 0) {
      return parseFloat(value.toFixed(num));
    }
  }

  getBankMeasurementQuantities({path3D, path3d2, height}) {
    if (path3d2?.length > 1) {
      return getBankMeasurementQuantities({
        pathInf: path3d2,
        pathSup: path3D,
        height,
      });
    } else
      return {
        count: 1,
        area: 0,
        volume: 0,
      };
  }

  getSlopingPolygonMeasurementQuantities({path3D, slopingA, height}) {
    if (path3D?.length > 1) {
      return getSlopingPolygonMeasurementQuantities({
        pathInf: path3D,
        slopingA,
        height,
      });
    } else
      return {
        count: 1,
        area: 0,
        volume: 0,
        length: 0,
      };
  }

  getGableMeasurementQuantities({path3D, heights, dim1}) {
    if (path3D?.length > 1) {
      return getGableMeasurementQuantities({
        path3D,
        heights,
        dim1,
      });
    } else
      return {
        count: 1,
        area: 0,
        volume: 0,
        length: 0,
      };
  }

  getBridgeMeasurementQuantities({path3D, height, dim1}) {
    if (path3D?.length > 1) {
      return getBridgeMeasurementQuantities({
        path3D,
        height,
        dim1,
      });
    } else
      return {
        count: 1,
        area: 0,
        volume: 0,
        length: 0,
      };
  }

  getAnnotationQuantities(annot, mode, elementType, zone) {
    try {
      let length, area, volume, lengthP, areaP;
      let dim1,
        dim2,
        dim3,
        height,
        heightE,
        heightN,
        slopeH,
        side,
        faces,
        slopingA;
      let drawingShape;

      const mpd = zone ? zone.scale : this.mpd;

      if (mode === "CREATE" && !elementType) {
        drawingShape = this.tempMeasurement?.drawingShape;
        dim1 = this.tempMeasurement?.dim1;
        dim2 = this.tempMeasurement?.dim2;
        dim3 = this.tempMeasurement?.dim3;
        height = this.tempMeasurement.height;
        heightE = this.tempMeasurement.heightE;
        heightN = this.tempMeasurement.heightN;
        side = this.tempMeasurement.side;
        faces = this.tempMeasurement.faces;
        slopeH = this.tempMeasurement.slopeH;
        slopingA = this.tempMeasurement.slopingA;
      } else if (mode === "EDIT" && !elementType) {
        drawingShape = this.selectedMeasurement?.drawingShape;
        dim1 = this.selectedMeasurement?.dim1;
        dim2 = this.selectedMeasurement?.dim2;
        dim3 = this.selectedMeasurement?.dim3;
        height = this.selectedMeasurement?.height;
        heightE = this.selectedMeasurement?.heightE;
        heightN = this.selectedMeasurement?.heightN;
        side = this.selectedMeasurement?.side;
        faces = this.selectedMeasurement?.faces;
        slopeH = this.selectedMeasurement?.slopeH;
        slopingA = this.selectedMeasurement?.slopingA;
      } else if (mode === "EDITING" && !elementType) {
        drawingShape = this.editedMeasurement?.drawingShape;
        dim1 = this.editedMeasurement?.dim1;
        dim2 = this.editedMeasurement?.dim2;
        dim3 = this.editedMeasurement?.dim3;
        height = this.editedMeasurement?.height;
        heightE = this.editedMeasurement?.heightE;
        heightN = this.editedMeasurement?.heightN;
        slopeH = this.editedMeasurement?.slopeH;
        slopingA = this.editedMeasurement?.slopingA;
        side = this.editedMeasurement?.side;
        faces = this.editedMeasurement?.faces;
      } else if (mode === "FROM_ELEMENT_TYPE" && !elementType) {
        drawingShape = elementType.drawingShape;
        dim1 = elementType.dim1;
        dim2 = elementType.dim2;
        dim3 = elementType.dim3;
        height = elementType.height;
        heightE = elementType.heightE;
        heightN = elementType.heightN;
        side = elementType.side;
        faces = elementType.faces;
        slopeH = elementType.slopeH;
        slopingA = elementType.slopingA;
      } else if (elementType) {
        drawingShape = elementType.drawingShape;
        dim1 = elementType.dim1;
        dim2 = elementType.dim2;
        dim3 = elementType.dim3;
        height = elementType.height;
        slopeH = elementType.slopeH;
        slopingA = elementType.slopingA;
        heightE = elementType.heightE;
        heightN = elementType.heightN;
        side = elementType.side;
        faces = elementType.faces;
      }

      const annotDim1 = annot?.StrokeThickness * mpd;
      if (!dim1 && annotDim1) dim1 = annotDim1;

      switch (drawingShape) {
        case "SEGMENT": {
          if (dim2) {
            length = dim2;
          } else {
            if (annot?.getLineLength) length = annot.getLineLength() * mpd;
          }
          area = length * height;
          volume = dim1 * area;
          break;
        }
        case "BEAM": {
          if (dim2) {
            length = dim2;
          } else {
            length = annot.getLineLength() * mpd;
          }
          if (!heightE && !heightN) heightE = height;
          else if (!heightE && heightN) heightE = height - heightN;
          area = length * (2 * heightE + dim1);
          volume = heightE * dim1 * length;
          break;
        }
        case "POLYLINE": {
          length = this.getPathPerimeter(annot.getPath(), mpd);
          area = length * height;
          volume = dim1 * area;
          break;
        }
        case "GABLE": {
          length = this.getPathPerimeter(annot.getPath(), mpd);
          area = length * height;
          volume = dim1 * area;
          break;
        }
        case "BRIDGE": {
          length = this.getPathPerimeter(annot.getPath(), mpd);
          area = length * height;
          volume = dim1 * area;
          break;
        }
        case "SLOPE": {
          lengthP = this.getPathPerimeter(annot.getPath(), mpd);
          length = Math.sqrt(lengthP ** 2 + slopeH ** 2);
          area = length * dim1;
          volume = area * height;
          break;
        }
        case "STAIRS": {
          lengthP = this.getPathPerimeter(annot.getPath(), mpd);
          length = Math.sqrt(lengthP ** 2 + height ** 2);
          area = length * dim1;
          volume = area * 0.1;
          break;
        }
        case "RECTANGLE": {
          if (dim2) {
            length = 2 * (dim2 + dim1);
            area = dim1 * dim2;
          } else {
            length = (annot.getLineLength() * mpd + dim1) * 2;
            area = annot.getLineLength() * mpd * dim1;
          }
          volume = area * height;
          break;
        }
        case "RECTANGLE2": {
          if (dim1 && dim2) {
            length = 2 * dim1 + 2 * dim2;
            area = dim1 * dim2;
          } else {
            const rot = (annot.Rotation * Math.PI) / 180;
            const _perimeter = mpd * 2 * (annot.Width + annot.Height);
            // correction : the width is relative to the bounding box. Need to take into account the rotation.
            const sin = Math.abs(Math.sin(rot));
            const cos = Math.abs(Math.cos(rot));
            length = _perimeter / (sin + cos);

            const _area = mpd * mpd * annot.Width * annot.Height;
            area =
              (1 / (1 - 2 * sin * cos)) *
              (_area - (length ** 2 / 4) * sin * cos);
          }
          // volume
          volume = area * height;
          break;
        }
        case "POLYGON": {
          length = this.getPathPerimeter(annot.getPath(), mpd);
          area = this.getPathArea(annot.getPath(), mpd);
          volume = area * height;
          break;
        }
        case "BOWL": {
          length = this.getPathPerimeter(annot.getPath(), mpd);
          area = this.getPathArea(annot.getPath(), mpd);
          volume = area * height;
          break;
        }
        case "SLOPING_POLYGON": {
          length = this.getPathPerimeter(annot.getPath(), mpd);
          area = this.getPathArea(annot.getPath(), mpd);
          volume = area * height;
          break;
        }
        case "CIRCLE": {
          if (dim1) {
            area = Math.PI * (dim1 / 2) ** 2;
            volume = area * height;
          }
        }
      }
      const toReturn = {
        count: 1,
        length: this.round(length, 5),
        area: this.round(area, 5),
        volume: this.round(volume, 5),
      };
      if (lengthP) toReturn["lengthP"] = this.round(lengthP, 5);
      if (areaP) toReturn["areaP"] = this.round(areaP, 5);
      return toReturn;
    } catch (e) {
      console.log("error", e);
    }
  }

  getABLength(A, B) {
    return Math.sqrt((B.x - A.x) ** 2 + (B.y - A.y) ** 2);
  }
  getPathPerimeter(path, mpd) {
    const perimeter = path.reduce((ac, cur, index) => {
      if (index === 0) return ac;
      const dist = this.getABLength(path[index], path[index - 1]);
      return ac + dist;
    }, 0);
    return perimeter * mpd;
  }
  getPathArea(path, mpd) {
    const points = [];
    path.forEach((point) => {
      points.push(new Vector2(point.x, point.y));
    });
    const area = Math.abs(ShapeUtils.area(points));
    return area * mpd ** 2;
  }

  getAnnotationPath(annotation) {
    if (!annotation.getPath) return;
    return annotation.getPath().map((point) => [point.x, point.y]);
  }

  /*
   * Parser & update
   */

  measurementIsValid(measurement) {
    const isValid =
      typeof measurement.length === "number" &&
      measurement.length > 0 &&
      typeof measurement.area === "number" &&
      measurement.area > 0 &&
      typeof measurement.volume === "number" &&
      measurement.volume > 0 &&
      ["UN", "ML", "M2", "M3"].includes(measurement.unit);

    if (!isValid) {
      const message = "Repérage non valide. Vérifiez la hauteur ou l'unité.";
      const triggeredAt = Date.now();
      this.caplaEditor?.dispatch(
        setSnackbarMessage({message, triggeredAt, isError: true})
      );
    }
    return isValid;
  }

  // KEY METHOD : used to convert one pdfTron annotation into a measurement object
  annotationToMeasurementFromPdf = async (annotation, options) => {
    // options

    const mode = options?.mode;
    const tempMeasurement = options?.tempMeasurement;
    const fromZone = options?.fromZone;
    const toZone = options?.toZone;

    //

    console.log(
      "annotationToMeasurementFromPdf",
      annotation,
      mode,
      tempMeasurement,
      fromZone,
      toZone
    );

    //

    const {annotationManager} = this.webViewer.Core;
    const id = annotation.Id;

    // const pdfModelId = this.modelId;

    let m;
    if (mode === "CREATE") m = this.tempMeasurement;
    if (mode === "CREATE_FROM_IMAGE") m = {...tempMeasurement};
    if (mode === "CREATE_FROM_MEASUREMENT") m = {...tempMeasurement};
    if (mode === "EDIT") m = this.selectedMeasurement;
    if (mode === "EDITING" || mode === "MULTIPLE_UPDATES")
      m = this.editedMeasurement;
    if (!m) m = {};

    //
    let pdfModelId = m.pdfModelId;
    if (!pdfModelId) pdfModelId = m.measurementsModel?.fromModel?.modelId;
    delete m.measurementsModel;

    let {
      elementTypeId,
      drawingShape,
      dim1,
      dim2,
      dim3,
      height,
      heightE,
      heightN,
      heights,
      slopeH,
      slopingA,
      zSup,
      zInf,
      offset,
      relatedMId,
      sectorId,
      roomId,
      materialId,
      voids,
      zFrom,
    } = m;

    let zoneId = this.zoneId;
    let zone = this.zone;

    if (toZone?.id) {
      zone = toZone;
      zoneId = toZone.id;
    }

    let offsetXinPdf = 0;
    let offsetYinPdf = 0; // used to offset the annotation if necessary.
    let x2, y2, scaleFactor;

    if (mode !== "CREATE" && mode !== "CREATE_FROM_MEASUREMENT") {
      if (!zone) {
        zoneId = m.zoneId;
        zone = this.annotationsManager.getZoneById(zoneId);
      }
    } else if (mode === "CREATE_FROM_MEASUREMENT") {
      // mode used in copy/paste. Need to recompute the zone.
      const point = {
        x: annotation.X,
        y: annotation.Y,
        pageNumber: annotation.PageNumber,
      };
      if (toZone?.id) {
        zone = toZone;
      } else {
        zone = this.annotationsManager.getPointBoundingZone(point);
      }
      zoneId = zone?.id;
      //
      // compute translation & scaleFactor
      if (fromZone?.position && zone?.position) {
        [x2, y2] = getCoordinatesFromZone1InZone2(
          point.x,
          point.y,
          fromZone,
          zone,
          this.caplaEditor
        );
        scaleFactor = fromZone.scale / zone.scale;
      }
      if (zone?.id && zone?.id !== fromZone?.id) {
        annotation.X = x2;
        annotation.Y = y2;
        annotation.Width *= scaleFactor;
        annotation.Height *= scaleFactor;
        console.log("debugAFA310 paste annotation", x2, y2, scaleFactor);
      }
    } else if (!zone) {
      const point = {
        x: annotation.X,
        y: annotation.Y,
        pageNumber: annotation.PageNumber,
      };
      zone = this.annotationsManager.getPointBoundingZone(point);

      // /!\ the measuremnent needs to be created in one zone
      zoneId = zone?.id;
    }

    const mpd = zone ? zone.scale : this.mpd;

    // isNotHoriz

    const isNotHoriz = zone?.rotation.x === 0;

    // sectorId

    if (!sectorId) sectorId = zone?.sectorId;

    // height
    if (heights) {
      const floatHeights = heights.split(";").map((f) => parseFloat(f));
      height = Math.max(...floatHeights);
    }

    if (!height && height !== 0) {
      const zoneH = zone?.elementsH;
      if (zoneH || zoneH === 0) {
        height = zoneH;
      } else {
        height = 0;
      }
    }

    // zInf / zSup / offset
    let _offset = offset ? offset : 0; // offset for extrusion
    const zoneZ = zone?.position.y ?? 0;
    if (zFrom === "zInf") {
      zInf = zInf;
      zSup = zInf + height;
    } else if (zFrom === "zSup") {
      zSup = zSup;
      zInf = zSup - height;
    } else if (zone?.zBasis === "ZSUP") {
      zSup = zoneZ + _offset;
      zInf = zSup - height;
    } else {
      zInf = zoneZ + _offset;
      zSup = zInf + height;
    }

    console.log("debug 31-05 D", zoneZ, offset);

    zInf = Math.round((zInf + Number.EPSILON) * 10000) / 10000;
    zSup = Math.round((zSup + Number.EPSILON) * 10000) / 10000;
    offset = Math.round((offset + Number.EPSILON) * 10000) / 10000;

    //_offset = zInf; // ??

    let drawingProps = await this.getAnnotationDrawingPropsV2(
      annotation,
      drawingShape
    );

    // voids - drawingProps

    if (voids?.length > 0 && drawingShape === "POLYGON") {
      const drawingPropsVoids = [];
      for (let voidId of voids) {
        const voidAnnot = annotationManager
          .getAnnotationsList()
          .find((a) => a.Id === voidId);
        const props = await this.getAnnotationDrawingPropsV2(
          voidAnnot,
          "POLYGON"
        );
        drawingPropsVoids.push(props);
      }
      const voidPaths = drawingPropsVoids.map((v) => v.path);
      drawingProps = {...drawingProps, voidPaths};
    }

    const rot = annotation.Rotation;

    const offsetToGetPath3D = zInf - zoneZ; // this offset is used to compute the Path3D from the zone.

    let {path3D, path3d3} = this.getPath3DFromDrawingProps(drawingProps, {
      drawingShape,
      dim1,
      offset: offsetToGetPath3D,
      mpd: zone?.scale,
      zone,
    });

    let path3d2;
    if (relatedMId) {
      const p = this.getPath3DFromPolygonAnnot(relatedMId);
      path3d2 = p.path3D;
    } else if (dim1 && dim2 && height && drawingShape === "BANK") {
      const path = annotation.getPath().map((p) => ({x: p.x, y: p.y}));
      const width = ((dim1 / dim2) * height) / mpd;
      const exteriorPath = Polyline.getExteriorPolyline(path, width, true);
      const _path = this.getPath3DFromAnnotPath2D(
        exteriorPath,
        annotation.PageNumber
      );
      path3d2 = path3D;
      path3D = _path;
    }

    if (drawingShape === "SLOPING_POLYGON")
      path3D = annotation.getPath().map((p) => [p.x * mpd, p.y * mpd]);

    let quantities;
    if (drawingShape === "BANK") {
      quantities = this.getBankMeasurementQuantities({
        path3D,
        path3d2,
        height,
      });
    } else if (drawingShape === "SLOPING_POLYGON") {
      quantities = this.getSlopingPolygonMeasurementQuantities({
        path3D,
        slopingA,
        height,
      });
    } else if (drawingShape === "GABLE") {
      quantities = this.getGableMeasurementQuantities({
        path3D,
        heights,
        dim1,
      });
    } else if (drawingShape === "BRIDGE") {
      quantities = this.getBridgeMeasurementQuantities({
        path3D,
        height,
        dim1,
      });
      quantities.length = this.getPathPerimeter(annotation.getPath(), mpd);
    } else {
      quantities = this.getAnnotationQuantities(
        annotation,
        mode,
        {
          drawingShape,
          height,
          heightE,
          heightN,
          slopeH,
          dim1,
          dim2,
          dim3,
        },
        zone
      );
    }

    if (this.autoGeo3D && mode === "CREATE") {
      const {h, s} = this.getMeasurementAutoGeo3D(drawingProps);
      if (h) height = h;
      if (s) zSup = s;
      console.log("heightZSup", height, zSup);
    }

    const newM = {
      ...m,
      id,
      // pdfModelId,
      elementTypeId,
      zoneId,
      sectorId,
      roomId,
      materialId,
      // color,
      height,
      slopeH,
      slopingA,
      zSup,
      zInf,
      offset,
      drawingProps,
      path3D,
      path3d3,
      rot,
      isNotHoriz,
      ...quantities,
    };

    if (path3d2) newM["path3d2"] = path3d2;
    //if (_offset) newM["offset"] = _offset; // if not commented, prevent to change zInf when offset !==0

    return cleanMeasurementPaths(newM);
  };

  annotationToMeasurementFromElementType = async (annotation, elementType) => {
    const id = nanoid(); // why is it not the annotation id ? (=> because it is used when importing annots from pdfExchange)

    const elementTypeId = elementType.id;

    let {
      drawingShape,
      isVoid,
      color,
      dim1,
      dim2,
      dim3,
      height,
      heightE,
      heightN,
      heights,
      slopeH,
      slopingA,
      unit,
      zInf,
      offset,
    } = elementType;

    const {path3D, path3d2} = annotation;

    // zone
    const point = {
      x: annotation.X,
      y: annotation.Y,
      pageNumber: annotation.PageNumber,
    };
    const zone = this.annotationsManager.getPointBoundingZone(point);

    const mpd = zone ? zone.scale : this.mpd;

    const drawingProps = await this.getAnnotationDrawingPropsV2(
      annotation,
      drawingShape
    );
    // dim1
    const annotDim1 = annotation?.StrokeThickness * mpd;
    if (!dim1 && annotDim1 && ["SEGMENT", "POLYLINE"].includes(drawingShape))
      dim1 = annotDim1;
    if (!dim1 && drawingProps.rect) {
      const _dim1 = Math.min(drawingProps.rect.width, drawingProps.rect.height);
      dim1 = _dim1 * mpd;
    }

    //
    const rot = annotation.Rotation;

    if (drawingShape === "SLOPING_POLYGON")
      path3D = annotation.getPath().map((p) => [p.x * mpd, p.y * mpd]);

    let quantities;
    if (drawingShape === "BANK") {
      quantities = this.getBankMeasurementQuantities({
        path3D,
        path3d2,
        height,
      });
    } else if (drawingShape === "SLOPING_POLYGON") {
      quantities = this.getSlopingPolygonMeasurementQuantities({
        path3D,
        slopingA,
        height,
      });
    } else if (drawingShape === "GABLE") {
      quantities = this.getGableMeasurementQuantities({
        path3D,
        heights,
        dim1,
      });
    } else if (drawingShape === "BRIDGE") {
      quantities = this.getBridgeMeasurementQuantities({
        path3D,
        height,
        dim1,
      });
      const mpd = zone ? zone.scale : this.mpd;
      quantities.length = this.getPathPerimeter(annotation.getPath(), mpd);
    } else {
      quantities = this.getAnnotationQuantities(
        annotation,
        "FROM_ELEMENT_TYPE",
        elementType,
        zone
      );
    }

    const measurement = {
      id,
      elementTypeId,
      drawingShape,
      color,
      isVoid,
      dim1,
      dim2,
      dim3,
      height,
      heightE,
      heightN,
      slopeH,
      slopingA,
      unit,
      drawingProps,
      rot,
      builtAt: null,
      phaseId: null,
      ...quantities,
    };

    if (offset) {
      measurement.zFrom = "offset";
      measurement.offset = offset;
    } else if (zInf) {
      measurement.zFrom = "zInf";
      measurement.zInf = zInf;
    } else {
      measurement.zFrom = "offset";
    }
    return measurement;
  };

  async resetSelectedMeasurement() {
    try {
      const {annotationManager} = this.webViewer.Core;
      const annot = annotationManager.getAnnotationById(
        this.selectedMeasurement.id
      );
      const originalAnnotCopy = this.selectedMeasurementAnnotationCopy;

      originalAnnotCopy.Id = annot.Id;

      // this.unlockAnnot({annotation: annot});

      annot.NoDelete = false;
      annotationManager.deleteAnnotation(annot, {isUndoRedo: true});

      // annotationManager.updateAnnotation(originalAnnotCopy);

      annotationManager.addAnnotation(originalAnnotCopy, {isUndoRedo: true});
      // annotationManager.redrawAnnotation(originalAnnotCopy);

      const m = await this.annotationToMeasurementFromPdf(originalAnnotCopy, {
        mode: "EDIT",
      });
      this.caplaEditor?.editor3d.sceneEditor.updateMeasurement3D(m);
    } catch (e) {
      console.log("error", e);
    }
  }

  // KEY METHOD
  // used to update pdfTron & 3D from measurement props change.
  async updateAnnotation(measurement, options) {
    try {
      //

      console.log("debugafa411 updateAnnotation measMnger ", measurement?.zInf);

      // options

      const multipleUpdates = options?.multipleUpdates;
      const fromPoper = options?.fromPoper;
      const zonesById = options?.zonesById ?? {};

      //

      const zone = zonesById[measurement?.zoneId];
      const zoneScale = zone?.scale;
      const mpd = zoneScale ?? this.mpd;

      if (!measurement || !this.isEditing) {
        console.log("NOT_EDITING!!");
        return;
      } else {
        console.log("IS_EDITING!!");
      }

      const {
        dim1,
        dim2,
        dim3,
        color,
        drawingShape,
        drawingProps,
        isVoid,
        rot,
        builtAt,
        phaseId,
        sectorId,
        roomId,
        materialId,
        geoCat,
      } = measurement;

      this.setEditedMeasurement(measurement);

      const {annotationManager} = this.webViewer.Core;

      const annotation = annotationManager
        .getAnnotationsList()
        .find((a) => a.Id === measurement.id);

      //

      console.log(
        "updateAnnotation412",
        measurement?.zInf,
        measurement,
        zone,
        annotation
      );

      //

      if (!annotation) {
        // we update measurement props
        const measurementWithZs = updateMeasurementZsFromZone(
          measurement,
          zone
        );
        const zoneZ = zone?.position.y ?? 0;
        const zInf = measurementWithZs.zInf;
        const offsetToGetPath3D = zInf - zoneZ;
        let {path3D, path3d3} = this.getPath3DFromDrawingProps(drawingProps, {
          drawingShape,
          dim1,
          offset: offsetToGetPath3D,
          mpd: zone?.scale,
          zone,
        });
        const newM = {...measurementWithZs, path3d3, path3D};
        if (!multipleUpdates) {
          this.caplaEditor?.dispatch(setEditedMeasurement(newM)); // update edited newM before saving
        } else {
          // this.caplaEditor?.measDataManager.updateMeasurements([newM]); // update in state...we don't want to update the state unless we save the modifications...
        }
        this.caplaEditor?.editor3d.sceneEditor.updateMeasurement3D(newM);
        return;
      }

      // color

      const annotationColor = this.colorToAnnotationColor(color);

      if (
        [
          "POLYLINE",
          "SEGMENT",
          "BEAM",
          "SLOPE",
          "STAIRS",
          "GABLE",
          "BRIDGE",
        ].includes(drawingShape)
      ) {
        annotation.StrokeColor = annotationColor;
        annotation.StrokeThickness = dim1 / mpd;
      } else {
        annotation.FillColor = annotationColor;
      }

      const rgb = {
        r: annotationColor.R,
        g: annotationColor.G,
        b: annotationColor.B,
      };
      annotation.setCustomData("rgb", JSON.stringify(rgb)); // to restore color when unselected !

      // rotation

      annotation.Rotation = rot;

      // dash

      if (isVoid) {
        annotation.Style = "dash";
        annotation.Dashes = "5,5";
      } else {
        annotation.Style = "solid";
      }

      // width

      if (dim2 && ["SEGMENT", "BEAM"].includes(drawingShape)) {
        this.resizeSegmentAnnotation(annotation, dim2);
      }

      annotation.setCustomData("builtAt", builtAt);
      annotation.setCustomData("phaseId", phaseId);
      annotation.setCustomData("sectorId", sectorId);
      annotation.setCustomData("roomId", roomId);
      annotation.setCustomData("materialId", materialId);
      annotation.setCustomData("geoCat", geoCat);

      // redraw

      if (!multipleUpdates || fromPoper)
        annotationManager.redrawAnnotation(annotation);

      // 2D => 3D + State

      const newMeasurement = await this.annotationToMeasurementFromPdf(
        // use editedMeasurement as input.
        annotation,
        {
          mode: multipleUpdates ? "MULTIPLE_UPDATES" : "EDITING",
          toZone: zonesById[measurement.zoneId],
        }
      );

      this.caplaEditor?.editor3d.sceneEditor.updateMeasurement3D(
        newMeasurement
      );

      // console.log("haaaaaaaaaaaa we should nullify")

      if (!multipleUpdates) {
        this.caplaEditor?.dispatch(setEditedMeasurement(newMeasurement)); // update edited measurement before saving
        this.setEditedMeasurement(newMeasurement); // update edited measurement...
      } else {
        // this.caplaEditor?.measDataManager.updateMeasurements([newMeasurement]); // we don't want to update the state unless we save the modifications. Otherwise, the cancel button don't work.
      }
    } catch (e) {
      console.log("error updating measurement", e, measurement);
    }
  }

  /*
   * Saver
   */

  async saveCategories(categories) {
    const {annotationManager, Annotations} = this.webViewer.Core;
    const annot = new Annotations.Annotation();
    await annot.setCustomData("type", "MEASUREMENT_CATEGORIES");
    await annot.setCustomData("isFromCapla", "true");
    await annot.setCustomData("categories", JSON.stringify(categories));
    annotationManager.addAnnotation(annot);
  }
  /*
   * Loader - UNUSED
   */

  // async loadAnnotations() {
  //   console.log("loadAnnotations12");
  //   const {annotationManager} = this.webViewer.Core;
  //   // data
  //   const annotations = annotationManager
  //     .getAnnotationsList()
  //     .filter((a) => a.getCustomData("type") === "MEASUREMENT");
  //   const categoriesAnnot = annotationManager
  //     .getAnnotationsList()
  //     .find((a) => a.getCustomData("type") === "MEASUREMENT_CATEGORIES");
  //   const categoriesS = categoriesAnnot.getCustomData("categories");
  //   const categories = categoriesS && JSON.parse(categoriesS);

  //   // measurements
  //   const measurements = [];
  //   for (let annotation of annotations) {
  //     const measurement = await this.annotationToMeasurement(annotation);
  //     measurements.push(measurement);
  //   }
  //   console.log("measurements51", measurements);
  //   this.caplaEditor?.dispatch(addMeasurements(measurements));

  //   //categories
  //   this.caplaEditor?.dispatch(addCategories(categories));
  //   console.log("categories51", measurements);
  // }
  /*
   * Clear
   */

  clearMeasurements() {
    const {annotationManager} = this.webViewer.Core;
    const annots = annotationManager
      .getAnnotationsList()
      .filter((a) => a.getCustomData("type") === "MEASUREMENT");
    console.log("clearMeasurements", annots?.length);
    if (annots.length > 0) {
      annots.forEach((annot) => {
        annot.NoDelete = false;
        this.unlockAnnot({annotation: annot});
      });
      annotationManager.deleteAnnotations(annots, {
        source: "clearMeasurements",
      });
    }
  }

  reset() {
    this.clearMeasurements();
    // this.caplaEditor?.dispatch(resetMeasurements());
  }

  /*
   * AUTO_MEASURE mode
   */

  async triggerAutoMeasurementFullPage() {
    const {url, pageCoords, pageRotation, pageNumber, zoom, zoneId} =
      await this.getAnnotationImageUrl(null, true);
    const size = await getImageSize({fileURL: url});
    this.caplaEditor?.dispatch(
      setAutoMeasureZone({
        url,
        pageRotation,
        pageCoords,
        pageNumber,
        zoom,
        size,
        mpd: this.mpd,
        zoneId,
      })
    );
  }
  setRectangleAnnotStyle() {
    const {documentViewer} = this.webViewer.Core;
    const tool = documentViewer.getTool(
      this.webViewer.Core.Tools.ToolNames.RECTANGLE
    );
    //tool.setSnapMode(this.webViewer.Core.Tools.SnapModes.DEFAULT);

    tool.setStyles({
      StrokeThickness: 1,
      StrokeColor: this.annotationColor,
      Opacity: 0.8,
    });
  }

  setAutoMeasureAnnotStyle() {
    const {documentViewer} = this.webViewer.Core;
    const tool = documentViewer.getTool(
      this.webViewer.Core.Tools.ToolNames.RECTANGLE
    );
    //tool.setSnapMode(this.webViewer.Core.Tools.SnapModes.DEFAULT);

    tool.setStyles({
      StrokeThickness: 3,
      StrokeColor: this.annotationColor,
      Color: null,
      Opacity: 0.8,
    });
  }

  deleteAutoMeasureAnnotation() {
    console.log("delete13");
    const {annotationManager} = this.webViewer.Core;
    const annots = annotationManager
      .getAnnotationsList()
      .filter((a) => a.getCustomData("type") === "AUTO_MEASURE_ZONE");
    annotationManager.deleteAnnotations(annots);
  }

  enableAutoMeasurement() {
    this.setAutoMeasureAnnotStyle();
    this.setIsDrawingAuto(true);
    this.webViewer.UI.setToolMode(["AnnotationCreateRectangle"]);
  }
  disableAutoMeasurement() {
    this.stopDrawing();
    this.setIsDrawingAuto(false);
  }

  getImageGenerationProps(annotation) {
    const {documentViewer: docViewer} = this.webViewer.Core;
    const pageNumber = annotation.PageNumber;
    const zoom = docViewer.getZoomLevel();
    const rotation = docViewer.getCompleteRotation(pageNumber);
    const height = docViewer.getPageHeight(pageNumber);
    const width = docViewer.getPageWidth(pageNumber);
    let x1 = annotation.X;
    let y1 = annotation.Y;
    let x2 = x1 + annotation.Width;
    let y2 = y1 + annotation.Height;

    // viewport coordinates (to zoom if necessary)
    const vpCoords = this.caplaEditor?.editorPdf.getViewportPageCoordinates();
    const zoomScale =
      Math.abs(vpCoords.topLeft.x - vpCoords.bottomRight.x) / annotation.Width;
    const fullZoom = zoom * zoomScale * 1.5;

    let renderRect = {x1, y1, x2, y2};
    if (rotation === 1) {
      renderRect = {
        x1: height - renderRect.y2,
        y1: renderRect.x1,
        x2: height - renderRect.y1,
        y2: renderRect.x2,
      };
    } else if (rotation === 3) {
      renderRect = {
        x2: renderRect.y2,
        y2: width - renderRect.x1,
        x1: renderRect.y1,
        y1: width - renderRect.x2,
      };
    }
    return {
      zoom,
      renderRect,
      pageNumber,
      fullZoom,
      pageCoords: {x1, x2, y1, y2},
      pageRotation: rotation,
    };
  }

  async getAnnotationImageUrl(annotation, fullPage) {
    const {documentViewer: docViewer} = this.webViewer.Core;
    const doc = await docViewer.getDocument();

    let pageCoords, pageRotation, pageNumber, zoom, zoneId, renderRect;

    if (fullPage) {
      const currentPage = docViewer.getCurrentPage();
      const pW = docViewer.getPageWidth(currentPage);
      const pH = docViewer.getPageHeight(currentPage);

      pageCoords = {x1: 0, y1: 0, x2: pW, y2: pH};
      pageRotation = docViewer.getCompleteRotation(currentPage);
      pageNumber = currentPage;
      zoom = docViewer.getPageZoom(currentPage);
    } else {
      const {
        fullZoom: z,
        renderRect: rr,
        pageNumber: page,
        pageCoords: coords,
        pageRotation: rot,
      } = this.getImageGenerationProps(annotation);
      pageCoords = coords;
      pageRotation = rot;
      pageNumber = page;
      zoom = z;
      renderRect = rr;

      // zone
      const point = {
        x: annotation.X,
        y: annotation.Y,
        pageNumber: annotation.PageNumber,
      };
      const zone = this.annotationsManager.getPointBoundingZone(point);
      zoneId = zone?.id;
    }

    return new Promise((resolve) => {
      const loadCanvasOptionsFullPage = {
        pageNumber,
        zoom,
        drawComplete: async (canvas) => {
          const corePageRotation = (doc.getPageRotation(pageNumber) / 90) % 4;
          const annotationManager = docViewer.getAnnotationManager();
          annotationManager.setAnnotationCanvasTransform(
            canvas.getContext("2d"),
            zoom,
            corePageRotation
          );

          await docViewer.getAnnotationManager().drawAnnotations({
            pageNumber,
            overrideCanvas: canvas,
          });
          let url = canvas.toDataURL();

          return resolve({
            url,
            pageCoords,
            pageRotation,
            pageNumber,
            zoom,
          });
        },
      };

      const loadCanvasOptionsRect = {
        pageNumber,
        drawComplete: async (canvas) => {
          await docViewer.getAnnotationManager().drawAnnotations({
            pageNumber,
            overrideCanvas: canvas,
          });
          const url = canvas.toDataURL();
          resolve({
            url,
            pageCoords,
            pageRotation,
            pageNumber,
            zoom,
            zoneId,
          });
        },
        zoom,
        renderRect,
      };

      const options = fullPage
        ? loadCanvasOptionsFullPage
        : loadCanvasOptionsRect;

      docViewer.getDocument().loadCanvas(options);
    });
  }

  /*
   * Segment measurement
   */

  resizeSegmentAnnotation(annotation, width) {
    try {
      const {annotationManager} = this.webViewer.Core;
      console.log("resizeSegment", annotation, width);
      if (annotation?.setCaptionSnapPosition && annotation?.setLineLength) {
        console.log("A");
        //annotation.setCaptionSnapPosition("start");
        console.log("B", width, this.mpd);
        annotation.setLineLength(width / this.mpd);
        annotationManager.redrawAnnotation(annotation);
      }
    } catch (e) {
      console.log("error414", e);
    }
  }

  /*
   * Rectangle annotation
   */
  getDstDims(srcW, srcH, dim1, dim2) {
    const rSrc = srcW / srcH;
    const rDst = dim1 / dim2;
    if ((rSrc <= 1 && rDst <= 1) || (rSrc > 1 && rDst > 1)) {
      return [dim1, dim2];
    } else {
      return [dim2, dim1];
    }
  }

  resizeRectangleAnnotation = (annotation, dim1, dim2) => {
    const {annotationManager} = this.webViewer.Core;
    console.log("resizeRectangleAnnotation", annotation.elementName);
    if (annotation.Width) {
      const srcW = annotation.Width;
      const srcH = annotation.Height;
      const [dstW, dstH] = this.getDstDims(srcW, srcH, dim1, dim2);

      annotation.Width = dstW / this.mpd;
      annotation.Height = dstH / this.mpd;
      annotationManager.redrawAnnotation(annotation);
      annotationManager.updateAnnotation(annotation);
    }
  };

  async rectangleToPolygonAsync(annot) {
    const {Point} = this.webViewer.Core.Math;
    const {Annotations} = this.webViewer.Core;
    const rect = await annot.getRect();
    const {x1, y1, x2, y2} = rect;
    const pA = new Point(x1, y1);
    const pB = new Point(x2, y1);
    const pC = new Point(x2, y2);
    const pD = new Point(x1, y2);
    const points = [pA, pB, pC, pD, pA];
    const poly = new Annotations.PolygonAnnotation();
    //const path = await poly.getPath();
    //points.forEach((point) => path.push(point));
    points.forEach((point, index) =>
      poly.setPathPoint(index, point.x, point.y)
    );
    poly.StrokeColor = annot.StrokeColor;
    poly.StrokeThickness = annot.StrokeThickness;
    poly.FillColor = annot.FillColor;
    poly.PageNumber = annot.PageNumber;
    poly.Opacity = 0.8;

    poly.adjustRect();

    console.log("poly", poly);
    return poly;
  }

  async rectangleToPolylineAsync(annot) {
    const {Point} = this.webViewer.Core.Math;
    const {Annotations} = this.webViewer.Core;
    const rect = await annot.getRect();
    const {x1, y1, x2, y2} = rect;
    const pA = new Point(x1, y1);
    const pB = new Point(x2, y1);
    const pC = new Point(x2, y2);
    const pD = new Point(x1, y2);
    const points = [pA, pB, pC, pD, pA];
    const poly = new Annotations.PolylineAnnotation();
    points.forEach((point, index) =>
      poly.setPathPoint(index, point.x, point.y)
    );
    poly.StrokeColor = annot.StrokeColor;
    poly.StrokeThickness = annot.StrokeThickness;
    poly.PageNumber = annot.PageNumber;
    poly.Opacity = 0.8;
    poly.adjustRect();
    return poly;
  }

  /*
   * Conversions
   */

  polygonToPolyline(annot) {
    try {
      console.log("polygonToPolyline", annot);
      const {Annotations} = this.webViewer.Core;
      const poly = new Annotations.PolylineAnnotation();
      poly.StrokeColor = annot.StrokeColor;
      poly.StrokeThickness = annot.StrokeThickness;
      //poly.FillColor = annot.FillColor;
      poly.PageNumber = annot.PageNumber;
      poly.Opacity = 0.8;
      const path = annot.getPath().map((p) => ({x: p.x, y: p.y}));
      path.forEach((point, index) => {
        poly.setPathPoint(index, point.x, point.y);
      });
      return poly;
    } catch (e) {
      console.log("error512", e);
    }
  }

  segmentToPolyline(annot) {
    try {
      console.log("segmentToPolyline", annot);
      const {Annotations} = this.webViewer.Core;
      const poly = new Annotations.PolylineAnnotation();
      poly.StrokeColor = annot.StrokeColor;
      poly.StrokeThickness = annot.StrokeThickness;
      //poly.FillColor = annot.FillColor;
      poly.PageNumber = annot.PageNumber;
      poly.Opacity = 0.8;
      const path = [annot.getStartPoint(), annot.getEndPoint()];
      path.forEach((point, index) => {
        poly.setPathPoint(index, point.x, point.y);
      });
      return poly;
    } catch (e) {
      console.log("error512", e);
    }
  }

  /*
   * Automation from polygons
   */

  getZoneCorners(pageNumber) {
    const corners = [];
    const {annotationManager} = this.webViewer.Core;
    const polygons = annotationManager
      .getAnnotationsList()
      .filter((a) => a.elementName === "polygon");
    const rects = annotationManager
      .getAnnotationsList()
      .filter((a) => a.elementName === "rectangle");

    polygons.forEach(async (p) => {
      const path = this.getPolygonPath(p);
      corners.push(...path);
    });
    rects.forEach(async (r) => {
      const path = this.getRectanglePath(r);
      corners.push(...path);
    });
    return corners;
  }

  getPolygonPath(annot) {
    const path = annot.getPath().map((p) => ({x: p.x, y: p.y}));
    path.pop();
    return path;
  }

  getRectPath(annot) {
    const rect = annot.getRect();
    const {x1, y1, x2, y2} = rect;
    return [
      {x: x1, y: y1},
      {x: x1, y: y2},
      {x: x2, y: y2},
      {x: x2, y: y1},
    ];
  }

  getNearestPoints(points, point) {
    const dists = points.map(
      (p) => (p.x - point.x) ** 2 + (p.y - point.y) ** 2
    );
    const min1 = Math.min(...dists);
    const min2 = Math.min(...dists.filter((d) => d !== min1));
    const index1 = dists.indexOf(min1);
    const index2 = dists.indexOf(min2);
    return [points[index1], points[index2]];
  }

  // lock & unlock

  unlockMeasurementAnnotations() {
    const {annotationManager} = this.webViewer.Core;
    const annots = annotationManager
      .getAnnotationsList()
      .filter((a) => a.getCustomData("type") === "MEASUREMENT");
    annots.forEach((annot) => {
      this.unlockAnnot({annotation: annot, redraw: true});
      annot.NoDelete = false;
    });
  }

  lockMeasurementAnnotations() {
    const {annotationManager} = this.webViewer.Core;
    const annots = annotationManager
      .getAnnotationsList()
      .filter((a) => a.getCustomData("type") === "MEASUREMENT");
    annots.forEach((annot) =>
      this.lockAnnot({annotation: annot, redraw: true})
    );
  }

  lockAnnot({annotation, annotationId, redraw = true}) {
    const {annotationManager} = this.webViewer.Core;

    let annot = annotation;
    if (!annot) annot = annotationManager.getAnnotationById(annotationId);

    if (!annot) return;

    annot.NoDelete = true;
    annot.NoMove = true;
    annot.NoResize = true;
    annot.ReadOnly = true;
    annot.RotationControlEnabled = false;

    if (redraw) annotationManager.redrawAnnotation(annot);
  }

  unlockAnnot({annotation, annotationId, redraw = true}) {
    const {annotationManager} = this.webViewer.Core;
    const m = this.selectedMeasurement;

    let annot = annotation;
    if (!annot) annot = annotationManager.getAnnotationById(annotationId);

    if (!annot) return;

    //annot.NoDelete = false;
    annot.NoMove = false;
    annot.NoResize = false;
    annot.ReadOnly = false;
    annot.RotationControlEnabled = true;

    if (m && m.dim1 && m.dim2 && m.drawingShape === "RECTANGLE")
      annot.NoResize = true;
    if (m && m.dim1 && m.drawingShape === "CIRCLE") {
      annot.NoResize = true;
      annot.RotationControlEnabled = false;
    }
    if (redraw) annotationManager.redrawAnnotation(annot);
  }

  // show & hide

  getShowedElementTypesByPage(pageNumber) {
    const {annotationManager} = this.webViewer.Core;
    let types = annotationManager
      .getAnnotationsList()
      .map((a) => ({
        id: a.Id,
        hidden: a.Hidden,
        elementType: a.getCustomData("element"),
        pageNumber: a.pageNumber,
      }))
      .filter((a) => !a.hidden && a.pageNumber === pageNumber)
      .map((a) => a.elementType);
    return [...new Set(types)];
  }

  applyFilters({
    measurementsModelIds,
    elementTypes,
    builtAts,
    builtAtMax,
    builtAtMin,
    todo,
    zones,
    phases,
    rooms,
    materials,
    sectors,
  }) {
    const {annotationManager} = this.webViewer.Core;

    this.hideAllMeasurementAnnotations();

    const annots = annotationManager.getAnnotationsList().filter((a) => {
      let date = a.getCustomData("builtAt");
      if (date === "null" || !date) date = null;
      const elementType = a.getCustomData("elementTypeId");
      const measurementsModelId = a.getCustomData("measurementsModelId");
      const zoneId = a.getCustomData("zoneId");

      let phaseId = a.getCustomData("phaseId");
      if (phaseId === "null" || !phaseId) phaseId = null;

      let sectorId = a.getCustomData("sectorId");
      if (sectorId === "null" || !sectorId) sectorId = null;

      let roomId = a.getCustomData("roomId");
      if (roomId === "null" || !roomId) roomId = null;

      let materialId = a.getCustomData("materialId");
      if (materialId === "null" || !materialId) materialId = null;

      const dateIn = isDateInScope({
        date,
        dateMin: builtAtMin,
        dateMax: builtAtMax,
        todo,
        builtAts,
      });

      const typeIn = elementTypes.includes(elementType);
      //const zoneIn = zones.length === 0 || zones.includes(zoneId);
      const zoneIn =
        zones.includes(zoneId) || (!zoneId && zones.includes(null));
      const sectorIn =
        sectors.includes(sectorId) || (!sectorId && sectors.includes(null));
      const roomIn =
        rooms.includes(roomId) || (!roomId && rooms.includes(null));
      const materialIn =
        materials.includes(materialId) ||
        (!materialId && materials.includes(null));
      const modelIn = measurementsModelIds.includes(measurementsModelId);
      const phaseIn = phases.includes(phaseId);

      return (
        typeIn &&
        dateIn &&
        modelIn &&
        zoneIn &&
        phaseIn &&
        sectorIn &&
        roomIn &&
        materialIn
      );
    });

    annotationManager.showAnnotations(annots);
  }

  applyFilterByMeasurementIds({measurementIds, pageNumber}) {
    this.hideAllMeasurementAnnotations();
    this.showMeasurementAnnotations(measurementIds, pageNumber);
  }

  showMeasurementAnnotations(annotationIds, pageNumber) {
    if (annotationIds) {
      const {annotationManager} = this.webViewer.Core;

      const annots = annotationManager
        .getAnnotationsList()
        .filter(
          (a) =>
            annotationIds.includes(a.Id) &&
            (!pageNumber || a.PageNumber === pageNumber)
        );

      annotationManager.showAnnotations(annots);
    }
  }

  toggleShowMeasurementAnnotationsByElementType({elementTypeId, show}) {
    const {annotationManager} = this.webViewer.Core;
    const annots = annotationManager.getAnnotationsList().filter((a) => {
      const annotationType = a.getCustomData("elementTypeId");
      return annotationType === elementTypeId;
    });
    if (show) {
      annotationManager.showAnnotations(annots);
    } else {
      annotationManager.hideAnnotations(annots);
    }
  }

  showOnlyMeasurementAnnotations(annotationIds) {
    if (annotationIds) {
      this.hideAllMeasurementAnnotations();
      this.showMeasurementAnnotations(annotationIds);
    }
  }

  hideMeasurementAnnotations(annotationIds) {
    if (annotationIds) {
      const {annotationManager} = this.webViewer.Core;

      const annots = annotationManager
        .getAnnotationsList()
        .filter((a) => annotationIds.includes(a.Id));

      annotationManager.hideAnnotations(annots);
    }
  }

  hideAllMeasurementAnnotations() {
    const {annotationManager} = this.webViewer.Core;
    console.log("[DEBUG67] hide all annotations");
    const annots = annotationManager
      .getAnnotationsList()
      .filter((a) => a.getCustomData("type") === "MEASUREMENT");

    annotationManager.hideAnnotations(annots);
  }

  showAllMeasurementAnnotations() {
    const {annotationManager} = this.webViewer.Core;

    const annots = annotationManager
      .getAnnotationsList()
      .filter((a) => a.getCustomData("type") === "MEASUREMENT");

    annotationManager.showAnnotations(annots);
  }

  // position

  bringToBack(measurementId) {
    const {annotationManager} = this.webViewer.Core;
    const annot = annotationManager
      .getAnnotationsList()
      .find((a) => a.Id === measurementId);
    annotationManager.bringToBack(annot);
  }

  bringToFront(measurementId) {
    const {annotationManager} = this.webViewer.Core;
    const annot = annotationManager
      .getAnnotationsList()
      .find((a) => a.Id === measurementId);
    annotationManager.bringToFront(annot);
  }

  // delete measurement

  deleteMeasurement(measurementId) {
    const {annotationManager} = this.webViewer.Core;
    const annot = annotationManager
      .getAnnotationsList()
      .find((a) => a.Id === measurementId);
    if (annot) {
      annot.NoDelete = false;
      this.unlockAnnot({annotation: annot});
      console.log("delete14");
      annotationManager.deleteAnnotation(annot);
      console.log("annotation deleted", measurementId);
    }
  }
  deleteMeasurements(measurementIds) {
    const {annotationManager} = this.webViewer.Core;
    const annots = annotationManager
      .getAnnotationsList()
      .filter((a) => measurementIds.includes(a.Id));
    if (annots.length > 0) {
      annots.forEach((annot) => {
        annot.NoDelete = false;
        this.unlockAnnot({annotation: annot});
      });
      annotationManager.deleteAnnotations(annots);
    }
  }

  // draw annotation from 3D

  drawPolygonAnnotationFrom3D({path, pageNumber}) {
    const annotColor = this.annotationColor;
    // const strokeThickness = Math.trunc((this.strokeM / this.mpd) * 10) / 10;
    const {annotationManager, Annotations} = this.webViewer.Core;
    const annot = new Annotations.PolygonAnnotation();
    annot.PageNumber = pageNumber;
    path.forEach((point, index) =>
      annot.setPathPoint(index, point[0], point[1])
    );
    annot.StrokeColor = annotColor;
    annot.StrokeThickness = 0.5;
    annot.FillColor = annotColor;
    annot.Opacity = 0.8;
    annot.setCustomData("type", "MEASUREMENT");

    annotationManager.addAnnotation(annot);

    annotationManager.redrawAnnotation(annot);
  }

  /*
   * 2D => 3D
   */

  updateMeasurement3D(measurement, options) {
    try {
      const doNotUpdateMeasData = options?.doNotUpdateMeasData;

      let {
        drawingProps,
        drawingShape,
        dim1,
        dim2,
        dim3,
        id,
        height,
        heightE,
        heightN,
        heights,
        slopeH,
        slopingA,
        relatedMId,
        zSup,
        zInf,
        offset,
        zFrom,
      } = measurement;

      // annotation
      const {annotationManager} = this.webViewer.Core;
      const annot = annotationManager.getAnnotationById(id); // TO FIX : when importing annotations from annotated pdf, the annotations are not added to the annotationManager..

      if (!annot) return;

      // zone
      const point = {x: annot.X, y: annot.Y, pageNumber: annot.PageNumber};
      const zone = this.annotationsManager.getPointBoundingZone(point);

      const mpd = zone ? zone.scale : this.mpd;

      if (heights) {
        const floatHeights = heights.split(";").map((f) => parseFloat(f));
        height = Math.max(...floatHeights);
      }
      // v by default, the measurement is created with height = 0 v
      if (!height && height !== 0) height = zone?.elementsH;
      if (!height && height !== 0) height = this.defaultHeight;

      if (!offset) offset = 0;

      if (!zFrom || zFrom === "offset") {
        if (zone?.zBasis === "ZSUP" && zone?.position) {
          zSup = zone?.position?.y + offset;
          zInf = zSup - height;
          offset = zInf - height;
        } else if (zone?.position) {
          zInf = zone?.position?.y + offset;
          zSup = zInf + height;
        } else {
          zInf = offset;
          zSup = zInf + height;
        }
      } else if (zFrom === "zInf") {
        zSup = zInf + height;
        offset = zInf;
        if (zone?.position) offset -= zone.position.y;
      } else {
        zInf = zSup - height;
        offset = zInf;
        if (zone?.position) offset -= zone.position.y;
      }

      const zoneId = zone?.id;

      // isNotHoriz

      const isNotHoriz = zone?.rotation?.x === 0;
      // dim1
      let _dim1 = dim1;
      if (!_dim1) {
        _dim1 = annot.StrokeThickness * mpd; // use this for rectangle drawingShape annotation that are transformed in to line
      }

      // pat3D et path3d3
      let {path3D, path3d3} = this.getPath3DFromDrawingProps(drawingProps, {
        drawingShape,
        dim1: _dim1,
        offset,
        mpd: zone?.scale,
        zone,
      });

      let path3d2;
      if (relatedMId) {
        const p = this.getPath3DFromPolygonAnnot(relatedMId);
        path3d2 = p.path3D;
      } else if (dim1 && dim2 && height && drawingShape === "BANK") {
        const path = annot.getPath().map((p) => ({x: p.x, y: p.y}));
        const width = ((dim1 / dim2) * height) / mpd;
        const exteriorPath = Polyline.getExteriorPolyline(path, width, true);
        const _path = this.getPath3DFromAnnotPath2D(
          exteriorPath,
          annot.PageNumber
        );
        path3d2 = path3D;
        path3D = _path;
      }

      if (drawingShape === "SLOPING_POLYGON")
        path3D = annot.getPath().map((p) => [p.x * mpd, p.y * mpd]);

      let quantities;
      if (drawingShape === "BANK") {
        quantities = this.getBankMeasurementQuantities({
          path3D,
          path3d2: path3D,
          height,
        });
      } else if (drawingShape === "SLOPING_POLYGON") {
        quantities = this.getSlopingPolygonMeasurementQuantities({
          path3D,
          slopingA,
          height,
        });
      } else if (drawingShape === "GABLE") {
        quantities = this.getGableMeasurementQuantities({
          path3D,
          heights,
          dim1,
        });
      } else if (drawingShape === "BRIDGE") {
        quantities = this.getBridgeMeasurementQuantities({
          path3D,
          height,
          dim1,
        });
        const mpd = zone ? zone.scale : this.mpd;
        quantities.length = this.getPathPerimeter(annot.getPath(), mpd);
      } else {
        quantities = this.getAnnotationQuantities(
          annot,
          "UPDATE",
          {
            drawingShape,
            height,
            heightE,
            heightN,
            slopeH,
            dim1,
            dim2,
            dim3,
          },
          zone
        );
      }

      annot.setCustomData("zoneId", zoneId);
      const updatedM = cleanMeasurementPaths({
        ...measurement,
        path3D,
        path3d3,
        height,
        zSup,
        zInf,
        offset,
        zoneId,
        isNotHoriz,
        ...quantities,
      });

      console.log(
        "paste2 updatedM",
        measurement,
        updatedM,
        doNotUpdateMeasData
      );
      if (!doNotUpdateMeasData) {
        this.caplaEditor?.measDataManager.updateMeasurements([updatedM]);
      }

      this.caplaEditor?.editor3d.sceneEditor.addMeasurement3D(updatedM);
      //
      return updatedM;
    } catch (e) {
      console.log("error", e);
    }
    // used when annotation is created out of a zone. First need to find the zone.
  }

  getMeasurementAutoGeo3D(drawingProps) {
    const path = drawingProps?.path;
    const pageNumber = drawingProps?.pageNumber;
    const p1 = path[0];
    const p2 = path[1];
    const P = {x: (p1[0] + p2[0]) / 2, y: (p1[1] + p2[1]) / 2};
    const point3D = this.annotationsManager.getBimboxPointFromZonePoint(
      undefined,
      {
        x: P.x,
        y: P.y,
        pageNumber,
      }
    );
    const {height, zInf, zSup} =
      this.caplaEditor?.editor3d.sceneEditor.getAutoGeo3D(point3D, -1.5);
    return {h: height, s: zSup, i: zInf};
  }

  getPath3DFromAnnotPath2D(path2D, pageNumber) {
    // path2D : [{x,y}]
    // used to compute bank path3d2
    const path3D = path2D.map((point) => {
      return this.annotationsManager.getBimboxPointFromZonePoint(undefined, {
        x: point.x,
        y: point.y,
        pageNumber,
      });
    });
    const _path3D = path3D
      .filter((p) => typeof p?.x === "number" && !isNaN(p?.x))
      .map((p) => [p?.x, p?.z]);

    return _path3D;
  }

  getPath3DFromPolygonAnnot(annotId) {
    const {annotationManager} = this.webViewer.Core;
    const annot = annotationManager.getAnnotationById(annotId);
    const path = annot.getPath().map((p) => [p.x, p.y]);
    const pageNumber = annot.PageNumber;

    let path3D = [];
    if (Array.isArray(path)) {
      path3D = path.map((point) => {
        return this.annotationsManager.getBimboxPointFromZonePoint(undefined, {
          x: point[0],
          y: point[1],
          pageNumber,
        });
      });
      const _path3D = path3D
        .filter((p) => typeof p?.x === "number" && !isNaN(p?.x))
        .map((p) => [p?.x, p?.z]);
      const _path3d3 = path3D
        .filter((p) => typeof p?.x === "number" && !isNaN(p?.x))
        .map((p) => [p?.x, p?.y, p?.z]);

      return {path3D: _path3D, path3d3: _path3d3};
    }
  }

  getPath3DFromDrawingProps(drawingProps, options) {
    // options
    const drawingShape = options?.drawingShape;
    const dim1 = options?.dim1;
    const offset = options?.offset;
    const mpd = options?.mpd;
    const zone = options?.zone;
    //
    const _path = drawingProps?.path;
    let path = [];
    if (_path) path = [..._path];

    // mpd

    if (!mpd) mpd = this.mpd;

    //

    if (
      [
        "SEGMENT",
        "BEAM",
        "POLYLINE",
        "RECTANGLE",
        "SLOPE",
        "STAIRS",
        "GABLE",
        "BRIDGE",
      ].includes(drawingShape)
    ) {
      const p = path.map((point) => ({x: point[0], y: point[1]}));
      const polygon = Polyline.getPolygonFromPolyline(p, dim1 / mpd);
      path = polygon.filter((p) => p).map((p) => [p.x, p.y]);
    }

    const pageNumber = drawingProps.pageNumber;

    let path3D = [];
    if (Array.isArray(path)) {
      path3D = path.map((point) => {
        return this.annotationsManager.getBimboxPointFromZonePoint(
          undefined,
          {
            x: point[0],
            y: point[1],
            pageNumber,
          },
          offset,
          zone
        );
      });
      const _path3D = path3D
        .filter((p) => typeof p?.x === "number" && !isNaN(p?.x))
        .map((p) => [p?.x, p?.z]);
      const _path3d3 = path3D
        .filter((p) => typeof p?.x === "number" && !isNaN(p?.x))
        .map((p) => [p?.x, p?.y, p?.z]);

      return {path3D: _path3D, path3d3: _path3d3};
    }
  }

  // highlightAnnotations(measurementIds) {
  //   measurementIds.forEach((id) => this.highlightAnnotation(id));
  // }

  highlightAnnotations(measurementIds) {
    const {annotationManager} = this.webViewer.Core;
    const flashC = this.colorToAnnotationColor(theme.palette.primary.flash);
    const originStrokeMap = {};
    const originFillMap = {};

    measurementIds.forEach((measurementId) => {
      const annotation = annotationManager
        .getAnnotationsList()
        .find((a) => a.Id === measurementId);

      if (annotation) {
        const originS = annotation.StrokeColor;
        const originF = annotation.FillColor;
        originStrokeMap[measurementId] = originS;
        originFillMap[measurementId] = originF;
        annotation.Opacity = 0.2;
        if (originS) annotation.StrokeColor = flashC;
        if (originF) annotation.FillColor = flashC;

        // annotationManager.redrawAnnotation(annotation);
      }
    });

    setTimeout(() => {
      measurementIds.forEach((measurementId) => {
        const annotation = annotationManager
          .getAnnotationsList()
          .find((a) => a.Id === measurementId);
        if (annotation) {
          annotation.Opacity = 0.8;
          const originS = originStrokeMap[measurementId];
          const originF = originFillMap[measurementId];
          if (originS) annotation.StrokeColor = originS;
          if (originF) annotation.FillColor = originF;
        }
      });
    }, 2000);
  }

  highlightAnnotation(measurementId) {
    const {annotationManager} = this.webViewer.Core;
    const annotation = annotationManager
      .getAnnotationsList()
      .find((a) => a.Id === measurementId);

    if (annotation) {
      const originS = annotation.StrokeColor;
      const originF = annotation.FillColor;

      const flashC = this.colorToAnnotationColor(theme.palette.primary.flash);

      annotation.Opacity = 0.2;
      if (originS) annotation.StrokeColor = flashC;
      if (originF) annotation.FillColor = flashC;

      annotationManager.redrawAnnotation(annotation);
      setTimeout(() => {
        annotation.Opacity = 0.8;
        annotationManager.redrawAnnotation(annotation);
        setTimeout(() => {
          annotation.Opacity = 0.2;
          annotationManager.redrawAnnotation(annotation);
          setTimeout(() => {
            annotation.Opacity = 0.8;
            annotationManager.redrawAnnotation(annotation);
            setTimeout(() => {
              annotation.Opacity = 0.2;
              annotationManager.redrawAnnotation(annotation);
              setTimeout(() => {
                annotation.Opacity = 0.8;
                if (originS) annotation.StrokeColor = originS;
                if (originF) annotation.FillColor = originF;
                annotationManager.redrawAnnotation(annotation);
              }, 300);
            }, 300);
          }, 300);
        }, 300);
      }, 300);
    }
  }

  extractMeasurements() {}
}
