import {useSelector} from "react-redux";
import useSelectedScope from "Features/scopes/hooks/useSelectedScope";
import getArticlesGroupWithUpdates from "../utils/getArticlesGroupWithUpdates";

export default function useArticlesGroups(sceneId, options) {
  // options

  const sortByName = options?.sortByName;
  const filterByScope = options?.filterByScope;

  // data
  const articlesGroupsMap = useSelector((s) => s.articles.articlesGroupsMap);

  const scope = useSelectedScope();

  let articlesGroups = Object.values(articlesGroupsMap)
    .filter((gr) => !sceneId || gr.sceneId === sceneId)
    .map(getArticlesGroupWithUpdates);

  if (sortByName) {
    articlesGroups = articlesGroups.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }

  if (filterByScope) {
    articlesGroups = articlesGroups.filter((group) => {
      let test = false;
      if (scope?.data?.articlesGroupsIds) {
        test = scope.data.articlesGroupsIds.includes(group.id);
      }
      return test;
    });
  }
  return articlesGroups;
}
