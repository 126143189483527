import {useState, useEffect} from "react";

import {useDispatch} from "react-redux";
import {updateSurvey} from "../surveysSlice";

import {
  Grid,
  Typography,
  TextField,
  InputBase,
  Box,
  Button,
} from "@mui/material";

import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

import {Event as DateIcon} from "@mui/icons-material";

import frLocale from "date-fns/locale/fr";
import useAccessToken from "Features/auth/useAccessToken";

export default function FormEditSurvey({scene, survey, onSurveyUpdated}) {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();
  // strings

  const saveS = "Mettre à jour";
  // state

  const [tempName, setTempName] = useState("");
  const [tempGmapUrl, setTempGmapUrl] = useState("");
  const [tempScheduledAt, setTempScheduledAt] = useState(null);

  const [loading, setLoading] = useState(false);

  let _surveyScheduledAt;
  if (survey?.scheduledAt) {
    _surveyScheduledAt = new Date(survey.scheduledAt);
    //_surveyScheduledAt = _surveyScheduledAt.toISOString();
  }
  console.log("_surveyScheduledAt", _surveyScheduledAt);

  useEffect(() => {
    if (survey) {
      setTempName(survey.name);
      if (survey.gmapLink) setTempGmapUrl(survey.gmapLink);
      if (survey.scheduledAt) {
        setTempScheduledAt(_surveyScheduledAt);
      }
    }
  }, [survey?.id]);
  // helpers

  function dateIsValid(date) {
    return !Number.isNaN(new Date(date).getTime());
  }

  // handlers

  function handleNameChange(e) {
    setTempName(e.target.value);
  }

  function handleGmapUrlChange(e) {
    setTempGmapUrl(e.target.value);
  }

  function handleScheduledAtChange(date) {
    setTempScheduledAt(date);
  }

  async function handleSave() {
    if (loading) return;
    setLoading(true);
    const updates = {};
    if (tempName) updates.name = tempName;
    if (tempGmapUrl != survey.gmapLink) updates.gmapLink = tempGmapUrl;
    if (tempScheduledAt) updates.scheduledAt = tempScheduledAt;
    await dispatch(
      updateSurvey({
        accessToken,
        surveyId: survey.id,
        sceneId: scene.id,
        updates,
      })
    );
    setLoading(false);
    onSurveyUpdated();
  }

  return (
    <Box>
      <Box sx={{display: "flex", flexDirection: "column"}}>
        <TextField
          size="small"
          value={tempName}
          onChange={handleNameChange}
          sx={{mb: 2}}
        />
        <TextField
          size="small"
          sx={{mb: 2}}
          value={tempGmapUrl}
          onChange={handleGmapUrlChange}
          placeholder="Gmap url"
        />

        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={frLocale}
          size="small"
        >
          <DatePicker
            value={tempScheduledAt}
            onChange={handleScheduledAtChange}
            components={{
              OpenPickerIcon: DateIcon,
            }}
            //format="DD-MM-YYYY"
            renderInput={({inputRef, inputProps, InputProps}) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  svg: {width: "16px", height: "16px"},
                  pr: 1,
                }}
              >
                <InputBase
                  ref={inputRef}
                  {...inputProps}
                  sx={{fontSize: 13, width: 1}}
                />
                {InputProps?.endAdornment}
              </Box>
            )}
          />
        </LocalizationProvider>
      </Box>
      <Box sx={{p: 1}}>
        <Button disabled={loading} onClick={handleSave}>
          {saveS}
        </Button>
      </Box>
    </Box>
  );
}
