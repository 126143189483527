import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Box, Typography} from "@mui/material";

import ListElementTypesMultipleWithGroups from "./ListElementTypesMultipleWithGroups";
import DialogCreateElementTypeGroup from "./DialogCreateElementTypeGroup";

import useElementTypesBySceneProxy from "../hooks/useElementTypesBySceneProxy";
import getElementTypesWithMainQties from "../utils/getElementTypesWithMainQties";
import getMeasurementsCountByElementTypeGroupIdMap from "../utils/getMeasurementsCountByElementTypeGroupIdMap";
import useElementTypesGroupsProxyBySceneWithElementTypes from "../hooks/useElementTypesGroupsProxyBySceneWithElementTypes";

import useFilteredMeasurementsFromPackages from "Features/measurements/useFilteredMeasurementsFromPackages";
import {
  setSelectedElementTypeId,
  setSelectedElementTypesGroupProxy,
} from "Features/elementTypes/elementTypesSlice";
import {setShortcutElementTypes} from "Features/measurements/measurementsSlice";

export default function SectionElementTypesInLeftPanel({scene, caplaEditor}) {
  const dispatch = useDispatch();

  if (caplaEditor) {
    caplaEditor.timeEnd = performance.now();
    console.log(
      "debugtime navtime",
      caplaEditor.timeEnd - caplaEditor.timeStart
    );
  }

  //

  // strings

  const createGroupS = "Créer un groupe";
  const noGroupS = "0 liste d'éléments / ouvrages";

  // data

  const os = useSelector((s) => s.ui.openSections);
  const pdfOnly = os.fixedViewersBox && !os.viewer3D;
  const filterNoVoids = useSelector((s) => s.measurements.filterNoVoids);
  let types = useElementTypesBySceneProxy(scene, {
    sortByNum: true,
    filterByScope: true,
  });
  const fms = useFilteredMeasurementsFromPackages({
    scene,
    filterPackagesByScope: true,
    filterPackagesByVersion: true,
    filterPackagesBySelectedPdf: pdfOnly,
  });
  const selectedElementTypeId = useSelector(
    (s) => s.elementTypes.selectedElementTypeId
  );
  const selectedGroupProxy = useSelector(
    (s) => s.elementTypes.selectedElementTypesGroupProxy
  );
  const groupsProxy = useElementTypesGroupsProxyBySceneWithElementTypes(scene, {
    sortByName: true,
    filterByScope: true,
  });
  const shortcutElementTypes = useSelector(
    (s) => s.measurements.shortcutElementTypes
  );

  // state

  const [openDialogCreate, setOpenDialogCreate] = useState(false);

  // helpers

  const groupCountMap = getMeasurementsCountByElementTypeGroupIdMap(fms, types);
  const typesWithMainQties = getElementTypesWithMainQties(
    types,
    fms,
    filterNoVoids
  );

  // helpers - no group

  const noGroup = groupsProxy.length === 0;

  //
  // handlers

  function handleSelectionChange(ids) {
    let id = null;
    if (Array.isArray(ids) && ids.length > 0) {
      id = ids[0];
    }
    dispatch(setSelectedElementTypeId(id));
  }

  function handleSelectedElementTypesGroupProxyChange(groupProxy) {
    dispatch(setSelectedElementTypesGroupProxy(groupProxy));
  }

  function handleCloseDialogCreate() {
    setOpenDialogCreate(false);
  }

  function handleOnCreation(groupProxy) {
    dispatch(setSelectedElementTypesGroupProxy(groupProxy));
  }

  function handleVisibleIdsChange(ids) {
    dispatch(setShortcutElementTypes(ids));
  }

  return (
    <>
      {/* <DialogCreateElementTypeGroup
        open={openDialogCreate}
        onClose={handleCloseDialogCreate}
        scene={scene}
        onCreate={handleOnCreation}
      /> */}
      <Box
        sx={{
          flexGrow: 1,
          //border: "2px solid red",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          minHeight: 0, // make it works !!!
        }}
      >
        {noGroup ? (
          <Box
            sx={{
              width: 1,
              display: "flex",
              height: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{color: "grey.400"}}>{noGroupS}</Typography>
          </Box>
        ) : (
          <ListElementTypesMultipleWithGroups
            elementTypes={typesWithMainQties}
            groupsProxy={groupsProxy}
            selectedElementTypeIds={[selectedElementTypeId]}
            onSelectionChange={handleSelectionChange}
            multipleSelection={false}
            selectedGroupId={selectedGroupProxy?.id}
            onSelectedGroupProxyChange={
              handleSelectedElementTypesGroupProxyChange
            }
            maxHeightGroups={180}
            counters={groupCountMap}
            showZero={true}
            forceSelection={true}
            variant="list"
            visibleIds={shortcutElementTypes}
            onVisibleIdsChange={handleVisibleIdsChange}
            showVisibility={false}
            showMainQuantities={true}
            // onCreateGroup={() => {
            //   setOpenDialogCreate(true);
            // }}
            // onCreateGroupLabel={createGroupS}
          />
        )}
      </Box>
    </>
  );
}
