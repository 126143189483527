import {useSelector} from "react-redux";

import {Box, lighten, Paper, Typography} from "@mui/material";

import ButtonFocusOnExtractedText from "Features/pdf/components/ButtonFocusOnExtractedText";

import SectionArticleTypesFormulasInViewer from "./SectionArticleTypesFormulasInViewer";
import ContainerArticleQties from "./ContainerArticleQties";

export default function SectionSelectedArticleInViewer({
  scene,
  article,
  caplaEditor,
}) {
  // data

  const articleQtyMap = useSelector((s) => s.articles.articleQtyMap);

  // helpers

  const showFocus = article?.mainPdfLink;

  // helper

  const qty = articleQtyMap[article?.id]?.qty;

  return (
    <Box sx={{p: 1, width: 1}}>
      <Box>
        <Box sx={{mb: 1}}>
          <Typography>{article?.name}</Typography>
        </Box>
        {showFocus && (
          <ButtonFocusOnExtractedText
            editorPdf={caplaEditor.editorPdf}
            pdfExtractedText={article?.mainPdfLink}
          />
        )}
        {article && <ContainerArticleQties article={article} qty={qty} />}
        {article?.comment && (
          <Box
            sx={{
              p: 1,
              mt: 1,
              bgcolor: (theme) => lighten(theme.palette.primary.main, 0.9),
            }}
          >
            {
              <Typography
                sx={{
                  fontSize: 13,
                  color: "primary.main",
                  whiteSpace: "pre-line",
                }}
              >
                {article?.comment}
              </Typography>
            }
          </Box>
        )}
        <SectionArticleTypesFormulasInViewer scene={scene} article={article} />
      </Box>
    </Box>
  );
}
