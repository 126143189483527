import getItemsMapById from "Utils/getItemsMapById";
import sortByNum from "Utils/sortByNum";

const applyStyle = (row, i, isTitle, rank, style, isGroup) => {
  if (
    (isTitle && style === undefined) ||
    ["title1", "title2"].includes(style)
  ) {
    row.getCell(i).font = {bold: true};
    if (rank === 1 || style === "title1")
      row.getCell(i).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {argb: "bbbbc0"},
      };
    if (rank === 2 || style === "title2")
      row.getCell(i).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {argb: "F4F4F8"},
      };
  }
  if (style === "detail") row.getCell(i).font = {color: {argb: "777777"}};

  if (isGroup) {
    row.getCell(i).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: {argb: "000000"},
    };
    row.getCell(i).font = {color: {argb: "FFFFFF"}};
  }
};

export default function fillWorksheetWithMeasurementsByTypes({
  worksheet,
  byType,
  rowStartIndex,
  buildingTypes,
  elementTypes,
}) {
  const byTypeById = getItemsMapById(byType);
  let exportedTypes = elementTypes.sort(
    (a, b) => a.group.localeCompare(b.group) || sortByNum(a.num, b.num)
  );
  const groups = [];
  const rowsWithGroups = [];

  //exportedTypes.forEach((row) => {
  byType.forEach((row) => {
    if (groups.includes(row.group)) {
      let newRow = {...row};
      const rowByType = byTypeById[row.id];
      if (rowByType) newRow = {...row, ...rowByType};
      rowsWithGroups.push(newRow);
    } else if (row.group) {
      groups.push(row.group);
      rowsWithGroups.push({id: row.group, name: row.group, isGroup: true});
      let newRow = {...row};
      const rowByType = byTypeById[row.id];
      if (rowByType) newRow = {...row, ...rowByType};
      rowsWithGroups.push(newRow);
    }
  });
  let buildingIdx = 8;
  let maxCol = 0;
  const totals = {};
  const bySectors = {};
  let sectorIdx = 0;
  let startIdx = buildingIdx;
  for (const building of Object.keys(buildingTypes).sort((a, b) =>
    a.localeCompare(b)
  )) {
    const total = [];
    worksheet.getRow(1).getCell(buildingIdx + sectorIdx).value = building;
    worksheet.getRow(1).getCell(buildingIdx + sectorIdx).font = {bold: true};
    for (const sector of Object.values(buildingTypes[building]).sort(
      (a, b) => a.z - b.z
    )) {
      worksheet.getRow(2).getCell(buildingIdx + sectorIdx).value = sector.name;
      total.push(buildingIdx + sectorIdx);
      Object.entries(sector).forEach(([k, v]) => {
        if (!["name", "z"].includes(k)) {
          if (buildingIdx + sectorIdx > maxCol)
            maxCol = buildingIdx + sectorIdx;
          if (k in bySectors) {
            bySectors[k][buildingIdx + sectorIdx] = v;
          } else {
            bySectors[k] = {[buildingIdx + sectorIdx]: v};
          }
        }
      });
      sectorIdx += 1;
    }
    worksheet.getRow(2).getCell(buildingIdx + sectorIdx).value = "Total";
    worksheet.getRow(2).getCell(buildingIdx + sectorIdx).font = {bold: true};
    totals[buildingIdx + sectorIdx] = total;
    worksheet.mergeCells(1, startIdx, 1, buildingIdx + sectorIdx);
    buildingIdx += 1;
    startIdx = buildingIdx + sectorIdx;
  }
  const areTotals = Object.keys(totals);
  rowsWithGroups.forEach(
    (
      {
        isGroup,
        group,
        count,
        num,
        mainQuantity,
        unit,
        name,
        rank,
        style,
        isTitle,
        description,
      },
      index
    ) => {
      const rowIdx = rowStartIndex + index;
      const row = worksheet.getRow(rowIdx);
      if (isGroup) {
        row.getCell(1).value = name;
        row.getCell(1).font = {bold: true};
        //worksheet.mergeCells(rowIdx, 0, rowIdx, 5);
        //row.height = 20;
        for (let i = 1; i <= maxCol + 1; i++) {
          applyStyle(row, i, isTitle, rank, style, isGroup);
        }
      } else {
        //row.getCell(1).value = num;
        row.getCell(2).value = name;
        row.getCell(3).value = description;
        row.getCell(4).value = count;
        row.getCell(5).value = mainQuantity;
        row.getCell(6).value = unit;
        const byBuildings = bySectors[group + num];
        if (byBuildings) {
          Object.entries(totals).forEach(([k, v]) => {
            const w = v
              .map((x) => (byBuildings[x] ? byBuildings[x] : 0))
              .reduce((a, b) => a + b);
            if (w > 0) {
              byBuildings[k] = w;
            }
          });
        }
        if (byBuildings)
          for (const [i, v] of Object.entries(byBuildings)) {
            if (v) {
              const value = Math.round(v * 100) / 100;
              row.getCell(parseInt(i)).value = value;
              if (areTotals.includes(i.toString()))
                row.getCell(parseInt(i)).font = {bold: true};
              if (value < 0)
                row.getCell(parseInt(i)).fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: {argb: "FF0000"},
                };
            }
          }
        for (let i = 1; i <= maxCol + 1; i++) {
          applyStyle(row, i, isTitle, rank, style, isGroup);
        }
      }
    }
  );
}
