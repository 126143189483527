import {createSlice} from "@reduxjs/toolkit";

export const pdfSlice = createSlice({
  name: "pdf",
  initialState: {
    //
    measurementsShowedInViewerPdf: [],
    measurementsShowedInViewerPdfWithoutShortcut: [],
    //
    show3D: false,
    pdfViewerIsLoaded: false,
    showScreenPdfViewerHelper: false,
    pdfViewerMode: "DEFAULT", // MEASUREMENTS, ISSUES
    addingMeasurementMode: false, // true when the user clicked on "+ new Element"
    panOrSelect: "PAN", // tool used in the pdf editor.
    pdfEditorIsLoadingDoc: false, // to prevent annotation creation when loading.
    hideAnnotations: false, // hide document annotations
    pdfModelIdLoaded: null, // updated by the pdf loader
    storedModelsFetchingStatus: [], // {modelId,status:"idle","fetching","fetched"}
    openDialogLoadBimData: false, // true when new pdf model with type "BIM_DATA" = true
    hasBimData: false, // current pdfModel has bim data (triggered when loading the PDF)
    dialogLoadBimDataScope: [], // ids of models for which we should open the dialog if it has bim data
    extractedText: {}, // {text,quads,extractedAt}, extractedAt : used to update dialog.
    isEditing: false,
    editedPdfModel: {},
    showPdfColors: true,
    modelIdsWithAnnotationsLoaded: [], // use to know if the annotations are loaded (extract external annotations)
    tempPdfModelId: null, // used to visualize temp file when selecting it before creation.
    clickedPointerPosition: {x: 0, y: 0}, // pointer position when clicked in pdf viewer.
    openSearchPanel: false,
    useOptimized: true,
    jump: null,
    showPdfSelector: false,
    //
    // difference
    //
    selectedPdfModelIdOld: null,
    showSectionDifference: false,
    urlLoadedInInstance2: null,
    //
    pdfViewerResetAt: null,
  },
  reducers: {
    resetPdfViewer: (state) => {
      state.pdfViewerResetAt = Date.now();
    },
    setMeasurementsShowedInViewerPdf: (state, action) => {
      state.measurementsShowedInViewerPdf = action.payload;
    },
    setMeasurementsShowedInViewerPdfWithoutShortcut: (state, action) => {
      state.measurementsShowedInViewerPdfWithoutShortcut = action.payload;
    },
    setShowScreenPdfViewerHelper: (state, action) => {
      state.showScreenPdfViewerHelper = action.payload;
    },
    setPdfViewerIsLoaded: (state, action) => {
      state.pdfViewerIsLoaded = action.payload;
    },
    setShowPdfColors: (state, action) => {
      state.showPdfColors = action.payload;
    },
    setShow3D: (state, action) => {
      state.show3D = action.payload;
    },
    setPdfViewerMode: (state, action) => {
      state.pdfViewerMode = action.payload;
    },
    setPanOrSelect: (state, action) => {
      state.panOrSelect = action.payload;
    },
    setPdfEditorIsLoadingDoc: (state, action) => {
      state.pdfEditorIsLoadingDoc = action.payload;
    },
    setPdfModelIdLoaded: (state, action) => {
      state.pdfModelIdLoaded = action.payload;
    },
    setAddingMeasurementMode: (state, action) => {
      state.addingMeasurementMode = action.payload;
    },
    setHideAnnotations: (state, action) => {
      state.hideAnnotations = action.payload;
    },
    updateStoredModelFetchingStatus: (state, action) => {
      const status = action.payload;
      const ns = state.storedModelsFetchingStatus.filter(
        (s) => s.modelId !== status.modelId
      );
      ns.push(status);
      state.storedModelsFetchingStatus = ns;
    },
    // dialogLoadBimData
    setHasBimData: (state, action) => {
      state.hasBimData = action.payload;
    },
    addToDialogLoadBimDataScope: (state, action) => {
      state.dialogLoadBimDataScope.push(action.payload);
    },
    removeFromDialogLoadBimDataScope: (state, action) => {
      state.dialogLoadBimDataScope = state.dialogLoadBimDataScope.filter(
        (id) => id !== action.payload
      );
    },
    setExtractedText: (state, action) => {
      state.extractedText = action.payload;
    },
    // edition
    setIsEditing: (state, action) => {
      state.isEditing = action.payload;
    },
    setEditedPdfModel: (state, action) => {
      state.editedPdfModel = action.payload;
    },
    //
    annotationsLoaded: (state, action) => {
      const modelId = action.payload;
      const ids = state.modelIdsWithAnnotationsLoaded;
      if (!ids.includes(modelId))
        state.modelIdsWithAnnotationsLoaded.push(modelId);
    },
    //
    setTempPdfModelId: (state, action) => {
      state.tempPdfModelId = action.payload;
    },
    // ui
    setClickedPointerPosition: (state, action) => {
      state.clickedPointerPosition = action.payload;
    },
    setOpenSearchPanel: (state, action) => {
      state.openSearchPanel = action.payload;
    },
    setUseOptimized: (state, action) => {
      state.useOptimized = action.payload;
    },
    setJump: (state, action) => {
      state.jump = action.payload;
    },
    setShowPdfSelector: (state, action) => {
      state.showPdfSelector = action.payload;
    },
    //
    setSelectedPdfModelIdOld: (state, action) => {
      state.selectedPdfModelIdOld = action.payload;
    },
    setOldPdfAnnotationOpacity: (state, action) => {
      state.setOldPdfAnnotationOpacity = action.payload;
    },
    setShowSectionDifference: (state, action) => {
      state.showSectionDifference = action.payload;
    },
    setUrlLoadedInInstance2: (state, action) => {
      state.urlLoadedInInstance2 = action.payload;
    },
  },
});

export const {
  setMeasurementsShowedInViewerPdf,
  setMeasurementsShowedInViewerPdfWithoutShortcut,
  setPdfViewerIsLoaded,
  setShowScreenPdfViewerHelper,
  setShowPdfColors,
  setShow3D,
  setPdfViewerMode,
  setPanOrSelect,
  setPdfEditorIsLoadingDoc,
  setAddingMeasurementMode,
  setHideAnnotations,
  setPdfModelIdLoaded,
  updateStoredModelFetchingStatus,
  addToDialogLoadBimDataScope,
  removeFromDialogLoadBimDataScope,
  setHasBimData,
  setExtractedText,
  setIsEditing,
  setEditedPdfModel,
  annotationsLoaded,
  setTempPdfModelId,
  setClickedPointerPosition,
  setOpenSearchPanel,
  setUseOptimized,
  setJump,
  setShowPdfSelector,
  //
  setShowSectionDifference,
  setSelectedPdfModelIdOld,
  setOldPdfAnnotationOpacity,
  setUrlLoadedInInstance2,
  //
  resetPdfViewer,
} = pdfSlice.actions;

export default pdfSlice.reducer;
