import {useSelector, useDispatch} from "react-redux";

import {Box, Button, alpha} from "@mui/material";
import {Edit, Save} from "@mui/icons-material";

import {setIsEditingMaterial, setEditedMaterial} from "../materialsSlice";
import useSelectedMaterial from "../hooks/useSelectedMaterial";
import useUpdateMaterial from "../hooks/useUpdateMaterial";

export default function ActionsMaterialInSelectionPanel({scene}) {
  const dispatch = useDispatch();
  // strings

  const saveS = "Save";
  const editS = "Edit";
  const cancelS = "Cancel";

  // data

  const isEditing = useSelector((s) => s.materials.isEditingMaterial);
  const selectedMaterial = useSelectedMaterial(scene);
  const editedMaterial = useSelector((s) => s.materials.editedMaterial);

  const updateMaterial = useUpdateMaterial();

  // handlers

  function handleEditClick() {
    dispatch(setIsEditingMaterial(true));
    dispatch(setEditedMaterial({...selectedMaterial}));
  }

  function handleCancelClick() {
    dispatch(setIsEditingMaterial(false));
  }

  async function handleSaveClick() {
    // materials
    const updatedMaterial = {...editedMaterial};
    updateMaterial(updatedMaterial);
    dispatch(setIsEditingMaterial(false));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      {!isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Edit />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleEditClick}
          >
            {editS}
          </Button>
        </Box>
      )}

      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button variant="outlined" fullWidth onClick={handleCancelClick}>
            {cancelS}
          </Button>
        </Box>
      )}

      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Save />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSaveClick}
          >
            {saveS}
          </Button>
        </Box>
      )}
    </Box>
  );
}
