import useSceneModule from "Features/navigation/useSceneModule";
import useSceneModuleNameMap from "Features/navigation/useSceneModuleNameMap";

import {Box, Typography} from "@mui/material";

export default function ContainerModeSelector({mode, onModeChange}) {
  // data

  const sceneModule = useSceneModule();
  const sceneModuleName = useSceneModuleNameMap()[sceneModule];

  const modes = [
    {code: "SCENE_MODULE", label: sceneModuleName},
    {code: "SELECTION", label: "Sélection"},
  ];

  return (
    <Box
      sx={{
        width: 1,
        bgcolor: "common.caplaBlack",
        display: "flex",
        alignItems: "center",
        color: "grey.500",
        p: 1,
        justifyContent: "space-around",
        height: "36px",
      }}
    >
      {modes.map((m) => {
        const selected = m.code === mode;

        return (
          <Typography
            key={m.code}
            variant="body2"
            sx={{
              cursor: "pointer",
              ...(selected && {color: "common.white", fontWeight: "bold"}),
            }}
            onClick={() => onModeChange(m.code)}
          >
            {m.label}
          </Typography>
        );
      })}
    </Box>
  );
}
