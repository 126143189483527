import {useSelector} from "react-redux";
import articlesWithStyleToArticlesWithTempNum from "../utils/articlesWithStyleToArticlesWithTempNum";

export default function useArticlesByGroup(scene, articlesGroup) {
  // data

  const articlesMap = useSelector((s) => s.articles.articlesMap);

  const subArticlesMap = useSelector((s) => s.articles.subArticlesMap);

  const sortedArticlesProxyByGroupId = useSelector(
    (s) => s.articles.sortedArticlesProxyByGroupId
  );
  const sortedArticlesProxy = sortedArticlesProxyByGroupId[articlesGroup?.id];

  // const groups = useElementTypesGroupsProxyBySceneWithElementTypes(scene, {
  //   sortByName: true,
  //   filterByScope: true,
  //   filterByTypesGroupsIds: articlesGroup?.typesGroupsIds,
  //   sortElementTypesByNum: true,
  // }); // used to get nomenclature subitems.

  // data transfo

  const articles = Object.values(articlesMap).filter(
    (article) => article.articlesGroupId === articlesGroup?.id
  );

  const articlesByClientId = articles.reduce((acc, article) => {
    acc[article.clientId] = article;
    return acc;
  }, {});

  // result
  let result = [];
  //
  let sortedArticlesClientIds = articlesGroup?.sortedArticles;
  if (sortedArticlesProxy?.length > 0)
    sortedArticlesClientIds = sortedArticlesProxy;
  //
  if (!Array.isArray(sortedArticlesClientIds)) {
    result = articles;
  } else {
    sortedArticlesClientIds.forEach((clientId) => {
      const article = articlesByClientId[clientId];

      if (article) {
        //
        const updates = article?.updates ?? {};
        const updatedArticleTemp = {...article, ...updates};
        //
        result.push(updatedArticleTemp);
      }
      if (article?.isNomenclature) {
        const subArticles = subArticlesMap[article.id];
        if (subArticles) {
          const subArticlesArray = Object.values(subArticles).sort(
            (a, b) => a.index - b.index
          );
          result.push(...subArticlesArray);
        }
        // use that to keep the last updated item.
        //dispatch(updateLastUpdatedAt()); // to trigger qty computation.
      }
    });
  }
  const articlesWithTempNum = articlesWithStyleToArticlesWithTempNum(result);

  return articlesWithTempNum;
}
