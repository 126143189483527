import React from "react";

import {useSelector} from "react-redux";

import {Box, Typography} from "@mui/material";

import ImageScene from "Features/scenes/components/ImageScene";
import useSceneModule from "../useSceneModule";

export default function CardSceneInMenu({scene, showImage, hideTitle, height}) {
  return (
    <Box sx={{width: 1}}>
      {showImage ? (
        <Box sx={{width: 1, height, position: "relative"}}>
          <ImageScene scene={scene} />
          {!hideTitle && (
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                width: 1,
                p: 1,
                background: (theme) =>
                  `linear-gradient(to bottom,rgba(255, 255, 255, 0) 0%,${theme.palette.common.caplaBlack}) 100%`,
              }}
            >
              <Typography variant="body2" sx={{fontWeight: "bold"}}>
                {scene?.title}
              </Typography>
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={{height: 0}} />
      )}
    </Box>
  );
}
