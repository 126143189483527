import theme from "Styles/theme";
import Konva from "konva";
import getCoordsFromMarkerNode from "./getCoordsFromMarkerNode";

export default function createMarkerNode({
  marker,
  stage,
  onClick,
  onMarkerChange,
}) {
  // marker

  const {id, x, y, label, variant} = marker;

  // stage

  const stageScale = stage.scaleX();
  const stageX = stage.x();
  const stageY = stage.y();

  // validation

  if (!id || !label) return;

  // variant

  const variantMap = {
    DEFAULT: {
      color: theme.palette.secondary.main,
      fontSize: 12,
    },
    CLUSTER: {
      color: theme.palette.primary.main,
      fontSize: 12,
    },
  };

  const triangleRadiusCore = 5;
  const paddingCore = 5;

  // props

  const isDefault = variant === "DEFAULT";
  const color = variantMap[variant]?.color ?? variantMap.DEFAULT.color;
  const fontSizeCore =
    variantMap[variant]?.fontSize ?? variantMap.DEFAULT.fontSize;

  // text node

  const textNode = new Konva.Text({
    text: label,
    x,
    y,
    fontSize: fontSizeCore / stageScale,
    fill: "white",
  });

  textNode.setAttr("fontSizeCore", fontSizeCore);

  const textWidth = textNode.width();
  const textHeight = textNode.height();

  // rectangle node

  const backgroundNode = new Konva.Rect({
    x: textNode.x() - paddingCore / stageScale, // Offset by a margin
    y: textNode.y() - paddingCore / stageScale,
    width: textWidth + (2 * paddingCore) / stageScale, // Add some padding
    height: textHeight + (2 * paddingCore) / stageScale,
    fill: color,
  });

  const bgHeight = backgroundNode.height();
  const bgWidth = backgroundNode.width();

  // triangle node

  const triangleRadius = triangleRadiusCore / stageScale;
  const triangleHeight = (3 / 2) * triangleRadius;

  const triangleNode = new Konva.RegularPolygon({
    x: x,
    y: y,
    sides: 3,
    radius: triangleRadius,
    fill: color,
    offsetY: -triangleRadius,
    rotation: 180,
  });

  // Label group (text + background)

  const labelGroupNode = new Konva.Group();
  //
  labelGroupNode.add(backgroundNode);
  labelGroupNode.add(textNode);

  const offsetX = textWidth / 2;
  const offsetY = isDefault
    ? textHeight + paddingCore / stageScale + triangleHeight
    : textHeight;

  labelGroupNode.offset({x: offsetX, y: offsetY});

  // Group

  const groupNode = new Konva.Group({id, draggable: isDefault});
  groupNode.add(labelGroupNode);
  if (isDefault) groupNode.add(triangleNode);

  // ON CREATE

  const bbox = {
    x: x - (textWidth / 2) * stageScale,
    y:
      y -
      textHeight * stageScale -
      2 * paddingCore -
      (isDefault ? (3 / 2) * triangleRadiusCore : 0),
    width: textWidth * stageScale + 2 * paddingCore,
    height: textHeight * stageScale + 2 * paddingCore,
  };

  const newMarker = {
    ...marker,
    bbox,
  };

  onMarkerChange(newMarker);

  // LISTENERS

  groupNode.on("tap click", () => {
    //this.mapEditor.dispatch(setselectedRoomId(room.clientId));
    if (onClick) onClick(marker);
  });

  groupNode.on("dragend", () => {
    const {x, y} = getCoordsFromMarkerNode(groupNode, stage);
    const newMarker = {...marker, x, y};
    if (onMarkerChange) onMarkerChange(newMarker);
  });

  // RETURN

  return groupNode;
  //return labelGroupNode;
}
