import {useSelector, useDispatch} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {Box, Button, alpha} from "@mui/material";
import {Add} from "@mui/icons-material";

import {setSelectedMaterialClientId, setNewMaterial} from "../materialsSlice";
import useCreateMaterial from "../hooks/useCreateMaterial";

export default function ActionsNewMaterialInSelectionPanel({scene}) {
  const dispatch = useDispatch();

  // strings

  const createS = "Ajouter";

  // data

  const newMaterial = useSelector((s) => s.materials.newMaterial);
  const addMaterial = useCreateMaterial(scene);

  // handlers

  async function handleCreateClick() {
    const newMat = {clientId: nanoid(), ...newMaterial};
    addMaterial(newMat);
    dispatch(setSelectedMaterialClientId(newMat.clientId));
    dispatch(setNewMaterial({}));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      <Box sx={{flexGrow: 1}}>
        <Button
          startIcon={<Add />}
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleCreateClick}
        >
          {createS}
        </Button>
      </Box>
    </Box>
  );
}
