import remoteToStateArticle from "./remoteToStateArticle";

export default function remoteToStateArticlesGroup(articlesGroup) {
  const stateArticlesGroup = {...articlesGroup};
  //
  stateArticlesGroup.sceneId = stateArticlesGroup.scene;
  if (stateArticlesGroup.scene) delete stateArticlesGroup.scene;
  //
  if (
    stateArticlesGroup.articles?.length > 0 &&
    stateArticlesGroup.articles[0].id
  ) {
    stateArticlesGroup.articles = stateArticlesGroup.articles.map((article) => {
      return remoteToStateArticle(article);
    });
  }
  //
  return stateArticlesGroup;
}
