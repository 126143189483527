import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {generateApiKey} from "../organisationsSlice";

import {Paper, Box, Typography, Button, IconButton} from "@mui/material";

import {Save, ContentCopy as Copy} from "@mui/icons-material";

import useAccessToken from "Features/auth/useAccessToken";
import {fetchScenesServicePublic} from "Features/scenes/servicesPublic";

export default function ContainerEditableApiKey({organisation}) {
  //
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  // strings

  const labelS = "Clé API";
  const testS = "Tester";
  const generateS = "Générer";

  // state

  const initApiKey = organisation?.apiKey ?? "";

  const [tempApiKey, setTempApiKey] = useState(initApiKey);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTempApiKey(initApiKey);
    setLoading(false);
  }, [organisation?.id]);

  // helpers

  const showGenerate = tempApiKey.length === 0;
  const organisationId = organisation?.id;

  // handler

  async function handleGenerate() {
    if (loading) return;
    setLoading(true);
    await dispatch(
      generateApiKey({
        accessToken,
        organisationId,
      })
    );
    setLoading(false);
  }

  function handleCopyClick() {
    navigator.clipboard.writeText(tempApiKey);
  }

  async function handleTestClick() {
    setLoading(true);
    await fetchScenesServicePublic({apiKey: tempApiKey});
    setLoading(false);
  }

  return (
    <Box sx={{px: 2, width: 1}}>
      <Paper sx={{p: 1, width: 1}}>
        <Typography variant="body2" sx={{mb: 1}}>
          {labelS}
        </Typography>
        <Box
          sx={{
            my: 1,
            p: 1,
            bgcolor: "background.default",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography noWrap sx={{fontSize: 13}}>
            {tempApiKey}
          </Typography>
          <IconButton size="small" onClick={handleCopyClick}>
            <Copy fontSize="small" />
          </IconButton>
        </Box>

        <Box sx={{display: "flex", justifyContent: "end", mt: 1}}>
          <Box></Box>
          <Button disabled={loading || !showGenerate} onClick={handleGenerate}>
            {generateS}
          </Button>
          <Button disabled={loading} onClick={handleTestClick}>
            {testS}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
